import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDocumentTitle from 'react-document-title';
import { getBrandDomainSettings } from 'utils/brands';

const DocumentTitle = ({ children, title }) => {
  const { label, favicon } = getBrandDomainSettings();

  useEffect(() => {
    const changeFavicon = (newFaviconUrl) => {
      const favicon =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link');
      favicon.type = 'image/x-icon';
      favicon.rel = 'shortcut icon';
      favicon.href = newFaviconUrl;

      // Check if a favicon link already exists, and replace it if it does
      const existingFavicon = document.querySelector("link[rel*='icon']");
      if (existingFavicon) {
        document.head.removeChild(existingFavicon);
      }

      document.head.appendChild(favicon);
    };

    changeFavicon(favicon);
  }, [title]);

  return (
    <ReactDocumentTitle title={`${title} - ${label}`}>
      {/* ReactDocumentTitle doesn't accept multiple children fragment is here to bypass */}
      <>{children}</>
    </ReactDocumentTitle>
  );
};

DocumentTitle.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default DocumentTitle;
