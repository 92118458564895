import React, {
  useState,
  useCallback,
  useMemo,
  SyntheticEvent,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { ValueType } from 'react-select';
import { Button, SelectInput } from '@candidco/enamel';

import {
  Maybe,
  PatientRejectionReason,
  RejectionReason,
  SubmissionPhotoRejectionReasonType,
} from 'generated/legacy/graphql';

import {
  PhotoApprovalState,
  PhotoReasonsType,
  PatientReasonsType,
} from 'pages/OrthoPrism/types';
import { selectIsInClarificationStep } from 'pages/OrthoPrism/orthoSlice';

import RejectionChecklist from 'pages/OrthoPrism/RejectionChecklist';

import FormRadioGroup from 'pages/OrthoPrism/Photos/PhotoReviewForm/FormRadioGroup';
import * as S from 'pages/OrthoPrism/Photos/PhotoReviewForm/PhotoReviewForm.css';

import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';
import NeedsClarificationForm from 'pages/OrthoPrism/NeedsClarificationForm';

type Option = {
  label: string;
  value: string;
};

type PRRProps = {
  reasons: PatientRejectionReason[];
  selected: RejectionReason[];
  selectedCategory?: string;
  onToggle: (r: RejectionReason, addReason: boolean) => void;
  onChangeRejectionCategory: (r: RejectionReason) => void;
};

const PatientRejectionReasons = ({
  reasons,
  selected,
  selectedCategory = '',
  onToggle,
  onChangeRejectionCategory,
}: PRRProps) => {
  const categoryReasons = useMemo(
    () => reasons.find(({ name }) => name === selectedCategory)?.reasons,
    [reasons, selectedCategory]
  );

  const SubcategoryChecklists = categoryReasons && (
    <S.ReasonOptions>
      <p>Select all that apply</p>
      <S.MainReasons>
        {categoryReasons.map(({ name, label, reasons }) => (
          <li key={name}>
            <S.SmallHeading>{label}</S.SmallHeading>
            <RejectionChecklist
              reasons={reasons ?? []}
              selected={selected}
              onToggle={onToggle}
            />
          </li>
        ))}
      </S.MainReasons>
    </S.ReasonOptions>
  );

  const options = reasons.map(({ name, label }) => ({ value: name, label }));
  const value = options.find((o) => o.value === selectedCategory);

  const onSelectCategory = (option: ValueType<Option>) => {
    const { label, value } = option as Option;
    onChangeRejectionCategory({ name: value, label });
  };

  return (
    <>
      <S.FormRow data-testid="OrthoPrism-Photo-RejectionCategory">
        <SelectInput
          options={options}
          onChange={onSelectCategory}
          value={value}
          testId="OrthoPrism-Photo-RejectionCategoryDropdown"
        />
      </S.FormRow>
      {SubcategoryChecklists}
    </>
  );
};

export type OnSubmitPhotoReviewArgs = {
  isApproved: boolean;
  rejectionReasons?: RejectionReason[];
  rejectedEvaluationIds?: string[];
  rejectionNotes?: string;
};

type PhotoRejectionReason = Pick<
  SubmissionPhotoRejectionReasonType,
  'id' | 'name' | 'label'
>;

type PhotoReasonsMap = Record<string, RejectionReason>;

type Props = {
  approval: PhotoApprovalState;
  hasRejectedPhotos: boolean;
  isVisible: boolean;
  isSubmitting: boolean;
  onChange: () => void;
  onClickReviewPhotos: () => void;
  onSubmit: (args: OnSubmitPhotoReviewArgs) => void;
  patientRejectionReasons: PatientRejectionReason[];
  photoRejectionReasons: PhotoRejectionReason[];
  setApproval: React.Dispatch<React.SetStateAction<PhotoApprovalState>>;
  showNotification: (
    message: string,
    variant?: 'error' | 'info' | 'success' | undefined
  ) => void;
  submissionDate: string;
  isWhitening?: boolean;
  refreshMaterials: (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean
  ) => void;
};

const approvalDefault = {
  quality: true,
  formatting: true, // Deprecated for Pro Cases, but the d2c-shared PhotoApprovalState type requires this to remain
  customer: true, // Deprecated for Pro Cases, but the d2c-shared PhotoApprovalState type requires this to remain
};

const PhotoReviewForm = ({
  approval,
  hasRejectedPhotos,
  isVisible,
  isSubmitting = false,
  onChange,
  onClickReviewPhotos,
  onSubmit,
  patientRejectionReasons: inboundPatientReasons,
  photoRejectionReasons,
  setApproval,
  showNotification,
  isWhitening,
  refreshMaterials,
}: Props) => {
  const isApproved = approval.quality && approval.customer;

  const [selectedRejectionReasons, setSelectedRejectionReasons] = useState<
    RejectionReason[]
  >([]);
  const [clarificationChecked, setClarificationChecked] =
    useState<boolean>(false);
  const isInClarificationStep = useSelector(selectIsInClarificationStep);
  const { checkHasAccess } = useContext(AuthContext);
  const showClarificationOption =
    checkHasAccess(ACCESS_GROUPS.MATERIAL_REVIEW) && !isInClarificationStep;

  const patientRejectionReasons = useMemo(
    () =>
      isWhitening
        ? inboundPatientReasons
        : inboundPatientReasons.filter(
            (r) => r.name !== PatientReasonsType.WhiteningOnly
          ),
    [isWhitening]
  );

  const onToggleRejectionReason = (
    reason: RejectionReason,
    addReason: boolean
  ) => {
    if (addReason) {
      setSelectedRejectionReasons((selected) => [...selected, reason]);
    } else {
      setSelectedRejectionReasons((selected) =>
        selected.filter(({ name }) => name !== reason.name)
      );
    }
  };

  const [rejectionCategory, setRejectionCategory] =
    useState<Maybe<RejectionReason>>(null);

  const onChangeRejectionCategory = useCallback(
    (reason: RejectionReason) => {
      setRejectionCategory(reason);
      setSelectedRejectionReasons([]);
    },
    [setRejectionCategory, setSelectedRejectionReasons]
  );

  const [notes, setNotes] = useState<string>('');

  const hasRejectionReasons = !!(
    selectedRejectionReasons.length && rejectionCategory
  );

  const { [PhotoReasonsType.Quality]: photoQualityReason } = useMemo(
    () =>
      photoRejectionReasons.reduce((acc: PhotoReasonsMap, { name, label }) => {
        acc[name] = { name, label };
        return acc;
      }, {}),
    [photoRejectionReasons]
  );

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isApproved && hasRejectedPhotos) {
      showNotification(
        'You cannot approve a submission with rejected photos',
        'error'
      );
      return;
    }

    let rejectionReasons: RejectionReason[] = [];
    let isApprovedForWhiteningOnly = false;
    if (!approval.customer && rejectionCategory) {
      rejectionReasons = [rejectionCategory, ...selectedRejectionReasons];

      isApprovedForWhiteningOnly =
        rejectionCategory.name === PatientReasonsType.WhiteningOnly;
    }

    if (!approval.quality) {
      rejectionReasons = [photoQualityReason];
    }

    onSubmit({
      isApproved: isApprovedForWhiteningOnly || isApproved,
      rejectionReasons,
      rejectionNotes: notes,
    });
  };

  const canSubmit = useMemo(() => {
    if (isApproved) {
      return true;
    }

    if (!approval.customer) {
      return !!(hasRejectionReasons && notes.length);
    }

    if (!approval.quality) {
      return hasRejectedPhotos && notes.length;
    }

    return false;
  }, [notes, approval, isApproved, hasRejectionReasons, hasRejectedPhotos]);

  const ClarificationRow = (
    <FormRadioGroup
      label="Do you need clarification?"
      indicator={clarificationChecked}
      onChange={onChange}
      onYes={() => setClarificationChecked(true)}
      onNo={() => setClarificationChecked(false)}
      dataTestId="OrthoPrism-Photo-ClarificationRadio"
    />
  );

  const PhotoQualityRow = (
    <FormRadioGroup
      label="Is the photo quality acceptable?"
      indicator={approval.quality}
      onChange={onChange}
      onYes={() => setApproval((a) => ({ ...a, quality: true }))}
      onNo={() => setApproval(() => ({ ...approvalDefault, quality: false }))}
      dataTestId="OrthoPrism-Photo-QualityRadio"
    />
  );

  const showReviewLink = !approval.quality;
  const PhotoReviewLink = showReviewLink && (
    <S.ReviewLink
      onClick={onClickReviewPhotos}
      type="button"
      data-testid="OrthoPrism-Photo-ReviewPhotos"
    >
      Review photos
    </S.ReviewLink>
  );

  const RejectionReasons = !approval.customer && (
    <PatientRejectionReasons
      reasons={patientRejectionReasons}
      selected={selectedRejectionReasons}
      selectedCategory={rejectionCategory?.name}
      onToggle={onToggleRejectionReason}
      onChangeRejectionCategory={onChangeRejectionCategory}
    />
  );

  const Notes = (!approval.quality || !approval.customer) && (
    <S.FormRow isStacked>
      <S.SmallHeading>Notes (required)</S.SmallHeading>
      <S.NoteText>Your notes will be sent directly to the provider</S.NoteText>
      <S.TextArea
        maxLength={5000}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Notes to the provider"
        value={notes}
        data-testid="OrthoPrism-Photo-Notes"
      />
    </S.FormRow>
  );

  return (
    <S.FormContainer isVisible={isVisible}>
      {showClarificationOption ? ClarificationRow : null}
      {clarificationChecked && showClarificationOption ? (
        <NeedsClarificationForm onSubmit={refreshMaterials} />
      ) : (
        <>
          {PhotoQualityRow}
          {PhotoReviewLink}
          {RejectionReasons}
          {Notes}
          <S.SubmitButtonContainer>
            <Button
              isShort
              buttonType="secondary"
              type="submit"
              onClick={handleSubmit}
              disabled={!canSubmit || isSubmitting}
              isLoading={isSubmitting}
              data-testid="OrthoPrism-Photo-Submit"
            >
              Submit
            </Button>
          </S.SubmitButtonContainer>
        </>
      )}
    </S.FormContainer>
  );
};

export default PhotoReviewForm;
