import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Button } from '@candidco/enamel';

import { PRISM_AGGREGATE_STATES } from 'constants/index';
import { arrayContainer, itiArrayContainer, arrayItem } from 'styles/array.css';
import { evaluationIndicator, baseimgThumbStyles } from 'styles/imageThumb.css';

import extraoral_smile from 'assets/photoTypes/extraoral_smile.svg?react';
import extraoral_no_smile from 'assets/photoTypes/extraoral_no_smile.svg?react';
import extraoral_right_profile_no_smile from 'assets/photoTypes/extraoral_right_profile_no_smile.svg?react';
import intraoral_frontal_view from 'assets/photoTypes/intraoral_frontal_view.svg?react';
import intraoral_right_lateral_view from 'assets/photoTypes/intraoral_right_lateral_view.svg?react';
import intraoral_left_lateral_view from 'assets/photoTypes/intraoral_left_lateral_view.svg?react';
import intraoral_upper_occlusal from 'assets/photoTypes/intraoral_upper_occlusal.svg?react';
import intraoral_lower_occlusal from 'assets/photoTypes/intraoral_lower_occlusal.svg?react';

import intraoral_frontal_view_w_aligners from 'assets/photoTypes/intraoral_frontal_view_w_aligners.svg?react';
import intraoral_right_lateral_view_w_aligners from 'assets/photoTypes/intraoral_right_lateral_view_w_aligners.svg?react';
import intraoral_left_lateral_view_w_aligners from 'assets/photoTypes/intraoral_left_lateral_view_w_aligners.svg?react';
import intraoral_upper_occlusal_w_aligners from 'assets/photoTypes/intraoral_upper_occlusal_w_aligners.svg?react';
import intraoral_lower_occlusal_w_aligners from 'assets/photoTypes/intraoral_lower_occlusal_w_aligners.svg?react';

const aboSVGs: { [key: string]: string } = {
  extraoral_smile,
  extraoral_no_smile,
  extraoral_right_profile_no_smile,
  intraoral_frontal_view,
  intraoral_right_lateral_view,
  intraoral_left_lateral_view,
  intraoral_upper_occlusal,
  intraoral_lower_occlusal,

  intraoral_frontal_view_w_aligners,
  intraoral_lower_occlusal_w_aligners,
  intraoral_right_lateral_view_w_aligners,
  intraoral_left_lateral_view_w_aligners,
  intraoral_upper_occlusal_w_aligners,
};

export const Container = styled.div`
  width: 17.5rem;
  padding: 2.5rem 1.25rem;
  background: ${({ theme }) => theme.colors.black05};
`;

export const Header = styled.header`
  margin-bottom: 2rem;
`;

export const PatientTitle = styled.h3`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.fonts.modernEraMono};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

export const PatientName = styled.h4`
  ${({ theme }) => theme.text.headings.h4};
  margin-bottom: 0;
`;

export const PhotoReviewType = styled.h4`
  ${({ theme }) => theme.text.headings.h4};
  margin-bottom: 1.25rem;
`;

export const StateBadge = styled.div<{ state: string }>`
  display: inline-block;
  padding: 0.125rem 0.875rem 0;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.heading};
  vertical-align: middle;
  border: 1px solid currentColor;
  border-radius: 0.5rem;

  ${({ state, theme }) =>
    state === PRISM_AGGREGATE_STATES.EVALUATION &&
    css`
      color: ${theme.colors.yellow90};
    `}

  ${({ state, theme }) =>
    state === PRISM_AGGREGATE_STATES.SUBMISSION &&
    css`
      color: ${theme.colors.green70};
    `}

  ${({ state, theme }) =>
    state === PRISM_AGGREGATE_STATES.COMPLETED &&
    css`
      color: ${theme.colors.white};
      background: ${theme.colors.green70};
      border-color: ${theme.colors.green70};
    `}

  ${({ state, theme }) =>
    state === PRISM_AGGREGATE_STATES.NOT_TREATABLE &&
    css`
      color: ${theme.colors.white};
      background: ${theme.colors.red70};
      border-color: ${theme.colors.red70};
    `}
`;

export const ArrayContainer = styled.div<{ iti?: boolean }>`
  ${({ iti }) => (iti ? itiArrayContainer : arrayContainer)};
  grid-gap: 1.125rem;
  height: ${({ iti }) => (iti ? '10rem' : '15rem')};
  margin-bottom: 2rem;
`;

export const ArrayItem = styled.button<{
  isApproved: boolean;
  isRejected: boolean;
  isSelected: boolean;
  photo: string;
  title: string;
  key: string;
  view: string;
}>`
  ${arrayItem};
  ${evaluationIndicator};
  background: ${({ view, theme }) =>
    `${theme.colors.black10} url(${aboSVGs[view]}) no-repeat center`};
  ${baseimgThumbStyles};
`;

export const ActionButton = styled(Button)`
  padding-right: 1rem;
  padding-left: 1rem;

  & + & {
    margin-top: 0.75rem;
  }
`;

export const OrthoPrismButton = styled(ActionButton)`
  padding-right: 0.75rem;
  padding-left: 0.75rem;
`;

export const ConfirmationText = styled.div`
  margin-top: 1rem;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: center;
`;
