import moment from 'moment';
import * as Sentry from '@sentry/react';

import { useIsOnlyCandidPro } from 'hooks/useIsOnlyCandidPro';

import { TreatmentPlanStaging } from 'generated/core/graphql';
import { getAuthToken, getRefreshToken } from 'utils/auth';
import { Nullable } from 'utils/types';

export const hidePlanPermissionConditions: { (): boolean }[] = [
  (): boolean => useIsOnlyCandidPro(),
];

// Open Vision Doctor-Facing-Application (DFA)
// This is a slimmed-down version of the Vision TP Software,
//   to allow Doctors to make minor changes to plans.
export const openVisionSoftware = async (
  customerRef: string,
  tpId: Nullable<string | number>
) => {
  try {
    window.open(
      `vision2://launch treatmentPlanId=${customerRef} sessionId=${getAuthToken()}:${getRefreshToken()}` +
        (tpId !== null && tpId !== undefined
          ? ` treatmentPlanVersionId=${tpId}`
          : '')
    );
  } catch (error) {
    const humanReadableError =
      "Error authenticating, so we can't open the Treatment Planning software. Please submit a bug report.";
    alert(humanReadableError);
    Sentry.captureException(error);
    console.error(error);
  }
};

export const getTreatmentPlanStagingViewerUrl = (
  plan: TreatmentPlanStaging
) => {
  return `${window.location.origin.toString()}/softsmile-viewer?treatment_plan_staging_id=${
    plan.id
  }`;
};

export const toCreatedString = (created: string | null): string => {
  if (!created) {
    return '—';
  }

  return moment(created).format('MMM. D, YYYY');
};
