import React, { useContext, useEffect, useRef, useState } from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { Loading, NotificationContext } from '@candidco/enamel';
import { capitalizeWords } from 'utils/string';

import { coreClient } from 'state/GraphQLProvider';
import DocumentTitle from 'components/DocumentTitle';
import useConfirmChanges from 'hooks/useConfirmChanges';
import { PageSection } from 'styles/layout.css';
import { useLazyMutation } from 'utils/lazyQuery';
import { renderRichTextAsHtml } from 'utils/RichText';

import {
  AnswerType,
  EditableFormQuestion,
  QuestionFormikProps,
} from 'pages/IntakeForms/types';
import { IntakeFormsContext } from 'pages/IntakeForms/IntakeFormsContext';
import QuestionModal from 'pages/IntakeForms/QuestionModal';
import {
  AddQuestionButton,
  BackLink,
  ConditionText,
  DragTable,
  DragTableBody,
  DragTableCell,
  DragTableHeader,
  DragTableHeaderRow,
  DragTableRow,
  DragToggle,
  DragToggleCell,
  HeaderButton,
  HeaderHeading,
  Heading,
  SectionContainer,
  SectionHeader,
  SectionHeaderContent,
} from 'pages/IntakeForms/IntakeForms.css';

import {
  FormSectionInput,
  UpsertForm,
  UpsertFormMutationVariables,
  UpsertFormDocument,
  ConsentTypes,
  IntakeTypes,
  QuestionTypes,
} from 'generated/core/graphql';

type MatchParams = {
  formName: string;
  sectionIdx: string;
  formType: string;
};

const getBooleanFromAnswerTypeCondition = (condition: string) => {
  if (condition === AnswerType.YES) {
    return true;
  }

  if (condition === AnswerType.NO) {
    return false;
  }

  return null;
};

const formatQuestionFormData = (
  data: QuestionFormikProps,
  position: number,
  originalQuestionKey: string
): EditableFormQuestion => {
  const questionType = data['question-type'];
  const hasCondition =
    !!data['answer-type-condition'] || !!data['consent-type-condition'];
  const hasNonStandardCondition =
    data['consent-type-condition'] === ConsentTypes.NonStandard;
  const hasCNTCondition =
    data['consent-type-condition'] === ConsentTypes.CouldNotTreat;
  const conditionBoolean = getBooleanFromAnswerTypeCondition(
    data['answer-type-condition']
  );
  const isExplanationRequired = data['require-explanation'];

  return {
    explanationLabel:
      hasCondition && isExplanationRequired ? data['explanation-label'] : null,
    isCouldNotTreatExplanation: hasCNTCondition
      ? data['cnt-reason-text']
      : null,
    isCouldNotTreatFor: hasCNTCondition ? conditionBoolean : null,
    isNonStandardFor: hasNonStandardCondition ? conditionBoolean : null,
    needExplanationFor: isExplanationRequired ? conditionBoolean : null,
    question: data['question-text'],
    questionType: questionType,
    questionKey: data['question-key'],
    originalQuestionKey: originalQuestionKey,
    tooltip: data['question-tooltip'],
    isFollowUpQuestion: data['is-follow-up-question'],
    isRequired: data['is-required'],
    position,
    hasDefaultPreferenceOption: data['has-default-preference-option'],
    meta: {
      dateFormat: data['date-format'],
      choices: data['choices'],
    },
  };
};

const getConditionText = (value?: boolean | null) => {
  switch (value) {
    case true:
      return (
        <ConditionText>
          If <b>Yes</b>
        </ConditionText>
      );
    case false:
      return (
        <ConditionText>
          If <b>No</b>
        </ConditionText>
      );
    default:
      return '–';
  }
};

const getMaterialType = (materialType: string) => {
  // TODO: If we continue this pattern of using enums where we need an accessor like this
  // make this more generic and move to utils
  const [, materialTypeEnum] =
    Object.entries(IntakeTypes).find(([, val]) => val === materialType) || [];
  return materialTypeEnum;
};

const IntakeFormsSection = ({ match }: RouteComponentProps<MatchParams>) => {
  const { formsData, refreshData } = useContext(IntakeFormsContext);
  const { showNotification } = useContext(NotificationContext);
  const { formName, sectionIdx, formType } = match.params;
  const isFormBeta = formType === 'beta';
  const form = formsData?.find(
    (form) => form?.materialType.name === formName && form.beta === isFormBeta
  );
  const sectionData = form?.formSchema[parseInt(sectionIdx)];
  const { label, questions = [] } = sectionData ?? {};
  const [rows, setRows] = useState<EditableFormQuestion[]>(
    questions as EditableFormQuestion[]
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [dragOrigin, setDragOrigin] = useState(0);
  const [dragPosition, setDragPosition] = useState(0);
  const [draggableRow, setDraggableRow] = useState(0);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [questionModalData, setQuestionModalData] =
    useState<EditableFormQuestion | null>(null);
  const containerEl = useRef<HTMLDivElement>(null);

  useConfirmChanges(hasChanges);

  const upsertForm = useLazyMutation<UpsertForm, UpsertFormMutationVariables>(
    UpsertFormDocument,
    coreClient
  );

  useEffect(() => {
    if (questions!.length) {
      const editableQuestions = questions.map((q) => {
        return { ...q, originalQuestionKey: q.questionKey };
      });
      setRows(editableQuestions as EditableFormQuestion[]);
    }
  }, [questions]);

  const handleDragStart = (e: React.DragEvent, position: number) => {
    const dataTransfer = e.dataTransfer ?? {
      setData: () => {},
    };
    dataTransfer.setData('position', String(position));
    setDragOrigin(position);
  };

  const handleDragEnter = (position: number) => {
    setDragPosition(position);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDragEnd = () => {
    setDragOrigin(0);
    setDragPosition(0);
  };

  const handleDrop = (e: React.DragEvent, position: number) => {
    const dataTransfer = e.dataTransfer ?? {
      getData: () => {},
    };
    const oldPosition = Number(dataTransfer.getData('position'));
    setRows((prevRows) => {
      const rowsCopy = [...prevRows];
      rowsCopy.splice(position - 1, 0, rowsCopy.splice(oldPosition - 1, 1)[0]);
      const newRows = rowsCopy.map((rowData, i) => ({
        ...rowData,
        position: i + 1,
      }));

      return newRows;
    });
    setHasChanges(true);
    handleDragEnd();
  };

  const handleAddQuestion = () => {
    const defaultQuestion = {
      position: rows.length + 1,
      question: '',
      questionType: QuestionTypes.Boolean,
      questionKey: '',
      originalQuestionKey: '',
    };
    setQuestionModalData(defaultQuestion);
    setIsQuestionModalOpen(true);
  };

  const handleEditQuestion = (
    e: React.MouseEvent,
    questionData: EditableFormQuestion
  ) => {
    const elementClicked = e.target as HTMLElement;

    if (elementClicked.dataset?.type === 'toggle') {
      return;
    }

    setQuestionModalData(questionData);
    setIsQuestionModalOpen(true);
  };

  const handleCloseQuestionModal = () => {
    setIsQuestionModalOpen(false);
    setQuestionModalData(null);
    containerEl.current?.focus();
  };

  const handleSaveQuestion = async (
    data: QuestionFormikProps,
    position: number
  ) => {
    setRows((prevRows) => {
      const newRows = prevRows.map((row) =>
        row.position === position
          ? formatQuestionFormData(data, position, row.originalQuestionKey!)
          : row
      );
      if (prevRows.length < position) {
        newRows.push(formatQuestionFormData(data, position, ''));
      }
      return newRows;
    });
    setHasChanges(true);
  };

  const handleDeleteQuestion = async (position: number) => {
    setRows((prevRows) =>
      prevRows
        .filter((rowData) => rowData.position !== position)
        .map((rowData, i) => ({
          ...rowData,
          position: i + 1,
        }))
    );
    setHasChanges(true);
  };

  const handleSaveForm = (saveAsBeta: boolean) => async () => {
    try {
      setIsPublishing(true);
      // we have to update the section in particular since we
      // save all the sections as one form
      form!.formSchema[parseInt(sectionIdx)].questions = rows.map(
        ({ originalQuestionKey, ...other }) => other
      );

      await upsertForm({
        form: {
          materialType: getMaterialType(form!.materialType.name)!,
          formSchema: form!.formSchema as FormSectionInput[],
          beta: saveAsBeta,
        },
      });
      await refreshData(true);
      showNotification('Form published', 'success');
      setIsPublishing(false);
      setHasChanges(false);
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
      setIsPublishing(false);
    }
  };

  const secondarySaveButtonText = form?.beta
    ? 'Promote Beta questions to Standard form (GO LIVE)'
    : 'Copy questions to Beta form';
  const secondarySaveButtonSaveAsBeta = !form?.beta;

  return (
    <DocumentTitle
      title={!sectionData ? 'Loading…' : `${label} - Intake forms`}
    >
      {!sectionData ? (
        <Loading isCentered />
      ) : (
        <SectionContainer ref={containerEl} tabIndex={-1}>
          <SectionHeader>
            <SectionHeaderContent>
              <BackLink to={`/${match.url.split('/')[1]}`}>Back</BackLink>
              <HeaderHeading>Editing</HeaderHeading>
              <AddQuestionButton
                disabled={isPublishing}
                isShort
                onClick={handleAddQuestion}
                data-testid="add-question"
              >
                Question
              </AddQuestionButton>
              <HeaderButton
                buttonType="secondary"
                disabled={!hasChanges || isPublishing}
                isLoading={isPublishing}
                isShort
                onClick={handleSaveForm(!!form?.beta)}
              >
                Save / Launch Form
              </HeaderButton>
              <HeaderButton
                buttonType="primary"
                disabled={!hasChanges || isPublishing}
                isLoading={isPublishing}
                isShort
                onClick={handleSaveForm(secondarySaveButtonSaveAsBeta)}
              >
                {secondarySaveButtonText}
              </HeaderButton>
            </SectionHeaderContent>
          </SectionHeader>
          <PageSection>
            <Heading>
              {label} {capitalizeWords(formType)}
            </Heading>
            <DragTable>
              <DragTableHeader>
                <DragTableHeaderRow>
                  <DragTableCell>Question</DragTableCell>
                  <DragTableCell>Tooltip</DragTableCell>
                  <DragTableCell width="7rem">CNT</DragTableCell>
                  <DragTableCell width="7rem">Non-standard</DragTableCell>
                  <DragTableCell width="7rem">
                    Requires explanation
                  </DragTableCell>
                  <DragTableCell width="25%">Explanation label</DragTableCell>
                  <DragTableCell />
                </DragTableHeaderRow>
              </DragTableHeader>
              <DragTableBody isDisabled={isPublishing}>
                {rows.map((questionData) => {
                  const {
                    explanationLabel,
                    isCouldNotTreatFor,
                    isNonStandardFor,
                    needExplanationFor,
                    position,
                    question,
                    tooltip,
                  } = questionData;

                  return (
                    <DragTableRow
                      draggable={draggableRow === position}
                      isDraggedAbove={
                        dragPosition === position && dragOrigin > position
                      }
                      isDraggedBelow={
                        dragPosition === position && dragOrigin < position
                      }
                      onClick={(e: React.MouseEvent) =>
                        handleEditQuestion(e, questionData)
                      }
                      onDragEnd={handleDragEnd}
                      onDragEnter={() => handleDragEnter(position!)}
                      onDragOver={handleDragOver}
                      onDragStart={(e: React.DragEvent) =>
                        handleDragStart(e, position!)
                      }
                      onDrop={(e: React.DragEvent) => handleDrop(e, position!)}
                      key={position!}
                    >
                      <DragTableCell>
                        {renderRichTextAsHtml(question!)}
                      </DragTableCell>
                      <DragTableCell>{tooltip || '–'}</DragTableCell>
                      <DragTableCell>
                        {getConditionText(isCouldNotTreatFor)}
                      </DragTableCell>
                      <DragTableCell>
                        {getConditionText(isNonStandardFor)}
                      </DragTableCell>
                      <DragTableCell>
                        {getConditionText(needExplanationFor)}
                      </DragTableCell>
                      <DragTableCell>{explanationLabel || '–'}</DragTableCell>
                      <DragToggleCell>
                        <DragToggle
                          data-type="toggle"
                          onMouseEnter={() => setDraggableRow(position!)}
                          onMouseLeave={() => setDraggableRow(0)}
                        />
                      </DragToggleCell>
                    </DragTableRow>
                  );
                })}
              </DragTableBody>
            </DragTable>
            <QuestionModal
              data={questionModalData}
              isOpen={isQuestionModalOpen}
              onSave={handleSaveQuestion}
              onClose={handleCloseQuestionModal}
              onDelete={handleDeleteQuestion}
            />
          </PageSection>
          <Prompt
            message="Changes you made may not be saved."
            when={hasChanges}
          />
        </SectionContainer>
      )}
    </DocumentTitle>
  );
};

export default IntakeFormsSection;
