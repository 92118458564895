import React, { useEffect, useMemo, useState, useContext } from 'react';
import {
  Switch,
  Route,
  Prompt,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import { formatPhoneNumber } from 'utils/customer';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { NotificationContext, Loading } from '@candidco/enamel';

import { useIsLoading } from 'state/system';

import useConfirmChanges from 'hooks/useConfirmChanges';
import DocumentTitle from 'components/DocumentTitle';
import PatientHeaderLite from 'components/PatientHeader/PatientHeaderLite';
import { PageSection } from 'styles/layout.css';
import NeedsClarificationAlert from 'pages/OrthoPrism/NeedsClarificationAlert';

import EditPatientModal from 'components/EditPatientModal/EditPatientModal';
import OrderShippingModal from 'components/Modals/OrderShippingModal';
import EditClinicianModal from 'pages/OrthoPrism/EditClinicianModal';
import {
  fetchCases,
  fetchCustomer,
  fetchIntakeForms,
  fetchPlanRejectionReasons,
  fetchMaterialEvaluationRejectionReasonsMap,
  fetchPrismAggregates,
  fetchScans,
  fetchXrays,
  resetState,
  selectCustomer,
  selectCustomerId,
  selectFullName,
  selectHasChanges,
  selectLatestCase,
  selectActiveCase,
  selectSelectedCase,
  selectShortName,
  setSelectedCase,
  fetchTreatmentPlanStagings,
  selectHomeAddress,
} from 'pages/OrthoPrism/orthoSlice';
import { AddressType } from 'generated/legacy/graphql';
import Notifications from 'pages/OrthoPrism/Notifications';
import CaseSelectionNav from 'pages/OrthoPrism/CaseSelectionNav';
import CaseSummary from 'pages/OrthoPrism/CaseSummary';
import OrthoTabs from 'pages/OrthoPrism/OrthoTabs';
import D2CWarningBanner from 'components/D2CWarningBanner/D2CWarningBanner';

type RouteParams = {
  caseRef: string;
  id: string;
};

const Container = styled(PageSection)`
  padding-top: 0;
`;

type Props = {
  /**
     An optional path prefix to be used for embedding the
     OrthoPrismView inside other components while preserving route-based
     navigation
   */
  prefix?: string;
  isQc?: boolean;
};

const OrthoPrismView = ({ prefix = '', isQc = false }: Props) => {
  const { caseRef, id } = useParams<RouteParams>();
  const history = useHistory();
  const location = useLocation();
  const { showNotification } = useContext(NotificationContext);
  const dispatch = useDispatch();
  const isFetchingCustomerInfo = useIsLoading(fetchCustomer.type);
  const isFetchingCases = useIsLoading(fetchCases.typePrefix);
  const hasChanges = useSelector(selectHasChanges);
  const customerInfo = useSelector(selectCustomer);
  const customerId = useSelector(selectCustomerId);
  const shortName = useSelector(selectShortName);
  const fullName = useSelector(selectFullName);
  const selectedCase = useSelector(selectSelectedCase);
  const activeCase = useSelector(selectActiveCase);
  const latestCase = useSelector(selectLatestCase);
  const homeAddress = useSelector(selectHomeAddress);
  const isLoading = isFetchingCustomerInfo || isFetchingCases;

  const routeName = isQc ? 'tp-quality-control' : 'ortho-prism';

  const baseUrl = `${prefix}/${routeName}`;
  const casesRoute = `${baseUrl}/${id}/cases`;
  const [isEditPatientModalOpen, setIsEditPatientModalOpen] = useState(false);
  const [isOrderShippingModalOpen, setIsOrderShippingModalOpen] =
    useState(false);
  const [isEditClinicianModalOpen, setIsEditClinicianModalOpen] =
    useState(false);

  const customerAddress:
    | Pick<
        AddressType,
        | 'id'
        | 'addressLine1'
        | 'addressLine2'
        | 'businessName'
        | 'city'
        | 'zip'
        | 'stateCode'
        | 'countryCode'
      >
    | undefined = useMemo(
    () =>
      customerInfo?.addresses.filter(
        (a) => a.addressType === 'Shipping Address'
      )[0],
    [customerInfo]
  );
  const {
    preferredName: guardianPreferredName,
    firstName: guardianFirstName,
    middleName: guardianMiddleName,
    lastName: guardianLastName,
    phone: guardianPhone,
    birthday: guardianBirthday,
  } = customerInfo?.legalGuardian ?? {};
  const originalPatientInfo = {
    birthday: customerInfo?.birthday ?? '',
    phone: formatPhoneNumber(customerInfo?.phone).formatedPhoneNumber,
    email: customerInfo?.user?.email ?? '',
    addressLine1: customerAddress?.addressLine1 ?? '',
    addressLine2: customerAddress?.addressLine2 ?? '',
    businessName: customerAddress?.businessName ?? '',
    city: customerAddress?.city ?? '',
    stateCode: customerAddress?.stateCode ?? '',
    countryCode: customerAddress?.countryCode ?? '',
    zip: customerAddress?.zip ?? '',
    guardian_preferredName: guardianPreferredName ?? '',
    guardian_firstName: guardianFirstName ?? '',
    guardian_middleName: guardianMiddleName ?? '',
    guardian_lastName: guardianLastName ?? '',
    guardian_phone: guardianPhone ?? '',
    guardian_birthday: guardianBirthday ?? '',
    treatingProvider: customerInfo?.referringDentist ?? {},
    candidClinician: customerInfo?.doctor ?? {},
  };

  const handleCancelEditPatient = () => {
    setIsEditPatientModalOpen(false);
  };

  const handleEditPatientSuccess = () => {
    showNotification('Successfully updated patient info', 'success');
    setIsEditPatientModalOpen(false);
    dispatch(fetchCustomer({ customerId: id }));
  };

  const handleOpenEditPatient = () => {
    setIsEditPatientModalOpen(true);
  };

  const handleOpenEditClinicianModal = () => {
    setIsEditClinicianModalOpen(true);
  };

  const handleEditClinicianSuccess = () => {
    showNotification('Successfully updated clinician', 'success');
    setIsEditClinicianModalOpen(false);
    dispatch(fetchCustomer({ customerId: id }));
  };

  useConfirmChanges(hasChanges);

  useEffect(() => {
    dispatch(fetchCustomer({ customerId: id }));
    dispatch(fetchCases({ patientIds: [Number(id)] }));
    dispatch(fetchPlanRejectionReasons());
    dispatch(fetchMaterialEvaluationRejectionReasonsMap({}));

    return () => {
      // Reset state when leaving the page
      dispatch(resetState());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!caseRef) {
      return;
    }
    dispatch(fetchIntakeForms({ caseRef }));
    dispatch(fetchPrismAggregates({ caseRef }));
    dispatch(fetchXrays({ caseRef }));
    dispatch(fetchScans({ caseRef }));
    dispatch(fetchTreatmentPlanStagings({ caseRef }));
  }, [caseRef]);

  useEffect(() => {
    if (customerInfo) {
      if (caseRef) {
        dispatch(setSelectedCase(caseRef));
        return;
      }

      const initialCase = activeCase ?? latestCase;
      if (initialCase) {
        history.replace(`${casesRoute}/${initialCase.caseRef}`);
      }
    }
  }, [
    caseRef,
    location.pathname,
    casesRoute,
    activeCase,
    latestCase,
    customerInfo,
  ]);
  if (isLoading || !customerInfo) {
    return (
      <DocumentTitle title="Loading…">
        <Loading isCentered />
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title={`${shortName} - Ortho review`}>
      <Notifications />
      {customerInfo && !customerInfo.referringDentist && <D2CWarningBanner />}
      <PatientHeaderLite
        id={customerId}
        fullName={fullName}
        preferredName={customerInfo?.preferredName}
        extraPatientInfo={originalPatientInfo}
        practice={customerInfo?.practice}
        handleOpenEditPatientModal={handleOpenEditPatient}
        handleOpenEditClinicianModal={handleOpenEditClinicianModal}
      />
      <EditPatientModal
        isOpen={isEditPatientModalOpen}
        onClose={handleCancelEditPatient}
        onSuccess={handleEditPatientSuccess}
        patientData={customerInfo}
        shippingAddress={homeAddress}
      />
      <OrderShippingModal
        isOpen={isOrderShippingModalOpen}
        onClose={() => {
          setIsOrderShippingModalOpen(false);
        }}
        caseRef={selectedCase?.caseRef}
        patientName={fullName}
        internal={true}
      />
      <EditClinicianModal
        isOpen={isEditClinicianModalOpen}
        onClose={() => {
          setIsEditClinicianModalOpen(false);
        }}
        onSuccess={handleEditClinicianSuccess}
        clinician={customerInfo?.doctor?.fullName}
        customerId={customerId}
      />
      <Container as="div">
        <Switch>
          <Route path={casesRoute}>
            <CaseSelectionNav basePath={casesRoute} />
            <CaseSummary
              setIsOrderShippingModalOpen={() =>
                setIsOrderShippingModalOpen(true)
              }
            />
            <NeedsClarificationAlert />
            <OrthoTabs isQc={isQc} />
          </Route>
        </Switch>
      </Container>
      <Prompt message="Changes you made may not be saved." when={hasChanges} />
    </DocumentTitle>
  );
};

export default OrthoPrismView;
