import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { Button } from '@candidco/enamel';

import * as propTypes from 'utils/propTypes';
import { PageHeading, PageSection } from 'styles/layout.css';
import DocumentTitle from 'components/DocumentTitle';

class GlobalErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static propTypes = {
    children: propTypes.children.isRequired,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
      // Separately record custom message for tracking frequency of page-breaking JS errors
      Sentry.captureException('Global Error Boundary triggered');
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <DocumentTitle title="App error">
          <PageSection>
            <PageHeading>The app has encountered an error</PageHeading>
            <Button buttonType="secondary" onClick={Sentry.showReportDialog}>
              Report feedback
            </Button>
          </PageSection>
        </DocumentTitle>
      );
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;
