import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, mediaQueries, type } from '@candidco/enamel';

export const ToolSection = styled.section`
  padding-bottom: 2rem;

  &:not(:first-child) {
    padding-top: 1.75rem;
    border-top: 1px solid ${colors.black10};
  }
`;

export const SectionHeading = styled(type.H3)`
  margin-bottom: 1.5rem;
`;

export const ToolLink = styled(Link)<{ isRed?: boolean }>`
  position: relative;
  display: block;
  height: 3.5rem;
  padding: 1.125rem 2rem 0.875rem 1rem;
  font-size: ${type.size.t3};
  font-weight: ${type.weight.bold};
  line-height: ${type.lineHeight.heading};
  color: ${colors.black90};
  background: ${({ isRed }) => (isRed ? colors.red10 : colors.blue10)};

  &:hover {
    color: ${colors.black90};
  }

  &::after {
    position: absolute;
    top: 1.5rem;
    right: 0.875rem;
    width: 0;
    height: 0;
    content: '';
    border: 0.3125rem solid;
    border-color: transparent transparent transparent ${colors.blue50};
  }
`;

export const ToolGrid = styled.ul<{ isOrtho?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 0;

  @media ${mediaQueries.tabletAndAbove} {
    grid-template-columns: 1fr 1fr;
  }

  p {
    margin: 1rem 0;
  }

  ${({ isOrtho }) =>
    isOrtho &&
    css`
      ${ToolLink} {
        background: ${colors.red10};

        &::after {
          border-color: transparent transparent transparent ${colors.red50};
        }
      }
    `};
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
