import styled from 'styled-components/macro';
import { colors, mediaQueries, type, Button } from '@candidco/enamel';
import { PermissionedInput } from 'components/PermissionedInput';
import RightArrowSVG from 'assets/arrow-right-line.svg?react';

export const FormContainer = styled.div`
  max-width: 680px;
`;

export const RadioGroup = styled.div`
  margin-top: 1.25rem;

  @media ${mediaQueries.tabletAndAbove} {
    width: 50%;
  }
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    align-items: flex-start;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const Radio = styled(PermissionedInput)`
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: radio;
`;

export const SmallHeading = styled.h4`
  font-size: ${type.size.small};
  font-weight: ${type.weight.bold};
`;

export const NoteHeading = styled.div`
  font-size: ${type.size.small};
  font-weight: ${type.weight.bold};
`;

export const SmallSubHeading = styled.div`
  font-size: ${type.size.small};
  color: ${colors.black70};
  padding-bottom: 8px;
`;

export const StyledAlertContainer = styled.div`
  margin-top: 1.5rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 6.5rem;
  padding: 0.625rem;
  line-height: 1.4;
  border: 1px solid ${colors.black20};
  border-radius: 0.125rem;
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${colors.black40};
  }

  &::placeholder {
    color: ${colors.black60};
  }
`;

export const NotesContainer = styled.div`
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.text70};
`;

export const NotesHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
`;

export const OrderButton = styled(Button)`
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

export const ContinueButton = styled(Button)`
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
  border-radius: 200px;
  padding: 1rem 2rem;
`;

export const FormRow = styled.div`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 2.5rem;
  }

  p {
    margin-bottom: 0;
  }
`;

export const QuestionHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

export const RightArrow = styled(RightArrowSVG)`
  margin-left: 0.5rem;
  // make the color of the arrow white
  path {
    fill: white;
  }
`;
