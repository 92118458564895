import {
  ArticleList,
  ArticleListContent,
  ArticleListTitle,
  ArticleListItem,
  ArticleLink,
  ArticleListItemContainer,
} from 'pages/HelpCenter/Zendesk/ZendeskArticleList.css';
import React from 'react';

export interface Article {
  html_url: string;
  title: string;
}

type Props = {
  title: string;
  articles: Array<Article>;
};

const ZendeskArticleList = ({ title, articles }: Props) => {
  return (
    <ArticleList>
      <ArticleListContent>
        <ArticleListTitle>{title}</ArticleListTitle>
        <ArticleListItemContainer>
          {articles.map((article) => {
            return (
              <ArticleListItem>
                <ArticleLink
                  href={article.html_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {article.title}
                </ArticleLink>
              </ArticleListItem>
            );
          })}
        </ArticleListItemContainer>
      </ArticleListContent>
    </ArticleList>
  );
};

export default ZendeskArticleList;
