import { fetchApi } from 'utils/api';
import { RestMethod } from 'utils/api-types';

export const fetchRejectionReasons = async () => {
  try {
    const res = await fetchApi(
      '/api/v1/gateway/case/treatment-plan/rejection-reasons/',
      'GET'
    );

    return res;
  } catch (err) {
    throw new Error('Could not fetch rejection reasons');
  }
};
export const createTreatmentPlanStagingProOrder = async (
  caseRef,
  customerId,
  shippingAddress,
  shippingAddressType,
  sendPatientUpdate,
  clientCouponCode
) => {
  try {
    const res = await fetchApi(
      `/api/v1/customers/${customerId}/auto-create-pro-order/`,
      RestMethod.POST,
      {
        case_ref: caseRef,
        shipping_address: shippingAddress,
        shipping_address_type: shippingAddressType,
        send_patient_update: sendPatientUpdate,
        client_coupon_code: clientCouponCode,
      }
    );

    return res;
  } catch (err) {
    throw new Error('Could not create treatment plan review');
  }
};

export const createAppOrderForCore = async (
  caseRef,
  customerId,
  shippingAddress,
  shippingAddressType,
  sendPatientUpdate,
  orderItems,
  metadata = {}
) => {
  try {
    const res = await fetchApi(
      `/api/v1/customers/${customerId}/create-core-order/`,
      RestMethod.POST,
      {
        case_ref: caseRef,
        shipping_address: shippingAddress,
        shipping_address_type: shippingAddressType,
        send_patient_update: sendPatientUpdate,
        order_items: orderItems,
        metadata: metadata,
      }
    );

    return res;
  } catch (err) {
    throw new Error('Could not create treatment plan review');
  }
};

export const getOrderCatalog = async () => {
  try {
    const res = await fetchApi(`/api/v1/catalog/`, RestMethod.GET);

    return res;
  } catch (err) {
    throw new Error('Could not fetch order catalog');
  }
};
