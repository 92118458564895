import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  AddressContainer,
  FormRow,
  Line1Row,
  AddLine2,
} from 'components/AddressForm/AddressForm.css';
import Line1 from 'components/AddressForm/Line1';
import {
  FormikInputWrapper,
  FormikSelectWrapper,
} from 'components/FormikForms';
import {
  requiredValidator,
  zipCodeValidator,
} from 'components/FormikForms/utils';
import {
  GetSupportedCountriesQuery,
  GetSupportedCountriesQueryVariables,
  GetSupportedCountriesDocument,
} from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import { AddressFormType } from 'components/AddressForm/types';
import { CANADA_COUNTRY_CODE } from 'pages/Case/constants';
import AddressConfirmation from 'components/AddressForm/AddressConfirmation';
import { addressMatches } from 'utils/customer';
import { getValidAddresses } from 'components/AddressForm/utils';

export interface AddressFormProps<T> {
  getAddressData: (values: T) => AddressFormType;
  addressPath: string;
  validateAddress?: (values: T) => T;
  disabled?: boolean;
  submitButtonRef?: React.RefObject<HTMLButtonElement>;
  submitForm: (values: T) => void;
  updateAddress: (values: AddressFormType, originalProps: T) => T;
  hideBusinessName?: boolean;
}

const AddressForm = <T,>({
  getAddressData,
  addressPath,
  disabled = false,
  submitButtonRef,
  submitForm,
  updateAddress,
  hideBusinessName = false,
}: AddressFormProps<T>) => {
  const formikProps = useFormikContext<T>();

  const { 'enable-international-validation': enableInternationalValidation } =
    useFlags();
  const { setValues, values } = formikProps;
  const [getSupportedCountries] = useGQLQuery<
    GetSupportedCountriesQuery,
    GetSupportedCountriesQueryVariables
  >(GetSupportedCountriesDocument);

  const [isAddressConfirmationModalOpen, setIsAddressConfirmationModalOpen] =
    useState(false);
  const [addressValidationSuggestions, setAddressValidationSuggestions] =
    useState<AddressFormType[]>([]);
  const [supportedCountries, setSupportedCountries] = useState<
    GetSupportedCountriesQuery['getSupportedCountries']
  >([]);
  const [enableLine2, setShowLine2] = useState(false);

  useEffect(() => {
    fetchSupportedCountries();
  }, []);

  const fetchSupportedCountries = async () => {
    try {
      const data = await getSupportedCountries({});
      setSupportedCountries(data?.getSupportedCountries || []);
    } catch (err: unknown) {
      setSupportedCountries([]);
    }
  };
  const address = getAddressData(values);

  const validateSubmittedAddress = async (values: T) => {
    const currentAddress = getAddressData(values);

    if (currentAddress.countryCode !== 'US' && !enableInternationalValidation) {
      submitForm(values);
      return;
    }
    const suggestedAddresses = await getValidAddresses(currentAddress);
    const matches = addressMatches(suggestedAddresses, currentAddress);

    if (matches) {
      submitForm(values);
    } else {
      setAddressValidationSuggestions(suggestedAddresses);

      setIsAddressConfirmationModalOpen(true);
    }
  };

  const onAddressConfirmation = (
    value: AddressFormType,
    originalFormProps: T
  ) => {
    setIsAddressConfirmationModalOpen(false);
    submitForm(updateAddress(value, originalFormProps));
  };

  const showLine2 = address?.addressLine2 || enableLine2;
  //If there's only one region (guam, puerto rico, etc) then we should show the default (only) value
  const showDefaultStateValue =
    supportedCountries?.filter(
      (country) => country?.alpha2 === address?.countryCode
    )[0]?.adminRegions?.length === 1;

  return (
    <AddressContainer>
      <button
        style={{ display: 'none' }}
        ref={submitButtonRef}
        onClick={() => {
          //This is called on submit to validate the address
          //When the address is confirmed, it uses the submitForm callback
          validateSubmittedAddress(values);
        }}
      />
      <AddressConfirmation
        isOpen={isAddressConfirmationModalOpen}
        onCancel={() => setIsAddressConfirmationModalOpen(false)}
        originalAddress={getAddressData(values)}
        suggestions={addressValidationSuggestions}
        onConfirm={(selectedAddress) => {
          onAddressConfirmation(selectedAddress, values);
        }}
        isLoading={false}
      />
      <FormRow>
        <FormikSelectWrapper
          testId="basicinfo-country"
          name={`${addressPath}countryCode`}
          type="text"
          label="Country*"
          validate={requiredValidator}
          disabled={disabled}
          options={
            supportedCountries?.map((country) => ({
              value: country?.alpha2 as string,
              displayValue: country?.label as string,
            })) || []
          }
          defaultAnswer="US"
          onSelectChange={async () => {
            //Clear out the address fields when the country changes
            const newValues = updateAddress(
              {
                addressLine1: '',
                addressLine2: '',
                city: '',
                stateCode: '',
                zip: '',
                businessName: '',
                countryCode: address?.countryCode,
              },
              values
            );

            setValues(newValues, true);
          }}
          showDefaultValue
        />
      </FormRow>
      {!hideBusinessName && (
        <FormRow>
          <FormikInputWrapper
            label="Business or company name (optional)"
            name={`${addressPath}businessName`}
            type="text"
            disabled={disabled}
          />
        </FormRow>
      )}
      <Line1Row>
        <Line1
          disabled={disabled}
          addressPath={addressPath}
          getAddressData={getAddressData}
        />
        {!showLine2 && (
          <AddLine2 onClick={() => setShowLine2(true)}>
            Add apt, suite, or other
          </AddLine2>
        )}
      </Line1Row>
      {showLine2 && (
        <FormRow>
          <FormikInputWrapper
            name={`${addressPath}addressLine2`}
            label="Apt, suite, other (optional)"
            type="text"
            disabled={disabled}
            data-private
          />
        </FormRow>
      )}
      <FormRow>
        <FormikInputWrapper
          label="City*"
          name={`${addressPath}city`}
          type="text"
          validate={requiredValidator}
          disabled={disabled}
          data-private
        />
        <FormikSelectWrapper
          name={`${addressPath}stateCode`}
          type="text"
          label={
            address?.countryCode === CANADA_COUNTRY_CODE
              ? 'Province*'
              : 'State*'
          }
          options={
            supportedCountries
              ?.filter((country) => country?.alpha2 === address?.countryCode)[0]
              ?.adminRegions?.map((region) => ({
                value: region?.alpha2 as string,
                displayValue: region?.label as string,
              })) || []
          }
          validate={requiredValidator}
          showDefaultValue={showDefaultStateValue}
          disabled={disabled}
          menuPlacement="top"
          data-private
        />
      </FormRow>
      <FormRow>
        <FormikInputWrapper
          testId="basicinfo-zip"
          name={`${addressPath}zip`}
          type="text"
          label={
            address?.countryCode === CANADA_COUNTRY_CODE
              ? 'Postal Code*'
              : 'ZIP code*'
          }
          maxLength={address?.countryCode === CANADA_COUNTRY_CODE ? 7 : 5}
          validate={zipCodeValidator}
          disabled={disabled}
          data-private
        />
        {
          //Add a div for force zip to take up half the space
        }
        <div></div>
      </FormRow>
    </AddressContainer>
  );
};

export default AddressForm;
