import React, { useState, useEffect, useContext, useMemo } from 'react';
import { theme } from '@candidco/enamel';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  ProfileCloseButton,
  LeftCircle,
  DesktopProfileDrawer,
  Avatar,
  Flexbox,
  DesktopTitle,
  MobileTitle,
  DesktopSubtitle,
  MobileSubtitle,
  AttributesListContainer,
  AttributeContainer,
  AttributeLabel,
  AttributeValue,
  ProfileContainer,
  AttributeDivider,
  LeftButton,
  MobileProfileDrawer,
  FlexboxColumn,
  MobileDetailArrow,
  MobileTopBarRightButton,
  MobileTopProfileBarContainer,
  VerticalText,
  VerticalSideBarContainer,
  ButtonContainer,
  ShowGuardianInfoButton,
  BluePlusIcon,
  BlueMinusIcon,
} from 'pages/Patient/PatientDetail/ProfileSideBarLegacy/ProfileSideBar.css';
import { mediaQueries, Button } from '@candidco/enamel';
import PencilIcon from 'assets/pencil-2.svg?react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useScrollDirection } from 'hooks/useScrollDirection';
import { NotificationContext } from '@candidco/enamel';
import EditPatientModal from 'components/EditPatientModal/EditPatientModal';
import BrandChip from 'components/BrandChip/BrandChip';
import { getProfileBorderColor } from 'pages/Patient/utils';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber, formatBirthday } from 'utils/customer';
import {
  fetchPatient,
  selectPatientProfilePicture,
  selectPatient,
  selectProfileInfo,
  selectHomeAddress,
  selectProviderFacingStatus,
  selectAlertStatus,
  selectPatientBrandName,
} from 'pages/Patient/patientSlice';
import { ProfileInfoType, GuardianInfoType } from 'pages/Patient/types';
import { usePatientLoadingStates } from 'pages/Patient/utils';
import {
  AvatarSkeleton,
  PatientNameSkeleton,
  PatientIdSkeleton,
  AttributeValueSkeleton,
  AttributeLabelSkeleton,
} from 'pages/Patient/PatientDetail/Skeletons';
import { useAuthContext } from 'components/AuthProvider';

type Attribute = {
  key: string;
  value: string;
};

type AttributesListProps = {
  attributes: Attribute[];
  guardianAttributes: Attribute[];
};

type ProfileSideBarProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type ResponsiveProfileProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attributes: Attribute[];
  guardianAttributes: Attribute[];
  fullName: string;
  id: string;
  picture: Maybe<string> | undefined;
  onEditPatient: () => void;
  borderColor: string;
  showBrandChip: boolean;
  brandName: string;
};

const getProfileAttributes = (profileInfo: ProfileInfoType) => [
  {
    key: 'Preferred name',
    value: profileInfo?.preferredName,
  },
  {
    key: 'Date of birth',
    value: formatBirthday(profileInfo?.dateOfBirth),
  },
  {
    key: 'Home address',
    value: profileInfo?.displayAddress,
  },
  {
    key: 'Email',
    value: profileInfo?.email,
  },
  {
    key: 'Phone',
    value: formatPhoneNumber(profileInfo?.phone).formatedPhoneNumber,
  },
  {
    key: 'Treating provider',
    value: `Dr. ${profileInfo?.treatingProviderName}`,
  },
];

const getGuardianAttributes = (guardianInfo: GuardianInfoType) =>
  guardianInfo
    ? [
        {
          key: 'Guardian name',
          value: `${guardianInfo?.firstName} ${guardianInfo?.lastName}`,
        },
        {
          key: 'Preferred name',
          value: guardianInfo?.preferredName,
        },
        {
          key: 'Date of birth',
          value: formatBirthday(guardianInfo?.birthday),
        },
        {
          key: 'Guardian phone',
          value: formatPhoneNumber(guardianInfo?.phone ?? '')
            .formatedPhoneNumber,
        },
      ]
    : [];

const GuardianInfoList = ({
  guardianAttributes,
  isGuardianInfoOpen,
}: {
  guardianAttributes: Attribute[];
  isGuardianInfoOpen: boolean;
}) => {
  return (
    <>
      {isGuardianInfoOpen &&
        guardianAttributes.map(({ key, value }) => (
          <>
            <AttributeDivider />
            <AttributeContainer>
              <AttributeLabel>{key}</AttributeLabel>
            </AttributeContainer>
            <AttributeContainer>
              <AttributeValue>{value}</AttributeValue>
            </AttributeContainer>
          </>
        ))}
    </>
  );
};

const AttributesList = ({
  attributes,
  guardianAttributes,
}: AttributesListProps) => {
  const { isFetchingPatient } = usePatientLoadingStates();
  const [isGuardianInfoOpen, setIsGuardianInfoOpen] = useState(false);

  return (
    <AttributesListContainer>
      {attributes.map(({ key, value }) => {
        // Exception for address, we want to break after the street address
        const isHomeAddress = key === 'Home address';
        let streetAddress,
          restOfAddress = '';
        if (isHomeAddress) {
          streetAddress = value.split(',')[0];
          restOfAddress = value.split(',').slice(1).join(',');
        }
        return (
          <>
            <AttributeDivider />
            <AttributeContainer>
              <AttributeLabel data-testid="pdp-profile-attributes">
                {isFetchingPatient ? <AttributeLabelSkeleton /> : key}
              </AttributeLabel>
            </AttributeContainer>
            <AttributeContainer>
              <AttributeValue>
                {isFetchingPatient ? (
                  <AttributeValueSkeleton />
                ) : isHomeAddress ? (
                  <>
                    <div>{streetAddress}</div>
                    <div>{restOfAddress}</div>
                  </>
                ) : (
                  value
                )}
              </AttributeValue>
            </AttributeContainer>
          </>
        );
      })}
      <AttributeDivider />
      {guardianAttributes.length > 0 && (
        <>
          <AttributeContainer
            onClick={() => setIsGuardianInfoOpen(!isGuardianInfoOpen)}
            style={{
              justifyContent: 'space-between',
              paddingTop: 24,
              paddingBottom: 18,
            }}
          >
            <ShowGuardianInfoButton>
              {isGuardianInfoOpen ? 'Hide guardian info' : 'Show guardian info'}
            </ShowGuardianInfoButton>
            {isGuardianInfoOpen ? <BlueMinusIcon /> : <BluePlusIcon />}
          </AttributeContainer>
          <GuardianInfoList
            guardianAttributes={guardianAttributes}
            isGuardianInfoOpen={isGuardianInfoOpen}
          />
        </>
      )}
    </AttributesListContainer>
  );
};

const MobileProfile = (props: ResponsiveProfileProps) => {
  const {
    isOpen = true,
    setOpen,
    id,
    fullName,
    attributes,
    guardianAttributes,
    onEditPatient,
    picture,
    borderColor,
  } = props;
  const scrollDirection = useScrollDirection();
  useEffect(() => {
    if (scrollDirection === 'down') {
      setOpen(false);
    }
  }, [scrollDirection]);
  const { isFetchingPatient, isFetchingPrism } = usePatientLoadingStates();

  return (
    <>
      {/* Static top bar */}
      <MobileTopProfileBarContainer>
        <Flexbox>
          {isFetchingPatient || isFetchingPrism ? (
            <AvatarSkeleton isMobile />
          ) : (
            <Avatar src={picture || undefined} borderColor={borderColor} />
          )}
          <FlexboxColumn>
            <MobileTitle>
              {isFetchingPatient ? <PatientNameSkeleton /> : fullName}
            </MobileTitle>
            <MobileSubtitle data-testid="pdp-profile-id">
              {isFetchingPatient ? <PatientIdSkeleton /> : `PATIENT ${id}`}
            </MobileSubtitle>
          </FlexboxColumn>
        </Flexbox>
        {!isFetchingPatient && (
          <MobileTopBarRightButton
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            <span>Details</span>
            <span>
              <MobileDetailArrow aria-hidden upArrow={isOpen} />
            </span>
          </MobileTopBarRightButton>
        )}
      </MobileTopProfileBarContainer>

      {/* Colapsible top drawer */}
      <MobileProfileDrawer variant="persistent" anchor="top" open={isOpen}>
        <AttributesList
          attributes={attributes}
          guardianAttributes={guardianAttributes}
        />
        <ButtonContainer>
          <Button
            buttonType="secondary-outline"
            isFullWidth={true}
            leftIcon={<PencilIcon />}
            onClick={() => onEditPatient()}
          >
            Edit info
          </Button>
        </ButtonContainer>
      </MobileProfileDrawer>
    </>
  );
};

const DesktopProfile = (props: ResponsiveProfileProps) => {
  const {
    isOpen = true,
    setOpen,
    id,
    fullName,
    attributes,
    guardianAttributes,
    onEditPatient,
    picture,
    borderColor,
    showBrandChip,
    brandName,
  } = props;
  const { isFetchingPatient, isFetchingPrism } = usePatientLoadingStates();

  return (
    <>
      {/* Static left bar */}
      <VerticalSideBarContainer visible={!isOpen}>
        <VerticalText visible={!isOpen}>{fullName}</VerticalText>
      </VerticalSideBarContainer>

      {/* Colapsible left drawer */}
      <DesktopProfileDrawer variant="persistent" anchor="left" open={isOpen}>
        <LeftButton
          onClick={() => onEditPatient()}
          isHidden={isFetchingPatient}
        >
          Edit
        </LeftButton>
        <>
          <ProfileCloseButton
            onClick={() => {
              setOpen(!isOpen);
            }}
            stroke={theme.colors.blue50}
            aria-hidden
          />
          <LeftCircle />
        </>
        <ProfileContainer>
          <Flexbox>
            {isFetchingPatient || isFetchingPrism ? (
              <AvatarSkeleton />
            ) : (
              <Avatar src={picture || undefined} borderColor={borderColor} />
            )}
          </Flexbox>
          <Flexbox>
            <DesktopTitle>
              {isFetchingPatient ? <PatientNameSkeleton /> : fullName}
            </DesktopTitle>
          </Flexbox>
          <Flexbox>
            <DesktopSubtitle data-testid="pdp-profile-id">
              {isFetchingPatient ? <PatientIdSkeleton /> : `PATIENT ${id}`}
            </DesktopSubtitle>
          </Flexbox>
          {showBrandChip && brandName && (
            <Flexbox>
              <BrandChip brandInfo={{ name: brandName }} />
            </Flexbox>
          )}
          <AttributesList
            attributes={attributes}
            guardianAttributes={guardianAttributes}
          />
        </ProfileContainer>
      </DesktopProfileDrawer>
    </>
  );
};

const ProfileSideBar = ({ isOpen, setOpen }: ProfileSideBarProps) => {
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  const ResponsiveProfile = tabletSize ? DesktopProfile : MobileProfile;
  const dispatch = useDispatch();
  const profileInfo = useSelector(selectProfileInfo);
  const picture = useSelector(selectPatientProfilePicture);
  const [isEditPatientModalOpen, setIsEditPatientModalOpen] = useState(false);
  const { showNotification } = useContext(NotificationContext);
  const providerFacingStatus = useSelector(selectProviderFacingStatus);
  const alertStatus = useSelector(selectAlertStatus);
  const profileBorderColor = useMemo(
    () => getProfileBorderColor(providerFacingStatus ?? '', alertStatus),
    [providerFacingStatus, alertStatus]
  );

  const patient = useSelector(selectPatient);
  const { userInfo } = useAuthContext();
  const showBrandChip = userInfo?.isStaff ?? false;
  const patientBrandName = useSelector(selectPatientBrandName);
  const homeAddress = useSelector(selectHomeAddress);
  const { id, fullName } = profileInfo;
  const attributes = getProfileAttributes(profileInfo);
  const guardianAttributes = getGuardianAttributes(profileInfo?.legalGuardian);

  const handleCancelEditPatient = () => {
    setIsEditPatientModalOpen(false);
  };

  const handleEditPatientSuccess = () => {
    showNotification('Successfully updated patient info', 'success');

    setIsEditPatientModalOpen(false);
    dispatch(
      fetchPatient({
        customerId: id,
      })
    );
  };

  const handleOpenEditPatient = () => {
    setIsEditPatientModalOpen(true);
  };

  if (!patient) {
    return null;
  }

  return (
    <>
      <ResponsiveProfile
        isOpen={isOpen}
        setOpen={setOpen}
        id={id}
        fullName={fullName}
        picture={picture}
        attributes={attributes}
        guardianAttributes={guardianAttributes}
        onEditPatient={handleOpenEditPatient}
        borderColor={profileBorderColor}
        showBrandChip={showBrandChip}
        brandName={patientBrandName}
      />
      <EditPatientModal
        isOpen={isEditPatientModalOpen}
        onClose={handleCancelEditPatient}
        onSuccess={handleEditPatientSuccess}
        patientData={patient}
        shippingAddress={homeAddress}
      />
    </>
  );
};

export default ProfileSideBar;
