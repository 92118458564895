import React from 'react';
import { Grid, Skeleton } from '@candidco/enamel';

export const BaseSkeleton = ({
  isReversed = false,
}: {
  isReversed?: boolean;
}) => (
  <Grid container direction={isReversed ? 'column-reverse' : 'column'}>
    <Skeleton animation="wave" variant="text" width={84} height={26} />
    <Skeleton animation="wave" variant="text" width={176} height={40} />
  </Grid>
);
