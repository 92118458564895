import styled from 'styled-components/macro';
import { colors, Grid } from '@candidco/enamel';

export const TasksContainer = styled(Grid)`
  width: 85%;
`;

export const TaskContainer = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const TaskTitleContainer = styled(Grid)`
  padding-left: 10px;
  text-align: left;
`;

export const OverviewContainer = styled(Grid)`
  border: 1px solid ${colors.black20};
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
`;

export const ButtonContainer = styled(Grid)`
  width: 85%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const PopoverContainer = styled.div`
  padding-right: 0.5rem;
`;

export const TaskTitle = styled.span`
  color: ${({ theme }) => theme.colors.black90};
  line-height: ${({ theme }) => theme.lineHeights.bodySmall};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: left;
  font-size: 0.85rem;
`;

export const TaskNumber = styled.div`
  ${({ theme }) => theme.text.headings.h5};
  color: ${({ theme }) => theme.colors.black70};
  padding-right: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.bodySmall};
  text-align: left;
  font-size: 0.9rem;
`;

export const ColoredBullet = styled.li`
  color: ${(props) => props.color};
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: left;
  ${({ theme }) => theme.text.headings.h2};
  -webkit-margin-end: -1.5rem;
`;
