import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';
import {
  AccordionDetails as EnamelAccordionDetails,
  AccordionSummary as EnamelAccordionSummary,
  type,
} from '@candidco/enamel';
import { CaseTabsContainer as CaseTabs } from 'components/CaseTab/CaseTab.css';
import ArrowRight from 'assets/ic_chevron-right-blue.svg?react';

const drawerWidth = 300;
const mobileDrawerHeight = 500;

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
  background-color: white;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
  }

  /* The following should be removed when responsive typography is implemented in Enamel */
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    ${({ theme }) => theme.text.body.small};

    h4 {
      ${({ theme }) => theme.text.body.default};
    }
  }
`;

export const MainContentWrapper = styled.div<{
  isFullWidth?: boolean;
}>`
  ${({ theme, isFullWidth }) => `
   @media ${theme.mediaQueries.tabletAndAbove} {
     flex-grow: 1;
     transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
     margin-left: ${isFullWidth ? -(drawerWidth + 44) : 0}px;
   }
    @media ${theme.mediaQueries.mobile} {
      margin-top: ${isFullWidth ? 0 : mobileDrawerHeight}px;
    }
`}
`;

export const AccordionContainer = styled(Grid)<{ visible: boolean }>`
  ${({ visible, theme }) => `
    ${visible && `border-bottom: 1px solid ${theme.colors.border}`};    
    display: ${visible ? 'block' : 'none'};
    padding: 32px;
    @media ${theme.mediaQueries.mobile} {
      padding: 8px 20px;
      max-width: 100vw;
    }
  `}
`;

export const AccordionDetails = styled(EnamelAccordionDetails)`
  &.MuiAccordionDetails-root {
    display: block;
    padding: 0;
  }
`;

export const AccordionSummary = styled(EnamelAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const RightArrowButton = styled(ArrowRight)`
  transform: translateX(-44px);
  position: relative;
  z-index: 1;
  top: 24px;
  right: 263px;
  background-color: white;
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  width: 14px;
  height: 28px;
  cursor: pointer;
`;

export const OpenCircle = styled.div`
  ${({ theme }) => `
    background: #9e978e;
    margin: 0 1em 1em 0;
    height: 28px;
    width: 14px;
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
    border: 1px solid ${theme.colors.black20};
    transform: translateX(30%);
    background-color: white;
    position: relative;
    top: 24px;
    right: 332px;
    cursor: pointer;
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled(type.H4)`
  margin-bottom: 0;
  line-height: 1.15;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    ${({ theme }) => theme.text.body.default};
  }
`;

export const CaseTabsContainer = styled(CaseTabs)`
  margin-left: 32px;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-left: 20px;
  }
`;
