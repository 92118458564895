import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { RootState } from 'state/store';
import {
  ActivateOrderDocument,
  ActivateOrderMutation,
  ActivateOrderMutationVariables,
  Address,
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  GetProductVariantsDocument,
  GetProductVariantsQuery,
  GetProductVariantsQueryVariables,
  Maybe,
  OrderStatus,
  OrderType,
  ProductVariantsType,
} from 'generated/core/graphql';
import { client, coreClient } from 'state/GraphQLProvider';
import {
  GetPracticesDocument,
  GetPracticesQuery,
  GetPracticesQueryVariables,
  OrganizationTypes,
  Practice,
} from 'generated/legacy/graphql';

interface NarrowOrderType extends Omit<OrderType, 'orderItems'> {
  orderItems: {
    id: string;
    quantity: number;
    productVariant: {
      name: string;
      sku: string;
    };
  }[];
}

type NarrowPracticeType = Pick<Practice, 'id' | 'name'> & {
  brand?:
    | {
        name: string;
      }
    | null
    | undefined;
};

type FlattenedOrderItem = {
  caseRef: Maybe<string> | undefined;
  createdAt: string;
  orderId: string;
  practiceId: number;
  shippingAddress: Maybe<Address> | undefined;
  status: Maybe<OrderStatus> | undefined;
  updatedAt: string;
  orderItemRef: string;
  productVariantName: string;
  productVariantSku: string;
  quantity: number;
};

type CommerceState = {
  productVariants: Omit<
    ProductVariantsType,
    'createdAt' | 'updatedAt' | 'orderItems'
  >[];
  practices: NarrowPracticeType[];
  currentOrder: NarrowOrderType | null;
};

const initialState: CommerceState = {
  productVariants: [],
  practices: [],
  currentOrder: null,
};

export const fetchProductVariants = createAsyncThunk(
  'commerce/fetchProductVariants',
  async () => {
    const { data } = await coreClient.mutate<
      GetProductVariantsQuery,
      GetProductVariantsQueryVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: GetProductVariantsDocument,
    });

    return data;
  }
);

// This fetch action might belong in a different slice/domain. We will keep it here for now until
// a "org" domain is created
export const fetchPractices = createAsyncThunk(
  'commerce/fetchPractices',
  async (practiceType: OrganizationTypes) => {
    const { data } = await client.query<
      GetPracticesQuery,
      GetPracticesQueryVariables
    >({
      fetchPolicy: 'no-cache',
      query: GetPracticesDocument,
      variables: {
        organizationType: practiceType,
      },
    });

    return data;
  }
);

export const createOrder = createAsyncThunk(
  'commerce/createOrder',
  async (input: CreateOrderMutationVariables) => {
    const { data } = await coreClient.mutate<
      CreateOrderMutation,
      CreateOrderMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: CreateOrderDocument,
      variables: input,
    });

    return data;
  }
);

export const activateOrder = createAsyncThunk(
  'commerce/activateOrder',
  async (input: ActivateOrderMutationVariables) => {
    const { data } = await coreClient.mutate<
      ActivateOrderMutation,
      ActivateOrderMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: ActivateOrderDocument,
      variables: input,
    });

    return data;
  }
);

/**
 * Commerce slice for managing all things commerce.
 * This includes fetching product variants, creating orders, and activating orders.
 *
 * To expand to include more commerce-related functionality around shipping and billing.
 *
 * System slice can be used for loading and error states via isPending, isRejected, isFulfilled, typePrefix, useIsLoading, useError.
 */
const commerceSlice = createSlice({
  name: 'commerce',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(fetchProductVariants.fulfilled, (state, action) => {
      state.productVariants = action.payload?.productVariants ?? [];
    });
    builder.addCase(fetchPractices.fulfilled, (state, action) => {
      state.practices = action.payload?.practices ?? [];
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.currentOrder =
        action.payload?.createOrder?.order ?? (null as OrderType | null);
    });
    builder.addCase(activateOrder.fulfilled, (state, action) => {
      state.currentOrder =
        action.payload?.activateOrder?.order ?? (null as OrderType | null);
    });
  },
});

export const selectCurrentOrder = (state: RootState) =>
  state.commerce.currentOrder;
export const selectPractices = (state: RootState) => state.commerce.practices;

export const selectProductVariants = (
  state: RootState,
  hasCaseRequirement: boolean = false
) => {
  // Extract productVariants from the commerce slice of the state
  const productVariants = state.commerce.productVariants;
  // If hasCaseRequirement is true, filter productVariants to include only those with krakenFgs property
  // Otherwise, return all productVariants
  return hasCaseRequirement
    ? productVariants.filter((variant) => variant.krakenFgs)
    : productVariants;
};

export const selectCurrentOrderItems = createSelector(
  selectCurrentOrder,
  (order): FlattenedOrderItem[] =>
    order?.orderItems.map((item) => ({
      caseRef: order.caseRef,
      createdAt: order.createdAt,
      orderId: order.id,
      practiceId: order.practiceId,
      shippingAddress: order.shippingAddress,
      status: order.status,
      updatedAt: order.updatedAt,
      orderItemRef: item.id,
      productVariantName: item.productVariant.name,
      productVariantSku: item.productVariant.sku,
      quantity: item.quantity,
    })) ?? []
);

export default commerceSlice.reducer;
