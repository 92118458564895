import React from 'react';
import styled from 'styled-components/macro';
import { Column } from 'react-virtualized';
import { colors, type, Skeleton } from '@candidco/enamel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCaseTypeLabel } from 'utils/case';

import { VirtualizedTable } from 'components/VirtualizedTable';
import { TableProps } from 'components/SearchTablePage/types';
import { PageSection } from 'styles/layout.css';
import { LinkButton } from 'styles/inputs.css';

const HEADER_HEIGHT = 80;
const TOP_NAV_HEIGHT = 65;
const ROW_HEIGHT = 80;

const Wrapper = styled.div`
  position: relative;
  max-width: 100rem;
  height: calc(100vh - ${TOP_NAV_HEIGHT}px);
  margin: auto;
`;

const EmptyRender = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  background-color: ${colors.black05};
`;

const EmptyResults = () => (
  <PageSection>
    <EmptyRender>
      <type.H3>There are no search results</type.H3>
    </EmptyRender>
  </PageSection>
);

const customerCellRenderer = (cellData: string | number | boolean) => (
  <LinkButton data-testid="case-search-table" href={`case/${cellData}`}>
    {cellData}
  </LinkButton>
);

const privateCellRenderer = (cellData: string | number | boolean) => (
  <span data-private>{cellData}</span>
);

export const CustomerSearchTable = ({ rows, onLoadMore }: TableProps) => {
  const { 'enable-refinement-policy': enableRefinementPolicy } = useFlags();
  return (
    <Wrapper>
      {rows.length > 0 ? (
        <VirtualizedTable
          loadMoreRows={onLoadMore}
          rowHeight={ROW_HEIGHT}
          headerHeight={HEADER_HEIGHT}
          rowCount={rows.length}
          rows={rows}
          scrollToAlignment="start"
        >
          <Column
            label="Id"
            dataKey="id"
            width={100}
            cellRenderer={({ cellData }) => customerCellRenderer(cellData)}
          />
          <Column
            label="First name"
            dataKey="firstName"
            width={125}
            flexGrow={1}
            cellRenderer={({ cellData }) => privateCellRenderer(cellData)}
          />
          <Column
            label="Last name"
            dataKey="lastName"
            width={125}
            flexGrow={1}
          />
          <Column
            label="Case Type"
            dataKey="caseType"
            width={150}
            flexGrow={1}
            cellRenderer={({ cellData }) =>
              cellData !== '-' ? (
                getCaseTypeLabel(cellData ?? '', enableRefinementPolicy)
              ) : (
                <Skeleton width={100} />
              )
            }
          />
          <Column
            label="Journey State"
            dataKey="journeyState"
            width={200}
            flexGrow={1}
            cellRenderer={({ cellData }) =>
              cellData !== '-' ? cellData : <Skeleton width={100} />
            }
          />
        </VirtualizedTable>
      ) : (
        <EmptyResults />
      )}
    </Wrapper>
  );
};
