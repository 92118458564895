import React from 'react';
import { Modal } from '@candidco/enamel';
import { type } from '@candidco/enamel';

import TrimmingModalPng from 'assets/trimming-modal.png';
import {
  ButtonRow,
  ModalContainer,
  ModalRightContent,
  NewAlignersButton as AdditionalAlignersButton,
  TrimmingButton,
  TextContent,
  Title,
} from 'components/Modals/TrimmingModal.css';

type TrimmingModalProps = {
  isOpen: boolean;
  onTrimClicked: () => void;
  onNewAlignersClicked: () => void;
};

const TrimmingModal = ({
  isOpen,
  onTrimClicked,
  onNewAlignersClicked: onAdditionalAlignersClicked,
}: TrimmingModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={() => {}} hideCloseButton={true}>
      <ModalContainer>
        <img src={TrimmingModalPng} alt="" />
        <ModalRightContent>
          <TextContent>
            <Title>
              Before you request new aligners, consider trimming and polishing.
            </Title>
            <div>
              Candid aligners are printed at a very high resolution, and
              sometimes dental undercuts can prevent full seating of an aligner.
              Trimming is perfectly acceptable and may help your patients start
              treatment sooner and more comfortably.{' '}
              <type.SecondaryLink
                href="https://candidpro.zendesk.com/hc/en-us/articles/9019225550743"
                target="_blank"
                rel="noopener noreferrer"
              >
                More info on trimming can be found here.
              </type.SecondaryLink>
            </div>
          </TextContent>
          <ButtonRow>
            <TrimmingButton buttonType="secondary" onClick={onTrimClicked}>
              I’ll try trimming
            </TrimmingButton>
            <AdditionalAlignersButton onClick={onAdditionalAlignersClicked}>
              I need new aligners
            </AdditionalAlignersButton>
          </ButtonRow>
        </ModalRightContent>
      </ModalContainer>
    </Modal>
  );
};

export default TrimmingModal;
