import { ProgressBar } from '@candidco/enamel';
import React from 'react';

import LockSvg from 'assets/ic_lock.svg?react';
import {
  Container,
  NameText,
  NumCases,
  Row,
  StyledCheck,
  SubText,
  TierImg,
  TierName,
} from 'pages/LoyaltyProviderView/TierProgress.css';

type Props = {
  name: string;
  imgPath: string;
  price: number | null;
  caseCount: number;
  requiredCases: number;
  isNextTier: boolean;
  earningTowardsMaintainingCurrentTier: boolean;
};

const TierProgress = ({
  name,
  imgPath,
  price,
  caseCount,
  requiredCases,
  isNextTier,
  earningTowardsMaintainingCurrentTier,
}: Props) => {
  return (
    <Container>
      <ProgressBar
        currentValue={Math.min(caseCount, requiredCases)}
        totalValue={requiredCases}
        style={{ height: '8px' }}
      />
      <div>
        <Row>
          <TierName>
            <TierImg src={imgPath} alt=""></TierImg>
            <NameText>{name}</NameText>
          </TierName>
          {caseCount >= requiredCases ? (
            <TierName>
              <StyledCheck />
              <NumCases>{requiredCases} cases</NumCases>
            </TierName>
          ) : (
            <TierName>
              <LockSvg />
              <NumCases>
                {caseCount} of {requiredCases} cases
              </NumCases>
            </TierName>
          )}
        </Row>

        <Row center={!price}>
          {!!price && (
            <SubText>
              {(price / 100).toLocaleString('en-us', {
                style: 'currency',
                currency: 'USD',
              })}{' '}
              per case
            </SubText>
          )}
          {isNextTier ? (
            <SubText>
              {requiredCases - caseCount}{' '}
              {requiredCases - caseCount === 1 ? 'case ' : 'cases '}
              away to
              {earningTowardsMaintainingCurrentTier ? ' maintain ' : ' unlock '}
              {name}
            </SubText>
          ) : (
            <div />
          )}
        </Row>
      </div>
    </Container>
  );
};

export default TierProgress;
