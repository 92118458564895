import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { AlertCard, Loading, NotificationContext } from '@candidco/enamel';
import {
  Wrapper,
  ReopenLink,
  PhotoReviewLink,
  StyledArrow,
  DownloadPhotosButton,
  NotApplicableContainer,
  HistorySection,
  SectionHeading,
} from 'pages/OrthoPrism/Photos/Photos.css';
import {
  PhotoType,
  PhotoTypeType,
  RejectionReason,
  StateDataType,
  SubmissionItemType,
  SubmissionTransitions,
  SubmissionType,
  CustomerType,
} from 'generated/legacy/graphql';
import { AppDispatch } from 'state/store';
import { toStatusLabel } from 'utils/prism';
import { useIsLoading } from 'state/system';
import History from 'components/PrismHistory';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';

import {
  TabProps,
  PatientReasonsType,
  PhotoApprovalState,
  PhotoMap,
  PhotoReasonsType,
  SubmissionItemState,
  SubmissionState,
} from 'pages/OrthoPrism/types';
import {
  fetchPrismAggregates,
  selectCurrentAggregate,
  selectCurrentPhotoSet,
  selectCurrentSubmissionSet,
  selectPhotoViewNames,
  setHasChanges,
  submitPrismReview,
  selectCustomer,
  selectSelectedCase,
} from 'pages/OrthoPrism/orthoSlice';
import {
  StatusBar,
  TechNote,
  Overline,
  Note,
  AlertContainer,
  MaterialReviewFormContainer,
  MaterialReviewFormItem,
} from 'pages/OrthoPrism/OrthoPrism.css';
import PhotoDetail from 'pages/OrthoPrism/Photos/PhotoDetail';
import PhotoOverview from 'pages/OrthoPrism/Photos/PhotoOverview';
import PhotoReviewForm from 'pages/OrthoPrism/Photos/PhotoReviewForm';
import { OnSubmitPhotoReviewArgs } from 'pages/OrthoPrism/Photos/PhotoReviewForm/PhotoReviewForm';
import { useHasCustomerAccess } from 'hooks/useHasCustomerAccess';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS, AUTO_EXPAND_HISTORY_GROUPS } from 'constants/index';
import { selectIsZippingPhotos, zipPhotos } from 'pages/Prism/prismSlice';
import CollapsibleHistory from 'components/CollapsableHistory/CollapsibleHistory';
import { useHistory } from 'react-router-dom';
import { LinkItem } from 'pages/OrthoPrism/OrthoPrism.css';
import { MaterialStates } from 'generated/core/graphql';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';

const rejectedStates: SubmissionState[] = [
  SubmissionState.RejectedPhotos,
  SubmissionState.RejectedCustomer,
  SubmissionState.RejectedMissingInfo,
];

const Photos = ({ refreshMaterials }: TabProps) => {
  const { showNotification } = useContext(NotificationContext);
  const customerInfo = useSelector(selectCustomer);
  const isZippingPhotos = useSelector(selectIsZippingPhotos);
  const selectedCase = useSelector(selectSelectedCase);
  const canReviewPhotos = useHasCustomerAccess(customerInfo?.userAccessLevel);
  const { checkHasAccess } = useContext(AuthContext);
  const inMaterialReviewGroup = checkHasAccess(ACCESS_GROUPS.MATERIAL_REVIEW);
  const [currentView, setCurrentView] = useState('');
  const [isReviewingPhotos, setIsReviewingPhotos] = useState(false);
  const [photoMap, setPhotoMap] = useImmer<PhotoMap>({});
  const [approvalState, setApprovalState] = useState<PhotoApprovalState>({
    quality: true,
    formatting: true,
    customer: true,
  });
  const dispatch = useDispatch<AppDispatch>();
  const isFetchingAggregates = useIsLoading(fetchPrismAggregates.typePrefix);
  const isSubmitting = useIsLoading(submitPrismReview.typePrefix);
  const currentAggregate = useSelector(selectCurrentAggregate);
  const photoSet = useSelector(selectCurrentPhotoSet);
  const submissionSet = useSelector(selectCurrentSubmissionSet);
  const aggregateType = currentAggregate?.aggregateType;
  const [treatmentViewNames, itiViewNames] = useSelector(selectPhotoViewNames);
  const { push } = useHistory();
  const photoTypes = (aggregateType?.requiredPhotoTypes ??
    []) as PhotoTypeType[];
  const vpProps = useVersionPills(submissionSet ?? []);
  const currentVersion = vpProps.current;
  const selectedSubmission = submissionSet[vpProps.currentIndex];
  const submissionItems = selectedSubmission?.submissionItems;
  const aggregateHistory = (currentAggregate?.stateData?.history ??
    []) as StateDataType[];
  const primaryHistory = [
    { data: 'created', created: currentAggregate?.createdAt! },
  ].concat(aggregateHistory);

  const photoReasonsType = useMemo(() => {
    if (!approvalState.formatting) {
      return PhotoReasonsType.Formatting;
    }
    if (!approvalState.quality) {
      return PhotoReasonsType.Quality;
    }
    return null;
  }, [approvalState]);

  const hasRejectedPhotos = useMemo(
    () => Object.values(photoMap).some(({ state }) => state.isRejected),
    [photoMap]
  );

  const photoUrlLink =
    currentAggregate?.submissionSet[currentVersion - 1]?.pdfUrl ?? '';

  const handleClarificationSubmission = (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean
  ) => {
    selectedCase?.caseRef &&
      refreshMaterials &&
      refreshMaterials(
        reloadScans,
        reloadXrays,
        reloadPhotos,
        selectedCase?.caseRef
      );
  };

  const handleZipPhotos = () => {
    const photos = Object.values(photoMap).map((a) => a.photo);
    dispatch(
      zipPhotos({
        photos: photos as PhotoType[],
        customer: customerInfo as CustomerType,
      })
    );
  };

  useEffect(() => {
    if (submissionSet.length && currentVersion === 0) {
      const activeIndex = submissionSet.findIndex(({ active }) => active);
      const lastIndex = submissionSet.length - 1;
      const selectedIndex = activeIndex !== -1 ? activeIndex : lastIndex;
      vpProps.onSelect(selectedIndex + 1);
    }
  }, [submissionSet]);

  useEffect(() => {
    setPhotoMap(
      () =>
        photoTypes?.reduce((acc: PhotoMap, { name }) => {
          const submissionItem = submissionItems?.find(
            ({ photo }) => photo.photoType.name === name
          ) as SubmissionItemType;
          const photo = photoSet.find(
            ({ ref }) => ref === submissionItem?.photo.ref
          ) as PhotoType;
          const itemState = submissionItem?.stateData?.data;

          acc[name] = {
            photo,
            submissionItem,
            state: {
              isRejected: itemState === SubmissionItemState.Rejected,
            },
          };

          return acc;
        }, {}) ?? {}
    );
  }, [submissionItems]);

  if (
    selectedCase?.caseState?.internal ===
    PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION
  ) {
    return (
      <AlertCard
        type="warning"
        header={'Photos not yet submitted'}
        displayIcon={true}
      >
        <LinkItem
          onClick={() =>
            push(`/patient/${selectedCase?.patientId}/case-creator`)
          }
        >
          Continue submission
        </LinkItem>
      </AlertCard>
    );
  }

  if (isFetchingAggregates) {
    return <Loading isCentered />;
  } else if (!currentAggregate?.photoSet.length) {
    return (
      <AlertCard type="warning" displayIcon={true}>
        Photos not yet submitted
      </AlertCard>
    );
  } else if (!submissionSet.length) {
    return (
      <AlertCard type="warning" displayIcon={true}>
        Photos pending review
      </AlertCard>
    );
  }

  const currentPhotoData = photoMap[currentView];
  const firstView = photoTypes[0]?.name ?? '';
  const submissionState = selectedSubmission?.stateData?.data;

  const { patientRejectionReasons = [], photoRejectionReasons = [] } =
    aggregateType ?? {};
  const submissionDate = '';

  const setCurrentPhotoState = (
    isRejected: boolean,
    rejectionReasons?: RejectionReason[]
  ) => {
    setPhotoMap((draft) => {
      draft[currentView].state = {
        isRejected,
        rejectionReasons,
      };
    });
  };

  const reverseApproval = () => {
    const submissionItems = Object.values(photoMap);
    const submissionRef = selectedSubmission.ref;

    dispatch(setHasChanges(false));

    dispatch(
      submitPrismReview({
        submissionItems,
        submissionArgs: {
          submissionRef,
          transition: SubmissionTransitions.ForceReverseApproval,
        },
      })
    );
    return;
  };

  const handleCreateSubmissionReview = ({
    isApproved,
    rejectionReasons,
    rejectionNotes,
  }: OnSubmitPhotoReviewArgs) => {
    const submissionItems = Object.values(photoMap);
    const submissionRef = selectedSubmission.ref;

    dispatch(setHasChanges(false));

    if (isApproved) {
      dispatch(
        submitPrismReview({
          submissionItems,
          submissionArgs: {
            submissionRef,
            transition: SubmissionTransitions.Approve,
            rejectionReasons,
            rejectionNotes,
          },
        })
      );

      return;
    }

    const transition = (() => {
      const mainReason = rejectionReasons?.[0]?.name;

      switch (mainReason) {
        case PatientReasonsType.CouldNotTreat:
        case PatientReasonsType.NeedsDentalWork:
          return SubmissionTransitions.RejectCustomer;
        case PatientReasonsType.MissingInformation:
          return SubmissionTransitions.RejectMissingInformation;
        case PhotoReasonsType.Quality:
        case PhotoReasonsType.Formatting:
          return SubmissionTransitions.RejectPhotos;
        default:
          return SubmissionTransitions.RejectCustomer;
      }
    })();

    dispatch(
      submitPrismReview({
        submissionItems,
        submissionArgs: {
          submissionRef,
          transition,
          rejectionReasons,
          rejectionNotes,
        },
      })
    );
  };

  const renderApprovalBanner = () => {
    const isPreTreatmentPlanning =
      selectedCase?.isActive &&
      [MaterialStates.NotStarted, MaterialStates.Uploaded].includes(
        selectedCase.caseState?.treatmentPlanStaging?.state!
      );

    return (
      <AlertContainer>
        <AlertCard
          type="success"
          header="Submission Approved"
          displayIcon={true}
        >
          {inMaterialReviewGroup && isPreTreatmentPlanning && (
            <ReopenLink onClick={reverseApproval}>
              Re-open photo review process
            </ReopenLink>
          )}
        </AlertCard>
      </AlertContainer>
    );
  };

  return (
    <Wrapper>
      <VersionPills {...vpProps} />
      {currentAggregate && (
        <PhotoReviewLink>
          <a
            href={`/prism/${customerInfo?.id}/${currentAggregate?.ref}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Open in Photo Review
            <StyledArrow aria-hidden />
          </a>
        </PhotoReviewLink>
      )}
      {submissionState === SubmissionState.Approved && renderApprovalBanner()}
      {rejectedStates.includes(submissionState as SubmissionState) && (
        <StatusBar isRejected>{toStatusLabel(selectedSubmission)}</StatusBar>
      )}
      {selectedSubmission?.initialNote && (
        <TechNote>
          <Overline data-testid="overline-heading">Tech note</Overline>
          <Note>{selectedSubmission.initialNote}</Note>
        </TechNote>
      )}
      {isReviewingPhotos ? (
        <PhotoDetail
          isSubmissionComplete={submissionState !== SubmissionState.Submitted}
          photoData={currentPhotoData}
          photoReasonsType={photoReasonsType}
          photoTypes={photoTypes}
          setPhotoState={setCurrentPhotoState}
          setView={setCurrentView}
          showOverview={() => setIsReviewingPhotos(false)}
          view={currentView}
        />
      ) : (
        <PhotoOverview
          onReviewPhoto={(view) => {
            setCurrentView(view);
            setIsReviewingPhotos(true);
          }}
          photoMap={photoMap}
          photoViewNames={{ treatmentViewNames, itiViewNames }}
        />
      )}
      <DownloadPhotosButton
        buttonType="secondary-outline"
        disabled={!photoUrlLink}
        isShort
        href={photoUrlLink}
      >
        <a href={photoUrlLink}>Download array (PDF)</a>
      </DownloadPhotosButton>
      <DownloadPhotosButton
        buttonType="secondary-outline"
        disabled={isZippingPhotos}
        isLoading={isZippingPhotos}
        isShort
        onClick={handleZipPhotos}
      >
        Download zip folder
      </DownloadPhotosButton>
      <MaterialReviewFormContainer
        container
        direction="column"
        alignItems="center"
        spacing={0}
      >
        {selectedCase?.isActive &&
          submissionState === SubmissionState.Submitted &&
          inMaterialReviewGroup &&
          canReviewPhotos && (
            <MaterialReviewFormItem item>
              <PhotoReviewForm
                approval={approvalState}
                hasRejectedPhotos={hasRejectedPhotos}
                isSubmitting={isSubmitting}
                isVisible={!isReviewingPhotos}
                onChange={() => dispatch(setHasChanges(true))}
                onClickReviewPhotos={() => {
                  setCurrentView(firstView);
                  setIsReviewingPhotos(true);
                }}
                onSubmit={handleCreateSubmissionReview}
                // @TODO remove ! when types are updated
                patientRejectionReasons={patientRejectionReasons!}
                photoRejectionReasons={photoRejectionReasons}
                setApproval={setApprovalState}
                showNotification={showNotification}
                submissionDate={submissionDate}
                refreshMaterials={handleClarificationSubmission}
              />
            </MaterialReviewFormItem>
          )}
        {!selectedCase?.isActive && !isReviewingPhotos && (
          <MaterialReviewFormItem item xs={12}>
            <NotApplicableContainer>
              <h3>Review is not applicable</h3>
              <p>Case has been archived</p>
            </NotApplicableContainer>
          </MaterialReviewFormItem>
        )}
        <MaterialReviewFormItem item xs={12}>
          <HistorySection>
            <CollapsibleHistory
              header={<SectionHeading>Submission history</SectionHeading>}
              isExpanded={checkHasAccess(AUTO_EXPAND_HISTORY_GROUPS)}
            >
              <History
                selectedPhotoType={null}
                photoSubmissionHistory={undefined}
                patientRejectionReasons={patientRejectionReasons!}
                primaryHistory={primaryHistory}
                submissionSet={submissionSet as SubmissionType[]}
                testId="SubmissionHistory"
              />
            </CollapsibleHistory>
          </HistorySection>
        </MaterialReviewFormItem>
      </MaterialReviewFormContainer>
    </Wrapper>
  );
};

export default Photos;
