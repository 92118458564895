import React from 'react';
import CaseSubmittedIcon from 'assets/case-submitted.svg?react';
import { Button, Grid } from '@candidco/enamel';
import {
  ModalContainer as Container,
  HeaderContainer,
  IconContainer,
  BulletPoint,
  ButtonContainer,
  HeaderText,
  Subheading,
  Subtext,
} from 'pages/Patient/CaseCreator/CaseSubmitted/CaseSubmitted.css';
import { useSelector } from 'react-redux';
import { selectIsSubmitted } from 'pages/Patient/patientSlice';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Patient/CaseCreator/types';

const steps = [
  {
    text: 'Candid reviews your diagnostic materials',
    subtext: 'If needed, we’ll request changes',
  },
  {
    text: 'Treatment plan will be sent for your review',
    subtext: '',
  },
  {
    text: 'You review the treatment plan',
    subtext: 'Rejecting the plan may delay the overall turnaround time',
  },
  {
    text: 'Aligner kit is manufactured',
    subtext: '',
  },
  {
    text: 'Aligner kit is shipped',
    subtext: '',
  },
];

const CaseSubmitted = () => {
  const { id } = useParams<RouteParams>();
  const location = useLocation<{ isNewCaseSubmisison?: boolean }>();

  const submitted = useSelector(selectIsSubmitted);
  // Added this check so for new case submissions we don't immediately redirect back to the case creator
  if (!location?.state?.isNewCaseSubmisison && !submitted) {
    return <Redirect to={`/patient/${id}/case-creator`} />;
  }

  return (
    <Container data-testid="success-message-container">
      <HeaderContainer>
        <IconContainer>
          <CaseSubmittedIcon />
        </IconContainer>
      </HeaderContainer>

      <HeaderText>Case submitted</HeaderText>
      <Subheading>
        Our team will begin reviewing your materials. Here’s what happens next:
      </Subheading>
      <br />
      <Grid container direction="row">
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          wrap="nowrap"
          xs={9}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="column"
            style={{ marginLeft: '3rem' }}
          >
            {steps.map((step) => {
              const text = step.text;
              const subtext = step.subtext;
              return (
                <Grid
                  container
                  direction="row"
                  alignItems="baseline"
                  style={{ marginBottom: '1.1rem' }}
                >
                  <Grid item>
                    <BulletPoint />
                  </Grid>
                  <Grid
                    item
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    {text}
                    {subtext && <Subtext>{subtext}</Subtext>}
                  </Grid>
                </Grid>
              );
            })}
            <Grid item>
              <ButtonContainer>
                <Button
                  buttonType="secondary"
                  isFullWidth
                  onClick={() => (window.location.href = '/action-items')}
                >
                  Back to action items
                </Button>
              </ButtonContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CaseSubmitted;
