import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { hideVisually } from 'polished';
import { Grid, Chip } from '@candidco/enamel';

export const Wrapper = styled.div``;

export const TabLabels = styled.nav`
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
  overflow-y: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 1.5rem;
  }
`;

export const Tab = styled.button`
  align-items: center;
  justify-content: space-around;
  padding: 1.25rem 1.5rem 1rem 0;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  white-space: nowrap;

  *:nth-child(3) {
    padding-left: 0.5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 1rem 1rem 1rem 0;
    font-size: ${({ theme }) => theme.fontSizes.tiny};
  }
`;

export const TabLabelContainer = styled.span<{
  isActive?: boolean;
}>`
  padding-top: 0.25rem;
  margin-right: 0.5rem;
  padding-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.black70};
  ${({ isActive, theme }) =>
    isActive &&
    css`
      border-bottom: 3px solid ${theme.colors.blue50};
      color: ${theme.colors.blue50};
    `};
`;

export const CompareBar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const ScanTabWrapper = styled.div`
  position: relative;
  table {
    display: table;
  }
  th {
    text-align: left;
    width: fit-content;
  }

  table > tbody {
    tr {
      width: fit-content;
    }
  }

  thead tr th:first-child,
  tbody tr td:first-child {
    width: 8em;
    min-width: 8em;
    max-width: 8em;
  }
`;

export const TabWrapper = styled.div`
  position: relative;
`;

export const VersionPillsContainer = styled.div`
  margin-bottom: 1rem;

  font-size: ${({ theme }) => theme.fontSizes.tiny};
`;

export const TgWrapper = styled.section`
  position: relative;
`;

export const TgDetailHeader = styled.h4`
  ${({ theme }) => theme.text.headings.h3};
  margin: 0;
`;

export const TgHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TgSubmissionInformation = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  color: ${({ theme }) => theme.colors.black70};
  margin-top: 0.25rem;
  margin-bottom: 2rem;
`;

export const TabContent = styled.div<{ isVisible?: boolean }>`
  ${({ isVisible }) => !isVisible && hideVisually()};
`;

export const RightPanel = styled.div`
  flex: 1;
  padding-left: 2rem;
`;

export const LeftPanel = styled.div`
  flex: 1;
  width: 100%;
`;

export const MaybeSideBySide = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HistoryWrapper = styled(Grid)`
  padding-top: 2rem;
  margin-top: 2.5rem;
  border-top: ${({ theme }) => theme.borders.default};
`;

export const HistorySection = styled(Grid)`
  max-width: 680px;
`;

export const StyledChip = styled(Chip)<{
  background: string;
  foreground: string;
}>`
  ${({ theme, background, foreground }) => `
        margin-left: 8px;
        &.MuiChip-root {
            background-color: ${background};
            font-size: ${theme.fontSizes.tiny};
            font-weight: ${theme.fontWeights.default};
            line-height: 1.5;
        }

        .MuiChip-label {
            color: ${foreground};
        }
    `}
`;
