import { useFlags } from 'launchdarkly-react-client-sdk';
import { AlertCard } from '@candidco/enamel';
import React from 'react';
import styled from 'styled-components/macro';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.default};
  padding-bottom: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const QuestionText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const AnswerText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.black70};
  font-style: italic;
`;

export const IFIMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.default};
  > span {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

const Container = styled.div`
  margin-bottom: 2rem;
`;
type RefinementsRequestedAlertCardProps = {
  currentStep: number | undefined;
  daysOnCurrentStep: number | undefined;
  itiType: string | null | undefined;
  providerFacingState: string | null | undefined;
  hasDentalWorkBeenDone: boolean | undefined;
  notes: string | null | undefined;
  paymentCategory: string | null | undefined;
};

const RefinementsRequestedAlertCard = ({
  currentStep,
  daysOnCurrentStep,
  itiType,
  providerFacingState,
  hasDentalWorkBeenDone,
  notes,
  paymentCategory,
}: RefinementsRequestedAlertCardProps) => {
  const { 'enable-refinement-policy': enableRefinementPolicy } = useFlags();
  console.log('hello world');
  if (!enableRefinementPolicy) {
    return (
      <>
        <LegacyAdditionalAlignersDataAlertCard
          currentStep={currentStep}
          daysOnCurrentStep={daysOnCurrentStep}
          itiType={itiType}
          providerFacingState={providerFacingState}
          hasDentalWorkBeenDone={hasDentalWorkBeenDone}
          notes={notes}
          paymentCategory={paymentCategory}
        />
      </>
    );
  }

  const caseClosedForRefinements =
    providerFacingState &&
    [
      PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE,
      PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
    ].includes(providerFacingState as PROVIDER_FACING_STATUSES);

  // Legacy refinement cases won't have this refinement data, so the alert card
  // should not display. If refinement data is partially backfilled then those
  // fields should still display
  const caseHasCurrentStepData = typeof currentStep === 'number';

  const caseMissingRefinementData =
    !caseHasCurrentStepData && !itiType && !notes;

  if (!caseClosedForRefinements || caseMissingRefinementData) {
    return null;
  }

  const isInitialFitIssue = itiType === 'initial_fit_issue';

  // A standard refinement is a midcourse correction or end of treatment refinement, opposed to an IFI

  const StandardRefinementAlert = (
    <AlertCard displayIcon={false} type="default">
      <IFIMessage>
        Refinements requested when the patient was on step{' '}
        <span>{currentStep}</span> of their aligners.
      </IFIMessage>
    </AlertCard>
  );

  const InitialFitIssueAlert = (
    <AlertCard
      displayIcon={false}
      type="default"
      header={"Provider's description of initial fit issue"}
    >
      {notes}
    </AlertCard>
  );

  return (
    <Container>
      {isInitialFitIssue ? InitialFitIssueAlert : StandardRefinementAlert}
    </Container>
  );
};

const LegacyAdditionalAlignersDataAlertCard = ({
  currentStep,
  daysOnCurrentStep,
  itiType,
  providerFacingState,
  hasDentalWorkBeenDone,
  notes,
  paymentCategory,
}: RefinementsRequestedAlertCardProps) => {
  const caseClosedForRefinements =
    providerFacingState &&
    [
      PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE,
      PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
    ].includes(providerFacingState as PROVIDER_FACING_STATUSES);

  // Legacy refinement cases won't have this refinement data, so the alert card
  // should not display. If refinement data is partially backfilled then those
  // fields should still display
  const caseHasCurrentStepData = typeof currentStep === 'number';
  const caseHasDaysOnCurrentStepData = typeof daysOnCurrentStep === 'number';
  const caseHasDentalWorkData = typeof hasDentalWorkBeenDone === 'boolean';

  const caseMissingRefinementData =
    !caseHasDaysOnCurrentStepData &&
    !caseHasCurrentStepData &&
    !caseHasDentalWorkData &&
    !itiType &&
    !notes &&
    !paymentCategory;

  if (!caseClosedForRefinements || caseMissingRefinementData) {
    return null;
  }

  const AdditionalAlignersInformation = (
    <>
      {caseHasCurrentStepData && (
        <>
          <QuestionText>What aligner step is this patient on?</QuestionText>
          <AnswerText>{currentStep}</AnswerText>
        </>
      )}
      {caseHasDaysOnCurrentStepData && (
        <>
          <QuestionText>
            How many days has the patient been on this step?
          </QuestionText>
          <AnswerText>{daysOnCurrentStep}</AnswerText>
        </>
      )}
    </>
  );

  const InitialFitIssueInformation = (
    <>
      {caseHasDentalWorkData && (
        <>
          <QuestionText>
            Has this patient had dental work since the treatment plan was
            created?
          </QuestionText>
          <AnswerText>{hasDentalWorkBeenDone ? 'Yes' : 'No'}</AnswerText>
        </>
      )}
      {notes && (
        <>
          <QuestionText>Please describe the initial fit issue</QuestionText>
          <AnswerText>{notes}</AnswerText>
        </>
      )}
    </>
  );

  return (
    <Container>
      <AlertCard displayIcon={false} type="default">
        <Header>Refinements request details</Header>
        {caseHasDaysOnCurrentStepData && (
          <>
            <QuestionText>
              Has this patient started treatment with the current set of
              aligners?
            </QuestionText>
            <AnswerText>{currentStep === 0 ? 'No' : 'Yes'}</AnswerText>
          </>
        )}
        {itiType === 'initial_fit_issue'
          ? InitialFitIssueInformation
          : AdditionalAlignersInformation}
      </AlertCard>
    </Container>
  );
};

export default RefinementsRequestedAlertCard;
