import styled from 'styled-components/macro';
import { css } from 'styled-components';

import checkCircleIconPath from 'assets/check-circle-outline.svg';
import minusCircleIconPath from 'assets/minus-circle-outline.svg';
import { colors, Grid } from '@candidco/enamel';
export const MaterialReviewFormContainer = styled(Grid)``;

export const MaterialReviewFormItem = styled(Grid)`
  width: 640px;
`;

export const AlertContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const StatusBar = styled.div<{
  isPending?: boolean;
  isApproved?: boolean;
  isRejected?: boolean;
}>`
  padding: 0.8125rem 1rem 0.6875rem 2.5rem;
  margin-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-position: 1rem 0.9375rem;

  :only-child {
    margin-bottom: 0;
  }

  ${({ isPending }) =>
    isPending &&
    css`
      padding-left: 1rem;
      background-color: ${({ theme }) => theme.colors.yellow30};
    `};

  ${({ isApproved }) =>
    isApproved &&
    css`
      background-color: ${({ theme }) => theme.colors.green30};
      background-image: url(${checkCircleIconPath});
    `};

  ${({ isRejected }) =>
    isRejected &&
    css`
      background-color: ${({ theme }) => theme.colors.red30};
      background-image: url(${minusCircleIconPath});
    `};
`;

export const TechNote = styled.div`
  margin-bottom: 1.5rem;
`;

export const Overline = styled.p`
  font-weight: bold;
  display: block;
  margin-bottom: 0.25rem;
  color: ${(p) => p.theme.colors.black90};
`;

export const Note = styled.div`
  color: ${(p) => p.theme.colors.black70};
`;

export const ScanWrapper = styled.div`
  position: relative;
  table {
    display: table;
  }
  th {
    text-align: left;
    width: fit-content;
  }

  table > tbody {
    tr {
      width: fit-content;
    }
  }

  thead tr th:first-child,
  tbody tr td:first-child {
    width: 8em;
    min-width: 8em;
    max-width: 8em;
  }
`;

export const ReopenLink = styled.a`
  font-size: 0.875rem;
  line-height: 1.7;
  color: ${colors.text90};
  text-decoration: underline;
  &:hover {
    color: ${colors.text90};
  }
`;

export const LinkItem = styled.a`
  color: ${colors.black90};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  &:hover {
    color: ${colors.black90};
    text-decoration: underline;
  }
`;
