import { theme, type } from '@candidco/enamel';
import styled from 'styled-components/macro';
import Chat from 'assets/chat-icon.svg?react';
import Mail from 'assets/icons/small/mail.svg?react';
import Phone from 'assets/icons/small/phone.svg?react';

export const Title = styled(type.H2)`
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.text90};
  line-height: 32px;
  font-size: 32px;
`;

export const PageSection = styled.section`
  width: 100%;
  max-width: 680px;
  margin-right: auto;
  margin-left: auto;
  padding: 2.5rem 1.25rem;
`;

export const PageBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactHeader = styled.div`
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.medium};
  line-height: 20px;
`;

export const ContactSubHeader = styled.div`
  font-size: ${theme.fontSizes.small};
  line-height: 21px;
`;

export const ContactHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContactTypeTitle = styled.div`
  font-weight: ${type.weight.bold};
  font-size: ${type.size.tiny};
  color: ${theme.colors.black70};
`;

export const ContactSectionTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 19.2px;
`;

export const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const PhoneNumber = styled.div``;
export const PhoneNumberSubtext = styled.div`
  color: ${theme.colors.black70};
`;

export const HoursText = styled.div`
  line-height: 15px;
  font-size: 10px;
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

export const ContactText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactBody = styled.div<{ isGlidewell?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 32px;
  margin-top: 40px;
  gap: 24px;

  border: 1px solid ${theme.colors.black20};
  border-radius: 8px;
`;

export const PhoneSvg = styled(Phone)`
  path {
    stroke: ${theme.colors.black70};
  }

  width: 20px;
  height: 20px;
`;

export const MailSvg = styled(Mail)`
  path {
    stroke: ${theme.colors.black70};
  }

  width: 20px;
  height: 20px;
`;

export const ChatSvg = styled(Chat)`
  path {
    stroke: ${theme.colors.black70};
  }

  width: 20px;
  height: 20px;
`;
