import React, { useCallback, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { AlertCard, TextInput } from '@candidco/enamel';
import { AUTH_GROUPS } from 'constants/index';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as TreatmentPlans from 'pages/OrthoPrism/treatmentPlans';
import Notes from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/ProReviewFormNotes';
import {
  ApplyPromotionsToCartDocument,
  ApplyPromotionsToCartQueryVariables,
  ApplyPromotionsToCartQuery,
  IneligiblePromotion,
} from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import { AuthContext } from 'components/AuthProvider';
import {
  SelectedAddress,
  SelectedAddressType,
} from 'components/AddressForm/types';
import {
  FormContainer,
  FormRow,
  Label,
  QuestionHeading,
  Radio,
  RadioGroup,
  NotesContainer,
  NotesHeader,
  OrderButton,
  StyledAlertContainer,
  ButtonContainer,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/ProReviewForm.css';
import {
  selectPatient,
  selectBypassOrthoReview,
  selectSelectedCase,
} from 'pages/Patient/patientSlice';
import {
  usePatientLoadingStates,
  scanIntervalDaystoNumber,
} from 'pages/Patient/utils';
import * as TreatmentPlanStagings from 'pages/OrthoPrism/treatmentPlanStagings';
import {
  useUpdateCaseMutation,
  UpdateCaseInput,
  ScanIntervalDaysOptions,
} from 'generated/legacy/graphql';
import { AddressSelection } from 'components/AddressSelection/AddressSelection';
import { REFACTOR_ANY } from '@Types/refactor';
import { ProblemCategory } from 'utils/types';

type Props = {
  isSubmitting: boolean;
  onSubmit?: (
    args: TreatmentPlanStagings.TreatmentPlanStagingSubmitCallbackArgs
  ) => void;
  isDfaEnabled?: boolean;
  onOpenDfaSoftware?: () => void;
};

type ReviewOption =
  | typeof TreatmentPlans.ReviewOption.ProReject
  | typeof TreatmentPlans.ReviewOption.Approve;

type SelectedAddressInfo = {
  addressType: SelectedAddressType;
  value: SelectedAddress;
  image: string;
  label: string;
  sendPatientUpdateDefaultOption: boolean;
};

const isReviewOption = (s: REFACTOR_ANY): s is ReviewOption =>
  s === TreatmentPlans.ReviewOption.ProReject ||
  s === TreatmentPlans.ReviewOption.Approve;

export type RejectionDetailProblem = {
  id: string;
  isEditing: boolean;
  topic: ProblemCategory | null;
  photos: Array<File>;
  notes: string;
};

const CandidProForm = ({
  isSubmitting,
  onSubmit = () => {},
  isDfaEnabled = false,
  onOpenDfaSoftware = () => {},
}: Props) => {
  const [applyPromotionsToCart] = useGQLQuery<
    ApplyPromotionsToCartQuery,
    ApplyPromotionsToCartQueryVariables
  >(ApplyPromotionsToCartDocument);
  const { userInfo } = useContext(AuthContext);
  const scanIntervalPreference =
    userInfo?.accountPreferences?.doctor?.scanIntervalDays;
  const [updateCase] = useUpdateCaseMutation();
  const selectedCase = useSelector(selectSelectedCase);
  const { isFetchingPatient, isFetchingCases } = usePatientLoadingStates();
  const patient = useSelector(selectPatient);
  const bypassOrthoReview = useSelector(selectBypassOrthoReview);
  const dentalWorkCTA = `Please ensure that the treatment plan you are approving is based on the patient's latest records. If any unplanned dental work is performed please reach out to our support team to determine if a new case should be started.`;

  const [transition, setTransition] = useState<ReviewOption | undefined>();
  const [couponCode, setCouponCode] = useState<string | undefined>();
  const [couponError, setCouponError] = useState<
    IneligiblePromotion | undefined | null
  >();
  const [scanIntervalDays, setScanIntervalDays] =
    useState<ScanIntervalDaysOptions>(
      scanIntervalPreference || ScanIntervalDaysOptions.Fourteen
    );
  const isApproved = transition === TreatmentPlans.ReviewOption.Approve;
  const [notes, setNotes] = useState<string | undefined>();
  const [sendPatientUpdate, setSendPatientUpdate] = useState<boolean>(false);
  // Set default address here, currently to patient
  const [selectedAddress, setSelectedAddress] = useState<
    SelectedAddressInfo | undefined
  >(undefined);

  const onChangeTransition = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (isReviewOption(value)) {
        setTransition(value);
        return;
      }
      console.error(`[panic] malformed transition: ${value}`);
    },
    []
  );

  let args = isApproved
    ? {
        transition,
        shippingAddress: selectedAddress?.value,
        shippingAddressType: selectedAddress?.addressType,
        sendPatientUpdate: sendPatientUpdate,
      }
    : {
        transition,
        notes,
        reason: '',
      };

  /*
  TODO: Remove this once the UI for phase 2 is ready.
  Phase 1 of enabling clinicians to bypass ortho review focused on enabling this feature 
  from primarily the backend only. Phase 2 which will involve full UI support for this feature,
  but until then we need to hardcode the transition reason as "revise_setup" for Salesforce's workflow purposes.
  */
  if (bypassOrthoReview && !isApproved) {
    args = {
      transition,
      notes,
      reason: 'revise_setup',
    };
  }

  const isValid = TreatmentPlans.isProClinicianSubmissionArgs(args);
  const submitRef = React.createRef<HTMLButtonElement>();
  const {
    'enable-coupon-codes': enableCouponCodes,
    'enable-scan-schedule-options': enableScanScheduleOptions,
  } = useFlags();

  const otherAddressSubmit = async (customerAddress: SelectedAddress) => {
    // This submit action that get passed in the address form which is a child of this component
    // The address in the form should already been validated and accepted by user before this action is called
    args.transition = TreatmentPlans.ReviewOption.Approve;
    args.shippingAddress = customerAddress;
    args.shippingAddress.firstName = patient?.firstName;
    args.shippingAddress.lastName = patient?.lastName;
    args.shippingAddressType = 'other';
    args.sendPatientUpdate = sendPatientUpdate;
    submitTreatmentPlan(args);
  };

  const isCouponValid = async () => {
    if (!couponCode) {
      return true;
    }

    // NOTE: This is temporary code determine if a coupon code is valid
    // for a practice.
    // Eventually we'll be moving to a true checkout page where we have the
    // correct skus and prices for validation
    const input = {
      input: {
        lineItems: [
          {
            sku: 'aligner_kit',
            quantity: 1,
            originalPriceInCents: 1499,
          },
        ],
        couponCodes: [couponCode],
        practiceId: patient?.practice?.id,
      },
    };
    const response = await applyPromotionsToCart(input);
    if (response?.applyPromotionsToCart?.ineligiblePromotions?.length) {
      setCouponError(
        response.applyPromotionsToCart
          .ineligiblePromotions[0] as IneligiblePromotion
      );
      return false;
    }
    return true;
  };

  const handleSubmit = useCallback(async () => {
    // This is the submit action from this Pro form, this will call the inner Basic address form's submit action
    // which will validate the address and submit the form
    if (submitRef.current) {
      submitRef.current.click();
      return;
    }

    submitTreatmentPlan(args);
  }, [args]);

  const submitTreatmentPlan = async (args: REFACTOR_ANY) => {
    const isValidAddress = args.shippingAddress;
    if (TreatmentPlans.isProClinicianSubmissionArgs(args)) {
      const valid = await isCouponValid();
      if (valid) {
        onSubmit({
          transition: args.transition,
          notes: args.notes,
          problems: [
            {
              id: '',
              isEditing: false,
              topic: null,
              photos: [],
              notes: '',
            },
          ],
          ...(isValidAddress && {
            proOrderArgs: {
              shippingAddress: args.shippingAddress,
              shippingAddressType: args.shippingAddressType,
              sendPatientUpdate: !!args.sendPatientUpdate,
              clientCouponCode: couponCode,
            },
          }),
        });

        if (enableScanScheduleOptions && selectedCase && scanIntervalDays) {
          const caseInput: UpdateCaseInput = {
            caseRef: selectedCase.caseRef,
            updates: {
              data: {
                scanIntervalDays: scanIntervalDaystoNumber(scanIntervalDays),
              },
            },
          };
          await updateCase({
            variables: {
              caseInput,
            },
          });
        }
      }
    } else {
      Sentry.captureException(
        `This is a quiet error. No network calls or logs get captured in this case
      TPs.isProClinicianSubmissionArgs: ${TreatmentPlans.isProClinicianSubmissionArgs(
        args
      )}, 
      args: ${JSON.stringify(args)}`
      );
    }
  };

  const onChangeScanInterval = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScanIntervalDays(event.target.value as ScanIntervalDaysOptions);
  };

  return (
    <>
      <FormContainer>
        <FormRow>
          <QuestionHeading>Is the treatment plan acceptable?</QuestionHeading>
          <p>
            By approving, you are agreeing that the treatment plan{' '}
            <strong>will be pushed to production</strong> and your office will
            be invoiced.
          </p>
          <RadioGroup role="radiogroup">
            <Label>
              <Radio
                type="radio"
                name="transition"
                errorText="You do not have the permissions to approve a treatment plan"
                value={TreatmentPlans.ReviewOption.Approve}
                onChange={onChangeTransition}
                forbiddenGroups={[
                  AUTH_GROUPS.TREATMENT_PLAN_QA,
                  AUTH_GROUPS.TREATMENT_PLAN_TECH,
                ]}
              />
              Approve this treatment plan version
            </Label>
            <Label>
              <Radio
                type="radio"
                name="transition"
                errorText="You do not have the permissions to reject a treatment plan"
                value={TreatmentPlans.ReviewOption.ProReject}
                onChange={onChangeTransition}
                forbiddenGroups={[AUTH_GROUPS.TREATMENT_PLAN_TECH]}
              />
              Request a revision or ask a question
            </Label>
          </RadioGroup>
          {isApproved && (
            <StyledAlertContainer>
              <AlertCard type="default">
                After approval, aligner kits ship within 10 days and will be
                invoiced in your next billing cycle.
              </AlertCard>
            </StyledAlertContainer>
          )}
        </FormRow>
        {transition && (
          <Notes isProClinician tpState={transition} onChange={setNotes} />
        )}
        {enableScanScheduleOptions && isApproved && (
          <FormRow>
            <QuestionHeading>Aligner wear schedule</QuestionHeading>
            <p>
              Choose what schedule you want to set for your patients.
              Notifications in ProMonitoring will be sent accordingly.
            </p>
            <RadioGroup role="radiogroup">
              <Label>
                <Radio
                  type="radio"
                  name="scanSchedule"
                  value={ScanIntervalDaysOptions.Seven}
                  onChange={onChangeScanInterval}
                  checked={scanIntervalDays === ScanIntervalDaysOptions.Seven}
                />
                7-day wear schedule
              </Label>
              <Label>
                <Radio
                  type="radio"
                  name="scanSchedule"
                  value={ScanIntervalDaysOptions.Fourteen}
                  onChange={onChangeScanInterval}
                  checked={
                    scanIntervalDays === ScanIntervalDaysOptions.Fourteen
                  }
                />
                14-day wear schedule
              </Label>
            </RadioGroup>
          </FormRow>
        )}
        {isApproved && (
          <>
            <QuestionHeading>
              {`Where do you want to ship this patient's aligners?`}
            </QuestionHeading>
            <AddressSelection
              setResultAddress={setSelectedAddress}
              setResultSendPatientUpdate={setSendPatientUpdate}
              otherAddressFormRef={submitRef}
              onOtherAddressValidationConfirm={otherAddressSubmit}
            />
          </>
        )}
        {enableCouponCodes && isApproved && (
          <FormRow>
            <QuestionHeading>
              Do you have a promo code to apply?
            </QuestionHeading>
            <TextInput
              label="Promo Code (optional)"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCouponCode(e.target.value);
                setCouponError(null);
              }}
              value={couponCode}
              errorText={couponError?.message}
            />
          </FormRow>
        )}
        <ButtonContainer>
          <OrderButton
            isShort
            onClick={handleSubmit}
            buttonType="secondary"
            disabled={
              !isValid || isSubmitting || isFetchingPatient || isFetchingCases
            }
            isLoading={isSubmitting}
          >
            {isApproved ? 'Order aligners' : 'Submit'}
          </OrderButton>
          {isDfaEnabled && (
            <OrderButton
              isShort
              onClick={onOpenDfaSoftware}
              buttonType="secondary"
            >
              Launch 3D Controls
            </OrderButton>
          )}
        </ButtonContainer>
        <NotesContainer>
          <NotesHeader>Note about dental work</NotesHeader>
          <div>{dentalWorkCTA}</div>
        </NotesContainer>
      </FormContainer>
    </>
  );
};

export default CandidProForm;
