import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import ReactSelect from 'react-select';
import { Practice } from 'generated/legacy/graphql';
import { Loading } from '@candidco/enamel';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedPractice,
  selectAllPractices,
  setSelectedPractice,
} from 'pages/PracticeManagement/shared/slice';

type PracticeSelectOption = {
  label: string;
  value: Practice;
};

const PracticeSelect = styled(ReactSelect)<{
  hasError?: boolean;
  width?: number;
}>`
  min-width: 200px;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => (width ? `${width}px` : '200px')};
`;

const optionMap = (practice: Practice): PracticeSelectOption => ({
  label: practice.name,
  value: practice,
});

const PracticeSearch = () => {
  const dispatch = useDispatch();
  const allPractices = useSelector(selectAllPractices);
  const selectedPractice = useSelector(getSelectedPractice);
  const practiceOptions = useMemo(
    () => allPractices.map(optionMap),
    [allPractices]
  );

  const getValue = () =>
    selectedPractice ? optionMap(selectedPractice) : ('' as string);

  const getSelectWidth = () => {
    // Autosize the select to the practice name width. Else use the minimum of 200
    if (selectedPractice) {
      return 8 * selectedPractice.name.length + 75;
    }
    return 200;
  };

  const hasPractices = practiceOptions.length > 0;

  if (!hasPractices) {
    return <Loading />;
  }

  return (
    <>
      {allPractices && hasPractices && (
        <PracticeSelect
          options={practiceOptions}
          onChange={(option: PracticeSelectOption) =>
            dispatch(setSelectedPractice(option.value))
          }
          value={getValue()}
          width={getSelectWidth()}
        />
      )}
    </>
  );
};

PracticeSearch.propTypes = {};

export default PracticeSearch;
