import { OptionTypes } from 'pages/Patient/Checkout/types';
import { ProductTypes } from 'pages/Patient/types';
import { SKUs } from 'constants/brands';
import Dinero from 'dinero.js';

const defaultItem = {
  title: '',
  question: '',
  optionType: OptionTypes.None,
  quantity: 1,
  totalPriceAfterDiscounts: Dinero({ amount: 0 }),
  totalPriceAfterLoyalty: Dinero({ amount: 0 }),
  totalPriceBeforeDiscounts: Dinero({ amount: 0 }),

  price: Dinero({ amount: 0 }),
};

export const defaultItemsByProductType = (brandName: string) => {
  const aligner = SKUs[brandName].aligner;
  const refinement = SKUs[brandName].refinement;
  const retainer = SKUs[brandName].retainer;
  const replacement = SKUs[brandName].replacement;
  return {
    [ProductTypes.Aligner]: {
      ...defaultItem,
      providerFacingProductName: aligner.providerFacingProductName,
      title: aligner.providerFacingProductName,
      description: aligner.description,
      sku: aligner.sku,
      imageSrc: aligner.imageSrc,
      defaultPrice: Dinero({
        amount: aligner.price,
      }),
    },
    [ProductTypes.Refinement]: {
      ...defaultItem,
      providerFacingProductName: refinement.providerFacingProductName,
      title: refinement.providerFacingProductName,
      description: refinement.description,
      sku: refinement.sku,
      imageSrc: refinement.imageSrc,
      defaultPrice: Dinero({
        amount: refinement.price,
      }),
    },
    [ProductTypes.Retainer]: {
      ...defaultItem,
      providerFacingProductName: retainer.providerFacingProductName,
      title: aligner.providerFacingProductName,
      description: retainer.description,
      sku: retainer.sku,
      imageSrc: retainer.imageSrc,
      defaultPrice: Dinero({
        amount: retainer.price,
      }),
      optionType: OptionTypes.QuantityDropDown,
    },
    [ProductTypes.Replacement]: {
      ...defaultItem,
      providerFacingProductName: replacement.providerFacingProductName,
      title: replacement.providerFacingProductName,
      description: replacement.description,
      sku: replacement.sku,
      imageSrc: replacement.imageSrc,
      defaultPrice: Dinero({
        amount: replacement.price,
      }),
    },
  };
};
