import styled from 'styled-components/macro';
import { theme, type } from '@candidco/enamel';

export const Title = styled(type.H2)`
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.text90};
  line-height: 32px;
  font-size: 32px;
`;

export const PageSection = styled.section`
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2.5rem;
`;

export const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
`;

export const ResourceContainers = styled.div`
  padding-top: 40px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

export const BottomContent = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  margin-top: 40px;
  gap: 24px;

  border: 1px solid ${theme.colors.black20};
  border-radius: 8px;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
  }
`;

export const RightContent = styled.div<{ isGlidewell: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 32px;
  margin-top: 40px;
  gap: 24px;
  max-width: 420px;
  max-height: ${({ isGlidewell }) => (isGlidewell ? '560px' : '294px')};

  border: 1px solid ${theme.colors.black20};
  border-radius: 8px;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const ContactHeader = styled.div`
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.medium};
  line-height: 20px;
`;

export const ContactSubHeader = styled.div`
  font-size: ${theme.fontSizes.small};
  line-height: 21px;
`;

export const ContactHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContactTypeTitle = styled.div`
  font-weight: ${type.weight.bold};
  font-size: ${type.size.tiny};
  color: ${theme.colors.black70};
`;

export const ContactSectionTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 19.2px;
`;

export const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const PhoneNumber = styled.div``;
export const PhoneNumberSubtext = styled.div`
  color: ${theme.colors.black70};
`;

export const HoursText = styled.div`
  line-height: 15px;
  font-size: 10px;
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

export const ContactText = styled.div`
  display: flex;
  flex-direction: column;
`;
