import {
  MaterialReviewTransitions,
  SubmitMaterialAggregateReviewMutationVariables,
  useSubmitMaterialAggregateReviewMutation,
} from 'generated/legacy/graphql';
import {
  RequestClarificationMutation,
  RequestClarificationMutationVariables,
  RequestClarificationDocument,
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataDocument,
  GetMaterialUploadDataQueryVariables,
  MaterialStates,
  MaterialState,
  StateTransitions,
} from 'generated/core/graphql';

import {
  selectCustomerId,
  selectLastSubmission,
  selectLatestCase,
  selectXrayMaterialState,
  selectXraysFromLatestSubmission,
  selectScanMaterialState,
  selectScansFromLatestSubmission,
} from 'pages/OrthoPrism/orthoSlice';
import React, { useState } from 'react';
import {
  FormRow,
  Label,
  QuestionHeading,
  SmallHeading,
  ButtonRow,
  DeleteButton,
  UploadContainer,
  AttachmentRow,
  FileName,
  Checkbox,
  SubForm,
  TextAreaFullWidth,
} from 'pages/OrthoPrism/MaterialReview/MaterialReviewForm.css';
import { Button } from '@candidco/enamel';
import FileUpload from 'components/FileUpload';
import readFile from 'utils/readFile';
import ImgIcon from 'assets/img-icon.svg?react';
import { useSelector } from 'react-redux';
import { FileInfo } from 'pages/OrthoPrism/utils';
import { SubmissionState } from 'pages/OrthoPrism/types';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';
import axios from 'axios';
import { StringMap } from 'utils/types';

type Props = {
  onSubmit: (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean
  ) => void;
};

const NeedsClarificationForm = ({ onSubmit }: Props) => {
  const [note, setNote] = useState<string>('');
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [isPhotosSelected, setIsPhotosSelected] = useState<boolean>(false);
  const [isXraysSelected, setIsXraysSelected] = useState<boolean>(false);
  const [isScansSelected, setIsScansSelected] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const prismSubmission = useSelector(selectLastSubmission);
  const currentCase = useSelector(selectLatestCase);
  const customerId = useSelector(selectCustomerId);

  const scanMaterialState = useSelector(selectScanMaterialState);
  const scansFromLatestSubmission = useSelector(
    selectScansFromLatestSubmission
  );

  const xrayMaterialState = useSelector(selectXrayMaterialState);
  const xraysFromLatestSubmission = useSelector(
    selectXraysFromLatestSubmission
  );

  const hasFiles = !!files.length;
  const patientId = parseInt(customerId);

  const [submitReview, { loading: isLegacySubmissionLoading }] =
    useSubmitMaterialAggregateReviewMutation();

  const [requestClarification, { loading: isRequestClarificationLoading }] =
    useGQLMutation<
      RequestClarificationMutation,
      RequestClarificationMutationVariables
    >(RequestClarificationDocument);

  const [getMaterialUploadUrl] = useGQLQuery<
    GetMaterialUploadDataQuery,
    GetMaterialUploadDataQueryVariables
  >(GetMaterialUploadDataDocument);

  const canSubmit = () => {
    return (
      (isPhotosSelected || isScansSelected || isXraysSelected) &&
      note.trim() !== ''
    );
  };

  const uploadAttachments = async () => {
    // upload attachment files to s3
    return await Promise.all(
      files.map(async ({ file }) => {
        const uploadData = await getMaterialUploadUrl({
          patientId,
          fileName: file.name,
        });
        const fields: StringMap = uploadData?.getMaterialUploadData.fields;
        const url = uploadData?.getMaterialUploadData.url!;

        const data = new FormData();
        Object.entries(fields).forEach(([key, value]) =>
          data.append(key, value)
        );
        data.append('file', file);

        try {
          await axios({
            method: 'POST',
            url,
            data,
            headers: {
              'Content-Type': file.type,
            },
          });
        } catch (err) {
          throw new Error('Attachment could not be uploaded, Try again');
        }
        return fields['key'];
      })
    );
  };

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    // Sanity check before resubmitting
    if (isSubmitting || note.trim() === '') {
      return;
    }
    try {
      // Set local isSubmitting flag, this track the uploadFileToS3 sync call as well
      setIsSubmitting(true);

      // Enforce only upload photo for need clarificaton when scan is selected
      // Loop over the attachments and upload them to S3
      let awsFileLocations: string[] = [];
      if (isScansSelected && hasFiles) {
        // doing this as teh first thing in case of failures we fail
        // first before we actually change any state
        awsFileLocations = await uploadAttachments();
      }
      if (isPhotosSelected) {
        const variables: SubmitMaterialAggregateReviewMutationVariables = {
          submissionInput: {
            transition: MaterialReviewTransitions.QcRequestedClarification,
            prismSubmissionRef: isPhotosSelected ? prismSubmission?.ref : null,
            evaluationNotes: note,
            transitionAttachments: [],
          },
        };
        await submitReview({
          variables: variables,
        });
      }
      if (isScansSelected) {
        await requestClarification({
          question: note,
          caseRef: currentCase?.caseRef!,
          relatedMaterialIds: scansFromLatestSubmission.map((scan) => scan.id),
          patientId,
          attachmentFileLocations: awsFileLocations,
        });
      }
      if (isXraysSelected) {
        await requestClarification({
          question: note,
          caseRef: currentCase?.caseRef!,
          relatedMaterialIds: xraysFromLatestSubmission.map((xray) => xray.id),
          patientId,
          attachmentFileLocations: awsFileLocations,
        });
      }

      onSubmit(isScansSelected, isXraysSelected, isPhotosSelected);
      setNote('');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChangeNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const handleSelectFiles = async (files: FileList | null) => {
    if (!files) {
      return;
    }

    const fileArray = Array.from(files);
    const selected = await Promise.all(
      fileArray.map(async (file) => {
        const fileSrc = await new Promise<string>((resolve) =>
          readFile(file, resolve)
        );

        return {
          file,
          fileSrc,
        };
      })
    );

    setFiles((prevFiles) => [...prevFiles, ...selected]);
  };

  const handleDeleteFile = (fileName: string) => {
    setFiles((prevFiles) =>
      prevFiles.filter(({ file }) => file.name !== fileName)
    );
  };

  const getPhotosDisabledText = () => {
    if (prismSubmission?.stateData?.data === SubmissionState.Submitted) {
      return '';
    } else if (prismSubmission?.stateData?.data === SubmissionState.Approved) {
      return '(already approved)';
    } else if (prismSubmission?.stateData?.data?.includes('rejected')) {
      return '(already rejected)';
    }
    return '(not yet submitted)';
  };

  const getMaterialsDisabledText = (state?: MaterialState | null) => {
    if (state?.state === MaterialStates.Completed) {
      return '(already approved)';
    } else if (state?.transition === StateTransitions.Reject) {
      return '(already rejected)';
    } else if (state?.state === MaterialStates.NeedsRepair) {
      return '(being repaired)';
    }
  };

  const isLoading =
    isSubmitting || isRequestClarificationLoading || isLegacySubmissionLoading;

  const isOrthoReviewingXrays =
    xrayMaterialState?.state === MaterialStates.AwaitingOrthodonticEvaluation;
  const isOrthoReviewingScans =
    scanMaterialState?.state === MaterialStates.AwaitingOrthodonticEvaluation;

  return (
    <SubForm>
      <FormRow>
        <QuestionHeading>What needs clarification?</QuestionHeading>
        <SmallHeading>Select at least one</SmallHeading>
        <ul>
          <li>
            <Label
              disabled={
                prismSubmission?.stateData?.data !== SubmissionState.Submitted
              }
            >
              <Checkbox
                type="checkbox"
                disabled={
                  prismSubmission?.stateData?.data !== SubmissionState.Submitted
                }
                checked={isPhotosSelected}
                onChange={() => setIsPhotosSelected(!isPhotosSelected)}
              />
              Photos {getPhotosDisabledText()}
            </Label>
          </li>
          <li>
            <Label disabled={!isOrthoReviewingXrays}>
              <Checkbox
                type="checkbox"
                disabled={!isOrthoReviewingXrays}
                checked={isXraysSelected}
                onChange={() => setIsXraysSelected(!isXraysSelected)}
              />
              X-rays {getMaterialsDisabledText(xrayMaterialState)}
            </Label>
          </li>
          <li>
            <Label disabled={!isOrthoReviewingScans}>
              <Checkbox
                type="checkbox"
                disabled={!isOrthoReviewingScans}
                checked={isScansSelected}
                onChange={() => setIsScansSelected(!isScansSelected)}
              />
              Scans {getMaterialsDisabledText(scanMaterialState)}
            </Label>
          </li>
        </ul>
      </FormRow>
      <>
        {isScansSelected && (
          <FormRow>
            <SmallHeading>Attachment(s)</SmallHeading>
            {files.map((f) => (
              <AttachmentRow key={f.file.name}>
                <FileName src={f.fileSrc}>
                  <ImgIcon role="document" />
                  {' ' + f.file.name}
                </FileName>
                <DeleteButton
                  disabled={isLoading}
                  onClick={() => handleDeleteFile(f.file.name)}
                  type="button"
                />
              </AttachmentRow>
            ))}
            <UploadContainer isSmall={hasFiles}>
              <FileUpload
                fileType=".jpg, .jpeg, .png"
                allowMultipleFiles
                isHorizontal={hasFiles}
                isDisabled={isLoading}
                onSelectFile={handleSelectFiles}
                textOverride={hasFiles ? 'Add another' : ''}
                testId={`clarification-attachment`}
              />
            </UploadContainer>
          </FormRow>
        )}

        <FormRow>
          <SmallHeading>Notes to doctor *</SmallHeading>
          <TextAreaFullWidth
            data-testid={`clarification-notes`}
            maxLength={5000}
            onChange={onChangeNotes}
            placeholder="Notes or instructions"
          />
        </FormRow>
      </>
      <ButtonRow>
        <Button
          buttonType="secondary"
          data-testid={`clarification-submit`}
          disabled={!canSubmit() || isLoading}
          isLoading={isLoading}
          isShort
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ButtonRow>
    </SubForm>
  );
};

export default NeedsClarificationForm;
