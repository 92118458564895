import React, { useEffect, useState } from 'react';
import { type } from '@candidco/enamel';
import axios from 'axios';
import ZendeskArticleList, {
  Article,
} from 'pages/HelpCenter/Zendesk/ZendeskArticleList';
import DocumentTitle from 'components/DocumentTitle';
import {
  ContactContainer,
  ContactHeaderSection,
  ContactSection,
  ContactSectionTitle,
  ContactText,
  ContactTypeTitle,
  Content,
  PageBody,
  PageSection,
  PhoneNumber,
  PhoneNumberSubtext,
  PhoneNumberContainer,
  HoursText,
  ResourceContainers,
  BottomContent,
  RightContent,
  ContactHeader,
  ContactSubHeader,
  Title,
} from 'pages/HelpCenter/HelpCenter.css';

import MailSvg from 'assets/icons/small/mail.svg?react';
import PhoneSvg from 'assets/icons/small/phone.svg?react';
import { useAuthContext } from 'components/AuthProvider';
import { getBrandDomainSettings } from 'utils/brands';

type ContactInfoProps = {
  phoneNumbers: PhoneNumber[];
  email: string;
};

type PhoneNumber = {
  phoneNumber: string;
  subtext: string;
};

const ContactInfo = ({ phoneNumbers, email }: ContactInfoProps) => {
  return (
    <ContactContainer>
      <ContactSection>
        <PhoneSvg />
        <ContactText>
          <ContactTypeTitle>PHONE</ContactTypeTitle>

          {phoneNumbers.map((phoneNumber, idx) => (
            <PhoneNumberContainer key={idx}>
              <PhoneNumber>{phoneNumber.phoneNumber}</PhoneNumber>•
              <PhoneNumberSubtext>{phoneNumber.subtext}</PhoneNumberSubtext>
            </PhoneNumberContainer>
          ))}
          <HoursText>Weekdays 9:00 am - 8:00 pm EST</HoursText>
        </ContactText>
      </ContactSection>
      <ContactSection>
        <MailSvg />
        <ContactText>
          <ContactTypeTitle>EMAIL</ContactTypeTitle>
          <type.Link>{email}</type.Link>
        </ContactText>
      </ContactSection>
    </ContactContainer>
  );
};

const HelpCenter = () => {
  const [caseSubmissionArticles, setCaseSubmissionArticles] = useState<
    Array<Article>
  >([]);
  const [marketingInventoryArticles, setMarketingInventoryArticles] = useState<
    Array<Article>
  >([]);
  const [monitoringArticles, setMonitoringArticles] = useState<Array<Article>>(
    []
  );
  const [otherResourcesArticles, setOtherResourcesArticles] = useState<
    Array<Article>
  >([]);

  const { userInfo } = useAuthContext();
  const { supportEmail, monitoringLabel } = getBrandDomainSettings();

  const configs = userInfo?.brandInfo?.configs?.zendesk;

  const caseSubmissionId = configs?.caseSubmissionArticleId ?? '';
  const marketingInventoryId = configs?.marketingInventoryArticleId ?? '';
  const monitoringId = configs?.monitoringArticleId ?? '';
  const otherResourceId = configs?.otherResourcesArticleId ?? '';
  const baseUrl = configs?.zendeskBaseUrl ?? '';

  const fetchZDArticles = async (
    resourcesId: string,
    setState: React.Dispatch<React.SetStateAction<Article[]>>
  ) => {
    const result = await axios.get(`${baseUrl}/${resourcesId}/articles.json`);
    setState(result.data.articles);
  };

  const ContactUs = () => {
    return (
      <>
        <ContactHeaderSection>
          <ContactHeader>More ways to get help</ContactHeader>

          <ContactSubHeader>
            Have a technical, clinical, or patient status question? Here are two
            more ways to connect with our Provider Support Team:
          </ContactSubHeader>
        </ContactHeaderSection>
        {userInfo?.brandInfo?.name === 'glidewell' ? (
          <GlidewellContactInfo />
        ) : (
          <CandidContactInfo />
        )}
      </>
    );
  };

  const GlidewellContactInfo = () => {
    return (
      <>
        <ContactSectionTitle>For case support</ContactSectionTitle>
        <ContactInfo
          phoneNumbers={[
            { phoneNumber: '+1 (888) 335-4696', subtext: 'call or text' },
          ]}
          email="doctor@promonitoring.ai"
        />
        <ContactSectionTitle>For billing/finance support</ContactSectionTitle>
        <ContactInfo
          phoneNumbers={[
            { phoneNumber: '(800) 854-7256', subtext: 'USA' },
            { phoneNumber: '(888) 278-0414', subtext: 'Canada' },
          ]}
          email="mail@glidewelldental.com"
        />
      </>
    );
  };

  const CandidContactInfo = () => {
    return (
      <ContactInfo
        phoneNumbers={[
          { phoneNumber: '(855) 640-8606', subtext: 'call or text' },
        ]}
        email={supportEmail as string}
      />
    );
  };

  useEffect(() => {
    fetchZDArticles(caseSubmissionId, setCaseSubmissionArticles);
    fetchZDArticles(marketingInventoryId, setMarketingInventoryArticles);
    fetchZDArticles(monitoringId, setMonitoringArticles);
    fetchZDArticles(otherResourceId, setOtherResourcesArticles);
  }, [userInfo]);

  return (
    <DocumentTitle title="Help center">
      <PageSection>
        <PageBody>
          <Title>Help center</Title>
          <Content>
            <ResourceContainers>
              <ZendeskArticleList
                title="Case Submission"
                articles={caseSubmissionArticles}
              />
              <ZendeskArticleList
                title="Marketing & Inventory"
                articles={marketingInventoryArticles}
              />
              <ZendeskArticleList
                title={`${monitoringLabel as string}`}
                articles={monitoringArticles}
              />
              <ZendeskArticleList
                title="Other Resources"
                articles={otherResourcesArticles}
              />
              <BottomContent>
                <ContactUs />
              </BottomContent>
            </ResourceContainers>
            <RightContent
              isGlidewell={userInfo?.brandInfo?.name === 'glidewell'}
            >
              <ContactUs />
            </RightContent>
          </Content>
        </PageBody>
      </PageSection>
    </DocumentTitle>
  );
};

export default HelpCenter;
