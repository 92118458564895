import React from 'react';
import LeftArrowSVG from 'assets/arrow-left.svg?react';
import { useHistory } from 'react-router-dom';

import { BackButton } from 'styles/layout.css';
import { Grid, Heading } from '@candidco/enamel';

export const PageHeader = ({ title }: { title: string }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      style={{ padding: '0 1rem' }}
    >
      <Grid item>
        <BackLink />
      </Grid>
      <Grid item>
        <Heading variant={'h4'}>{title}</Heading>
      </Grid>
    </Grid>
  );
};

export const BackLink = () => {
  const history = useHistory();

  return (
    <BackButton onClick={history.goBack}>
      <LeftArrowSVG aria-hidden />
      Back
    </BackButton>
  );
};
