import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FormRow,
  Label,
  Card,
  AddressDetailCard,
  StyledImage,
  Checkbox,
  TextLine,
  BoldLine,
  AddressContainer,
  EditPatientText,
  LeftAddressSection,
  SideNote,
  ContactSupportSection,
  ContactSupportText,
  HelpSvg,
  Container,
} from 'components/AddressSelection/AddressSelection.css';
import {
  Grid,
  Avatar as EnamelAvatar,
  AlertCard,
  type,
  colors,
  NotificationContext,
} from '@candidco/enamel';
import {
  patientAddressSimilarToPractice,
  Address as AddressType,
} from 'utils/shippingAddress';
import HomeAddressPng from 'assets/home-address.png';
import PracticeAddressPng from 'assets/candid-academy.png';
import OtherAddressPng from 'assets/other-address.png';
import EditPatientModal from 'components/EditPatientModal/EditPatientModal';

import {
  SelectedAddress,
  SelectedAddressType,
} from 'components/AddressForm/types';

import {
  selectPatient,
  fetchPatient,
  selectHomeAddress,
} from 'pages/Patient/patientSlice';
import OtherAddressForm from 'components/AddressSelection/OtherAddressForm';
import ContactSupport from 'components/ContactSupport';

type SelectedAddressInfo = {
  addressType: SelectedAddressType;
  value: SelectedAddress;
  image: string;
  label: string;
  sendPatientUpdateDefaultOption: boolean;
};

type AvatarProps = {
  selectedAddress: SelectedAddressInfo;
  patient: any;
};
const Avatar = ({ selectedAddress, patient }: AvatarProps) => {
  return (
    <EnamelAvatar
      style={{
        width: 67,
        height: 67,
        backgroundColor: colors.blue50,
        paddingTop: '0.25rem',
        marginTop: '0.2rem',
        fontSize: '2rem',
        marginRight: '16px',
      }}
    >
      {selectedAddress?.value?.businessName ? (
        <>{`${selectedAddress?.value?.businessName[0]}`}</>
      ) : (
        <>{patient && `${patient?.firstName[0]}${patient?.lastName[0]}`}</>
      )}
    </EnamelAvatar>
  );
};

const AddressText = ({
  selectedAddress,
}: {
  selectedAddress: SelectedAddressInfo;
}) => (
  <>
    <TextLine>
      {selectedAddress?.value?.addressLine1}{' '}
      {selectedAddress?.value?.addressLine2}
    </TextLine>
    <TextLine>{`${selectedAddress?.value?.city}, ${selectedAddress?.value?.stateCode} ${selectedAddress?.value?.zip}`}</TextLine>
  </>
);

type AddressProps = {
  selectedAddress: SelectedAddressInfo;
  patient: any;
};
const Address = ({ selectedAddress, patient }: AddressProps) => {
  switch (selectedAddress?.addressType) {
    case 'patient':
      return (
        <>
          <Avatar selectedAddress={selectedAddress} patient={patient} />
          <AddressContainer>
            {selectedAddress?.value?.businessName ? (
              <BoldLine>{selectedAddress?.value?.businessName}</BoldLine>
            ) : (
              <BoldLine>{`${patient?.firstName} ${patient?.lastName}`}</BoldLine>
            )}
            <AddressText selectedAddress={selectedAddress} />
          </AddressContainer>
        </>
      );
    case 'practice':
      return (
        <AddressContainer>
          <BoldLine>{selectedAddress?.value?.businessName}</BoldLine>
          <AddressText selectedAddress={selectedAddress} />
        </AddressContainer>
      );
    default:
      return null;
  }
};

type AddressSelectionCardProps = {
  addressSelections: SelectedAddressInfo[];
  selectedAddress: SelectedAddressInfo;
  handleAddressSelection: (address: SelectedAddressInfo) => void;
};
const AddressSelectionCard = ({
  addressSelections,
  selectedAddress,
  handleAddressSelection,
}: AddressSelectionCardProps) => {
  return (
    <Grid container spacing={1}>
      {addressSelections.map((address) => (
        <Grid key={address.addressType} item xs={12} md={4}>
          <Card
            style={{ cursor: 'pointer' }}
            selected={address.addressType === selectedAddress?.addressType}
            onClick={() => handleAddressSelection(address)}
            isRowOnMobile
          >
            <StyledImage src={address.image}></StyledImage>
            <type.H6>{address.label}</type.H6>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

type AddressDetailProps = {
  selectedAddress: SelectedAddressInfo;
  setSelectedAddress: (address: SelectedAddressInfo) => void;
  otherAddressFormRef: any;
  patient: any;
  handleOpenEditPatient: () => void;
  onOtherAddressValidationConfirm: (arg0: any) => void;
  addressMatch: boolean;
};
const AddressDetail = ({
  selectedAddress,
  setSelectedAddress,
  patient,
  handleOpenEditPatient,
  otherAddressFormRef,
  onOtherAddressValidationConfirm,
  addressMatch,
}: AddressDetailProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {['patient', 'practice'].some(
          (a) => a === selectedAddress?.addressType
        ) ? (
          <AddressDetailCard>
            <Grid
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
              container
            >
              <Grid item xs={7}>
                <Grid
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  container
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <LeftAddressSection>
                      <Address
                        selectedAddress={selectedAddress}
                        patient={patient}
                      />
                    </LeftAddressSection>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} style={{ textAlign: 'right' }}>
                {selectedAddress?.addressType === 'patient' && (
                  <EditPatientText onClick={handleOpenEditPatient}>
                    Edit patient home address
                  </EditPatientText>
                )}
              </Grid>
            </Grid>
            {selectedAddress?.addressType === 'practice' && (
              <Grid
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                container
              >
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <ContactSupportSection>
                    <HelpSvg />
                    <ContactSupportText>
                      <ContactSupport text="Contact support" />
                      {` if your practice address isn't accurate`}
                    </ContactSupportText>
                  </ContactSupportSection>
                </Grid>
              </Grid>
            )}
          </AddressDetailCard>
        ) : (
          <OtherAddressForm
            submitButtonRef={otherAddressFormRef}
            onFormSubmit={(values) => {
              setSelectedAddress({
                ...selectedAddress,
                value: values,
              });
              onOtherAddressValidationConfirm({ ...values });
            }}
          />
        )}

        {addressMatch && selectedAddress?.addressType === 'patient' && (
          <div style={{ marginBottom: '32px' }}>
            <AlertCard
              header="Double check address"
              displayIcon={true}
              body="It looks you’re using your practice’s address for the patient home address."
              type="default"
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

type SentPatientShippingUpdateSelectionProps = {
  sendPatientUpdate: boolean;
  setSendPatientUpdate: (value: boolean) => void;
};
const SentPatientShippingUpdateSelection = ({
  sendPatientUpdate,
  setSendPatientUpdate,
}: SentPatientShippingUpdateSelectionProps) => {
  return (
    <FormRow>
      <Label style={{ marginBottom: 0 }}>
        <Checkbox
          checked={sendPatientUpdate}
          onChange={() => setSendPatientUpdate(!sendPatientUpdate)}
          type="checkbox"
          data-testid="PrismPhotoReview-ReasonsModal-Checkbox"
        />
        Send shipping update emails to the patient's email address?
      </Label>
      <SideNote>You will receive shipping update emails either way</SideNote>
    </FormRow>
  );
};

type AddressSelectionProps = {
  setResultAddress: (value: SelectedAddressInfo) => void;
  setResultSendPatientUpdate: (value: boolean) => void;
  otherAddressFormRef: React.RefObject<HTMLButtonElement>;
  onOtherAddressValidationConfirm: (arg0: any) => void;
};
export const AddressSelection = ({
  setResultAddress,
  setResultSendPatientUpdate,
  otherAddressFormRef,
  onOtherAddressValidationConfirm,
}: AddressSelectionProps) => {
  const patient = useSelector(selectPatient);
  const patientAddress = useSelector(selectHomeAddress);
  const practiceAddress = useMemo(() => patient?.practice?.address, [patient]);
  const dispatch = useDispatch();
  const [sendPatientUpdate, setSendPatientUpdate] = useState<boolean>(false);
  const addressSelections: SelectedAddressInfo[] = [
    {
      addressType: 'practice',
      image: PracticeAddressPng,
      sendPatientUpdateDefaultOption: false,
      value: {
        firstName: patient?.firstName,
        lastName: patient?.lastName,
        businessName: practiceAddress?.name,
        addressLine1: practiceAddress?.addressLine1,
        addressLine2: practiceAddress?.addressLine2,
        city: practiceAddress?.city,
        zip: practiceAddress?.zip,
        stateCode: practiceAddress?.stateCode,
        countryCode: practiceAddress?.countryCode,
      },
      label: 'Practice address',
    },
    {
      addressType: 'patient',
      image: HomeAddressPng,
      sendPatientUpdateDefaultOption: true,
      value: {
        firstName: patient?.firstName,
        lastName: patient?.lastName,
        businessName: patientAddress?.businessName,
        addressLine1: patientAddress?.addressLine1,
        addressLine2: patientAddress?.addressLine2,
        city: patientAddress?.city,
        zip: patientAddress?.zip,
        stateCode: patientAddress?.stateCode,
        countryCode: patientAddress?.countryCode,
      },
      label: 'Patient home address',
    },
    {
      addressType: 'other',
      image: OtherAddressPng,
      sendPatientUpdateDefaultOption: false,
      value: {},
      label: 'Somewhere else',
    },
  ];
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddressInfo>(
    addressSelections.find((a) => a.addressType === 'practice') ??
      addressSelections[0]
  );

  useEffect(() => {
    setResultAddress(selectedAddress);
  }, [selectedAddress]);

  useEffect(() => {
    setResultSendPatientUpdate(sendPatientUpdate);
  }, [sendPatientUpdate]);

  const { showNotification } = useContext(NotificationContext);

  const handleCancelEditPatient = () => {
    setIsEditPatientModalOpen(false);
  };

  const handleOpenEditPatient = () => {
    setIsEditPatientModalOpen(true);
  };

  // use effect to re-set selectedAddress
  useEffect(() => {
    // if patient address is selected, update it
    if (selectedAddress?.addressType === 'patient') {
      handleAddressSelection(
        addressSelections.find((a) => a.addressType === 'patient') ??
          addressSelections[0]
      );
    }
  }, [patient]);

  const handleAddressSelection = (addressInfo: SelectedAddressInfo) => {
    setSelectedAddress(addressInfo);
    setSendPatientUpdate(addressInfo?.sendPatientUpdateDefaultOption || false);
  };

  const [isEditPatientModalOpen, setIsEditPatientModalOpen] = useState(false);

  const addressMatch = patientAddressSimilarToPractice(
    {
      ...practiceAddress,
      businessName: practiceAddress?.name || '',
    } as AddressType,
    { ...patientAddress } as AddressType
  );

  if (!patient) {
    return null;
  }

  const handleEditPatientSuccess = () => {
    showNotification('Successfully updated patient info', 'success');
    setIsEditPatientModalOpen(false);
    dispatch(fetchPatient({ customerId: patient.id }));
  };

  return (
    <Container>
      <EditPatientModal
        showShippingNote={false}
        isOpen={isEditPatientModalOpen}
        onClose={handleCancelEditPatient}
        onSuccess={handleEditPatientSuccess}
        patientData={patient}
        shippingAddress={patientAddress}
      />
      <AddressSelectionCard
        addressSelections={addressSelections}
        selectedAddress={selectedAddress}
        handleAddressSelection={handleAddressSelection}
      />
      <AddressDetail
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        patient={patient}
        handleOpenEditPatient={handleOpenEditPatient}
        addressMatch={addressMatch}
        otherAddressFormRef={otherAddressFormRef}
        onOtherAddressValidationConfirm={onOtherAddressValidationConfirm}
      />
      <SentPatientShippingUpdateSelection
        sendPatientUpdate={sendPatientUpdate}
        setSendPatientUpdate={setSendPatientUpdate}
      />
    </Container>
  );
};
