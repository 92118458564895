import React, {
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/macro';
import { colors } from '@candidco/enamel';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Spacer } from 'styles/layout.css';

const Wrapper = styled.div`
  position: relative;
`;

const Title = styled.div``;

const Caret = styled.div`
  color: ${colors.blue};
`;

const DropdownBody = styled.div`
  position: absolute;
  right: 0;
  background: ${colors.white};
  z-index: 9999;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  min-width: 240px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 8px;
`;

const DropdownItem = styled.div`
  position: relative;
  padding: 0.5rem 2rem;
  cursor: pointer;

  &:hover {
    background: ${colors.black20};
  }
`;

type DropdownProps = {
  title?: React.ReactNode | string;
  isOpen?: boolean;
  children: any;
  defaultIndex?: number;
  retainSelectedAsPlaceholder?: boolean;
  showCaret?: boolean;
};

const useOutsideClick = (
  ref: RefObject<HTMLDivElement>,
  callback: () => void
) => {
  const domEvent = 'mousedown';
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener(domEvent, handleClick);
    return () => document.removeEventListener(domEvent, handleClick);
  });
};

const Body = ({
  children,
  setIsOpenInternal,
  setSelectedIndex,
}: {
  children: ReactElement[];
  setIsOpenInternal: (isOpen: boolean) => void;
  setSelectedIndex: (selectedIndex: number) => void;
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, () => setIsOpenInternal(false));

  if (children) {
    return (
      <DropdownBody ref={modalRef}>
        {children.map((child: ReactElement, idx: number) =>
          child ? (
            <DropdownItem
              key={idx}
              onClick={(e) => {
                //If the user clicks the UI element inside the dropdown (the text)
                //The event will be executed once on the child UI element, then again from here
                //But if the user click outside the UI element (the whitespace around the text)
                //it will only be executed once. So check to see if the dropdown itself was click
                //and if so call the child's on click. Otherwise do nothing
                if (e.target === e.currentTarget) {
                  child.props.onClick(e);
                }
                setIsOpenInternal(false);
                setSelectedIndex(idx);
              }}
            >
              {child}
            </DropdownItem>
          ) : null
        )}
      </DropdownBody>
    );
  }

  return <></>;
};

export const Dropdown = ({
  isOpen,
  title,
  children,
  defaultIndex = -1,
  retainSelectedAsPlaceholder,
  showCaret,
}: DropdownProps) => {
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen);
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);

  return (
    <Wrapper>
      <Spacer
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpenInternal(!isOpenInternal)}
        spacing="0.25rem"
      >
        <Title>
          {retainSelectedAsPlaceholder
            ? selectedIndex > -1
              ? children[selectedIndex]
              : title
            : title}
        </Title>
        {showCaret ? (
          <Caret>
            {isOpenInternal ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Caret>
        ) : null}
      </Spacer>
      {isOpenInternal && (
        <Body
          setIsOpenInternal={setIsOpenInternal}
          setSelectedIndex={setSelectedIndex}
        >
          {children}
        </Body>
      )}
    </Wrapper>
  );
};
