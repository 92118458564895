import React from 'react';
import styled from 'styled-components/macro';
import { Text, Heading, Grid } from '@candidco/enamel';
import { StyledThumbsUpSVG } from 'pages/ActionItems/ActionItems.css';
import { headerHeight } from 'styles/layout.css';

const EmptyStateContainer = styled(Grid)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  padding: 10px;
  height: calc(100vh - ${headerHeight});
`;

const PaddedItem = styled(Grid)`
  padding: 10px 0px;
`;

const FirstPaddedItem = styled(Grid)`
  padding-top: 10%;
`;

const TextItem = styled(PaddedItem)`
  min-width: 300px;
  max-width: 400px;
  text-align: center;
`;

export const NoTaskMessage = () => {
  return (
    <EmptyStateContainer container>
      <FirstPaddedItem item>
        <StyledThumbsUpSVG />
      </FirstPaddedItem>
      <PaddedItem item>
        <Heading variant={'h4'}>No action items at this time</Heading>
      </PaddedItem>
      <TextItem item>
        <Text>
          You’ve successfully submitted all your patients. Give yourself a pat
          on the back.
        </Text>
      </TextItem>
    </EmptyStateContainer>
  );
};
