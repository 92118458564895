import ShaheenAvatar from 'assets/headshots/dr-shaheen-avatar.png';
import StevensonAvatar from 'assets/headshots/dr-stevenson-avatar.png';
import KabotAvatar from 'assets/headshots/dr-kabot-avatar.png';
import SiddiqiAvatar from 'assets/headshots/dr-siddiqi-avatar.png';
import { getBrandDomainSettings } from 'utils/brands';
import { BRAND_ORTHO_BIOS } from 'constants/brands';

interface DoctorConfig {
  displayName: string;
  avatar: string;
}

const ORTHO_CONFIG_BY_EMAIL: { [key: string]: DoctorConfig } = {
  'ed@candidco.com': {
    displayName: 'Dr. Ed Shaheen',
    avatar: ShaheenAvatar,
  },
  'dr.michael.kabot@candidco.com': {
    displayName: 'Dr. Michael Kabot',
    avatar: KabotAvatar,
  },
  'dr.perry.stevenson@candidco.com': {
    displayName: 'Dr. Perry Stevenson',
    avatar: StevensonAvatar,
  },
  'dr.ammar.siddiqi@candidco.com': {
    displayName: 'Dr. Ammar Siddiqi',
    avatar: SiddiqiAvatar,
  },
};

export const getOrthoName = (email: string): string => {
  if (ORTHO_CONFIG_BY_EMAIL[email]) {
    return ORTHO_CONFIG_BY_EMAIL[email].displayName;
  }
  return email;
};

export const getOrthoAvatar = (email: string): string | null => {
  if (ORTHO_CONFIG_BY_EMAIL[email]) {
    return ORTHO_CONFIG_BY_EMAIL[email].avatar;
  }
  return null;
};

export const getOrthoBioUrl = (email: string): string | null => {
  const { name } = getBrandDomainSettings();
  const orthoBio = BRAND_ORTHO_BIOS[name.toString()];
  if (email && orthoBio) {
    return orthoBio[email];
  }

  return null;
};
