import styled from 'styled-components/macro';
import { hideVisually } from 'polished';
import ReactSelect from 'react-select';
import { CSSProperties } from 'react';
import { colors, type } from '@candidco/enamel';

export const Label = styled.label`
  margin-bottom: 0.5rem;
  font-size: ${type.size.small};
  line-height: 1;
`;

export const Input = styled.input<{ hasError?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 0.625rem 0.75rem;
  background: ${colors.white};
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red50 : colors.black20)};
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out;

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const StyledReactSelect = styled(ReactSelect)<{ hasError?: boolean }>`
  width: 100%;
  height: 38px;
  background: white;
`;

export const CustomerReactSelectStyles = {
  control: (provided: CSSProperties, state: any) => {
    return {
      ...provided,
      borderRadius: '4px',
      borderColor: state.selectProps.hasError ? colors.red50 : colors.black20,
      height: '40px',
    };
  },
  valueContainer: (provided: CSSProperties) => {
    return {
      ...provided,
      height: '40px',
    };
  },
};

export const CheckboxLabel = styled.label`
  display: inline-flex;
  cursor: pointer;

  ${Input} {
    width: auto;
    margin-top: 0.1875rem;
    margin-right: 0.5rem;
    vertical-align: text-top;
    border: 0;
    border-radius: 0;
    appearance: checkbox;
  }
`;

export const TextArea = styled.textarea<{ hasError?: boolean }>`
  display: block;
  width: 100%;
  padding: 0.5rem;
  line-height: inherit;
  background: ${colors.white};
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red50 : colors.black20)};
  border-radius: 0.125rem;
  transition: border-color 0.15s ease-in-out;
`;

export const ErrorText = styled.div`
  margin-top: 0.5rem;
  font-size: ${type.size.small};
  color: ${colors.red50};
`;

export const FileInput = styled.input`
  ${hideVisually()};
`;

type LinkButtonProps = {
  color?: string;
  hoverColor?: string;
};

export const LinkButton = styled.a<LinkButtonProps>`
  font-weight: ${type.weight.default};
  color: ${({ color = colors.blue50 }) => color};
  transition:
    background 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover {
    color: ${({ hoverColor = colors.blue70 }) => hoverColor};
    text-decoration: underline;
    cursor: pointer;
  }
`;
