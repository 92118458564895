import styled from 'styled-components/macro';
import { theme, Text } from '@candidco/enamel';
import Help from 'assets/fi_info.svg?react';

export const FormRow = styled.div`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 2.5rem;
  }

  p {
    margin-bottom: 0;
  }
`;
export const Label = styled.label`
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    align-items: flex-start;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const Card = styled.div<{ selected?: boolean; isRowOnMobile?: boolean }>`
  text-align: center;
  border: 1px solid
    ${({ selected }) => (selected ? theme.colors.blue50 : theme.colors.black20)};
  border-radius: 12px;
  padding: 32px 16px;
  gap: 40px;

  ${({ isRowOnMobile, theme }) =>
    isRowOnMobile &&
    `@media ${theme.mediaQueries.mobile} {
      display: flex;
      align-items: center;
      padding: 12px 16px;

      h6 {
        margin: 0;
      }
    }`}
`;

export const AddressDetailCard = styled(Card)`
  padding: 24px;
  margin: 24px 0;
`;

export const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 64px;
    height: 64px;
    margin-bottom: 0;
  }
`;

export const TextLine = styled(Text)`
  font-size: 0.875rem;
  margin-bottom: 0px;
`;

export const BoldLine = styled.h4`
  margin-bottom: 0px;
`;

export const EditPatientText = styled(Text)`
  color: ${({ theme }) => theme.colors.blue50};
  cursor: pointer;
  padding-bottom: 3rem;
`;
export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: initial;
`;
export const LeftAddressSection = styled.div`
  display: inline-flex;
  margin-bottom: 0px;
`;
export const SideNote = styled(Text)`
  color: ${({ theme }) => theme.colors.black70};
  padding-left: 1.5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.tiny};
  }
`;

export const ContactSupportButton = styled.span`
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

export const ContactSupportSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.black70};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    font-size: ${({ theme }) => theme.fontSizes.tiny};
  }
`;

export const ContactSupportText = styled.div`
  margin-left: 0.5rem;
`;

export const HelpSvg = styled(Help)`
  margin-bottom: 0.15rem;
  width: 1.5rem;
`;

export const Checkbox = styled.input`
  margin-top: 0.125rem;
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: checkbox;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 0.25rem;
  }
`;

export const Container = styled.div`
  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    width: 100%;
  }
`;
