import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentTitle from 'components/DocumentTitle';
import { SidebarLayout } from 'components/SidebarLayout';
import styled from 'styled-components/macro';
import PracticeSearch from 'pages/PracticeManagement/shared/PracticeSearch';
import {
  fetchAllPractices,
  getSelectedPractice,
  getSelectedView,
  PracticeManagementView,
  setSelectedView,
} from 'pages/PracticeManagement/shared/slice';
import { Link } from 'react-router-dom';
import { Spacer } from 'styles/layout.css';
import { Divider } from '@candidco/enamel';
import PracticeLoyaltyProgramOverview from 'pages/PracticeManagement/LoyaltyProgramSettings/PracticeLoyaltyProgramOverview';
import PracticePricing from 'pages/PracticeManagement/Pricing/PracticePricing';

const Container = styled.div`
  padding: 1rem;
`;

const BodyContainer = styled(Container)`
  max-width: calc(100vw - 335px);
`;

const PracticeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.blue50 : theme.colors.black87};
`;

const PracticeInfo = () => {
  const selectedPractice = useSelector(getSelectedPractice);
  if (!selectedPractice) {
    return null;
  }
  return (
    <PracticeInfoContainer>
      <h4>{selectedPractice?.name}</h4>
      <small>Practice ID: {selectedPractice?.id}</small>
      {selectedPractice?.salesforceChildAccountId && (
        <Link
          to={{
            pathname: `https://candidco.lightning.force.com/lightning/r/Account/${selectedPractice?.salesforceChildAccountId}/view`,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <small>
            SF Child ID: {selectedPractice?.salesforceChildAccountId}
          </small>
        </Link>
      )}
      <small>Account ID: {selectedPractice?.account.id}</small>
      {selectedPractice?.account.salesforceParentAccountId && (
        <Link
          to={{
            pathname: `https://candidco.lightning.force.com/lightning/r/Account/${selectedPractice?.account.salesforceParentAccountId}/view`,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <small>
            SF Parent ID: {selectedPractice?.account.salesforceParentAccountId}
          </small>
        </Link>
      )}
      <small>
        Visible on Marketing Site:{' '}
        {selectedPractice?.isVisible ? 'true' : 'false'}
      </small>
    </PracticeInfoContainer>
  );
};

const Menu = () => {
  const dispatch = useDispatch();
  const selectedView = useSelector(getSelectedView);

  return (
    <PracticeInfoContainer>
      <h4>Menu</h4>
      <Spacer isVertical>
        {Object.entries(PracticeManagementView).map(([key, value]) => (
          <MenuItem
            key={key}
            isActive={selectedView === value}
            onClick={() => dispatch(setSelectedView(value))}
          >
            {value}
          </MenuItem>
        ))}
      </Spacer>
    </PracticeInfoContainer>
  );
};

const PracticeSidebar = () => {
  return (
    <Container>
      <Spacer isVertical>
        <PracticeSearch />
        <Divider />
        <PracticeInfo />
        <Divider />
        <Menu />
      </Spacer>
    </Container>
  );
};

const OverviewBody = () => {
  const selectedView = useSelector(getSelectedView);
  if (selectedView === PracticeManagementView.LOYALTY) {
    return (
      <BodyContainer>
        <PracticeLoyaltyProgramOverview />
      </BodyContainer>
    );
  }
  if (selectedView === PracticeManagementView.PRICING) {
    return (
      <BodyContainer>
        <PracticePricing />
      </BodyContainer>
    );
  }
  return (
    <BodyContainer>
      <p>Select a practice from the dropdown then a menu option.</p>
      <p>You can narrow the list by typing in the name of the practice.</p>
    </BodyContainer>
  );
};

const PracticeOverview = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPractices());
  }, []);

  return (
    <DocumentTitle title="Practice Management">
      <SidebarLayout isOpen>
        {{
          sidebar: PracticeSidebar(),
          body: OverviewBody(),
        }}
      </SidebarLayout>
    </DocumentTitle>
  );
};

export default PracticeOverview;
