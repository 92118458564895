import styled from 'styled-components';
import { colors, theme, type } from '@candidco/enamel';

export const HeaderText = styled.div`
  font-weight: ${theme.fontWeights.bold};
  line-height: 150%;
  font-size: ${type.size.medium};
  color: ${colors.black90};
`;
export const SubHeaderText = styled.div`
  font-size: ${type.size.tiny};
  line-height: 150%;
  color: ${colors.black70};
  letter-spacing: 0.01em;
  padding-bottom: 0.75rem;
`;

export const ImgContainer = styled.img`
  background-color: ${colors.white};
  height: 20rem;
  width: 30rem;
`;

//The right half of the screen
export const RightPanel = styled.div<{ backgroundImgSrc: string }>`
  width: 100%;
  background-image: url(${(props) => props.backgroundImgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${theme.mediaQueries.tabletAndBelow} {
    display: none;
  }
`;

//Wraps the launch notes header and elements,
export const RightContainer = styled.div`
  max-width: 30rem;
  margin: auto;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LaunchNotesContainer = styled.div`
  padding: 2rem;
  background-color: ${colors.white};

  .announcement-card-wrapper {
    font-family: ${type.family.modernEra};

    border: 1px solid ${colors.black20};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.125rem;
  }
`;

export const RightFloatingContent = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
`;
