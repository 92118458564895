import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { captureException } from '@sentry/browser';
import { Button, Modal } from '@candidco/enamel';
import OptionsBox from 'components/OptionsBox';
import NewScanIcon from 'assets/ic-file-plus.svg?react';
import ReuseLastStepIcon from 'assets/fi_refresh-cw.svg?react';
import {
  selectPatient,
  selectIsLastStepEligible,
} from 'pages/Patient/patientSlice';
import {
  Container,
  OptionsContainer,
  OptionsTitle,
  ModalContainer,
  Header,
  CancelButton,
  ButtonContainer,
  ModalContent,
} from 'pages/Patient/PatientDetail/MoreRetainers.css';
import { NotificationContext } from '@candidco/enamel';
import { useCreateRetainerCase } from 'pages/Patient/utils';

const MoreRetainers = () => {
  const { push } = useHistory();
  const { showNotification } = useContext(NotificationContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const patient = useSelector(selectPatient);
  const isLastStepEligible = useSelector(selectIsLastStepEligible);
  const createRetainerCase = useCreateRetainerCase();

  if (!patient) {
    return null;
  }

  const onClickUseNewScans = async () => {
    try {
      createRetainerCase({ patient });
    } catch (err) {
      captureException(err);
      showNotification(
        'Sorry there was an issue, please try again or reach out to support',
        'error'
      );
    }
  };

  const onClickReorder = () => {
    push(
      `/patient/${patient?.id}/case-creator/checkout?retainer_override=true&reorder=true`
    );
  };

  return (
    <Container data-testid="pdp-more-retainers">
      <Modal
        isOpen={showConfirmModal}
        hideCloseButton
        onClose={() => {
          setShowConfirmModal(false);
        }}
      >
        <ModalContainer>
          <ModalContent>
            <Header>Do you want to complete the current case?</Header>
            <div>
              Using new scans will complete the current case and start a new
              one.
            </div>
          </ModalContent>
          <ButtonContainer>
            <CancelButton
              buttonType="text"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </CancelButton>
            <Button
              buttonType="secondary"
              onClick={async () => {
                await onClickUseNewScans();
                setShowConfirmModal(false);
              }}
            >
              Yes, continue
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </Modal>
      <OptionsTitle>Need more retainers?</OptionsTitle>
      <OptionsContainer>
        <OptionsBox
          Icon={NewScanIcon}
          title="Use new scans"
          description="For optimal results, send us new scans to create retainers based on the patient’s current anatomy."
          onClick={() => setShowConfirmModal(true)}
          selected={false}
          chipText="PREFERRED"
        />
        <OptionsBox
          Icon={ReuseLastStepIcon}
          title="Re-order these retainers"
          description="If the patient’s anatomy matches the current retainers, you can order the same retainer again."
          onClick={onClickReorder}
          selected={false}
          disabled={!isLastStepEligible}
        />
      </OptionsContainer>
    </Container>
  );
};

export default MoreRetainers;
