import { AUTH_GROUP_TYPES } from 'constants';

export const setTokens = (accessToken, refreshToken, email) => {
  localStorage.setItem('auth_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
  localStorage.setItem('ld_key', email);
};
export const setLDKey = (email) => localStorage.setItem('ld_key', email);

export const getAuthToken = () => localStorage.getItem('auth_token');
export const getRefreshToken = () => localStorage.getItem('refresh_token');
export const getLDKey = () => localStorage.getItem('ld_key');

export const removeTokens = () => {
  localStorage.removeItem('auth_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('ld_key');
};

export const setAuthGroups = (groups) =>
  localStorage.setItem('auth_groups', JSON.stringify(groups));
export const getAuthGroups = () =>
  JSON.parse(localStorage.getItem('auth_groups'));
export const removeAuthGroups = () => localStorage.removeItem('auth_groups');

export const checkAuthGroups = (authGroups) =>
  authGroups.some((group) => AUTH_GROUP_TYPES.includes(group));
export const isAuthenticated = () => {
  const isLoggedIn = getAuthToken();
  const authGroups = getAuthGroups();

  if (!isLoggedIn || !authGroups) {
    return false;
  }

  return checkAuthGroups(authGroups);
};
