import React, { useState } from 'react';
import Dinero from 'dinero.js';
import styled from 'styled-components/macro';
import {
  TextInput,
  SelectInput,
  Loading,
  Box,
  Text,
  colors,
} from '@candidco/enamel';

import {
  AdjustmentTypes,
  AdjustmentCategory,
  MutationsAddLineItemAdjustmentArgs,
} from 'generated/core/graphql';
import { ReactSelectOption } from 'utils/types';
import PlusIcon from 'assets/ic_plus.svg?react';
import ArrowIcon from 'assets/arrow-right-small.svg?react';

const StyledPlusIcon = styled(PlusIcon)`
  margin-left: 1rem;
  cursor: pointer;
`;

const StyledTextInput = styled(TextInput)`
  display: inline-block;

  input {
    height: 2rem;
    padding: 0 6px;
  }
`;

const StyledFormRow = styled.tr`
  td {
    padding: 0.5rem 1rem;
  }
`;

const ADJUSTMENT_CATEGORY_OPTIONS = [
  { label: AdjustmentCategory.Tax, value: AdjustmentCategory.Tax },
  { label: AdjustmentCategory.Loyalty, value: AdjustmentCategory.Loyalty },
  { label: AdjustmentCategory.Other, value: AdjustmentCategory.Other },
];

const ADJUSTMENT_TYPE_OPTIONS = [
  { label: AdjustmentTypes.Debit, value: AdjustmentTypes.Debit },
  { label: AdjustmentTypes.Credit, value: AdjustmentTypes.Credit },
];

const INITIAL_FORM_STATE = {
  invoiceLineItemId: '',
  note: '',
  adjustmentCategory: AdjustmentCategory.Tax,
  adjustmentType: AdjustmentTypes.Debit,
  adjustmentAmountInDollars: 0,
};

const LineItemForm = ({
  originalFee,
  onAddLineItemAdjustment,
  isSubmittingAdjustment = false,
}: {
  originalFee: number;
  isSubmittingAdjustment: boolean;
  onAddLineItemAdjustment: (
    payload: MutationsAddLineItemAdjustmentArgs
  ) => void;
}) => {
  const [formValues, setFormValues] = useState(INITIAL_FORM_STATE);
  const { adjustmentType, adjustmentAmountInDollars } = formValues;
  const adjustmentAmountInCents = Math.round(
    formValues.adjustmentAmountInDollars * 100
  );

  const handleSubmit = async () => {
    const payload = {
      ...formValues,
      adjustmentAmountInCents,
    };
    await onAddLineItemAdjustment(payload);
    setFormValues(INITIAL_FORM_STATE);
  };

  const originalFeeDinero = Dinero({ amount: originalFee });

  let feePreview = null;

  if (adjustmentAmountInDollars > 0) {
    const adjustmentAmountInDollarsDinero = Dinero({
      amount: adjustmentAmountInCents,
    });

    if (adjustmentType === AdjustmentTypes.Credit) {
      feePreview = originalFeeDinero.subtract(adjustmentAmountInDollarsDinero);
    } else {
      feePreview = originalFeeDinero.add(adjustmentAmountInDollarsDinero);
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.currentTarget;

    setFormValues({ ...formValues, [name]: value });
  };
  return (
    <>
      <StyledFormRow>
        <td colSpan={1}></td>
        <td style={{ textAlign: 'right' }}>
          {isSubmittingAdjustment ? (
            <Loading color={colors.blue50} />
          ) : (
            <StyledPlusIcon onClick={handleSubmit} />
          )}
        </td>
        <td>
          <StyledTextInput
            name="note"
            value={formValues['note']}
            onChange={handleInputChange}
            disabled={isSubmittingAdjustment}
          />
        </td>
        <td>
          <SelectInput
            options={ADJUSTMENT_CATEGORY_OPTIONS}
            menuPosition="fixed"
            name="adjustmentCategory"
            onChange={(option) => {
              let adjustmentType = formValues.adjustmentType;
              const { value: optionValue } = option as ReactSelectOption;
              if (optionValue === AdjustmentCategory.Tax) {
                adjustmentType = AdjustmentTypes.Debit;
              } else if (optionValue === AdjustmentCategory.Loyalty) {
                adjustmentType = AdjustmentTypes.Credit;
              }
              setFormValues({
                ...formValues,
                adjustmentType,
                adjustmentCategory: optionValue as AdjustmentCategory,
              });
            }}
            value={ADJUSTMENT_CATEGORY_OPTIONS.find(
              (option) => option.value === formValues['adjustmentCategory']
            )}
            isDisabled={isSubmittingAdjustment}
          />
        </td>
        <td>
          <SelectInput
            options={ADJUSTMENT_TYPE_OPTIONS}
            menuPosition="fixed"
            name="adjustmentType"
            onChange={(option) => {
              const { value: optionValue } = option as ReactSelectOption;
              setFormValues({
                ...formValues,
                adjustmentType: optionValue as AdjustmentTypes,
              });
            }}
            value={ADJUSTMENT_TYPE_OPTIONS.find(
              (option) => option.value === formValues['adjustmentType']
            )}
            isDisabled={isSubmittingAdjustment}
          />
        </td>
        <td>
          <StyledTextInput
            name="adjustmentAmountInDollars"
            step="0.01"
            type="number"
            onChange={handleInputChange}
            style={{ maxWidth: '4rem' }}
            disabled={isSubmittingAdjustment}
            value={String(formValues['adjustmentAmountInDollars'])}
          />
        </td>
      </StyledFormRow>
      <StyledFormRow>
        <td colSpan={6}>
          {feePreview && (
            <Box display="flex" justifyContent="end" alignItems="center">
              New case fee:
              <Text px={['8px']} style={{ textDecoration: 'line-through' }}>
                {originalFeeDinero.toFormat()}
              </Text>
              <ArrowIcon />
              <Text px={['8px']}>{feePreview.toFormat()}</Text>
            </Box>
          )}
        </td>
      </StyledFormRow>
    </>
  );
};

export default LineItemForm;
