import { GetCurrentPracticeLoyaltyProgramQuery } from 'generated/core/graphql';
import {
  EnrolledLoyaltyProgram,
  LoyaltyProgramTier,
} from 'pages/PracticeManagement/shared/slice';

export const transformLoyaltyInfo = (
  payload: GetCurrentPracticeLoyaltyProgramQuery['getCurrentPracticeLoyaltyProgram']
): EnrolledLoyaltyProgram | undefined => {
  if (payload.loyaltyProgramTier?.loyaltyProgram?.loyaltyProgramTiers) {
    return {
      practiceLoyaltyStatusId: payload.id,
      caseCount: payload.caseCount,
      evaluationPeriodEnd: payload.evaluationPeriodEnd,
      programStart: payload.programStart,
      programEnd: payload.programEnd,
      loyaltyProgram: {
        name: payload.loyaltyProgramTier?.loyaltyProgram?.name,
        interval: payload.loyaltyProgramTier?.loyaltyProgram?.interval,
        tiersInProgram: payload.loyaltyProgramTier?.loyaltyProgram
          ?.loyaltyProgramTiers as LoyaltyProgramTier[],
        currentTier: {
          id: payload.loyaltyProgramTier?.id,
          name: payload.loyaltyProgramTier?.name,
          sortOrder: payload.loyaltyProgramTier?.sortOrder,
        },
      },
    };
  }

  return undefined;
};
