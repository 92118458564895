import { Button, colors } from '@candidco/enamel';

import styled from 'styled-components/macro';

import CloseSvg from 'assets/close.svg?react';

export const ConfirmDialogContainer = styled.div`
  max-width: 480px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px; 32px;
  box-shadow: 0px -1px 0px 0px ${({ theme }) => theme.colors.black20} inset;

  > div {
    font-weight:${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
`;

export const Body = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  padding: 24px 32px;
  box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.colors.black20} inset;
`;

export const StyledButton = styled(Button)<{ fontColor?: string }>`
  &:not(:disabled) {
    background-color: ${({ color }) => color};
    color: ${({ fontColor = colors.white }) => fontColor};

    &:hover {
      background-color: ${({ color }) => color};
      color: ${({ fontColor = colors.white }) => fontColor};
      filter: brightness(90%);
    }
    &:active {
      background-color: ${({ color }) => color};
      color: ${({ fontColor = colors.white }) => fontColor};
      filter: brightness(75%);
    }
  }
`;

export const StyledCancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black70};
  padding: 0;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Radio = styled.input`
  margin-right: 0.75rem;
  margin-top: 4px;
  cursor: pointer;
  appearance: radio;
  align-self: start;
`;

export const RadioLabel = styled.label`
  margin-right: 1.75rem;
  display: flex;
`;

export const StyledCloseButton = styled(CloseSvg)`
  fill: ${({ theme }) => theme.colors.black50}};
`;

export const EnteredAddress = styled.div`
  border-left: 4px solid ${({ theme }) => theme.colors.black20};
  padding-left: 16px;
`;

export const GroupTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const NoMatchingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
