import React from 'react';
import { Box, Button, Table } from '@candidco/enamel';
import { useShippingContext } from 'pages/Shipping/utils';
import styled from 'styled-components/macro';
import { Input } from 'styles/inputs.css';
import { MUIDataTableOptions } from 'mui-datatables';
import CloseIcon from 'assets/close.svg';
import { Title, TitleContainer } from 'pages/Shipping/Shipping.css';
import DocumentTitle from 'components/DocumentTitle';
import { ShippingProvider } from 'pages/Shipping/utils';
import { Grid } from '@candidco/enamel';

const StyledTable = styled(Table)`
  tr {
    cursor: pointer;
  }
`;
const DeleteButton = styled.button`
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  vertical-align: top;
  background: url(${CloseIcon}) no-repeat;
`;

const ExportShipments = () => {
  const {
    triggerFetchShipmentByTrackingId,
    removeShipmentFromExportableList,
    trackingId,
    setTrackingId,
    loadingExportPage,
    exportableShipments,
  } = useShippingContext();
  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: true,
    search: true,
    download: 'true',
  };
  const columns = [
    {
      name: 'id',
      label: 'Shipment Id',
      options: {
        filter: true,
        filterType: 'textField',
      },
    },
    {
      name: 'date',
      label: 'Date',
    },
    {
      name: 'kraken_order_number',
      label: 'Candid Order Number',
    },
    {
      name: 'supplier_case_id',
      label: 'Supplier Case ID',
    },
    {
      name: 'fgs_number',
      label: 'FGS Number',
    },
    {
      name: 'quantity_of_parts',
      label: 'Quantity of Parts',
    },
    {
      name: 'attachments',
      label: 'Attachments',
    },
    {
      name: 'trackingId',
      label: 'Tracking Id',
      options: {
        filter: true,
      },
    },
    {
      name: 'id',
      label: 'Remove',
      options: {
        customBodyRender: (value: string) => (
          <div onClick={() => removeShipmentFromExportableList(value)}>
            <DeleteButton />
          </div>
        ),
        download: false,
      },
    },
  ];
  const mappedExportableShipments = exportableShipments.map((shipment) => {
    const partCount =
      shipment?.containedItems[0]?.manufacturingData?.partCount || 0;
    const quantity = shipment?.containedItems[0]?.quantity || 0;
    return {
      id: shipment?.id,
      status: shipment?.tracker?.status,
      trackingId: shipment?.tracker?.trackingId,
      date: new Date().toLocaleDateString(),
      kraken_order_number: shipment?.containedItems[0]?.manufacturingData?.id,
      supplier_case_id:
        shipment?.containedItems[0]?.manufacturingData?.case?.identifier,
      fgs_number: shipment?.containedItems[0]?.manufacturingData?.product?.name,
      quantity_of_parts: partCount * quantity,

      attachments:
        shipment?.containedItems[0]?.manufacturingData?.hasAttachments?.toString(),
    };
  });

  return (
    <Grid
      container
      direction="column"
      style={{ gap: '32px', margin: '32px', width: 'unset' }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <h4>Search Shipments by Tracking ID</h4>
        <form
          style={{ display: 'flex' }}
          onSubmit={(e) => {
            e.preventDefault();
            triggerFetchShipmentByTrackingId();
          }}
        >
          <Input
            name="trackingId"
            placeholder="Enter Tracking ID"
            required
            type="text"
            onChange={(e) => setTrackingId(e.target.value)}
            value={trackingId}
          />
          <Button
            buttonType="secondary"
            isLoading={loadingExportPage}
            isShort
            type="submit"
          >
            Search
          </Button>
        </form>
        <Box style={{ width: '100%' }}>
          <StyledTable
            title="Scanned Shipments"
            data={mappedExportableShipments}
            columns={columns}
            options={options}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const ExportShipmentsPage = () => {
  return (
    <DocumentTitle title={'Shipping Export Tool'}>
      <ShippingProvider>
        <Grid container direction="column">
          <TitleContainer>
            <Title>Shipping Export tool</Title>
          </TitleContainer>
          <ExportShipments />
        </Grid>
      </ShippingProvider>
    </DocumentTitle>
  );
};

export default ExportShipmentsPage;
