import {
  AlertCard,
  Button,
  colors,
  SelectInput,
  TextInput,
  type,
} from '@candidco/enamel';
import styled from 'styled-components/macro';
import { HRDividerLine } from 'styles/layout.css';

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Radio = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  appearance: radio;
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2rem;

  > div {
    font-weight: ${type.weight.bold};
    font-size: ${type.size.medium};
    line-height: 20px;

    &:last-child {
      color: ${colors.black70};
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  gap: 1.5rem;
`;

export const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;

  a {
    font-size: ${type.size.small};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1.5rem 2rem;
  gap: 1.5rem;
`;

export const StyledDivider = styled(HRDividerLine)`
  margin: 0rem;
`;

export const StyledModal = styled.div`
  @media ${({ theme }) => theme.mediaQueries.isTabletAndAbove} {
    width: 42.5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: 11.25rem;
`;

export const TextArea = styled.textarea`
  height: 5rem;
  width: 100%;

  border: 1px solid ${colors.black40};
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;

  &::placeholder {
    color: ${colors.text60}
    font-weight: ${type.weight.default};
    line-height: ${type.lineHeight.default};
  } 
`;

export const LabelAndInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StyledSelectInput = styled(SelectInput)`
  padding-top: 0.125rem;
`;

export const CancelButton = styled(Button)`
  color: ${colors.black70};
  padding: 0px;

  &:hover {
    background: ${colors.white};
  }
`;

//Alert card is currently broken when header is hidden, but icon is not.
//To get around this, style the header like it's the body
export const StyledAlertCard = styled(AlertCard)`
  h5 {
    font-weight: ${type.weight.default};
    line-height: ${type.lineHeight.default};
    font-size: ${type.size.default};
    margin: 0px;
  }
  align-self: stretch;
`;
