import styled from 'styled-components/macro';
import { type } from '@candidco/enamel';

export const HistoryWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const HistoryHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;
