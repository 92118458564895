import React from 'react';

import { Modal, theme, Divider, Button } from '@candidco/enamel';
import {
  ModalBody,
  Title,
  Body,
  ExitNoSubmitButton,
  Footer,
} from 'pages/Patient/CaseCreator/ExitModal.css';

const ExitModal = ({
  title,
  body,
  onAction,
  actionText,
  exitText,
  onExit,
  isSubmitting,
  isOpen,
}: {
  title: string;
  body: string;
  onAction: () => void;
  actionText: string;
  onExit: () => void;
  exitText: string;
  isOpen: boolean;
  isSubmitting?: boolean;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayColor={theme.colors.text50}
      onClose={() => {}}
      hideCloseButton
    >
      <ModalBody>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </ModalBody>
      <Divider />
      <Footer>
        <ExitNoSubmitButton buttonType="text" onClick={onExit}>
          {exitText}
        </ExitNoSubmitButton>
        <Button
          buttonType="secondary"
          onClick={onAction}
          isLoading={isSubmitting}
        >
          {actionText}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ExitModal;
