import { AlertCard, Button, colors, type } from '@candidco/enamel';
import styled from 'styled-components/macro';
import { TextField } from '@material-ui/core';

export const ModalContainer = styled.div`
  width: 480px;
`;

export const HeaderText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: 20px 24px;
`;

export const Content = styled.div`
  padding: 20px 24px;
  box-sizing: border-box;
`;

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black70};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 24px;
  height: 80px;
  gap: 12px;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Radio = styled.input`
  margin-right: 0.5rem;
  margin-top: 0.15rem;
  cursor: pointer;
  appearance: radio;
  padding-top: 1rem;
`;

export const RadioLabel = styled.label`
  margin-right: 1.75rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Explanation = styled(TextField)`
  &.MuiFormControl-root {
    appearance: text;
    padding-left: 1.33rem;
  }

  .MuiOutlinedInput-multiline {
    padding: 12px 14px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.black40};
  }
`;

export const AlertContainer = styled.div`
  margin-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const StyledAlertCard = styled(AlertCard)`
  h5 {
    font-weight: ${type.weight.default};
    line-height: ${type.lineHeight.default};
    font-size: ${type.size.default};
    margin: 0px;
  }
  padding-top: 0;
  align-self: stretch;
`;
