import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;

  > input {
    appearance: checkbox;
    align-self: start;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    font-weight: 700;
    line-height: 150%;
  }
`;

export const GenerateImpressionKitContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${({ theme }) => theme.colors.blue10};
  border-radius: 8px;
  height: 120px;
  > div {
    color: ${({ theme }) => theme.colors.blue50};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
