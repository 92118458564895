import React, { useContext, useState } from 'react';

import { Modal, theme, Divider, NotificationContext } from '@candidco/enamel';
import {
  ButtonRow,
  ContentBody,
  CloseButton,
  Header,
  ModalBody,
  Response,
  StyledButton,
  StyledTextArea,
  Title,
} from 'pages/Patient/CaseCreator/ClarificationResponseModal.css';
import CloseSVG from 'assets/close.svg?react';
import {
  NeedsClarificationInfo,
  setPendingClarificationResponse,
} from 'pages/Patient/patientSlice';
import { AuthContext } from 'components/AuthProvider';
import useSubmitClarificationResponse from 'pages/Patient/CaseCreator/useSubmitNeedsClarification';
import { useDispatch } from 'react-redux';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  clarificationInfo: NeedsClarificationInfo;
  caseRef?: string;
  isCoreCase: boolean;
};

const ClarificationResponseModal = ({
  isOpen,
  onClose,
  clarificationInfo,
  caseRef,
  isCoreCase = false,
}: Props) => {
  const { userInfo } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [clarificationResponse, setClarificationResponse] = useState<string>(
    clarificationInfo?.responseInfo?.response || ''
  );

  const [isSubmittingResponse, setIsSubmittingResponse] =
    useState<boolean>(false);

  const { submitNeedsClarificationResponse } = useSubmitClarificationResponse();
  const { showNotification } = useContext(NotificationContext);

  const submitClarificationResponse = async () => {
    try {
      setIsSubmittingResponse(true);

      //If this is a core case, we want to submit the needs clarification as a part of case submission
      //This lets the backend know that the materials assoicated with this case should be considered submitted
      //We're only doing this for core, because non core cases work as expected, and no need to change what isn't broken
      if (isCoreCase) {
        const pendingNeedsClarifaction: NeedsClarificationInfo = {
          ...clarificationInfo,
          responseInfo: {
            response: clarificationResponse,
            respondedByEmail: userInfo?.email,
          },
        };
        dispatch(setPendingClarificationResponse(pendingNeedsClarifaction));
      } else {
        const promises = submitNeedsClarificationResponse(
          clarificationResponse,
          userInfo?.email,
          clarificationInfo,
          caseRef
        );

        await Promise.all(promises);
        showNotification(
          'Response saved, submit the case when you are ready',
          'success'
        );
      }
      onClose();
    } catch (err) {
      showNotification(
        'Failed to save your response, please try again or contact support',
        'error'
      );
    } finally {
      setIsSubmittingResponse(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlayColor={theme.colors.text50}
      hideCloseButton={true}
    >
      <ModalBody>
        <Header>
          <Title>We need clarification</Title>
          <CloseButton onClick={onClose}>
            <CloseSVG fill={theme.colors.black50} />
          </CloseButton>
        </Header>
        <Divider />
        <ContentBody>
          <div>{clarificationInfo?.question}</div>
          <Response>
            <div>Write a response</div>
            <StyledTextArea
              data-testid="needs-clarification-clarification-text"
              placeholder="Provide clarification"
              onChange={(ev) =>
                setClarificationResponse(ev.currentTarget.value)
              }
              value={clarificationResponse}
            />
          </Response>
        </ContentBody>
        <Divider />
        <ButtonRow>
          <div />
          <StyledButton
            data-testid="needs-clarification-submit-clarification"
            isShort
            buttonType="secondary"
            onClick={submitClarificationResponse}
            isLoading={isSubmittingResponse}
            disabled={!clarificationResponse?.trim().length}
          >
            Save
          </StyledButton>
        </ButtonRow>
      </ModalBody>
    </Modal>
  );
};

export default ClarificationResponseModal;
