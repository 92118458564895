import React from 'react';
import DocumentTitle from 'components/DocumentTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PromotionsListView from 'pages/Promotion/PromotionsListView';
import CouponsListView from 'pages/Promotion/CouponsListView';
import { Box } from '@candidco/enamel';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPromotions,
  selectPromotions,
} from 'pages/Promotion/promotionsSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Promotions = () => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const promotionData = useSelector(selectPromotions);

  useEffect(() => {
    if (!promotionData.length) {
      dispatch(fetchPromotions({}));
    }
  }, []);

  return (
    <DocumentTitle title="Promotion Tool">
      <Box py={['1rem']} m={['auto']} style={{ maxWidth: '1360px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="Promotions" {...a11yProps(0)} />
            <Tab label="Coupons" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PromotionsListView />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CouponsListView />
        </TabPanel>
      </Box>
    </DocumentTitle>
  );
};

export default Promotions;
