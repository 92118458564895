import { NetworkStatus } from '@apollo/client';
import { createQuerierState } from 'components/SearchTablePage/customerQuerier/strategy';
import {
  CoreCase,
  Customer,
  Filter,
  QueryResult,
  Sort,
} from 'components/SearchTablePage/customerQuerier/type';
import {
  GetCasesForSearchDocument,
  GetCasesForSearchQuery,
  GetCasesForSearchQueryVariables,
} from 'generated/core/graphql';
import {
  CustomerSearchDocument,
  CustomerSearchQuery,
  CustomerSearchQueryVariables,
  DoctorSelectorType,
  SortDirection,
  SortField,
} from 'generated/legacy/graphql';
import { client, coreClient } from 'state/GraphQLProvider';
import { Nullable } from 'utils/types';

export default (legacy: boolean = false) =>
  (
    pageSize: number,
    filter: Filter,
    sort: Sort,
    callback: (partial: QueryResult) => void
  ) => {
    let cancelled = false;
    const { addCustomers, addCases, getData, getFailed, setFailed } =
      createQuerierState();

    const getQueryVariables = getCustomerSearchQueryVariables(legacy);

    const querier = (endCursor: Nullable<string> = null) => {
      const pureQuerier = async (): Promise<void> => {
        if (cancelled) {
          return;
        }

        callback({
          errors: [],
          error: undefined,
          networkStatus: NetworkStatus.loading,
          data: getData(),
          loading: true,
          fetchMore: null,
        });

        const customersResponse = await client.query<
          CustomerSearchQuery,
          CustomerSearchQueryVariables
        >({
          query: CustomerSearchDocument,
          fetchPolicy: 'network-only',
          variables: {
            ...getQueryVariables(pageSize, filter, sort),
            after: endCursor,
          },
        });

        if (customersResponse.errors || customersResponse.error) {
          setFailed();
          if (cancelled) {
            return;
          }

          return callback({
            ...customersResponse,
            data: getData(),
            fetchMore: null,
          });
        }

        const customers =
          customersResponse.data.customerSearch?.edges
            .map((c) => c?.node)
            .filter((c: Nullable<Customer>): c is Customer => !!c) ?? [];

        const fetchMore = querier(
          customersResponse.data.customerSearch?.pageInfo?.endCursor ?? null
        ).promise;

        addCustomers(customers);
        if (cancelled) {
          return;
        }

        const callbackPayload = {
          ...customersResponse,
          data: getData(),
          loading: true,
          fetchMore: getFailed() ? null : fetchMore,
        };

        // if no additional customers were fetched, we can stop here
        // and not fetch cases
        if (!customers.length) {
          return callback({
            ...callbackPayload,
            loading: false,
          });
        } else {
          callback(callbackPayload);
        }

        const customerIds =
          customers
            .map((customer) => customer.id)
            .filter((id): id is string => !!id) ?? [];

        const casesResponse = await coreClient.query<
          GetCasesForSearchQuery,
          GetCasesForSearchQueryVariables
        >({
          query: GetCasesForSearchDocument,
          variables: {
            patientIds: customerIds.map((id) => +id),
          },
        });

        if (casesResponse.errors || casesResponse.error) {
          setFailed();
          if (cancelled) {
            return;
          }

          return callback({
            ...casesResponse,
            data: getData(),
            loading: false,
            fetchMore: null,
          });
        }

        const cases =
          casesResponse.data.getCases?.filter(
            (c: Nullable<CoreCase>): c is CoreCase => !!c
          ) ?? [];

        addCases(cases);
        if (cancelled) {
          return;
        }

        callback({
          errors: [],
          error: undefined,
          networkStatus: NetworkStatus.ready,
          data: getData(),
          loading: false,
          fetchMore: getFailed() ? null : fetchMore,
        });
      };

      return {
        cancel: () => (cancelled = true),
        promise: pureQuerier,
      };
    };

    return querier();
  };

const getCustomerSearchQueryVariables =
  (legacy: boolean = false) =>
  (pageSize: number, filter: Filter, sort: Sort) => {
    const variables: Partial<CustomerSearchQueryVariables> = {
      component: filter.component,
      first: pageSize,
      isCoreCase: true,
    };
    if (filter.state) {
      if (legacy) {
        variables.state = filter.state;
      } else {
        throw new Error(
          'Strategy does not support case-based status filtering'
        );
      }
    }

    if (filter.caseType) {
      throw new Error(
        'Strategy does not support case-based caseType filtering'
      );
    }

    if (filter.doctors?.length) {
      variables.doctors = {
        type: DoctorSelectorType.Include,
        ids: filter.doctors,
      };
    }

    if (filter.searchTerm) {
      variables.searchTerm = filter.searchTerm.trim();
    }

    if (filter.doctorOnly) {
      throw new Error('Not implemented');
    }

    if (sort) {
      variables.sort = {
        field: SortField[sort.field],
        direction: SortDirection[sort.direction],
      };
    }

    return variables;
  };
