import React from 'react';
import moment from 'moment';
import Dinero from 'dinero.js';

import { Grid, Table, Box } from '@candidco/enamel';

import {
  InvoiceLineItemAdjustmentType,
  MutationsAddLineItemAdjustmentArgs,
} from 'generated/core/graphql';
import LineItemForm from 'pages/BillingManagement/Invoices/LineItemForm';

const columns = [
  { name: 'adjustedAt', label: 'Adjusted At' },
  { name: 'adjustedBy', label: 'Adjusted By User ID' },
  { name: 'note', label: 'Note' },
  { name: 'adjustmentCategory', label: 'Category' },
  { name: 'adjustmentType', label: 'Type' },
  { name: 'adjustmentAmount', label: 'Amount' },
];

const AdjustmentsTable = ({
  originalFee = 0,
  adjustments = [],
  isSubmittingAdjustment = false,
  onAddLineItemAdjustment,
}: {
  originalFee?: number;
  adjustments?: InvoiceLineItemAdjustmentType[];
  isSubmittingAdjustment: boolean;
  onAddLineItemAdjustment: (
    formValues: MutationsAddLineItemAdjustmentArgs
  ) => void;
}) => {
  const mappedData = adjustments.map((adjustment) => {
    return {
      adjustedAt: moment.utc(adjustment.adjustedAt).format('MM/DD/YYYY'),
      adjustedBy: adjustment.adjustedById,
      note: adjustment.note,
      adjustmentCategory: adjustment.adjustmentCategory,
      adjustmentType: adjustment.adjustmentType,
      adjustmentAmount: Dinero({
        amount: adjustment.adjustmentAmount,
      }).toFormat(),
    };
  });
  return (
    <Box>
      <Grid item>
        <Table
          data={mappedData}
          columns={columns}
          options={{
            selectableRows: 'none',
            fixedHeader: false,
            pagination: false,
            customTableBodyFooterRender: () => {
              return (
                <LineItemForm
                  originalFee={originalFee}
                  isSubmittingAdjustment={isSubmittingAdjustment}
                  onAddLineItemAdjustment={onAddLineItemAdjustment}
                />
              );
            },
          }}
        />
      </Grid>
    </Box>
  );
};

export default AdjustmentsTable;
