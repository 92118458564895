import React, { createContext, useContext, FC } from 'react';
import { useImmer } from 'use-immer';
import axios from 'axios';
import { NotificationContext } from '@candidco/enamel';

import { fetchCustomerData } from 'api/customers';

import { ContextProps, CustomerData, StateProps } from 'pages/XrayUpload/types';
import { StringMap } from 'utils/types';
import {
  GetXraysByCaseRefDocument,
  GetXraysByCaseRefQuery,
  GetXraysByCaseRefQueryVariables,
  GetMaterialUploadDataDocument,
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataQueryVariables,
  AddXrayDocument,
  AddXrayMutation,
  AddXrayMutationVariables,
  AddSubmissionDocument,
  AddSubmissionMutation,
  AddSubmissionMutationVariables,
} from 'generated/core/graphql';
import { Sort, sortByCreated } from 'utils/prism';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';

export const XrayUploadContext = createContext({} as ContextProps);

const getXrayInfoFromQueryResult = (xrayResult: GetXraysByCaseRefQuery) => {
  let materials = xrayResult?.getXrayMaterialsByCaseRef ?? [];
  materials = materials.sort(sortByCreated(Sort.Desc));
  const latestXray = materials[0];
  const lastXraysMaterialEvaluation = latestXray?.materialEvaluations[0];
  const lastXraysMaterialSubmission = latestXray?.submissions[0];

  // For parity with legacy flow we only need to make sure that
  // the latest aggregate/submission is not rejected.
  // Using only the most recent xray to determine this
  const lastXrayIsRejected =
    !!lastXraysMaterialEvaluation && !lastXraysMaterialEvaluation.approved;
  const xrayData = lastXrayIsRejected
    ? []
    : materials?.filter((xray) =>
        xray.submissions.some(
          (sub) => sub.id === lastXraysMaterialSubmission?.id
        )
      );

  return xrayData;
};

const XrayUploadProvider: FC = ({ children }) => {
  const [state, setState] = useImmer<StateProps>({
    customerData: null,
    isFetchingData: false,
    isUploadingXrays: false,
    xrayData: null,
  });
  const { showNotification } = useContext(NotificationContext);

  const [addXray] = useGQLMutation<AddXrayMutation, AddXrayMutationVariables>(
    AddXrayDocument
  );
  const [addSubmission] = useGQLMutation<
    AddSubmissionMutation,
    AddSubmissionMutationVariables
  >(AddSubmissionDocument);
  const [fetchXrays] = useGQLQuery<
    GetXraysByCaseRefQuery,
    GetXraysByCaseRefQueryVariables
  >(GetXraysByCaseRefDocument);
  const [getXrayUploadUrl] = useGQLQuery<
    GetMaterialUploadDataQuery,
    GetMaterialUploadDataQueryVariables
  >(GetMaterialUploadDataDocument);

  const getXrayData = async (customerId: string, caseRef: string) => {
    try {
      setState((draft) => {
        draft.isFetchingData = true;
      });

      const [customerData, xrayResult]: [
        CustomerData,
        GetXraysByCaseRefQuery | undefined,
      ] = await Promise.all([
        fetchCustomerData(customerId),
        fetchXrays({ caseRef }),
      ]);

      const xrayData = getXrayInfoFromQueryResult(xrayResult!);

      setState((draft) => {
        draft.customerData = customerData;
        draft.isFetchingData = false;
        draft.xrayData = xrayData;
      });
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      setState((draft) => {
        draft.isFetchingData = false;
      });

      showNotification(err.message, 'error');
    }
  };

  const uploadXrays = async (
    files: File[],
    isCapturedWithinYear?: boolean,
    caseRef?: string,
    callback?: () => void
  ) => {
    const { customerData } = state;
    const patientId = customerData?.id!;

    try {
      setState((draft) => {
        draft.isUploadingXrays = true;
      });

      // upload files to s3
      const awsFileLocations = await Promise.all(
        files.map(async (file) => {
          const uploadData = await getXrayUploadUrl({
            patientId,
            fileName: file.name,
          });
          const fields: StringMap = uploadData?.getMaterialUploadData.fields;
          const url = uploadData?.getMaterialUploadData.url!;

          const data = new FormData();
          Object.entries(fields).forEach(([key, value]) =>
            data.append(key, value)
          );
          data.append('file', file);

          try {
            await axios({
              method: 'POST',
              url,
              data,
              headers: {
                'Content-Type': file.type,
              },
            });
          } catch (err) {
            throw new Error('X-rays could not be submitted');
          }
          return fields['key'];
        })
      );

      // create new core materials
      const newMaterialIds = await Promise.all(
        awsFileLocations.map(async (awsFileLocation) => {
          const addXrayResult = await addXray({
            awsFileLocation,
            data: {
              capturedWithinYearOfSubmission: !!isCapturedWithinYear,
            },
            patientId,
            caseRef,
          });
          return addXrayResult?.addXray?.xray?.id!;
        })
      );

      // submit xrays for evaluation
      await addSubmission({
        caseRef: caseRef!,
        patientId,
        materialIds: newMaterialIds,
      });

      const xrayResult = await fetchXrays({ caseRef: caseRef! });
      const xrayData = getXrayInfoFromQueryResult(xrayResult!);

      showNotification('X-rays uploaded', 'success');
      setState((draft) => {
        draft.isUploadingXrays = false;
        draft.xrayData = xrayData;
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
      setState((draft) => {
        draft.isUploadingXrays = false;
      });
    }
  };

  return (
    <XrayUploadContext.Provider
      value={{
        customerData: state.customerData,
        getXrayData,
        isFetchingData: state.isFetchingData,
        isUploadingXrays: state.isUploadingXrays,
        uploadXrays,
        xrayData: state.xrayData,
      }}
    >
      {children}
    </XrayUploadContext.Provider>
  );
};

export default XrayUploadProvider;
