import styled from 'styled-components/macro';
import { type, Avatar as EnamelAvatar, Divider } from '@candidco/enamel';
import ArrowLeft from 'assets/ic_chevron-left.svg?react';
import Drawer from '@material-ui/core/Drawer';
import PlusIcon from 'assets/ic_plus.svg?react';
import MinusIcon from 'assets/ic_minus.svg?react';
const drawerWidth = 300;
const halfCircleWidth = 14;

export const ProfileCloseButton = styled(ArrowLeft)`
    transform: translateX(${drawerWidth - halfCircleWidth + 5}px);
    position: relative;
    z-index: 1;
    top: 6px;
    background-color: white;
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
    width: 14px;
    height: 28px;
    cursor: pointer;
}
  `;
export const LeftCircle = styled.div`
  ${({ theme }) => `
    background: #9e978e;
    margin: 0 1em 1em 0;
    height: 28px;
    width: 14px;
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
    border: 1px solid ${theme.colors.black20};
    transform: translateX(${drawerWidth - halfCircleWidth}px);
    background-color: white;
    position: relative;
    top: -22px;
    cursor: pointer;
  `}
`;

export const DesktopProfileDrawer = styled(Drawer)`
  ${({ theme }) => `
  @media ${theme.mediaQueries.mobile} {
    display: none;
  }
  & .MuiDrawer-root: {
      position: relative;
  }
  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    position: inherit;
    overflow: visible;
    }
  & .MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid ${theme.colors.black20};
`}
`;

export const MobileProfileDrawer = styled(Drawer)`
  ${({ theme }) => `
  display: none;  
  @media ${theme.mediaQueries.mobile} {
    z-index: 1;
    display: flex;
    & .MuiDrawer-root: {
      top: 68px;
      position: relative;
    }
    & .MuiDrawer-paperAnchorTop {
      top: 125px;
    }
  }
`}
`;

export const Avatar = styled(EnamelAvatar)<{
  borderColor?: string;
}>`
  ${({ theme, borderColor }) => `
    border: 3px solid ${borderColor};
    &.MuiAvatar-root {
      width: 96px;
      height: 96px;
    }
    @media ${theme.mediaQueries.mobile} {
      border: 1px solid ${borderColor};
      &.MuiAvatar-root {
        width: 40px;
        height: 40px;
      } 
    }
    `}
`;

export const Flexbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexboxColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  left: 10px;
  top: 10px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -40px;
`;

export const AttributeContainer = styled.div`
  display: flex;
  justify-content: start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-left: 16px;
  }
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => `
  display: none;
  @media ${theme.mediaQueries.mobile} {
    display: flex;
    justify-content: start;
    margin: 16px;
    font-family: 'Modern Era';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
  }
`}
`;

export const AttributesListContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding-top: 12px;
`;

export const DesktopTitle = styled(type.H4)`
  margin: 12px;
  text-align: center;
`;

export const DesktopSubtitle = styled(type.Overline)`
  ${({ theme }) => `
    height: 18px;
    font-family: 'Modern Era Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: ${theme.colors.black70};
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-bottom: 10px;
`}
`;
export const MobileTitle = styled(type.H5)`
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #222222;
  margin-bottom: 1px;
  margin-right: 10px;
`;

export const MobileSubtitle = styled(type.BodySmall)`
  ${({ theme }) => `
    font-family: 'Modern Era Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: ${theme.colors.black70};
    `}
`;

export const AttributeLabel = styled(type.Body)`
  ${({ theme }) => `
    margin-top: 8px;
    margin-left: 32px;
    color: ${theme.colors.black70};
    padding-bottom: 0;
    margin-bottom: 2px;

    height: 21px;

    font-family: 'Modern Era';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

`}
`;

export const AttributeValue = styled(type.Body)`
  ${({ theme }) => `
    width: 250px;
    overflow-wrap: break-word;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 8px;
    color: ${theme.colors.black90};
    font-family: 'Modern Era';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
`}
`;

export const AttributeDivider = styled(Divider)`
  ${({ theme }) => `
  &.MuiDivider-root {
    margin: 0 32px;
  }
  @media ${theme.mediaQueries.mobile} {
    &.MuiDivider-root {
      margin: 0 16px;
    }
  }
`}
`;

export const LeftButton = styled.div<{
  isHidden?: boolean;
}>`
  ${({ isHidden }) => isHidden && `visibility: hidden;`}

  width: 31px;
  height: 19px;
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.blue50};
  cursor: pointer;
  position: relative;
  top: 32px;
  left: 32px;
`;

export const MobileDetailArrow = styled(ArrowLeft)<{
  upArrow?: boolean;
}>`
  ${({ upArrow }) => `
  width: 15px;
  height: auto;
  margin: -3px 4px 0 8px;
  transform: ${upArrow ? `rotate(90deg)` : `rotate(-90deg)`};
`}
`;

export const MobileTopBarRightButton = styled.div`
  color: ${({ theme }) => theme.colors.blue50};
  cursor: pointer;
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  display: flex;
`;

export const MobileTopProfileBarContainer = styled.div`
  ${({ theme }) => `
  display: none;  
  @media ${theme.mediaQueries.mobile} {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    height: 70px;
    z-index: 10;
    background-color: white;
    border-bottom: 1px solid ${theme.colors.black20}  
  }
`}
`;

export const VerticalText = styled.div<{
  visible?: boolean;
}>`
  ${({ theme, visible }) => `
  display: ${visible ? 'flex' : 'none'};
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: ${theme.colors.black90};
  flex: none;
  order: 0;
  flex-grow: 0;
  transform: rotate(360deg);
  writing-mode: vertical-lr;
  justify-content: flex-start;
  text-align: start;
  margin: 32px 24px 0 24px !important;
  position: fixed;
  `}
`;
export const VerticalSideBarContainer = styled.div<{
  visible?: boolean;
}>`
  ${({ theme, visible }) => `
  width: 78px;
  height: 100%;
  border-right: 1px solid ${theme.colors.black20};
  display: ${visible ? 'flex' : 'none'};
  @media ${theme.mediaQueries.mobile} {
    display: none;
  }
`}
`;

export const ShowGuardianInfoButton = styled.div`
  ${({ theme }) => `
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${theme.colors.blue50};
  padding-left: 32px;
  @media ${theme.mediaQueries.mobile} {
    padding-left: 16px;
  }
`}
`;

export const BluePlusIcon = styled(PlusIcon)`
  ${({ theme }) => `
  path {
    stroke: ${theme.colors.blue50};
  }
  width: 16px;
  margin-right: 32px;
  @media ${theme.mediaQueries.mobile} {
    margin-right: 16px;
  }
`}
`;

export const BlueMinusIcon = styled(MinusIcon)`
  ${({ theme }) => `
  path {
    stroke: ${theme.colors.blue50};
  }
  width: 16px;
  margin-right: 32px;
  @media ${theme.mediaQueries.mobile} {
    margin-right: 16px;
  }
`}
`;
