import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { coreClient } from 'state/GraphQLProvider';
import {
  CreateCouponMutation,
  CreateCouponMutationVariables,
  CreateCouponDocument,
  Promotion as PromotionType,
  PromotionsQuery,
  PromotionsQueryVariables,
  PromotionsDocument,
  Coupon as CouponsType,
  CouponsDocument,
  CouponsQuery,
  CouponsQueryVariables,
  UpdateCouponMutation,
  UpdateCouponMutationVariables,
  UpdateCouponDocument,
  ApplyPromotionsToCartQuery,
  ApplyPromotionsToCartQueryVariables,
  ApplyPromotionsToCartDocument,
} from 'generated/core/graphql';
import { SKUs, CANDID_BRAND_NAME } from 'constants/brands';
import { RootState } from 'state/store';
import moment from 'moment';
import { CatalogItemType } from 'pages/Patient/Checkout/types';

const DATE_FORMAT = 'll'; // "MMM. d, YYYY"
const FREE_REFINEMENTS_PROMOTION_NAME = 'Free Refinements';
const PAID_REFINEMENTS_PROMOTION_NAME = 'Paid Refinements';
const INITIAL_FIT_ISSUE_PROMOTION_NAME = 'Initial Fit Issue';
const TEAM_CASE_PROMOTION_NAME = 'Team Case';

export type Promotion = Pick<
  PromotionType,
  | 'name'
  | 'id'
  | 'name'
  | 'createdAt'
  | 'updatedAt'
  | 'updatedBy'
  | 'isActive'
  | 'method'
>;

export type Coupon = Pick<
  CouponsType,
  | 'id'
  | 'code'
  | 'redemptionLimit'
  | 'startsAt'
  | 'endsAt'
  | 'updatedBy'
  | 'promotion'
  | 'couponCriteria'
  | 'redemptionCount'
  | 'isExpired'
  | 'isRedeemable'
  | 'availableRedemptions'
>;

type PromotionsState = {
  promotions: Array<Promotion>;
  coupons: Array<Coupon>;
  patientCoupons: Array<Coupon>;
  refinementCartResult: ApplyPromotionsToCartQuery['applyPromotionsToCart'];
  paginatedCoupons: CouponsQuery['coupons'];
  checkoutCartResult: ApplyPromotionsToCartQuery['applyPromotionsToCart'];
};

export enum RefinementType {
  Free,
  Ifi,
  Paid,
}

const initialState: PromotionsState = {
  promotions: [],
  coupons: [],
  paginatedCoupons: {
    edges: [],
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: '',
      endCursor: '',
    },
  },
  patientCoupons: [],
  refinementCartResult: null,
  checkoutCartResult: null,
};

export const applyPromotionsToRefinementCart = createAsyncThunk(
  'promotions/applyPromotionsToRefinementCart',
  async (_, store) => {
    const patient = (store.getState() as RootState).patient.patient;
    if (!patient?.id || !patient?.user?.brandInfo?.name) {
      return null;
    }
    const refinementSku = SKUs[patient.user?.brandInfo?.name].refinement.sku;
    const variables = {
      input: {
        lineItems: [
          {
            sku: refinementSku,
            quantity: 1,
            originalPriceInCents: 40000,
          },
        ],
        patientId: patient.id,
        couponCodes: [],
      },
    };
    const { data } = await coreClient.query<
      ApplyPromotionsToCartQuery,
      ApplyPromotionsToCartQueryVariables
    >({
      query: ApplyPromotionsToCartDocument,
      variables,
    });

    return data;
  }
);
// Apply a single sku with quanity to cart
export const applyPromotionsToCart = createAsyncThunk(
  'promotions/applyPromotionsToCart',
  async (
    {
      cartItems,
      couponCodes,
    }: { cartItems: CatalogItemType[]; couponCodes: string[] },
    store
  ) => {
    const patient = (store.getState() as RootState).patient.patient;
    if (!patient?.id) {
      return null;
    }
    const variables = {
      input: {
        lineItems: cartItems.map((item) => ({
          sku: item.sku,
          quantity: item.quantity,
          originalPriceInCents: item.totalPriceBeforeDiscounts.getAmount(),
        })),
        patientId: patient.id,
        practiceId: patient?.practice?.id,
        couponCodes,
      },
    };
    const { data } = await coreClient.query<
      ApplyPromotionsToCartQuery,
      ApplyPromotionsToCartQueryVariables
    >({
      query: ApplyPromotionsToCartDocument,
      variables,
    });

    return data;
  }
);

export const fetchPromotions = createAsyncThunk(
  'promotions/fetchPromotions',
  async (variables: PromotionsQueryVariables) => {
    const { data } = await coreClient.query<
      PromotionsQuery,
      PromotionsQueryVariables
    >({
      query: PromotionsDocument,
      variables,
    });

    return data;
  }
);

export const fetchCoupons = createAsyncThunk(
  'promotions/fetchCoupons',
  async (variables: CouponsQueryVariables) => {
    const { data } = await coreClient.query<
      CouponsQuery,
      CouponsQueryVariables
    >({
      query: CouponsDocument,
      variables,
    });

    return data.coupons?.edges.map((edge) => edge?.node) ?? [];
  }
);

export const fetchPaginatedCoupons = createAsyncThunk(
  'promotions/fetchPaginatedCoupons',
  async (variables: CouponsQueryVariables) => {
    const { first, last, before, after, couponCriteria, promotionInput } =
      variables;
    const { data } = await coreClient.query<
      CouponsQuery,
      CouponsQueryVariables
    >({
      query: CouponsDocument,
      variables: {
        ...(first && { first }),
        ...(last && { last }),
        ...(before && { before }),
        ...(after && { after }),
        couponCriteria,
        promotionInput,
      },
    });

    return data.coupons;
  }
);

export const createCoupon = createAsyncThunk(
  'promotions/createCoupon',
  async (variables: CreateCouponMutationVariables, store) => {
    await coreClient.mutate<
      CreateCouponMutation,
      CreateCouponMutationVariables
    >({
      mutation: CreateCouponDocument,
      variables,
    });
    await store.dispatch(
      fetchPaginatedCoupons({
        first: 30,
      })
    );
  }
);

export const updateCoupon = createAsyncThunk(
  'promotions/updateCoupon',
  async (variables: UpdateCouponMutationVariables) => {
    await coreClient.mutate<
      UpdateCouponMutation,
      UpdateCouponMutationVariables
    >({
      mutation: UpdateCouponDocument,
      variables,
    });
  }
);

export const createInitialFitIssueCoupon = createAsyncThunk(
  'promotions/createInitialFitIssueCoupon',
  async ({ patientId }: { patientId: string }, store) => {
    const variables: CreateCouponMutationVariables = {
      input: {
        promotionName: INITIAL_FIT_ISSUE_PROMOTION_NAME,
        redemptionLimit: 1,
        startsAt: moment().toISOString(),
        endsAt: moment().add(6, 'months').toISOString(),
        code: undefined,
        criteria: {
          patientId: patientId,
        },
      },
    };
    await coreClient.mutate<
      CreateCouponMutation,
      CreateCouponMutationVariables
    >({
      mutation: CreateCouponDocument,
      variables,
    });
    await store.dispatch(setPatientCoupons([]));
    await store.dispatch(
      fetchPaginatedCoupons({
        first: 30,
      })
    );
  }
);

export const createTeamCaseCoupon = createAsyncThunk(
  'promotions/createTeamCaseCoupon',
  async ({ practiceId }: { practiceId: string }, store) => {
    const variables: CreateCouponMutationVariables = {
      input: {
        promotionName: TEAM_CASE_PROMOTION_NAME,
        redemptionLimit: 1,
        startsAt: moment().toISOString(),
        endsAt: moment().add(12, 'months').toISOString(),
        code: undefined,
        criteria: {
          practiceIds: [practiceId],
        },
      },
    };
    await coreClient.mutate<
      CreateCouponMutation,
      CreateCouponMutationVariables
    >({
      mutation: CreateCouponDocument,
      variables,
    });
    await store.dispatch(
      fetchPaginatedCoupons({
        first: 30,
      })
    );
  }
);

// Check if patient is eligible for IFI coupon, by trying to fetch a coupon for exising promotion
export const fetchCouponsForPatient = createAsyncThunk(
  'promotions/fetchCouponsForPatient',
  async (_, store) => {
    const patient = (store.getState() as RootState).patient.patient;
    if (!patient?.id) {
      return [];
    }
    const { data } = await coreClient.query<
      CouponsQuery,
      CouponsQueryVariables
    >({
      query: CouponsDocument,
      variables: {
        couponCriteria: {
          patientId: patient?.id,
        },
      },
    });

    return data.coupons?.edges.map((edge) => edge?.node).filter(Boolean) ?? [];
  }
);

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    resetpromotionsState: () => initialState,
    setPatientCoupons: (state, action) => {
      state.patientCoupons = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPromotions.fulfilled, (state, action) => {
      state.promotions = action.payload.promotions;
    });
    builder.addCase(fetchCoupons.fulfilled, (state, action) => {
      if (action.payload && action.payload.length > 0) {
        const nonNullCoupons = action.payload.filter(
          (coupon) => coupon !== null
        ) as Coupon[];
        state.coupons = nonNullCoupons;
      }
    });
    builder.addCase(fetchPaginatedCoupons.fulfilled, (state, action) => {
      state.paginatedCoupons = action.payload;
    });
    builder.addCase(fetchCouponsForPatient.fulfilled, (state, action) => {
      const nonNullCoupons = action.payload.filter(
        (coupon) => coupon !== null
      ) as Coupon[];
      state.patientCoupons = nonNullCoupons;
    });
    builder.addCase(
      applyPromotionsToRefinementCart.fulfilled,
      (state, action) => {
        state.refinementCartResult = action.payload?.applyPromotionsToCart;
      }
    );
    builder.addCase(applyPromotionsToCart.fulfilled, (state, action) => {
      state.checkoutCartResult = action.payload?.applyPromotionsToCart;
    });
  },
});

export const selectPatientSlice = (state: RootState) => state.patient;

export const selectPromotions = createSelector(
  (state: { promotions: PromotionsState }) => state.promotions,
  (promotions) => promotions.promotions
);

export const selectCoupons = createSelector(
  (state: { promotions: PromotionsState }) => state.promotions,
  (promotions) => promotions.coupons
);

export const selectPaginatedCoupons = createSelector(
  (state: { promotions: PromotionsState }) => state.promotions,
  (promotions) => promotions.paginatedCoupons
);

export const selectPatientCoupons = createSelector(
  (state: { promotions: PromotionsState }) => state.promotions,
  (promotions) => promotions.patientCoupons
);

export const selectRefinementPromotions = createSelector(
  (state: { promotions: PromotionsState }) => state.promotions,
  (promotions) =>
    promotions.promotions.filter((promotion) =>
      promotion.name.includes('Refinements')
    )
);

export const selectInitialFitIssueCoupon = createSelector(
  selectPatientCoupons,
  (coupons) =>
    coupons.find(
      (coupon) => coupon.promotion.name === INITIAL_FIT_ISSUE_PROMOTION_NAME
    )
);

export const selectPatientQualifyForIFICoupon = createSelector(
  selectRefinementPromotions,
  selectPatientCoupons,
  (promotions, coupons) => {
    // Patient is eligible for IFI coupon if they have a coupon for a refinement promotion
    if (!promotions || !coupons) {
      return false;
    }
    return coupons.some((coupon) =>
      promotions.some(
        (promotion) =>
          coupon.isRedeemable && coupon.promotion.name === promotion.name
      )
    );
  }
);

export const selectRefinementCartResult = createSelector(
  (state: { promotions: PromotionsState }) => state.promotions,
  (promotions) => promotions.refinementCartResult
);

export const selectCheckoutCartResult = createSelector(
  (state: { promotions: PromotionsState }) => state.promotions,
  (promotions) => promotions.checkoutCartResult
);

// Selector for paid refinement coupon
export const selectPaidRefinementCoupon = createSelector(
  selectPatientCoupons,
  (patientCoupons) =>
    patientCoupons.filter((coupon) =>
      // Add another check for endsAt date to not show paid coupon if it's expired
      coupon.promotion.name.includes(PAID_REFINEMENTS_PROMOTION_NAME)
    )[0]
);

// Selector for free refinement coupon
export const selectFreeRefinementCoupon = createSelector(
  selectPatientCoupons,
  (patientCoupons) =>
    patientCoupons.filter((coupon) =>
      coupon.promotion.name.includes(FREE_REFINEMENTS_PROMOTION_NAME)
    )[0]
);

// Selector for if all coupons are expired compare to current date
export const selectAllCouponsExpired = createSelector(
  selectPaidRefinementCoupon,
  selectFreeRefinementCoupon,
  (paidCoupon, freeCoupon) => {
    return (
      paidCoupon && paidCoupon.isExpired && freeCoupon && freeCoupon.isExpired
    );
  }
);

// Selector for applied refinement coupons
export const selectAppliedRefinementCoupon = createSelector(
  selectRefinementCartResult,
  (refinementCartResult) => {
    const appliedCoupons = refinementCartResult?.appliedCoupons;
    return appliedCoupons?.length ? appliedCoupons[0] : null;
  }
);

// Select the number of free refinements left
export const selectNumberOfFreeRefinementsLeft = createSelector(
  selectFreeRefinementCoupon,
  selectInitialFitIssueCoupon,
  (freeCoupon, ifiCoupon) => {
    if (
      freeCoupon &&
      (freeCoupon?.availableRedemptions === null ||
        freeCoupon?.availableRedemptions === undefined)
    ) {
      // If availableRedemptions is undefined, it means it's a free coupon with unlimited redemptions
      return '';
    }
    const freeCouponLeft = freeCoupon
      ? freeCoupon?.availableRedemptions ?? 0
      : 0;
    const ifiCouponLeft = ifiCoupon ? ifiCoupon?.availableRedemptions ?? 0 : 0;
    return freeCouponLeft + ifiCouponLeft;
  }
);
export const selectAppliedRefinementDiscountType = createSelector(
  selectFreeRefinementCoupon,
  selectInitialFitIssueCoupon,
  selectPaidRefinementCoupon,
  (freeCoupons, ifiCoupons, paidCoupon) => {
    if (
      freeCoupons?.isRedeemable &&
      (freeCoupons?.availableRedemptions === null ||
        freeCoupons?.availableRedemptions === undefined)
    ) {
      return RefinementType.Free;
    } else if (
      freeCoupons?.isRedeemable &&
      (freeCoupons?.availableRedemptions ?? 0) > 0
    ) {
      return RefinementType.Free;
    }

    if (
      ifiCoupons?.isRedeemable &&
      (ifiCoupons?.availableRedemptions ?? 0) > 0
    ) {
      return RefinementType.Ifi;
    }

    if (paidCoupon?.isRedeemable) {
      return RefinementType.Paid;
    }
    return null;
  }
);

// Selector for policy message
export const selectPolicyMessage = createSelector(
  selectPatientSlice,
  selectRefinementCartResult,
  selectPaidRefinementCoupon,
  selectFreeRefinementCoupon,
  selectAllCouponsExpired,
  selectNumberOfFreeRefinementsLeft,
  (
    patient,
    refinementCartResult,
    paidCoupon,
    freeCoupon,
    isExpired,
    numberOfFreeCouponsLeft
  ) => {
    const isFree = refinementCartResult?.totalAfterDiscounts === 0;
    const patientBrand = patient?.patient?.user?.brandInfo?.name;

    let policyMessage = '';

    if (isExpired) {
      return 'The refinement window for this case has passed. If you wish to continue aligner treatment for this patient, you’ll need to start a new case.';
    }

    if (patientBrand === CANDID_BRAND_NAME) {
      policyMessage = isFree
        ? `This patient is eligible for ${numberOfFreeCouponsLeft} free refinements ${freeCoupon?.endsAt ? `until ${moment(freeCoupon?.endsAt).format(DATE_FORMAT)}` : ''}. After that date additional refinements can be purchased for $400 each ${paidCoupon?.endsAt ? `until ${moment(paidCoupon?.endsAt).format(DATE_FORMAT)}` : ''}.`
        : `This case no longer qualifies for free refinements. Your case included paid refinements until ${paidCoupon?.endsAt && moment(paidCoupon?.endsAt).format(DATE_FORMAT)}. You will be invoiced $400 for this refinement.`;
    } else {
      policyMessage = isFree
        ? `This patient is eligible for ${numberOfFreeCouponsLeft} free refinements ${freeCoupon?.endsAt ? `until ${moment(freeCoupon?.endsAt).format(DATE_FORMAT)}` : ''}. After that date additional refinements can be purchased for $400 each ${paidCoupon?.endsAt ? `until ${moment(paidCoupon?.endsAt).format(DATE_FORMAT)}` : ''}.`
        : `This patient has used ${freeCoupon?.redemptionCount ?? 0} of ${freeCoupon?.redemptionLimit ?? 'unlimited'} free refinements available ${freeCoupon?.endsAt ? `until ${moment(freeCoupon?.endsAt).format(DATE_FORMAT)}` : ''}. After that date additional refinements can be purchased for $400 each ${paidCoupon?.endsAt ? `until ${moment(paidCoupon?.endsAt).format(DATE_FORMAT)}` : ''}.`;
    }
    return policyMessage;
  }
);

export const selectRefinementCheckoutMessage = createSelector(
  selectCheckoutCartResult,
  selectFreeRefinementCoupon,
  selectInitialFitIssueCoupon,
  selectAllCouponsExpired,
  (checkoutCartResult, freeCoupon, ifiCoupon, isExpired) => {
    const isRefinement = checkoutCartResult?.lineItems.some((lineItem) =>
      lineItem?.sku.includes('REFINE')
    );
    if (!isRefinement || !freeCoupon?.redemptionLimit) {
      return '';
    }

    const isFree = checkoutCartResult?.totalAfterDiscounts === 0;
    const numberOfFreeCouponsLeft = freeCoupon
      ? freeCoupon?.redemptionLimit - freeCoupon?.redemptionCount
      : 0;
    if (
      !isFree &&
      (isExpired || numberOfFreeCouponsLeft === 0) &&
      (!ifiCoupon ||
        (ifiCoupon?.endsAt && moment(ifiCoupon?.endsAt).isBefore(moment())))
    ) {
      return `This case no longer qualifies for free refinements. Your case included two free refinements ${freeCoupon?.endsAt ? `until ${moment(freeCoupon?.endsAt).format(DATE_FORMAT)}` : ''}. You will be invoiced $400 for this refinement.`;
    }
    return '';
  }
);

export const { resetpromotionsState, setPatientCoupons } =
  promotionsSlice.actions;

export default promotionsSlice.reducer;
