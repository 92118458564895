import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { AlertCard, Loading } from '@candidco/enamel';

import {
  PhotoType,
  PhotoTypeType,
  StateDataType,
  SubmissionItemType,
  SubmissionType,
  CustomerType,
} from 'generated/legacy/graphql';
import { AppDispatch } from 'state/store';
import { toStatusLabel } from 'utils/prism';
import { usePatientLoadingStates } from 'pages/Patient/utils';

import History from 'components/PrismHistory';
import CollapsibleHistory from 'components/CollapsableHistory/CollapsibleHistory';

import VersionPills, { useVersionPills } from 'components/StyledVersionPills';

import {
  PhotoMap,
  SubmissionItemState,
  SubmissionState,
} from 'pages/OrthoPrism/types';
import { selectIsZippingPhotos, zipPhotos } from 'pages/Prism/prismSlice';

import {
  selectCurrentAggregate,
  selectCurrentPhotoSet,
  selectCurrentSubmissionSet,
  selectPhotoViewNames,
  selectPatient,
} from 'pages/Patient/patientSlice';
import {
  SectionHeading,
  DownloadPhotosButton,
  StatusBar,
  TechNote,
  Overline,
  Note,
  AlertContainer,
  PhotoTypeItem,
  PhotoTypeGrid,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/PhotosTab.css';
import {
  TabWrapper as Wrapper,
  VersionPillsContainer,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';

import {
  HistorySection,
  HistoryWrapper,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';

const rejectedStates: SubmissionState[] = [
  SubmissionState.RejectedPhotos,
  SubmissionState.RejectedCustomer,
  SubmissionState.RejectedMissingInfo,
];

const PhotosTab = () => {
  const customerInfo = useSelector(selectPatient);
  const isZippingPhotos = useSelector(selectIsZippingPhotos);
  const [photoMap, setPhotoMap] = useImmer<PhotoMap>({});

  const dispatch = useDispatch<AppDispatch>();
  const { isFetchingPrism } = usePatientLoadingStates();
  const currentAggregate = useSelector(selectCurrentAggregate);
  const photoSet = useSelector(selectCurrentPhotoSet);
  const submissionSet = useSelector(selectCurrentSubmissionSet);
  const aggregateType = currentAggregate?.aggregateType;
  const [treatmentViewNames] = useSelector(selectPhotoViewNames);
  const photoTypes = (aggregateType?.requiredPhotoTypes ??
    []) as PhotoTypeType[];
  const vpProps = useVersionPills(submissionSet ?? []);
  const currentVersion = vpProps.current;
  const selectedSubmission = submissionSet[vpProps.currentIndex];
  const submissionItems = selectedSubmission?.submissionItems;
  const aggregateHistory = (currentAggregate?.stateData?.history ??
    []) as StateDataType[];
  const primaryHistory = [
    { data: 'created', created: currentAggregate?.createdAt! },
  ].concat(aggregateHistory);

  const handleZipPhotos = () => {
    const photos = Object.values(photoMap).map((a) => a.photo);
    dispatch(
      zipPhotos({
        photos: photos as PhotoType[],
        customer: customerInfo as CustomerType,
      })
    );
  };

  useEffect(() => {
    if (submissionSet.length && currentVersion === 0) {
      const activeIndex = submissionSet.findIndex(({ active }) => active);
      const lastIndex = submissionSet.length - 1;
      const selectedIndex = activeIndex !== -1 ? activeIndex : lastIndex;
      vpProps.onSelect(selectedIndex + 1);
    }
  }, [submissionSet]);
  useEffect(() => {
    setPhotoMap(
      () =>
        photoTypes?.reduce((acc: PhotoMap, { name }) => {
          const submissionItem = submissionItems?.find(
            ({ photo }) => photo.photoType.name === name
          ) as SubmissionItemType;
          const photo = photoSet.find(
            ({ ref }) => ref === submissionItem?.photo.ref
          ) as PhotoType;
          const itemState = submissionItem?.stateData?.data;

          acc[name] = {
            photo,
            submissionItem,
            state: {
              isRejected: itemState === SubmissionItemState.Rejected,
            },
          };

          return acc;
        }, {}) ?? {}
    );
  }, [submissionItems]);

  if (isFetchingPrism) {
    return <Loading isCentered />;
  }

  const submissionState = selectedSubmission?.stateData?.data;

  const { patientRejectionReasons = [] } = aggregateType ?? {};

  const ApprovalBanner = () => {
    return (
      <AlertContainer>
        <AlertCard
          type="success"
          header="Submission Approved"
          displayIcon={true}
        />
      </AlertContainer>
    );
  };

  return (
    <Wrapper>
      <VersionPillsContainer>
        <VersionPills {...vpProps} />
      </VersionPillsContainer>
      {submissionState === SubmissionState.Approved && <ApprovalBanner />}
      {rejectedStates.includes(submissionState as SubmissionState) && (
        <StatusBar isRejected>{toStatusLabel(selectedSubmission)}</StatusBar>
      )}
      {selectedSubmission?.initialNote && (
        <TechNote>
          <Overline data-testid="overline-heading">Tech note</Overline>
          <Note>{selectedSubmission.initialNote}</Note>
        </TechNote>
      )}
      <PhotoTypeGrid>
        {treatmentViewNames.map((name) => {
          const { photo, state } = photoMap[name] ?? {};
          return (
            <PhotoTypeItem
              key={name}
              isRejected={state?.isRejected}
              photo={photo?.photoUrl!}
              title={photo?.photoType.label}
              data-private
            />
          );
        })}
      </PhotoTypeGrid>
      <DownloadPhotosButton
        buttonType="secondary-outline"
        disabled={isZippingPhotos}
        isLoading={isZippingPhotos}
        isShort
        onClick={handleZipPhotos}
      >
        Download photos
      </DownloadPhotosButton>
      <HistoryWrapper container alignItems="center" justifyContent="flex-start">
        <HistorySection item>
          <CollapsibleHistory
            header={<SectionHeading>Submission history</SectionHeading>}
          >
            <History
              patientRejectionReasons={patientRejectionReasons!}
              primaryHistory={primaryHistory}
              submissionSet={submissionSet as SubmissionType[]}
              testId="SubmissionHistory"
            />
          </CollapsibleHistory>
        </HistorySection>
      </HistoryWrapper>
    </Wrapper>
  );
};

export default PhotosTab;
