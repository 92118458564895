import styled, { css } from 'styled-components';
import { ChevronLeft } from '@material-ui/icons';
import PencilIcon from 'assets/pencil-2.svg?react';
import { Button, Grid, theme } from '@candidco/enamel';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 2rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
`;

export const PracticeName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${theme.fontSizes[4]};
  h4 {
    margin: 0;
  }
`;

export const BackIcon = styled(ChevronLeft)`
  color: ${theme.colors.black90};
  margin: 0 0.75rem 0 0;
  scale: 1.25;
`;

export const StyledBackButton = styled(Link)`
  padding-bottom: 3px;
  margin: 0;
  vertical-align: middle;
`;

export const EditButton = styled(Button)`
  padding: 0.5rem 1.5rem;
  height: min-content;
`;

export const StyledPencilIcon = styled(PencilIcon)`
  margin-right: 0.5rem;
`;

export const Info = styled(Grid)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const InfoText = styled.p`
  margin: 0;
`;

export const Label = styled(InfoText)`
  color: ${theme.colors.black60};
`;

export const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding-left: 0rem !important;
`;

export const TabContainer = styled.nav`
  display: flex;
  width: 100%;
  margin-bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Tab = styled.button<{
  isActive?: boolean;
}>`
  justify-content: space-around;
  align-items: center;
  padding: 1.25rem 1.5rem 1rem;
  border-bottom: 3px solid white;
  ${({ isActive, theme }) => css`
    color: ${isActive ? theme.colors.text : theme.colors.black50};
    border-color: ${isActive ? theme.colors.blue50 : 'none'};
  `};
`;

export const TabLabelContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoyaltyBadge = styled.div<{
  enrolled: boolean;
}>`
  background-color: ${({ enrolled }) =>
    enrolled ? theme.colors.green70 : theme.colors.red50};
  color: ${theme.colors.white};
  border-radius: 100rem;
  padding: 0.25rem 0.5rem;
  font-size: ${theme.fontSizes[2]};
  margin-left: 0.5rem;
`;
