import styled from 'styled-components/macro';
import { theme } from '@candidco/enamel';

export const ArticleList = styled.div`
  border: 1px solid ${(p) => p.theme.colors.border};
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  width: 100%;
`;

export const ArticleListContent = styled.div`
  padding: 2rem;
`;

export const ArticleListTitle = styled.h3`
  font-size: 1.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
`;

export const ArticleListItemContainer = styled.ul`
  margin-bottom: 0;
`;

export const ArticleListItem = styled.li`
  padding-top: 1rem;
`;

export const ArticleLink = styled.a`
  color: ${theme.colors.text90};

  &:hover {
    color: ${theme.colors.text90};
    text-decoration: underline;
  }
`;
