import React from 'react';
import { theme } from '@candidco/enamel';
import { useHistory } from 'react-router-dom';
import CheckedCircleIcon from 'assets/checked-circle.svg?react';
import ChevronRightIcon from 'assets/ic_chevron-right.svg?react';

import ErrorCircleIcon from 'assets/red-exclamation-circle.svg?react';
import QuestionCircleIcon from 'assets/question-icon.svg?react';

import {
  Container,
  CompletedContainer,
  BodyContainer,
  Title,
  Description,
  ActionButton,
  RightIconContainer,
  LeftIconContainer,
} from 'pages/Patient/CaseCreator/CaseSection.css';
import { ButtonType } from '@candidco/enamel/dist/components/Button/Button';
import { ActionType, ButtonInfo } from 'pages/Patient/CaseCreator/types';

type SectionProps = {
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  description: string;
  path: string;
  actionType: ActionType;
  isComplete?: boolean;
  isDisabled?: boolean;
};

type CompletedSectionProps = {
  title: string;
  actionOnClick: () => void;
};

const CompletedSection = ({ actionOnClick, title }: CompletedSectionProps) => {
  return (
    <CompletedContainer onClick={actionOnClick}>
      <LeftIconContainer>
        <CheckedCircleIcon width={24} height={24} aria-hidden />
      </LeftIconContainer>
      <BodyContainer>
        <Title>{title}</Title>
        {/* <Description>Last edited on: TBD</Description> */}
      </BodyContainer>
      <RightIconContainer>
        <ChevronRightIcon stroke={theme.colors.black70} aria-hidden />
      </RightIconContainer>
    </CompletedContainer>
  );
};

const CaseSection = ({
  title,
  Icon,
  description,
  path,
  actionType,
  isComplete,
  isDisabled,
}: SectionProps) => {
  const { push, location } = useHistory();

  const actionOnClick = () => push(`${location.pathname}/${path}`);

  if (isComplete) {
    return <CompletedSection actionOnClick={actionOnClick} title={title} />;
  }

  const actionMap: Record<ActionType, ButtonInfo> = {
    [ActionType.NOT_STARTED]: {
      text: 'Get started',
      buttonType: 'secondary',
      LeftIcon: () => <Icon stroke={theme.colors.black70} aria-hidden />,
    },
    [ActionType.STARTED]: {
      text: 'Keep going',
      buttonType: 'secondary',
      LeftIcon: () => <Icon stroke={theme.colors.black70} aria-hidden />,
    },
    [ActionType.REJECTED]: {
      text: 'View rejection',
      buttonType: 'primary',
      LeftIcon: () => <ErrorCircleIcon />,
    },
    [ActionType.NEEDS_CLARIFICATION]: {
      text: 'View clarification',
      buttonType: 'primary',
      LeftIcon: () => <QuestionCircleIcon />,
      buttonStyle: { background: theme.colors.yellow90 },
    },
  };

  const { text, buttonType, LeftIcon, buttonStyle } = actionMap[actionType];

  return (
    <Container isDisabled={isDisabled}>
      <LeftIconContainer>
        <LeftIcon />
      </LeftIconContainer>
      <BodyContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ActionButton
          onClick={actionOnClick}
          disabled={isDisabled}
          buttonType={buttonType as ButtonType}
          style={buttonStyle}
        >
          {text}
        </ActionButton>
      </BodyContainer>
    </Container>
  );
};

export default CaseSection;
