import styled from 'styled-components/macro';
import { colors, theme, type } from '@candidco/enamel';

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DrName = styled.div`
  font-weight: ${theme.fontWeights.bold};
  color: ${colors.black90};
  line-height: 100%;
`;
export const BioLink = styled(type.Link)`
  font-weight: ${theme.fontWeights.medium};
`;

export const Date = styled.div`
  font-weight: ${theme.fontWeights.medium};
  color: ${colors.text70};
  font-size: ${theme.fontSizes.small};
`;

export const Title = styled.div`
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.medium};
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${theme.fontSizes.default};
  }
`;

export const Container = styled.div`
  margin-bottom: 32px;
  max-width: 680px;
`;
