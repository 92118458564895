import React, { useRef, FormEvent, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';

import { pinLogIn } from 'api/pinLogin';

import { getCandidEnvSpecificUrl } from 'utils/url';
import DocumentTitle from 'components/DocumentTitle';
import {
  PageHeader,
  PageHeaderContent,
  PageHeading,
  PageSection,
} from 'styles/layout.css';

import {
  useFetchDoctorLazyQuery,
  useGenerateUserPinLoginMutation,
  GenerateUserPinLoginMutationVariables,
} from 'generated/legacy/graphql';

import { Button, type, NotificationContext } from '@candidco/enamel';

import { Input as FormInput } from 'styles/inputs.css';

const SmallHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

const SearchForm = styled.form`
  display: flex;
`;

const Input = styled(FormInput)`
  flex: 1;
  width: 50%;
  max-width: 12rem;
  margin-right: 1rem;
`;

const SubmitButton = styled(Button)`
  margin-right: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  marginleft: 1rem;
`;

const LoginButton = styled(SubmitButton)`
  margin-right: 0rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const PinLogin = () => {
  const [fetchDoctor, { data: doctorData, loading: isDoctorQueryLoading }] =
    useFetchDoctorLazyQuery({ errorPolicy: 'all' });

  const [generatePin, { data: pinLoginData, loading: isPinLoading }] =
    useGenerateUserPinLoginMutation({ errorPolicy: 'all' });

  const { showNotification } = useContext(NotificationContext);
  const doctorUserId = doctorData?.doctor?.user?.id;
  const doctorEmail = doctorData?.doctor?.email;
  const doctorFullName = doctorData?.doctor?.fullName;
  const doctorBrandLabel = doctorData?.doctor?.user?.brandInfo?.label;
  const doctorProviderUrl =
    doctorData?.doctor?.user?.brandInfo?.configs?.website?.provider;
  const pin = pinLoginData?.generateUserPinLogin?.pin;

  useEffect(() => {
    if (doctorData) {
      if (!doctorData?.doctor) {
        showNotification('No doctor was found.', 'error');
      } else if (!doctorUserId) {
        // This is most often the case for staging doctors
        showNotification(
          'A login pin cannot be generated for this doctor because there is no user associated.',
          'error'
        );
      }
    }

    if (pinLoginData && !pin) {
      showNotification(
        'The pin failed to generate, please try again.',
        'error'
      );
    }
  }, [doctorData, pinLoginData]);

  const generatePinOnSubmit = async () => {
    if (!doctorUserId) {
      return;
    }

    const generateUserPinLoginMutationVariables: GenerateUserPinLoginMutationVariables =
      {
        userId: doctorUserId,
      };

    try {
      const generatePinResponse = await generatePin({
        variables: generateUserPinLoginMutationVariables,
      });

      const pin = generatePinResponse?.data?.generateUserPinLogin?.pin;

      if (!pin) {
        showNotification('Error, please try again', 'error');
        return;
      }
      const {
        refresh_token: refreshToken,
        access_token: accessToken,
        groups: authGroups,
      } = await pinLogIn(pin);
      if (doctorProviderUrl) {
        const targetUrl = getCandidEnvSpecificUrl({
          url: doctorProviderUrl,
        });

        const searchParams = new URLSearchParams();
        searchParams.set('token', accessToken);
        searchParams.set('refreshToken', refreshToken);
        searchParams.set('authGroups', JSON.stringify(authGroups));
        searchParams.set('email', doctorEmail!);
        searchParams.set('path', '/action-items');

        window.location.href =
          targetUrl + '/login/token?' + searchParams.toString();
      }
    } catch (err) {
      showNotification(
        'Unable to generate pin login. Please try again.',
        'error'
      );
    }
  };

  const inputEl = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (inputEl.current) {
      const input = inputEl.current.value.trim();
      await fetchDoctor({
        variables: {
          id: input,
        },
      });
    }
  };

  return (
    <DocumentTitle title="Doctor Login">
      <PageHeader>
        <PageHeaderContent>
          <PageHeading>Doctor Login</PageHeading>
        </PageHeaderContent>
      </PageHeader>
      <PageSection>
        <SmallHeading>Search by doctor ID</SmallHeading>
        <Container>
          <SearchForm onSubmit={handleSubmit}>
            <Input
              ref={inputEl}
              name="doctorID"
              placeholder="Doctor ID"
              required
              type="number"
            />
            <SubmitButton
              buttonType="secondary"
              isLoading={isDoctorQueryLoading}
              isShort
              type="submit"
            >
              Search
            </SubmitButton>
          </SearchForm>
          {doctorUserId && (
            <LoginButton
              buttonType="primary"
              isLoading={isPinLoading}
              isShort
              onClick={generatePinOnSubmit}
              type="submit"
            >
              Log in as {`${doctorFullName} (${doctorBrandLabel})`}
            </LoginButton>
          )}
        </Container>
      </PageSection>
    </DocumentTitle>
  );
};

export default PinLogin;
