import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { colors } from '@candidco/enamel';

export const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: -1.25rem;
  left: 0;
  z-index: 1;
  display: ${({ isVisible }) => !isVisible && 'none'};
  width: 20rem;
  margin: 0 auto;
`;

export const ToolBar = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.25rem;
  background: ${colors.white};
  border: 0.125rem solid ${colors.black20};
  border-radius: 1.25rem;
  box-shadow: 0 1px 0.3125rem 0 rgba(0, 0, 0, 0.05);

  ${({ isSaving }) =>
    isSaving &&
    css`
      cursor: not-allowed;

      button,
      input {
        pointer-events: none;
      }
    `};
`;

export const ToolButton = styled.button`
  width: 2.25rem;
  height: 2.25rem;
  fill: ${colors.slate};
  transition: fill 0.2s;

  &:hover {
    fill: ${colors.blue};
  }

  svg {
    pointer-events: none;
  }
`;

const sliderTrackStyles = css`
  width: 100%;
  height: 0.125rem;
  background: ${colors.black60};
  border-radius: 0.0625rem;
`;

const sliderThumbStyles = css`
  width: 0.875rem;
  height: 0.875rem;
  margin-top: -0.375rem;
  cursor: pointer;
  background: ${colors.blue};
  border: 1px solid ${colors.blue50};
  border-radius: 50%;
  box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.15);
  appearance: none;
  transition:
    box-shadow 0.2s,
    transform 0.2s;

  &:hover,
  &:active {
    box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.2);
  }
`;

export const RotationSlider = styled.input`
  width: 10rem;
  margin: 0 0.75rem;

  &::-webkit-slider-runnable-track {
    ${sliderTrackStyles};
  }

  &::-moz-range-track {
    ${sliderTrackStyles};
  }

  &::-webkit-slider-thumb {
    ${sliderThumbStyles};
  }

  &::-moz-range-thumb {
    ${sliderThumbStyles};
  }

  &::-moz-focus-outer {
    border: 0;
  }
`;

export const ConfirmButtons = styled.div`
  position: absolute;
  right: 0;
  bottom: -3rem;
  left: 0;
  text-align: center;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      opacity: 0;
      pointer-events: none;
      transform: translateY(-100%);
    `};

  button {
    height: 2.25rem;
    padding: 0.4375rem 1.5rem 0.375rem;
    border-radius: 1.125rem;

    &:first-child {
      margin-right: 0.75rem;
    }
  }
`;
