import styled from 'styled-components/macro';
import ReactSelect from 'react-select';

export const Header = styled.h2`
  ${({ theme }) => theme.text.headings.h3};
  margin-top: 4rem;
  margin-bottom: 0;
`;

export const PracticeSelect = styled(ReactSelect)<{
  hasError?: boolean;
  width?: number;
}>`
  min-width: 200px;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => (width ? `${width}px` : '200px')};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 680px;
  margin-right: auto;
  margin-left: auto;
`;

export const CurrentTierContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  border-radius: 0.25rem;
  padding: 1.5rem;
`;

export const CurrentTierLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  > img {
    height: 1.5rem;
    width: 1.5rem;
  }

  > div {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    align-self: center;
  }
`;

export const CurrentTierHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const CurrentTierUntil = styled.div`
  > span {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
  > :last-child {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

export const CurrentTierTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.default};
  line-height: 28px;
`;

export const Price = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;

  align-items: center;

  > :first-child {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  > :last-child {
    color: ${({ theme }) => theme.colors.text70};
  }
`;

export const NextCycleText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

export const NextCycleSubText = styled.div`
  > span {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 200%;
    color: ${({ theme }) => theme.colors.text70};
  }

  > :nth-child(2) {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const LearnMoreContainer = styled.span`
  color: ${({ theme }) => theme.colors.blue50};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const LearnMoreLink = styled.a`
  margin-right: 0.5rem;
`;
