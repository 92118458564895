import styled from 'styled-components/macro';
import {
  Stepper as MuiStepper,
  StepContent as MuiStepContent,
  StepConnector as MuiStepConnector,
} from '@material-ui/core';
import ActiveStepIconRaw from 'assets/step-icon-active.svg?react';
import ActiveStepIconRedRaw from 'assets/step-icon-active-red.svg?react';
import ActiveStepIconYellowRaw from 'assets/step-icon-active-yellow.svg?react';
import InactiveStepIconRaw from 'assets/step-icon-inactive.svg?react';
import { type } from '@candidco/enamel';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  max-width: 680px;
  padding-top: 20px;
`;

export const StepConnector = styled(MuiStepConnector)`
  & .MuiStepConnector-lineVertical {
    display: none;
  }
`;

export const Stepper = styled(MuiStepper)`
  ${({ theme }) => `
  & .MuiStepContent-root {
    margin-top: -14px;
    margin-bottom: -16px;
    margin-left: 12px;
    padding-left: 24px;
    padding-right: 8px;
    padding-top: 16px;
    padding-bottom: 18px;
    border-left: 2px solid ${theme.colors.black20};
  }

  & .MuiStepLabel-label.MuiStepLabel-active {
    padding-left: 8px;
  }

  & .MuiStepLabel-label.MuiStepLabel-completed {
    padding-left: 10px;
  }
  
  & .MuiStepContent-last {
    border-left: none;
  }

  &.MuiStepper-root {
    padding: 0;
    margin-left: -7px;
  }
`}
`;

export const StepContent = styled(MuiStepContent)``;

export const ContentText = styled(type.BodySmall)`
  ${({ theme }) => `
  margin: 0;
  color: ${theme.colors.black70};

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`}
`;

export const ActiveStepIcon = styled(ActiveStepIconRaw)`
  margin-left: 3.9px;
`;
export const ActiveStepIconRed = styled(ActiveStepIconRedRaw)`
  margin-left: 3.9px;
`;
export const ActiveStepIconYellow = styled(ActiveStepIconYellowRaw)`
  margin-left: 3.9px;
`;

export const InactiveStepIcon = styled(InactiveStepIconRaw)`
  margin-left: 7.85px;
  margin-right: 3.5px;
`;

export const ActionButton = styled.div`
  ${({ theme }) => `
  cursor: pointer;
  color: ${theme.colors.blue50};
`}
`;

export const ActionLink = styled(Link)`
  ${({ theme }) => `
  cursor: pointer;
  color: ${theme.colors.blue50};
`}
`;
