import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button, NotificationContext } from '@candidco/enamel';

import { createTestUser } from 'api/testUserCreator';
import { TreatmentProtocolLabels } from 'constants/Material';
import { useDoctorsQuery, CaseTypes } from 'generated/legacy/graphql';
import DocumentTitle from 'components/DocumentTitle';
import {
  FormikCheckboxWrapper,
  FormikInputWrapper,
  FormikOption,
  FormikSelectWrapper,
} from 'components/FormikForms';
import {
  notRequiredEmailValidator,
  requiredValidator,
} from 'components/FormikForms/utils';
import { PageHeading, PageSection } from 'styles/layout.css';
import { IntakeTypes } from 'generated/core/graphql';

const Input = styled(FormikInputWrapper)`
  margin-bottom: 1.25rem;

  &:last-of-type {
    margin-bottom: 1.75rem;
  }
`;

const Select = styled(FormikSelectWrapper)`
  margin: 1.25rem 0;
`;

const Checkbox = styled(FormikCheckboxWrapper)`
  margin: 1.25rem 0;
`;

const proCaseTypeOptions = [
  {
    displayValue: 'CandidPro Adult Aligner',
    value: CaseTypes.CpioAdultAligner,
  },
  {
    displayValue: 'CandidPro Teen Aligner',
    value: CaseTypes.CproTeenAligner,
  },
  {
    displayValue: 'CandidPro Refinement',
    value: CaseTypes.ProAdditionalAligners,
  },
];

const treatmentGoalOptions = [
  {
    displayValue: TreatmentProtocolLabels.Airway,
    value: IntakeTypes.CandidAirwayTreatmentGoals,
  },
  {
    displayValue: TreatmentProtocolLabels.StandardRx,
    value: IntakeTypes.CandidRxTreatmentGoals,
  },
];

type FormProps = {
  first_name: string;
  last_name: string;
  email: string;
  referring_dentist_id?: number;
  case_type: CaseTypes;
  treatment_goal_type?: IntakeTypes;
  upload_photos: boolean;
  upload_scans: boolean;
  upload_xrays: boolean;
  submit_case: boolean;
  qc_approve_photos: boolean;
  is_gen2: boolean;
  require_ortho_review: boolean;
};

const initialValues: FormProps = {
  first_name: '',
  last_name: '',
  email: '',
  case_type: CaseTypes.CpioAdultAligner,
  upload_photos: false,
  upload_scans: false,
  upload_xrays: false,
  submit_case: false,
  qc_approve_photos: false,
  is_gen2: true,
  require_ortho_review: true,
};

const TestUserCreator = () => {
  const { showNotification } = useContext(NotificationContext);
  const { push } = useHistory();
  const [doctorOptions, setDoctorOptions] = useState<FormikOption[]>([]);

  const { data: doctorData, loading: isDoctorDataLoading } = useDoctorsQuery({
    variables: {
      specialty: 'orthodontist',
    },
  });

  useEffect(() => {
    if (doctorData) {
      const doctorOpt = doctorData.doctors.map((doc) => ({
        value: doc.id,
        displayValue: doc.fullName,
      }));
      setDoctorOptions(doctorOpt);
    }
  }, [doctorData, setDoctorOptions]);

  const getRedirectLocation = (formValues: FormProps, patientId: number) => {
    // Redirect to next stage in the funnel
    if (formValues.qc_approve_photos) {
      return `/ortho-prism/${patientId}`;
    } else if (formValues.submit_case) {
      return `/prism/${patientId}`;
    } else {
      return `/patient/${patientId}/case-creator`;
    }
  };

  const handleSubmit = async (formValues: FormProps) => {
    try {
      const { id } = await createTestUser(formValues);
      showNotification('Test user created', 'success');
      const redirectLocation = getRedirectLocation(formValues, id);
      push(redirectLocation);
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    }
  };

  return (
    <DocumentTitle title="Test user creator">
      <PageSection isNarrow>
        <PageHeading>Test user creator</PageHeading>
        {isDoctorDataLoading ? null : (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Input
                  label="First name"
                  name="first_name"
                  type="text"
                  placeholder="Auto-generated if left blank."
                />
                <Input
                  label="Last name"
                  name="last_name"
                  type="text"
                  placeholder="Auto-generated if left blank."
                />
                <Input
                  label="Email"
                  placeholder="Auto-generated if left blank: {first_name}.{last_name}.{date_time}+test@candidco.com"
                  name="email"
                  type="email"
                  validate={notRequiredEmailValidator}
                />
                <Input
                  label="Referring Dentist ID"
                  name="referring_dentist_id"
                  type="number"
                  placeholder="Current user if left blank."
                />
                <Select
                  label="Candid Ortho"
                  name="doctor_id"
                  type="select"
                  options={doctorOptions}
                />
                <Select
                  label="Case type *"
                  name="case_type"
                  options={proCaseTypeOptions}
                  type="select"
                  validate={requiredValidator}
                />
                <Checkbox
                  label="Require ortho review"
                  name="require_ortho_review"
                  type="checkbox"
                />
                <Checkbox label="Gen 2" name="is_gen2" type="checkbox" />
                <Select
                  label="Upload treatment goals (select form)"
                  name="treatment_goal_type"
                  options={treatmentGoalOptions}
                  type="select"
                />
                <Checkbox
                  label="Upload photos"
                  name="upload_photos"
                  type="checkbox"
                />
                <Checkbox
                  label="Upload scans"
                  name="upload_scans"
                  type="checkbox"
                />
                <Checkbox
                  label="Upload xrays"
                  name="upload_xrays"
                  type="checkbox"
                />
                <Checkbox
                  label="Submit Case"
                  name="submit_case"
                  type="checkbox"
                />
                <Checkbox
                  label="QC approve photos"
                  name="qc_approve_photos"
                  type="checkbox"
                />
                <Button
                  buttonType="secondary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Create user
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </PageSection>
    </DocumentTitle>
  );
};

export default TestUserCreator;
