import { ValuesOf } from 'utils/types';

import {
  PhotoType,
  SubmissionItemType,
  Maybe,
  AssociatedOrderItem,
} from 'generated/legacy/graphql';
import { ChangeEvent } from 'react';
import { CoreShippingDetails } from 'components/Modals/OrderShippingModal/OrderShipping';

export type RejectionReason = {
  name: string;
  label: string;
  reasons?: RejectionReason[];
};

export const SubmissionState = {
  Submitted: 'submitted',
  Approved: 'approved',
  RejectedPhotos: 'rejected_photos',
  RejectedCustomer: 'rejected_customer',
  RejectedMissingInfo: 'rejected_missing_information',
} as const;
export type SubmissionState = ValuesOf<typeof SubmissionState>;

export const SubmissionItemState = {
  Submitted: 'submitted',
  Approved: 'approved',
  Rejected: 'rejected',
} as const;
export type SubmissionItemState = ValuesOf<typeof SubmissionItemState>;

export type TabProps = {
  refreshMaterials?: (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean,
    caseRef: string
  ) => void;
};

export type PhotoData = {
  photo?: PhotoType;
  submissionItem?: SubmissionItemType;
  state: {
    isRejected: boolean;
    rejectionReasons?: RejectionReason[];
  };
};

export type PhotoMap = {
  [key: string]: PhotoData;
};

export type PhotoApprovalState = {
  quality: boolean;
  formatting: boolean;
  customer: boolean;
};

export enum PatientReasonsType {
  CouldNotTreat = 'could_not_treat',
  NeedsDentalWork = 'needs_dental_work',
  MissingInformation = 'missing_information',
  WhiteningOnly = 'approved_for_whitening_only',
}

export enum PhotoReasonsType {
  Quality = 'photo_quality',
  Formatting = 'photo_formatting',
}

export enum AggregateHistoryStateName {
  Collection = 'collection',
  Evaluation = 'evaluation',
  SubmissionUnderReview = 'submission_under_review',
  Completed = 'completed',
  CouldNotTreat = 'could_not_treat',
}

// like StateDataType but without the spurious optional maybes
export type AggregateHistory = {
  created: Date;
  data: AggregateHistoryStateName;
};

type Doctor = {
  fullName?: string;
  email?: string;
};

export type EditPatientInfo = {
  birthday: string;
  phone: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  businessName: string;
  city: string;
  stateCode: string;
  countryCode: string;
  zip: string;
  guardian_preferredName: string;
  guardian_firstName: string;
  guardian_middleName: string;
  guardian_lastName: string;
  guardian_phone: string;
  guardian_birthday: string;
  treatingProvider?: Doctor | null;
  candidClinician?: Doctor | null;
};

export type EditPatientError = {
  email: boolean;
  birthDate: boolean;
};

type GenericAddress = {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state_code: string;
  zip: string;
  name: string;
};

export type EditableFieldProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

export type OrderDetailFieldProps = {
  editable?: boolean;
  copyable?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  value?: any;
};

export type OrderDetailContainerProps = {
  internal: boolean;
  getOrdersData?: () => void;
  selectedOrderItem?: any;
  shipmentDetail: CoreShippingDetails;
  isInbound?: boolean;
  orderSummary?: any;
  title?: string;
};

export type OrderShippingProps = {
  internal: boolean;
  incitingOrderItemRef?: string | null;
  patientName: string;
  orderItems?: Maybe<Maybe<AssociatedOrderItem>[]> | undefined;
  caseRef?: string | null;
};

export type OrderItemsWithShipment = {
  order_item_ref: string;
  order_id: number;
  quantity: number;
  product_sku: string;
  product_name: string;
  product_type: string;
  order_date: string;
  shipping_address: GenericAddress;
  shipping_address_type: string;
  sent_patient_shipping_update: boolean;
  shipment: Shipment;
  inbound_shipment: Shipment | undefined;
  coupon_code: string;
};
export type Shipment = {
  carrier: string;
  id: number;
  items?: ItemsEntity[] | null;
  registrations?: string[] | null;
  delivered_at?: string;
  created: string;
  shipment_direction: string;
  tracking_id?: string;
  status_datetime: string;
  logistics_provider: string;
  logistics_provider_ref: string;
  customer_ref: string;
  notified_3pl?: boolean;
  status: string;
  shipped_at?: string;
  shipment_ref: string;
  last_updated?: string;
  contains_aligners: boolean;
  contains_pvs: boolean;
  contains_redo_kit: boolean;
  contains_starter_kit: boolean;
  contains_whitening: boolean;
};

type ItemsEntity = {
  created: string;
  id: number;
  shipment_id: number;
  item_ref: string;
  last_updated?: null;
  product_type: string;
  sku: string;
};
