import styled from 'styled-components/macro';
import { CSSObject } from 'styled-components';
import { Button, type, theme, AlertCard, Grid } from '@candidco/enamel';

import ArrowSVG from 'assets/arrow-right-line.svg?react';

export const Container = styled.section`
  padding: 1.5rem 0;
  border-top: ${(p) => p.theme.borders.default};
  border-bottom: ${(p) => p.theme.borders.default};
`;

export const Overline = styled.h5`
  ${(p) => p.theme.text.overline as CSSObject};
  display: block;
  margin-bottom: 0.25rem;
  color: ${(p) => p.theme.colors.text70};
`;

export const JourneyStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const JourneyState = styled.div`
  ${(p) => p.theme.text.headings.h4};
  margin-bottom: 1.25rem;
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;
  font-size: ${type.size.t2};
  margin-top: 1rem;
`;

export const Checkbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  appearance: checkbox;
`;

export const HighLightedButton = styled.div`
  color: ${({ theme }) => theme.colors.blue50};
  cursor: pointer;
`;

export const StyledArrow = styled(ArrowSVG)`
  width: 10px;
  height: auto;
  margin-top: -2px;
  margin-left: 0.25rem;
  fill: currentColor;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
`;

export const AdditionalItemsMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 16.5rem;
  gap: 1rem;
`;

export const LinkItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  > a,
  button {
    font-size: ${theme.fontSizes.small};
    text-rendering: geometricPrecision;
    line-height: 1.25rem;
    color: ${theme.colors.black80};
    text-align: left;
  }

  > button:hover {
    color: ${theme.colors.blue70};
  }
  > svg {
    > path {
      stroke: ${theme.colors.black80};
    }
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
`;

export const AlertContainer = styled.div`
  margin-top: 1rem;
`;

export const StyledAlertCard = styled(AlertCard)`
  h5 {
    font-weight: ${type.weight.default};
    line-height: ${type.lineHeight.default};
    font-size: ${type.size.default};
    margin: 0px;
  }
  padding-top: 0;
  align-self: stretch;
`;

export const CaseInfoContainer = styled(Grid)`
  margin-top: 2rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
