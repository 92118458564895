import React from 'react';
import {
  ContactContainer,
  ContactHeaderSection,
  ContactSection,
  ContactSectionTitle,
  ContactText,
  ContactTypeTitle,
  ContactHeader,
  ContactSubHeader,
  HoursText,
  PhoneNumberSubtext,
  PageBody,
  Title,
  ContactBody,
  PageSection,
  ChatSvg,
  MailSvg,
  PhoneSvg,
} from 'pages/ContactUs/ContactUs.css';
import { useAuthContext } from 'components/AuthProvider';
import { getBrandDomainSettings } from 'utils/brands';
import {
  PhoneNumber,
  PhoneNumberContainer,
} from 'pages/ContactUs/ContactUs.css';
import { colors, type } from '@candidco/enamel';
import DocumentTitle from 'components/DocumentTitle';
import useZendesk from 'hooks/useZendesk';

type ContactInfoProps = {
  phoneNumbers: PhoneNumber[];
  email: string;
};

type PhoneNumber = {
  phoneNumber: string;
  subtext: string;
};

const ContactInfo = ({ phoneNumbers, email }: ContactInfoProps) => {
  const { handleOpenWidget } = useZendesk();
  return (
    <ContactContainer>
      <ContactSection>
        <PhoneSvg />
        <ContactText>
          <ContactTypeTitle>PHONE</ContactTypeTitle>

          {phoneNumbers.map((phoneNumber, idx) => (
            <PhoneNumberContainer key={idx}>
              <PhoneNumber>{phoneNumber.phoneNumber}</PhoneNumber>•
              <PhoneNumberSubtext>{phoneNumber.subtext}</PhoneNumberSubtext>
            </PhoneNumberContainer>
          ))}
          <HoursText>Weekdays 9:00 am - 8:00 pm EST</HoursText>
        </ContactText>
      </ContactSection>
      <ContactSection>
        <MailSvg stroke={colors.black70} />
        <ContactText>
          <ContactTypeTitle>EMAIL</ContactTypeTitle>
          <type.Link>{email}</type.Link>
        </ContactText>
      </ContactSection>
      <ContactSection>
        <ChatSvg width={'20px'} height={'20px'} />
        <ContactText>
          <ContactTypeTitle>LIVE CHAT</ContactTypeTitle>
          <type.Link
            onClick={() => {
              handleOpenWidget();
            }}
          >
            Start a live chat
          </type.Link>
          <HoursText>Weekdays 9:00 am - 8:00 pm EST</HoursText>
        </ContactText>
      </ContactSection>
    </ContactContainer>
  );
};

export const ContactUs = () => {
  const { userInfo } = useAuthContext();
  const { supportEmail } = getBrandDomainSettings();

  const GlidewellContactInfo = () => {
    return (
      <>
        <ContactSectionTitle>For case support</ContactSectionTitle>
        <ContactInfo
          phoneNumbers={[
            { phoneNumber: '+1 (888) 335-4696', subtext: 'call or text' },
          ]}
          email="doctor@promonitoring.ai"
        />
        <ContactSectionTitle>For billing/finance support</ContactSectionTitle>
        <ContactInfo
          phoneNumbers={[
            { phoneNumber: '(800) 854-7256', subtext: 'USA' },
            { phoneNumber: '(888) 278-0414', subtext: 'Canada' },
          ]}
          email="mail@glidewelldental.com"
        />
      </>
    );
  };

  const CandidContactInfo = () => {
    return (
      <ContactInfo
        phoneNumbers={[
          { phoneNumber: '(855) 640-8606', subtext: 'call or text' },
        ]}
        email={supportEmail as string}
      />
    );
  };

  const header = "We're here to help";

  return (
    <DocumentTitle title="Help center">
      <PageSection>
        <PageBody>
          <Title>Contact us</Title>
          <ContactBody isGlidewell={userInfo?.brandInfo?.name === 'glidewell'}>
            <ContactHeaderSection>
              <ContactHeader>{header}</ContactHeader>

              <ContactSubHeader>
                Have a technical, clinical, or patient status question? Here are
                two more ways to connect with our Provider Support Team:
              </ContactSubHeader>
            </ContactHeaderSection>
            {userInfo?.brandInfo?.name === 'glidewell' ? (
              <GlidewellContactInfo />
            ) : (
              <CandidContactInfo />
            )}
          </ContactBody>
        </PageBody>
      </PageSection>
    </DocumentTitle>
  );
};

export default ContactUs;
