import { Modal, colors, theme } from '@candidco/enamel';
import React, { useEffect, useState } from 'react';

import { AddressFormType } from 'components/AddressForm/types';
import {
  NoMatchingContainer,
  GroupTitle,
  EnteredAddress,
  ConfirmDialogContainer,
  StyledCloseButton,
  StyledCancelButton,
  Header,
  Body,
  RadioLabel,
  CloseButton,
  ButtonContainer,
  RadioGroup,
  StyledButton,
  Radio,
} from 'components/AddressForm/AddressConfirmation.css';

export type AddressConfirmationProps = {
  isOpen: boolean;
  originalAddress: AddressFormType;
  suggestions: AddressFormType[];
  onConfirm: (selected_value: AddressFormType) => void;
  onCancel: () => void;
  businessName?: string | null;
  isLoading?: boolean;
};

const AddressConfirmation = ({
  isOpen,
  originalAddress,
  suggestions,
  onConfirm,
  onCancel,
  businessName = null,
  isLoading = false,
}: AddressConfirmationProps) => {
  const [selectedValue, setSelectedValue] = useState<AddressFormType>();
  const hasMatches = suggestions.length;

  useEffect(() => {
    if (hasMatches) {
      setSelectedValue(suggestions[0]);
    } else {
      setSelectedValue(originalAddress);
    }
  }, [suggestions]);

  const displayAddressSuggestion = (
    suggestedAddress: AddressFormType,
    original: AddressFormType
  ) => {
    if (!suggestedAddress) {
      return '';
    }

    return (
      <div>
        {WrapInUnderlineIfDifferent(
          original?.addressLine1 ?? '',
          suggestedAddress?.addressLine1 ?? ''
        )}{' '}
        {original.addressLine2 &&
          WrapInUnderlineIfDifferent(
            original?.addressLine2 ?? '',
            suggestedAddress?.addressLine2 ?? ''
          )}{' '}
        <br />
        {WrapInUnderlineIfDifferent(
          original?.city ?? '',
          suggestedAddress?.city ?? ''
        )}
        {', '}
        {WrapInUnderlineIfDifferent(
          original?.stateCode ?? '',
          suggestedAddress?.stateCode ?? ''
        )}{' '}
        {WrapInUnderlineIfDifferent(
          original?.zip ?? '',
          suggestedAddress?.zip ?? ''
        )}
      </div>
    );
  };

  const WrapInUnderlineIfDifferent = (original: string, suggested: string) => {
    return original === suggested ? suggested : <u>{suggested}</u>;
  };

  const bodyText = hasMatches
    ? 'To ensure accurate delivery, consider the underlined changes below.'
    : 'No matching addressed were found. Do you want to continue with the entered address or enter a new address?';

  const NoMatchingAddressRadioGroup = () => {
    let line1 = originalAddress?.addressLine1;
    if (originalAddress?.addressLine2) {
      line1 += ' ' + originalAddress?.addressLine2;
    }

    const line2 = `${originalAddress?.city}, ${originalAddress?.stateCode} ${originalAddress?.zip}`;
    return (
      <NoMatchingContainer>
        <GroupTitle>Entered Address</GroupTitle>
        <EnteredAddress>
          <div>{line1}</div>
          <div>{line2}</div>
        </EnteredAddress>
      </NoMatchingContainer>
    );
  };

  const MatchingAddressRadionGroup = () => {
    return (
      <>
        <div>
          <GroupTitle>Suggested Addresses</GroupTitle>
          {suggestions?.map((suggestion, idx) => (
            <RadioLabel key={originalAddress?.addressLine1}>
              <Radio
                data-testid={'addressConfirmation-' + suggestion.addressLine1}
                name="suggestions"
                type="radio"
                value={suggestion.addressLine1 ?? ''}
                id="suggestions"
                onClick={() => setSelectedValue(suggestion)}
                defaultChecked={idx === 0}
              />

              {displayAddressSuggestion(suggestion, originalAddress)}
            </RadioLabel>
          ))}
        </div>
        <div>
          <GroupTitle>Entered Addresses</GroupTitle>
          <RadioLabel>
            <Radio
              data-testid={
                'addressConfirmation-' + originalAddress?.addressLine1 ?? ''
              }
              name="suggestions"
              type="radio"
              value={originalAddress.addressLine1 ?? ''}
              id="originalAddress"
              onClick={() => setSelectedValue(originalAddress)}
            />

            {displayAddressSuggestion(
              originalAddress, //It's intentional we use address twice here.
              originalAddress
            )}
          </RadioLabel>
        </div>
      </>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      overlayColor="rgba(0, 0, 0, 0.73)"
      hideCloseButton={true}
      onClose={onCancel}
      closeOnOverlayClick={false}
    >
      <ConfirmDialogContainer>
        <Header>
          <div>Verify address</div>
          <CloseButton onClick={onCancel}>
            <StyledCloseButton />
          </CloseButton>
        </Header>
        <Body>
          <div>{bodyText}</div>
          <RadioGroup>
            {hasMatches ? (
              <MatchingAddressRadionGroup />
            ) : (
              <NoMatchingAddressRadioGroup />
            )}
          </RadioGroup>
        </Body>
        <ButtonContainer>
          {!hasMatches ? (
            <StyledCancelButton
              buttonType="text"
              onClick={onCancel}
              color={theme.colors.black70}
            >
              Enter new address
            </StyledCancelButton>
          ) : (
            <div />
          )}
          <StyledButton
            testId="addressConfirmation-Confirm"
            color={colors.blue}
            disabled={!selectedValue}
            onClick={() => {
              onConfirm({ ...selectedValue, businessName });
              setSelectedValue(undefined);
            }}
            isLoading={isLoading}
          >
            Use {hasMatches ? 'selected' : 'entered'} address
          </StyledButton>
        </ButtonContainer>
      </ConfirmDialogContainer>
    </Modal>
  );
};

export default AddressConfirmation;
