import React from 'react';
import { Divider, Grid } from '@candidco/enamel';

import { MobileParent, CloseIcon } from 'components/Header/Header.css';
import HeaderLogo from 'components/Header/Logo';

export const renderMobileDrawerHeader = (
  onClick,
  logoSubString,
  loyaltyInfo
) => {
  return (
    <MobileParent>
      <Grid container direction="row" justifyContent="space-between">
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CloseIcon onClick={onClick} />
        </Grid>

        <Grid
          item
          xs={7}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <HeaderLogo logoSubString={logoSubString} loyaltyTier={loyaltyInfo} />
        </Grid>

        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </Grid>

      <Divider />
    </MobileParent>
  );
};
