export const searchDMPatientsURL = (whiteLabeling?: boolean) => {
  let baseUrl = import.meta.env.VITE_REACT_APP_DENTAL_MONITORING_URL;
  if (!whiteLabeling && import.meta.env.VITE_REACT_APP_ENV === 'production') {
    baseUrl = 'https://monitoring.candidco.com';
  }
  return `${baseUrl}/patients/search?page=1&sort=patient-asc&q=`;
};

export const getCandidEnvSpecificUrl = ({
  url,
  path = '',
}: {
  url: string;
  path?: string;
}) => {
  const environment = import.meta.env.VITE_REACT_APP_ENV;
  const envPrefixMap: Record<string, string> = {
    development: 'local-',
    staging: 'stage-',
    qa: 'qa-',
    production: '',
  };

  // Handles edge cases during local development
  // Use current protocol (no https in local dev)
  // Use existing port if it's 3000 (local dev port) otherwise ignore (uses default port for protocol)
  const { protocol, port } = window.location;
  const newPort = port === '3000' ? ':3000' : '';

  const { host: newHost } = new URL(url);

  return `${protocol}//${envPrefixMap[environment]}${newHost}${newPort}${path}`;
};
