import React from 'react';
import styled from 'styled-components/macro';
import { Skeleton, Grid } from '@candidco/enamel';
import {
  CartItemContainer,
  CheckoutContainer,
  ItemBody,
} from 'pages/Patient/Checkout/Checkout.css';

const HeaderContainer = styled.div`
  width: 100%;
`;

export const SkeletonCheckout = () => (
  <Grid container justifyContent="center">
    <CheckoutContainer>
      <HeaderContainer>
        <Skeleton animation="wave" height={50} width="60%" />
      </HeaderContainer>
      <HeaderContainer>
        <Skeleton animation="wave" height={40} width="80%" />
      </HeaderContainer>
      <CartItemContainer style={{ WebkitFlex: 'none' }}>
        <Skeleton animation="wave" height={200} width="60%" />
        <ItemBody style={{ width: '100%' }}>
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
        </ItemBody>
      </CartItemContainer>
      <HeaderContainer>
        <Skeleton animation="wave" height={40} width="70%" />
      </HeaderContainer>
      <CartItemContainer style={{ WebkitFlex: 'none' }}>
        <Skeleton animation="wave" height={200} width="60%" />
        <Skeleton animation="wave" height={200} width="60%" />
        <Skeleton animation="wave" height={200} width="60%" />
      </CartItemContainer>
      <CartItemContainer style={{ WebkitFlex: 'none' }}>
        <ItemBody style={{ width: '100%' }}>
          <Skeleton animation="wave" height={30} width="30%" />
          <Skeleton animation="wave" height={30} width="40%" />
          <Skeleton animation="wave" height={30} width="80%" />
        </ItemBody>
      </CartItemContainer>
    </CheckoutContainer>
  </Grid>
);
