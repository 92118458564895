import AddressForm from 'components/AddressForm/AddressForm';
import { SelectedAddress } from 'components/AddressForm/types';
import { Formik } from 'formik';
import React from 'react';

const addressInitialValues = {
  businessName: null, // default business name to null
  addressLine1: '',
  addressLine2: '',
  city: '',
  stateCode: '',
  countryCode: '',
  zip: '',
};

type OtherAddressFormProps = {
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  onFormSubmit: (value: SelectedAddress) => void;
};

const OtherAddressForm = ({
  submitButtonRef,
  onFormSubmit,
}: OtherAddressFormProps) => {
  return (
    <div style={{ padding: '16px 0' }}>
      <Formik
        initialValues={addressInitialValues}
        onSubmit={() => {
          //This one is weird. This form doesn't actually submit anything, but acts as a store
          //for propagating values updward.
          //Do nothing on submit. The inner ref will be used to submit the form.
        }}
      >
        <AddressForm
          addressPath=""
          getAddressData={(value: SelectedAddress) => value}
          submitForm={onFormSubmit}
          submitButtonRef={submitButtonRef}
          updateAddress={(value, originalFormProps) => {
            return {
              addressLine1: value.addressLine1,
              addressLine2: value.addressLine2,
              city: value.city,
              stateCode: value.stateCode,
              zip: value.zip,
              //Smarty street doesn't return country, so use the original.
              countryCode: originalFormProps.countryCode,
            };
          }}
        />
      </Formik>
    </div>
  );
};
export default OtherAddressForm;
