import { colors, type } from '@candidco/enamel';
import styled from 'styled-components/macro';
import { CaseTabProps } from 'components/CaseTab/CaseTab';

export const CaseTabsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100vw;
    overflow-x: auto;
  }
`;

export const CaseTabContainer = styled.div<Pick<CaseTabProps, 'isSelected'>>`
  padding: 16px 0;
  color: ${colors.text50};
  cursor: pointer;
  border-bottom: ${({ theme, isSelected }) =>
    isSelected ? `solid 3px ${theme.colors.blue50}` : 'solid 3px transparent'};

  &:not(:first-child) {
    margin-left: 32px;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 12px 0;
    &:not(:first-child) {
      margin: 0 20px;
    }
  }
`;

export const CaseTypeLabel = styled(type.Body)<
  Pick<CaseTabProps, 'isSelected'>
>`
  margin-bottom: 0;
  font-weight: 700;
  text-wrap: nowrap;

  ${({ isSelected = false }) => isSelected && `color: ${colors.black90}`};
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const CaseCreatedAtLabel = styled(type.Overline)<
  Pick<CaseTabProps, 'isSelected'>
>`
  margin: 0;
  color: ${({ isSelected = false }) =>
    isSelected ? colors.text70 : colors.text50};
  text-wrap: nowrap;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.micro};
  }
`;

export const CaseTag = styled.div<Pick<CaseTabProps, 'isSelected'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  padding: 2px 6px 1px;
  margin-left: 8px;
  font-family: 'Modern Era Mono';
  font-size: 10px;
  line-height: 1;
  color: ${colors.white};
  text-transform: uppercase;
  background-color: ${colors.black70};
  border-radius: 16px;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;
