import { ProductTypes } from 'pages/Patient/types';
import { ReactNode } from 'react';

export interface ProductDetails {
  titleText: string;
  bodyText: string;
  imagePath: string;
  productType: ProductTypes;
  isAvailable: () => boolean;
  //TODO: Actually add a price lookup for aligners/refinements, https://app.shortcut.com/candid/story/88181
  PriceComponent?: ReactNode;
}

export type RetainerProduct = {
  header: string;
  body: string;
  Icon: ReactNode;
  retainerType: RetainerTypes;
  chipText?: string;
  isAvailable: () => boolean;
};

export enum RetainerTypes {
  NewScans,
  Reorder,
  LastStep,
}
