import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useSetAccountPreferencesMutation,
  DoctorPreferencesInput,
  MeDocument,
  useMeQuery,
} from 'generated/legacy/graphql';
import ScanPreferences from 'pages/AccountPreferencesPage/Scan';
import TreatmentPreferences from 'pages/AccountPreferencesPage/Treatment';
import ProMonitoring from 'pages/AccountPreferencesPage/ProMonitoring';
import ScanIntervalDays from 'pages/AccountPreferencesPage/ScanIntervalDays';
import {
  Page,
  Container,
  Sidebar,
  Header,
  StyledSnackbar,
  FormSections,
  SidebarLink,
  SidebarTitle,
} from 'pages/AccountPreferencesPage/AccountPreferencesView.css';
import DocumentTitle from 'components/DocumentTitle';
import { getBrandSupportedFeatures } from 'utils/brands';
import { CANDID_BRAND_NAME } from 'constants/brands';

enum ANCHOR_TAGS {
  TREATMENT = 'treatment',
  SCAN = 'scan',
  SCAN_INTERVAL = 'scan-interval',
  PRO_MONITORING = 'pro-monitoring',
}

const AutoSaveForm = ({
  userId,
  initialValues,
}: {
  userId: number;
  initialValues: DoctorPreferencesInput;
}) => {
  const { values, dirty, resetForm } =
    useFormikContext<DoctorPreferencesInput>();

  const [setAccountPreferences] = useSetAccountPreferencesMutation({
    refetchQueries: [
      {
        query: MeDocument,
      },
    ],
    onCompleted: (result) => {
      const newValues =
        result.setAccountPreferences?.user?.accountPreferences?.doctor;
      if (newValues) {
        resetForm({ values: newValues });
      }
      setIsSnackbarOpen(true);
    },
  });

  useEffect(() => {
    if (dirty) {
      setAccountPreferences({
        variables: {
          input: {
            userId,
            preferences: {
              doctor: {
                ...initialValues,
                ...values,
              },
            },
          },
        },
      });
    }
  }, [values]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  return (
    <StyledSnackbar
      open={isSnackbarOpen}
      message="Preferences updated"
      autoHideDuration={2000}
      onClose={() => setIsSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    />
  );
};

const AccountPreferencesView = () => {
  const { data: meData } = useMeQuery();
  const userInfo = meData?.me;
  const { accountPreferences, id: userId } = userInfo || {};
  const initialValues = accountPreferences?.doctor || {};
  const brand = userInfo?.brandInfo?.name ?? CANDID_BRAND_NAME;
  const { PvsScanFlow: supportPvsScan } = getBrandSupportedFeatures(brand);
  const {
    'enable-monitoring-account-preferences': enableMonitoringAccountPreferences,
    'enable-scan-schedule-options': enableScanScheduleOptions,
    'enable-account-preferences-sidebar': enableAccountPreferencesSidebar,
  } = useFlags();

  return (
    <DocumentTitle title="Preferences">
      <Page>
        <Container>
          {enableAccountPreferencesSidebar && (
            <Sidebar>
              <Header>Preferences</Header>
              <SidebarTitle>CASE SUBMISSION</SidebarTitle>
              <SidebarLink href={`#${ANCHOR_TAGS.TREATMENT}`}>
                Treatment preferences
              </SidebarLink>
              <SidebarLink href={`#${ANCHOR_TAGS.SCAN}`}>
                Scan upload preferences
              </SidebarLink>
              {(enableMonitoringAccountPreferences ||
                enableScanScheduleOptions) && (
                <>
                  <SidebarTitle>MONITORING</SidebarTitle>
                  {enableScanScheduleOptions && (
                    <SidebarLink href={`#${ANCHOR_TAGS.SCAN_INTERVAL}`}>
                      Aligner wear schedule
                    </SidebarLink>
                  )}
                  {enableMonitoringAccountPreferences && (
                    <SidebarLink href={`#${ANCHOR_TAGS.PRO_MONITORING}`}>
                      ProMonitoring patient app activation
                    </SidebarLink>
                  )}
                </>
              )}
            </Sidebar>
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            // onSubmit isRequired by Formik but since we are auto-saving we don't need it
            onSubmit={() => {}}
          >
            <FormSections
              enableAccountPreferencesSidebar={enableAccountPreferencesSidebar}
            >
              <Header
                enableAccountPreferencesSidebar={
                  enableAccountPreferencesSidebar
                }
              >
                Preferences
              </Header>
              <TreatmentPreferences id={ANCHOR_TAGS.TREATMENT} />
              <ScanPreferences
                isLoading={!userInfo}
                supportPvsScan={supportPvsScan}
                id={ANCHOR_TAGS.SCAN}
              />
              {enableScanScheduleOptions && (
                <ScanIntervalDays
                  isLoading={!userInfo}
                  id={ANCHOR_TAGS.SCAN_INTERVAL}
                />
              )}
              {enableMonitoringAccountPreferences && (
                <ProMonitoring
                  isLoading={!userInfo}
                  id={ANCHOR_TAGS.PRO_MONITORING}
                />
              )}
              <AutoSaveForm
                userId={Number(userId)}
                initialValues={initialValues}
              />
            </FormSections>
          </Formik>
        </Container>
      </Page>
    </DocumentTitle>
  );
};

export default AccountPreferencesView;
