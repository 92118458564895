import React, { useMemo } from 'react';
import moment from 'moment';
import { MUIDataTableOptions } from 'mui-datatables';
import { useSelector } from 'react-redux';
import { Table, Box, Loading, Button } from '@candidco/enamel';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';
import { useIsLoading } from 'state/system';
import DocumentTitle from 'components/DocumentTitle';

import { selectPromotions } from 'pages/Promotion/promotionsSlice';
import {
  PromotionContextProvider,
  usePromotionContext,
} from 'pages/Promotion/Context';
import { CreateBundleModal } from 'pages/Promotion/BundleCreation';
import PlusIcon from 'assets/plus-circle-white.svg?react';

const StyledTable = styled(Table)<{ loading: boolean }>`
  tr {
    cursor: pointer;
  }
  opacity: ${({ loading }) => (loading ? 0.3 : 1)};
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const columns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'createdAt',
    label: 'Created At',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'updatedAt',
    label: 'Updated At',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'method',
    label: 'Method',
    options: {
      filter: false,
      sort: false,
    },
  },
];

const CreateBundle = () => {
  const { setBundleCreationVisible, practiceOptions } = usePromotionContext();
  return (
    <Button
      leftIcon={<PlusIcon aria-hidden />}
      isFullWidth
      disabled={!practiceOptions.length}
      isLoading={!practiceOptions.length}
      onClick={() => setBundleCreationVisible(true)}
      buttonType="secondary"
    >
      Create Bundle
    </Button>
  );
};

const PromotionsListView = () => {
  const promotionData = useSelector(selectPromotions);
  const isFetchingPromotions = useIsLoading('promotions/fetchPromotions');
  const mappedPromotions = useMemo(() => {
    if (!promotionData) {
      return [];
    }
    return promotionData.map(
      ({ id, name, updatedAt, createdAt, isActive, method }) => ({
        id,
        name,
        updatedAt: moment.utc(updatedAt).format('MM/DD/YYYY'),
        createdAt: moment.utc(createdAt).format('MM/DD/YYYY'),
        status: isActive ? 'Active' : 'Inactive',
        method,
      })
    );
  }, [promotionData]);

  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: true,
    filter: true,
    serverSide: true,
    count: mappedPromotions.length,
    tableBodyMaxHeight: 'calc(80vh)',
    filterType: 'textField',
    onRowClick: (_rowData, _rowMeta) => {
      // const promo = mappedPromotions[rowMeta.dataIndex];
    },
  };
  //Display this if no data has loaded yet
  if (isFetchingPromotions) {
    return <Loading isCentered />;
  }

  return (
    <PromotionContextProvider>
      <DocumentTitle title="Promotion Tool - List View">
        <Box py={['1rem']} m={['auto']}>
          <Box display="flex" flexDirection="column">
            {mappedPromotions && isFetchingPromotions && (
              <StyledLoader>
                <Loading />
              </StyledLoader>
            )}
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-end"
            >
              <Grid
                item
                style={{
                  marginRight: '1rem',
                }}
              >
                <CreateBundle />
              </Grid>
            </Grid>
            <CreateBundleModal />
            <StyledTable
              title="Promotions"
              data={mappedPromotions}
              columns={columns}
              options={options}
              loading={isFetchingPromotions}
            />
          </Box>
        </Box>
      </DocumentTitle>
    </PromotionContextProvider>
  );
};

export default PromotionsListView;
