import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import PracticeOverview from 'pages/PracticeManagement/shared/PracticeOverview';
import PracticeSearch from 'pages/PracticeManagement/Search';
import PracticeManager from 'pages/PracticeManagement/PracticeManager';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PracticeSettings = ({ match }: RouteComponentProps) => {
  const { 'practice-management-improvements': practiceManagementImprovements } =
    useFlags();
  return (
    <Switch>
      <Route
        component={
          practiceManagementImprovements ? PracticeSearch : PracticeOverview
        }
        exact
        path={match.path}
      />
      {practiceManagementImprovements && (
        <Route
          component={PracticeManager}
          exact
          path={`${match.path}/:practiceId`}
        />
      )}
      <Route component={NotFound} />
    </Switch>
  );
};

export default PracticeSettings;
