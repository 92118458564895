import { Grid } from '@candidco/enamel';
import React from 'react';

import {
  CheckBox as CheckBoxContainer,
  CheckBoxesContainer,
  Container,
} from 'components/ToothChart/ToothChart.css';
import CheckSvg from 'assets/check.svg?react';

type ToothChartProps = {
  values: number[];
  onChange?: (value: number[]) => void;
  readOnly?: boolean;
  disabled?: boolean;
};

/*
 * A component that renders an array of check boxes representing tooth positions
 * The positions are 1 indexed, and are tied to the id of the rendered checkbox
 *
 * Values is passed in as a list of ints (ex: 3,6,23) indicating which check boxes are checked
 *
 */
const ToothChart = ({
  values,
  readOnly = false,
  onChange,
  disabled = false,
}: ToothChartProps) => {
  const onCheckBoxChanged = (index: number, isChecked: boolean) => {
    const newValues = values.map((e) => e);
    if (isChecked && !values.includes(index)) {
      newValues.push(index);
    } else if (!isChecked) {
      const position = newValues.indexOf(index);
      if (position >= 0) {
        newValues.splice(position, 1);
      }
    }

    if (onChange) {
      onChange(newValues);
    }
  };

  return (
    <Container container xs={12}>
      <Grid item xs={1}>
        R
      </Grid>
      <Grid item container xs={10}>
        <Grid container item xs={12} direction="row">
          <Grid
            item
            xs={6}
            style={{
              borderRight: '1px dotted #c9c9c9c9',
              borderBottom: '1px dotted #c9c9c9c9',
            }}
          >
            <CheckBoxes
              start={1}
              end={8}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              bottomRow={false}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} style={{ borderBottom: '1px dotted #c9c9c9c9' }}>
            <CheckBoxes
              start={9}
              end={16}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              bottomRow={false}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} direction="row">
          <Grid item xs={6} style={{ borderRight: '1px dotted #c9c9c9c9' }}>
            <CheckBoxes
              start={25}
              end={32}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              bottomRow
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckBoxes
              start={17}
              end={24}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              bottomRow
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        L
      </Grid>
    </Container>
  );
};

type ToothCheckBoxesProps = {
  start: number;
  end: number;
  values: number[];
  readOnly: boolean;
  onChange: (id: number, value: boolean) => void;
  bottomRow: boolean;
  disabled?: boolean;
};

/*
 * One quadrant of the tooth chart. Responsible for rendering the array of checkboxes, and label for
 * each quadrant. The start and end values are inclusive, so start=1 end=3 will render 1,2,3
 */
const CheckBoxes = ({
  start,
  end,
  values,
  readOnly,
  onChange,
  bottomRow,
  disabled,
}: ToothCheckBoxesProps) => {
  //Creates a list if indicies from start to end, inclusive on both ends
  //Note element is unused here because it's all undefined
  let range: number[] = Array.from(
    new Array(end - start + 1),
    (_, index) => index + start
  );

  //Bottom row is reversed going from end to start
  if (bottomRow) {
    range = range.reverse();
  }
  return (
    <CheckBoxesContainer>
      {range.map((index) => {
        return (
          <CheckBoxContainer bottomRow={bottomRow}>
            <Checkbox
              index={index.toString()}
              checked={values.includes(index)}
              readOnly={readOnly}
              onChange={onChange}
              disabled={disabled!}
            />
            <label>{index}</label>
          </CheckBoxContainer>
        );
      })}
    </CheckBoxesContainer>
  );
};

type ToothCheckboxProps = {
  index: string;
  checked: boolean;
  readOnly: boolean;
  onChange: (index: number, value: boolean) => void;
  disabled: boolean;
};
const Checkbox = ({
  index,
  checked,
  readOnly,
  onChange,
  disabled,
}: ToothCheckboxProps) => {
  if (readOnly) {
    return checked ? <CheckSvg /> : <div> </div>;
  }
  return (
    <input
      type="checkbox"
      id={index}
      name={index}
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        onChange(parseInt(e.target.id), e.target.checked);
      }}
    />
  );
};

export default ToothChart;
