import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const TableContainer = styled.div``;

export const CellInput = styled.input<{
  isCentered?: boolean;
  isEdited?: boolean;
  isEditable?: boolean;
}>`
  ${({ isCentered }) =>
    isCentered &&
    css`
      text-align: center;
    `}

  ${({ isEditable, theme }) =>
    isEditable &&
    css`
      border: ${theme.colors.black87} solid 2px;
    `}

  background: ${({ theme, isEdited }) =>
    isEdited ? theme.colors.yellow30 : 'none'};
  padding-top: 0.25rem;
  padding-left: 0.25rem;
`;

export const TableRow = styled.tr`
  :nth-child(even) {
    background: ${({ theme }) => theme.colors.blue30};
  }
`;

export const PageTurnContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  > * {
    padding-left: 1rem;
  }
`;
