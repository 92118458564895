import { Middleware } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { DocumentNode } from '@apollo/client';

import { query } from 'state/system/graphql';
import { documentToString } from 'state/system/util';
import {
  ApolloClientType,
  client as legacyClient,
} from 'state/GraphQLProvider';

/**
   A `GraphQLAutoQuery` action will be intercepted by the `autoQuery`
   middleware, which dispatches a `graphql.query` request and, on
   successful fulfillment of the request, dispatches an action with the
   type of the `meta.setter` property and a payload of the GraphQL
   response.
 */
interface GraphQLAutoQuery {
  type: string;
  payload: {
    query: DocumentNode;
    variables?: unknown;
    client?: ApolloClientType;
  };
  meta: {
    autoQueryType: 'autoQuery/request';
    setter: string;
  };
}

interface GraphQLAutoResponse {
  type: string;
  payload: {
    data: unknown;
  };
  meta: {
    autoQueryType: 'autoQuery/response';
    autoQueryRegistryKey: string;
    setter: string;
  };
}

type GraphQLAutoAction = GraphQLAutoQuery | GraphQLAutoResponse;

const isAutoResponse = (a: any): a is GraphQLAutoResponse =>
  a?.meta?.autoQueryType === 'autoQuery/response';

const isAutoQuery = (a: any): a is GraphQLAutoQuery =>
  a?.meta?.autoQueryType === 'autoQuery/request';

const isAutoAction = (a: any): a is GraphQLAutoAction =>
  isAutoResponse(a) || isAutoQuery(a);

/**
   Example:

   ```
     const fetchDoctors = graphql.createQueryAction(
        'crafter/fetchDoctors',
         GetDoctorNameDocument,
        'crafter/setDoctors'
     );

   ```

   Dispatching this action `dispatch(fetchDoctors())` will:

   - Make a graphql query with the passed document (`GetDoctorNameDocument`)
   - Dispatch `graphql/pending|rejected|fulfilled` actions
   - On a successful response, will dispatch `crafter/setDoctors` with a
     `payload` set to the `response.data` property.

   For queries that take variables, pass them as an object, e.g.

   ```
   dispatch(
     fetchThingWithArgs({
       arg1,
       arg2
     })
   );
   ```
*/

export const createQueryAction = <Variables = never>(
  fetchType: string,
  queryDocument: DocumentNode,
  setterType: string,
  client: ApolloClientType = legacyClient
) =>
  createAction(fetchType, (variables?: Variables) => ({
    payload: {
      query: documentToString(queryDocument),
      variables,
      client: client,
    },
    meta: {
      autoQueryType: 'autoQuery/request',
      setter: setterType,
    },
  }));

const withoutGraphQLSuffix = (actionType: string): string =>
  actionType.replace(/\/graphql\/(fulfilled|rejected)/, '');

const toKey = (action: GraphQLAutoQuery): string => {
  const base = withoutGraphQLSuffix(action.type);
  const args =
    typeof action.payload.variables === 'undefined'
      ? 'NO_ARGS'
      : action.payload.variables;

  if (typeof args !== 'object') {
    return [base, args].join('-');
  }

  // js gotcha: typeof null === 'object'
  if (args === null) {
    return `${base}-null`;
  }

  // Hic Sunt Dracones: this has a failure mode for nested objects,
  // whose keys will not be ordered. In practice none of our queries
  // require nested, complex objects. (nor should they, really.)
  //
  // e.g. { b: 1, a: 2, c: 3} => {"a": 2, "b": 1, "c": 3}
  //  but
  // {b: 1, a: {z: 1, b: 2}} => {"a": {"z": 1, "b": 2}, "b": 1}
  const slug = JSON.stringify(args, Object.keys(args).sort());

  return [base, slug].join('-');
};

/** private, exported for test only */
export const toResponseMeta = (
  action: GraphQLAutoQuery
): GraphQLAutoResponse['meta'] => ({
  ...action.meta,
  autoQueryType: 'autoQuery/response',
  autoQueryRegistryKey: toKey(action),
});

type AutoQueryRegistry = Set<string>;
const autoQueryRegistry: AutoQueryRegistry = new Set<string>();

const mkWaitFor =
  (registry: AutoQueryRegistry) =>
  (action: GraphQLAutoQuery): void => {
    registry.add(toKey(action));
  };

const mkIsWaitingFor =
  (registry: AutoQueryRegistry) =>
  (action: GraphQLAutoAction): boolean => {
    if (isAutoQuery(action)) {
      return registry.has(toKey(action));
    }
    return registry.has(action.meta.autoQueryRegistryKey);
  };

const mkStopWaitingFor =
  (registry: AutoQueryRegistry) =>
  (action: GraphQLAutoResponse): void => {
    registry.delete(action.meta.autoQueryRegistryKey);
  };

type AutoMiddleware = (registry?: AutoQueryRegistry) => Middleware;
const autoQuery: AutoMiddleware = (registry = autoQueryRegistry) => {
  const isWaitingFor = mkIsWaitingFor(registry);
  const waitFor = mkWaitFor(registry);
  const stopWaitingFor = mkStopWaitingFor(registry);

  return (_store) => (next) => (action) => {
    next(action);

    if (!isAutoAction(action)) {
      return;
    }

    if (isAutoQuery(action)) {
      if (isWaitingFor(action)) {
        console.warn(`${toKey(action)} is in progress, ignoring.`);
        return;
      }

      next(
        query(
          {
            origin: action.type,
            query: action.payload.query,
            variables: action.payload.variables,
            client: action.payload.client,
          },
          toResponseMeta(action)
        )
      );
      waitFor(action);
      return;
    }

    // if we're here, we've narrowed the type of the action to an AutoQueryResponse
    if (isWaitingFor(action)) {
      if (action.type.endsWith('graphql/fulfilled')) {
        // only call the setter if we got a successful action. Otherwise
        // the rejection will have populated the errors object and we're
        // already done.
        next({
          type: action.meta.setter,
          payload: action.payload,
          meta: action.meta,
        });
      }

      // in any case we can remove this action from our waiting registry
      stopWaitingFor(action);
    }
  };
};

export default autoQuery;
