import React, { useMemo, useContext, useEffect, useState } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components/macro';
import { Table, Box, Button, Grid, SelectInput } from '@candidco/enamel';
import CreateOrUpdateCouponModal, {
  CreateOrUpdateCouponFormValues,
} from 'components/Modals/CreateCouponsModal/CreateOrUpdateCouponModal';
import CreateInitialFitIssueCouponModal from 'components/Modals/CreateCouponsModal/CreateInitialFitIssueCouponModal';
import CreateTeamCaseCouponModal from 'components/Modals/CreateCouponsModal/CreateTeamCaseCouponModal';
import {
  fetchPaginatedCoupons,
  selectPaginatedCoupons,
  setPatientCoupons,
  createTeamCaseCoupon,
} from 'pages/Promotion/promotionsSlice';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';
import { OptionType } from '@candidco/enamel/dist/components/SelectInput/SelectInput';
import { ValueType } from 'react-select';

const DATE_FORMAT = 'll'; // "MMM. d, YYYY"
const StyledTable = styled(Table)<{ loading: boolean }>`
  tr {
    cursor: pointer;
  }
  opacity: ${({ loading }) => (loading ? 0.3 : 1)};
`;

const StyledDropdownContainer = styled(Grid)`
  z-index: 999;
`;

interface CouponCriteria {
  patientIds?: string[] | null;
  practiceIds?: string[] | null;
  patientId?: string | null;
}

interface PromotionInput {
  name?: string;
}

interface filter {
  couponCriteria: CouponCriteria;
  promotionInput: PromotionInput;
}

const CouponsListView = () => {
  const { checkHasAccess } = useContext(AuthContext);
  const isProdEng = checkHasAccess(ACCESS_GROUPS.ADMIN);
  const [openModal, setOpenModal] = useState(false);
  const [openIfiModal, setOpenIfiModal] = useState(false);
  const [openTeamCaseModal, setOpenTeamCaseModal] = useState(false);
  const [updatingCoupon, setUpdatingCoupon] = useState<
    CreateOrUpdateCouponFormValues | undefined
  >(undefined);

  const [page, setPage] = useState<number>(0);
  const [beforeCursor, setBeforeCursor] = useState<string | null>(null);
  const [afterCursor, setAfterCursor] = useState<string | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [first, setFirst] = useState<number | null>(30);
  const [last, setLast] = useState<number | null>(30);
  const [filter, setFilter] = useState<filter | null>(null);

  const dispatch = useDispatch();
  const paginatedCoupons = useSelector(selectPaginatedCoupons);

  const couponsData = useMemo(() => {
    return paginatedCoupons?.edges.map((couponNode) => {
      const coupon = couponNode?.node;
      return {
        ...coupon,
        startsAt: coupon?.startsAt
          ? moment(coupon?.startsAt).format(DATE_FORMAT)
          : null,
        endsAt: coupon?.endsAt
          ? moment(coupon?.endsAt).format(DATE_FORMAT)
          : null,
        promotionName: coupon?.promotion.name,
        patientIds:
          coupon?.couponCriteria
            ?.map((criteria) => criteria.argsJson?.patientIds)
            .join(', ') ||
          coupon?.couponCriteria?.map(
            (criteria) => criteria.argsJson?.patientId
          ),
        skus: coupon?.couponCriteria
          ?.map((criteria) => criteria.argsJson?.skus)
          .join(', '),
        practiceIds: coupon?.couponCriteria
          ?.map((criteria) => criteria.argsJson?.practiceIds)
          .join(', '),
      };
    });
  }, [paginatedCoupons]);

  useEffect(() => {
    dispatch(
      fetchPaginatedCoupons({
        first: first,
        last: last,
        after: afterCursor,
        before: beforeCursor,
        couponCriteria: filter?.couponCriteria,
        promotionInput: filter?.promotionInput,
      })
    );
  }, [page, rowsPerPage, filter]);

  useEffect(() => {
    if (!paginatedCoupons?.pageInfo) {
      return;
    }

    setBeforeCursor(paginatedCoupons?.pageInfo?.startCursor ?? null);
    setAfterCursor(paginatedCoupons?.pageInfo?.endCursor ?? null);
  }, [paginatedCoupons]);

  const columns = [
    {
      name: 'code',
      label: 'Code',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'redemptionLimit',
      label: 'Redemption Limit',
      options: {
        customBodyRender: (value: string) => value || 'Unlimited',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'startsAt',
      label: 'Starts At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'endsAt',
      label: 'Ends At',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'promotionName',
      label: 'Promotion Name',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'patientIds',
      label: 'Patient Ids',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'skus',
      label: 'Skus',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'practiceIds',
      label: 'Practice Ids',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_: string, tableMeta: any) => {
          const couponCode = tableMeta.rowData[0];
          const redemptionLimit = String(tableMeta.rowData[1]);
          const startsAt = tableMeta.rowData[2];
          const endsAt = tableMeta.rowData[3];
          const promotionName = tableMeta.rowData[4];
          const patients = tableMeta.rowData[5];
          const practices = tableMeta.rowData[7];

          return (
            <Button
              buttonType="secondary"
              onClick={() => {
                setUpdatingCoupon({
                  couponCode,
                  redemptionLimit,
                  startsAt,
                  endsAt,
                  updatedReason: '',
                  promotionName,
                  patients,
                  practices,
                });
                setOpenModal(true);
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];

  const handleFilterChange = (filterList: string[][]) => {
    const PROMOTION_NAME_INDEX = 4;
    const PATIENT_IDS_INDEX = 5;
    const PRACTICE_IDS_INDEX = 7;
    if (
      filterList.length >= 2 &&
      (filterList[PROMOTION_NAME_INDEX].length ||
        filterList[PATIENT_IDS_INDEX].length ||
        filterList[PRACTICE_IDS_INDEX].length)
    ) {
      const promotion_name = filterList[4][0];
      const patient_ids =
        filterList[PATIENT_IDS_INDEX][0]?.split(',').map((id) => id.trim()) ||
        [];
      const practiceIds =
        filterList[PRACTICE_IDS_INDEX][0]?.split(',').map((id) => id.trim()) ||
        [];
      setFilter({
        couponCriteria: {
          patientId: patient_ids.length === 1 ? patient_ids[0] : null,
          patientIds: patient_ids.length > 1 ? patient_ids : null,
          practiceIds: practiceIds,
        },
        promotionInput: {
          name: promotion_name,
        },
      });
    } else {
      setFilter(null);
    }
    setPage(0);
    setAfterCursor(null);
    setBeforeCursor(null);
    setFirst(rowsPerPage);
    setLast(null);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage === page) {
      return;
    }
    if (newPage === 0) {
      setAfterCursor(null);
      setBeforeCursor(null);
      setLast(null);
      setFirst(rowsPerPage);
    } else if (newPage > page) {
      setBeforeCursor(null);
      setLast(null);
      setFirst(rowsPerPage);
    } else {
      setAfterCursor(null);
      setFirst(null);
      setLast(rowsPerPage);
    }
    setPage(newPage);
  };

  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: true,
    pagination: true,
    filter: true,
    serverSide: true,
    count: paginatedCoupons?.totalCount ?? 0,
    page: page,
    tableBodyMaxHeight: 'calc(80vh)',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 30, 50, 100],
    filterType: 'textField',
    customFilterDialogFooter: (_, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            buttonSize="small"
            onClick={() => {
              if (applyNewFilters) {
                applyNewFilters();
              }
            }}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    onFilterConfirm(filterList) {
      handleFilterChange(filterList);
    },
    onFilterChipClose: (_, __, filterList) => {
      handleFilterChange(filterList);
    },
    onChangeRowsPerPage: (rowsPerPage: number) => {
      setRowsPerPage(rowsPerPage);
      setFirst(rowsPerPage);
      setLast(null);
      setAfterCursor(null);
      setBeforeCursor(null);
    },
    onChangePage: handlePageChange,
  };

  const ActionOptions: OptionType[] = [
    { value: 'initial_fit_coupon', label: 'Create Initial Fit Coupon' },
    { value: 'team_case_coupon', label: 'Create Team Case Coupon' },
  ];
  if (isProdEng) {
    ActionOptions.push({ value: 'coupon', label: 'Create Coupon' });
  }

  return (
    <Box py={['1rem']} m={['auto']}>
      <Box>
        <Grid container justifyContent="flex-end" spacing={1}>
          <StyledDropdownContainer md={3} xs={12}>
            <SelectInput
              options={ActionOptions}
              placeholder="Add"
              onChange={(selectedOption: ValueType<OptionType>) => {
                const { value } = selectedOption as OptionType;
                if (value === 'initial_fit_coupon') {
                  setOpenIfiModal(true);
                } else if (value === 'coupon') {
                  setUpdatingCoupon(undefined);
                  setOpenModal(true);
                } else if (value === 'team_case_coupon') {
                  setOpenTeamCaseModal(true);
                }
              }}
            />
          </StyledDropdownContainer>
        </Grid>
        <CreateOrUpdateCouponModal
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false);
            dispatch(
              fetchPaginatedCoupons({
                first: 30,
                couponCriteria: filter?.couponCriteria,
                promotionInput: filter?.promotionInput,
              })
            );
          }}
          isEditing={!!updatingCoupon}
          coupon={updatingCoupon}
        />
        <CreateInitialFitIssueCouponModal
          isOpen={openIfiModal}
          onClose={() => {
            setOpenIfiModal(false);
            dispatch(setPatientCoupons(null));
          }}
        />
        <CreateTeamCaseCouponModal
          isOpen={openTeamCaseModal}
          onClose={() => {
            setOpenTeamCaseModal(false);
            dispatch(setPatientCoupons(null));
          }}
          createTeamCaseCoupon={createTeamCaseCoupon}
        />
        <StyledTable
          title="Coupons"
          data={couponsData}
          columns={columns}
          options={options}
          loading={false}
        />
      </Box>
    </Box>
  );
};

export default CouponsListView;
