import styled from 'styled-components/macro';
import { colors, type } from '@candidco/enamel';

import DeleteIcon from 'assets/delete.svg';

export const Container = styled.div`
  margin-top: 2rem;
`;

export const UploadContainer = styled.div<{ isSmall?: boolean }>`
  height: ${({ isSmall }) => (isSmall ? '3.5rem' : '30rem')};
  background: ${colors.black05};
  margin-bottom: 1.5rem;
`;

export const FormContainer = styled.form<{ isDisabled?: boolean }>`
  display: block;

  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  margin-bottom: 1.5rem;
`;

export const FormContent = styled.div`
  max-width: 37.375rem;
  margin: 0 auto;
`;

export const SelectedXrays = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  grid-auto-rows: 146px;
  grid-gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;
`;

export const XrayTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const XrayTile = styled.div<{ src?: string }>`
  position: relative;
  background: ${({ src }) => src && `url(${src}) no-repeat center`};
  background-size: cover;
  width: 120px;
  height: 120px;
`;

export const XrayCaseLabel = styled.div`
  font-size: 12px;
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  width: 1rem;
  height: 1rem;
  background: url(${DeleteIcon}) no-repeat;
`;

export const UploadTile = styled.div`
  position: relative;
  height: 120px;
  width: 120px;
  background: ${colors.black05};
`;

export const Checkbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  appearance: checkbox;
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;
  font-size: ${type.size.t2};
`;

export const ListContainer = styled.ul`
  margin-left: 1.5rem;
  margin-top: -0.75rem;
`;

export const ListItem = styled.li`
  list-style-type: disc;
`;

export const ListItemText = styled(type.BodySmall)`
  display: inline;
`;

export const AlertContainer = styled.div`
  margin: 16px 0;
`;
