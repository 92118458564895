import React, {
  createContext,
  useState,
  FunctionComponent,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Maybe } from 'graphql/jsutils/Maybe';

import {
  CustomerWithFullCasesQuery,
  Case,
  useCustomerWithFullCasesLazyQuery,
} from 'generated/legacy/graphql';
import { decodeCase, DecodedCase } from 'utils/case';
import { RejectionReason } from 'pages/OrthoPrism/types';
import { Supplier } from 'pages/Case/types';
import { fetchRejectionReasons } from 'api/case';
import withNotifications from 'hocs/withNotifications';
import useCallbackQueue from 'hooks/useCallbackQueue';
import { isNotNil } from 'utils/typeCheck';
import { useGQLMutation } from 'hooks/useGQL';
import {
  AddTreatmentPlanStagingDocument,
  AddTreatmentPlanStagingMutation,
  AddTreatmentPlanStagingMutationVariables,
  TreatmentPlanningSoftware,
  TreatmentPlanStagingTypes,
} from 'generated/core/graphql';
import {
  fetchTreatmentPlanStagings,
  selectScans,
} from 'pages/OrthoPrism/orthoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { REFACTOR_ANY } from '@Types/refactor';

type CustomerWithCasesCallback = (
  customerWithCases: CustomerWithFullCasesQuery
) => any;

/**
 * @type {React.Context<CaseContextType>}
 */
export const CaseContext = createContext({} as CaseContextProps);

export const ALIGNER_JOURNEY_TYPE = 'aligner_journey';
export const ALIGNER_PRODUCTION_LEG = 'production_aligner_leg';

type RemoteReason = {
  name: string;
  slug: string;
  reasons: RemoteReason[];
};

interface CaseProviderState {
  caseId: Case['id'] | null;
  caseRef: Case['caseRef'];
  cases: Maybe<DecodedCase[]>;
  activeAlignersCase: Maybe<DecodedCase>;
  selectedCase: Maybe<DecodedCase>;
  customerData: Maybe<CustomerWithFullCasesQuery['customer']>;
  isDentalMonitoring: boolean;
  isFetchingData: boolean;
  isLoadingCase: boolean;
  isRefreshingTreatmentPlans: boolean;
  orderItems: Case['orderItems'];
  incitingOrderItem: Case['incitingOrderItem'];
  rejectionReasons: RejectionReason[] | null;
  treatmentPlanSupplier: Maybe<Supplier>;
}

interface CaseContextProps extends CaseProviderState {
  getCaseData: (
    customerId: string,
    caseRef?: string,
    returnError?: boolean,
    callback?: () => any
  ) => void;
  handleCreateTreatmentPlan: () => Promise<void>;
  setSelectedCase: (caseRef: string, callback?: () => void) => Promise<void>;
}

interface CaseProviderProps {
  showNotification: (message: string, variant: string) => void;
}

const CaseProvider: FunctionComponent<CaseProviderProps> = ({
  showNotification,
  children,
}) => {
  const [caseState, setCaseState] = useState<CaseProviderState>({
    caseId: null,
    caseRef: null,
    cases: null,
    activeAlignersCase: null,
    selectedCase: null,
    customerData: null,
    isDentalMonitoring: false,
    isFetchingData: false,
    isLoadingCase: false,
    isRefreshingTreatmentPlans: false,
    orderItems: null,
    incitingOrderItem: null,
    rejectionReasons: null,
    treatmentPlanSupplier: null,
  });

  const { addToQueue: addToCallbackQueue, executeQueue: executeCallbackQueue } =
    useCallbackQueue<CustomerWithCasesCallback>();

  const [
    getCustomerWithCases,
    { data: customerWithCasesData, loading: loadingCustomerWithCases },
  ] = useCustomerWithFullCasesLazyQuery();

  const [addTreatmentPlanStaging] = useGQLMutation<
    AddTreatmentPlanStagingMutation,
    AddTreatmentPlanStagingMutationVariables
  >(AddTreatmentPlanStagingDocument, true);
  const dispatch = useDispatch();
  const scans = useSelector(selectScans);

  useEffect(() => {
    if (!customerWithCasesData) {
      return;
    }

    const updateCaseState = async () => {
      const customerData = customerWithCasesData?.customer;
      setCaseState((caseState) => ({
        ...caseState,
        customerData: customerData,
      }));

      await executeCallbackQueue(customerWithCasesData);
      setCaseState((caseState) => ({ ...caseState, isFetchingData: false }));
    };

    updateCaseState();
  }, [customerWithCasesData, executeCallbackQueue]);

  const getCaseData = async (
    customerId: string,
    caseRef = '',
    returnError = false,
    callback = () => {}
  ) => {
    setCaseState((caseState) => ({ ...caseState, isFetchingData: true }));

    addToCallbackQueue((customerWithCases) =>
      processCaseData(customerWithCases, caseRef, returnError, callback)
    );

    if (!loadingCustomerWithCases) {
      getCustomerWithCases({
        variables: {
          customerId,
        },
      });
    }
  };

  const processCaseData = async (
    customerWithCases: CustomerWithFullCasesQuery,
    caseRef = '',
    returnError = false,
    callback = () => {}
  ) => {
    try {
      const mapReasons = (r: RemoteReason): RejectionReason => ({
        name: r.slug,
        label: r.name,
        reasons: r.reasons?.map(mapReasons),
      });

      const rejectionReasons = (await fetchRejectionReasons()).map(mapReasons);

      const remoteCases = customerWithCases?.customer?.cases ?? [];
      const cases = remoteCases
        .map((c: REFACTOR_ANY /* TODO: Casting is a quickfix */) =>
          decodeCase(c)
        )
        // Sort to show active cases first
        .sort((a, b) =>
          a?.isActive === b?.isActive ? 0 : a?.isActive ? -1 : 1
        )
        .filter(isNotNil);

      const activeAlignersCase = cases?.find(
        (c) => c?.isActive && c?.journey?.journeyType === ALIGNER_JOURNEY_TYPE
      );
      const matchingCase = cases?.find((c) => c?.caseRef === caseRef);

      if (!!caseRef && !matchingCase) {
        showNotification(`Unable to find case with ref: ${caseRef}`, 'error');
      }

      if (!caseRef || !matchingCase) {
        setCaseState((state) => ({
          ...state,
          cases,
          activeAlignersCase,
          isFetchingData: false,
          orderItems: [],
          rejectionReasons,
          treatmentPlans: [],
        }));
        callback();
      } else {
        const {
          caseRef,
          id: caseId,
          orderItems,
          incitingOrderItem,
          isDentalMonitoring,
          supplierServices,
        } = matchingCase;

        const treatmentPlanSupplier =
          supplierServices?.treatment_planning?.supplier;

        setCaseState((state) => ({
          ...state,
          cases,
          caseId,
          caseRef,
          activeAlignersCase,
          selectedCase: matchingCase,
          isDentalMonitoring: !!isDentalMonitoring,
          isFetchingData: false,
          incitingOrderItem,
          orderItems,
          rejectionReasons,
          treatmentPlanSupplier,
        }));
        callback();
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      setCaseState((state) => ({
        ...state,
        isFetchingData: false,
      }));

      if (returnError) {
        throw err;
      }

      showNotification(err.message, 'error');
    }
  };

  const setSelectedCase = async (caseRef: string, callback?: () => void) => {
    const selectedCase = caseState.cases?.find((c) => c?.caseRef === caseRef);

    if (!selectedCase) {
      showNotification(`Unable to select case with ref: ${caseRef}`, 'error');

      return;
    }

    const {
      id: caseId,
      orderItems,
      incitingOrderItem,
      isDentalMonitoring,
      supplierServices,
    } = selectedCase;

    const treatmentPlanSupplier =
      supplierServices?.treatment_planning?.supplier;

    try {
      setCaseState((state) => ({
        ...state,
        isLoadingCase: true,
      }));
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    } finally {
      setCaseState((state) => ({
        ...state,
        caseId,
        caseRef,
        selectedCase,
        isDentalMonitoring: !!isDentalMonitoring,
        isLoadingCase: false,
        incitingOrderItem,
        orderItems,
        treatmentPlanSupplier,
      }));
      if (callback) {
        callback();
      }
    }
  };

  const createTreatmentPlanStaging = async (
    caseRef: string,
    patientId: number,
    lowerScanId: string,
    upperScanId: string
  ) => {
    const draftPlanMetadataAwsLocation =
      'materials/sample_patient/planmetadata.json';
    const draftReportAwsLocation = 'materials/sample_patient/report.json';
    const draftViewAwsLocation = 'materials/sample_patient/viewer.zip';
    const draftPlanAwsLocation = 'materials/sample_patient/plan.zip';
    const mutationVariables: AddTreatmentPlanStagingMutationVariables = {
      patientId,
      caseRef,
      creationResources: {
        vision: {
          draftPlanMetadataAwsLocation,
          draftReportAwsLocation,
          draftViewAwsLocation,
          draftPlanAwsLocation,
        },
      },
      scanIds: {
        lowerScanId,
        upperScanId,
      },
      notes: '',
      treatmentPlanStagingType: TreatmentPlanStagingTypes.AlignerStaging,
      software: TreatmentPlanningSoftware.Vision,
    };
    await addTreatmentPlanStaging(mutationVariables);
  };

  const handleCreateTreatmentPlan = async () => {
    const { caseRef, customerData } = caseState;
    if (!customerData || !caseRef) {
      showNotification('Missing customer or case data', 'error');
      return;
    }

    try {
      setCaseState((state) => ({ ...state, isRefreshingTreatmentPlans: true }));
      const selectedScans = scans.filter((scan) =>
        scan.materialType.name.includes('scan')
      );
      await createTreatmentPlanStaging(
        caseRef,
        parseInt(customerData.id),
        selectedScans[0].id,
        selectedScans[1].id
      );
      dispatch(fetchTreatmentPlanStagings({ caseRef }));
      // Not setting the treatmentPlans key on the CaseState below as we are fetching and
      // setting that value when we dispatch the fetch action above
      setCaseState((state) => ({
        ...state,
        isRefreshingTreatmentPlans: false,
      }));
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
      setCaseState((state) => ({
        ...state,
        isRefreshingTreatmentPlans: false,
      }));
    }
  };

  return (
    <CaseContext.Provider
      value={{
        ...caseState,
        getCaseData,
        handleCreateTreatmentPlan,
        setSelectedCase,
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};

CaseProvider.propTypes = {
  showNotification: PropTypes.func.isRequired,
};

export default withNotifications(CaseProvider);
