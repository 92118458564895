import { theme, Button } from '@candidco/enamel';
import styled from 'styled-components/macro';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  max-width: 50rem;
`;

export const ModalRightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem;
  gap: 1.5rem;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.125rem;
`;

export const Title = styled.div`
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.medium};
  line-height: 150%;
`;

export const TrimmingButton = styled(Button)`
  padding: 0.75rem 1.5rem;
  line-height: 24px;
`;

export const NewAlignersButton = styled.button`
  font-weight: ${theme.fontWeights.bold};
  line-height: 150%;
  color: ${theme.colors.black70};
`;
