import React from 'react';

import {
  Container,
  HeaderText,
  TextContainer,
  HeaderContainer,
} from 'pages/Patient/ProductSelectionModal/Card.css';
import { Chip } from '@candidco/enamel';

type RetainerCardProps = {
  header: string;
  body: string;
  Icon: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
  chipText?: string;
};

const RetainerCard = ({
  header,
  body,
  Icon,
  isSelected,
  chipText,
  onClick,
}: RetainerCardProps) => {
  return (
    <Container
      isSelected={isSelected}
      onClick={onClick}
      selectionType="retainers"
    >
      {Icon}
      <TextContainer selectionType="retainers">
        <HeaderContainer>
          <HeaderText>{header}</HeaderText>
          {chipText && <Chip variant="outlined" label={chipText} size="tiny" />}
        </HeaderContainer>
        <div>{body}</div>
      </TextContainer>
    </Container>
  );
};

export default RetainerCard;
