import { Loading } from '@candidco/enamel';
import {
  GetCurrentPracticeLoyaltyProgramQuery,
  GetCurrentPracticeLoyaltyProgramQueryVariables,
  GetCurrentPracticeLoyaltyProgramDocument,
} from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import {
  getSelectedPractice,
  getSelectedPracticeLoyaltyStatus,
  setSelectedPracticeLoyaltyStatus,
} from 'pages/PracticeManagement/shared/slice';
import EnrollInLoyaltyForm from 'pages/PracticeManagement/LoyaltyProgramSettings/EnrollInLoyaltyForm';
import UpdateLoyaltyStatusForm from 'pages/PracticeManagement/LoyaltyProgramSettings/LoyaltyUpdateTierForm';

const Ribbon = styled.div<{ status: 'ok' | 'issue' }>`
  background: ${({ status }) => {
    if (status === 'ok') {
      return 'green';
    }
    return 'red';
  }};
  color: white;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const PracticeEnrolled = () => {
  return (
    <>
      <Ribbon status="ok">Enrolled in Loyalty</Ribbon>
      <UpdateLoyaltyStatusForm />
    </>
  );
};

const PracticeNotEnrolled = () => {
  return (
    <>
      <Ribbon status="issue">Not Enrolled in Loyalty</Ribbon>
      <EnrollInLoyaltyForm />
    </>
  );
};

const PracticeLoyaltyProgramOverview = () => {
  const dispatch = useDispatch();
  const selectedPracticeLoyaltyStatus = useSelector(
    getSelectedPracticeLoyaltyStatus
  );
  const selectedPractice = useSelector(getSelectedPractice);
  const [
    getCurrentPracticeLoyaltyProgram,
    { loading: getLoyaltyTierLoading, errors },
  ] = useGQLQuery<
    GetCurrentPracticeLoyaltyProgramQuery,
    GetCurrentPracticeLoyaltyProgramQueryVariables
  >(GetCurrentPracticeLoyaltyProgramDocument);

  useEffect(() => {
    const internal = async () => {
      if (selectedPractice) {
        const result = await getCurrentPracticeLoyaltyProgram({
          practiceId: selectedPractice.id,
        });
        if (result?.getCurrentPracticeLoyaltyProgram) {
          dispatch(setSelectedPracticeLoyaltyStatus(result));
        }
      }
    };
    if (!selectedPracticeLoyaltyStatus) {
      internal();
    }
  }, [selectedPractice]);

  if (getLoyaltyTierLoading) {
    return <Loading />;
  }

  if (selectedPracticeLoyaltyStatus) {
    return <PracticeEnrolled />;
  }

  if (errors) {
    return <PracticeNotEnrolled />;
  }

  return <Loading />;
};

export default PracticeLoyaltyProgramOverview;
