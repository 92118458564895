import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext, Button, Loading } from '@candidco/enamel';
import { useGQLQuery, useGQLMutation } from 'hooks/useGQL';
import {
  AddTreatmentPlanStagingDocument,
  AddTreatmentPlanStagingMutation,
  AddTreatmentPlanStagingMutationVariables,
  GetTreatmentPlanStagingDataDocument,
  GetTreatmentPlanStagingDataQuery,
  GetTreatmentPlanStagingDataQueryVariables,
  ScanIds,
  TreatmentPlanStagingVisionDraftLocations,
  TreatmentPlanStagingVisionCreationResources,
  TreatmentPlanStagingTypes,
  TreatmentPlanningSoftware,
} from 'generated/core/graphql';
import { ActionSection } from 'pages/TpCentralEnhanced/Actions/components';
import { camelCase } from 'lodash';
import { sortByISO8601DateNewestToOldest } from 'utils/time';
import { useTranslation } from 'react-i18next';

const draftObjectValid = (
  obj: Record<string, any> | null | undefined
): boolean => {
  const keys = [
    'draftPlanMetadataAwsLocation',
    'draftReportAwsLocation',
    'draftViewAwsLocation',
    'draftPlanAwsLocation',
  ];

  return (
    !!obj &&
    keys.every((requiredKey) => {
      const value = obj[requiredKey];
      return typeof value === 'string' && value.length > 0;
    })
  );
};

export const SyncTPToCandidButton = ({
  caseRef,
  customerRef,
  bypassOrthoReview,
  isComplete,
  isDisabled,
}: {
  caseRef: string;
  customerRef: string;
  bypassOrthoReview: boolean;
  isComplete: boolean;
  isDisabled: boolean;
}) => {
  // Get notification context
  const { showNotification } = useContext(NotificationContext);
  const { t } = useTranslation();
  // Initialize state
  const [notes, setNotes] = useState('');

  // Initialize query
  const [
    getDataForTPStaging,
    { loading: isDataDependencyLoading, data: mutationDataDependency },
  ] = useGQLQuery<
    GetTreatmentPlanStagingDataQuery,
    GetTreatmentPlanStagingDataQueryVariables
  >(GetTreatmentPlanStagingDataDocument, true);

  // Initialize mutation
  const [addTreatmentPlanStaging, { loading: isAddStagingLoading }] =
    useGQLMutation<
      AddTreatmentPlanStagingMutation,
      AddTreatmentPlanStagingMutationVariables
    >(AddTreatmentPlanStagingDocument, true);

  // Derive necessary data from selected case
  const patientId = parseInt(customerRef);

  // Prefetch Data for Button Function
  useEffect(() => {
    if (patientId && caseRef) {
      getDataForTPStaging({ patientId, caseRef });
    }
  }, [patientId, caseRef]);

  const vision =
    mutationDataDependency?.getTreatmentPlanStagingDraftLocations.vision;
  const someLocationsUndefined = !draftObjectValid(vision);

  const draftLocations = someLocationsUndefined
    ? (vision as TreatmentPlanStagingVisionDraftLocations)
    : vision;

  // Only process scans with at least one approval
  const approvedScans =
    mutationDataDependency?.getScanMaterialsByCaseRef.filter((scan) =>
      scan.materialEvaluations.some((evaluation) => evaluation.approved)
    ) ?? [];

  // Sort them by date to get the most recent approval
  const sortedScanMaterials = approvedScans.map((val) => {
    val.materialEvaluations.sort((a, b) =>
      sortByISO8601DateNewestToOldest(a.createdAt, b.createdAt)
    );
    return val;
  });

  // Collect scans ids by type and approval
  const scanIdsCollection = sortedScanMaterials.reduce(
    (acc, { id, materialType, materialEvaluations }) => {
      const key = `${camelCase(materialType.name)}Id` as keyof ScanIds;
      // Only process approved scans and the first one we hit should be the newest one
      if (
        materialEvaluations.some((evaluation) => evaluation.approved) &&
        !acc[key]
      ) {
        // lower_scan -> lowerScanId
        acc[key] = id;
      }
      return acc;
    },
    {} as ScanIds
  );

  // Handle form submission
  const handleSubmit = () => {
    if (someLocationsUndefined) {
      showNotification('Failed to get staging file locations.', 'error');
      return;
    }
    if (typeof scanIdsCollection === 'undefined') {
      showNotification('Failed to get scans.', 'error');
      return;
    }
    // Define mutation variables
    const mutationVariables: AddTreatmentPlanStagingMutationVariables = {
      patientId,
      caseRef,
      creationResources: {
        vision: draftLocations as TreatmentPlanStagingVisionCreationResources,
      },
      scanIds: scanIdsCollection,
      notes,
      treatmentPlanStagingType: TreatmentPlanStagingTypes.AlignerStaging,
      software: TreatmentPlanningSoftware.Vision,
      bypassOrthoReview: bypassOrthoReview,
    };

    // Execute mutation
    addTreatmentPlanStaging(mutationVariables)
      .then(() => {
        showNotification('Successfully synced treatment plan files', 'success');
        window.location.reload();
      })
      .catch(() => {
        showNotification('Failed to sync treatment plan files', 'error');
      });
  };

  const isLoading = isDataDependencyLoading || isAddStagingLoading;
  return (
    <ActionSection
      isComplete={isComplete}
      title={t('tp_central_enhanced.sync_treatment_plan')}
      isDisabled={isDisabled}
      disabledText={t('tp_central_enhanced.you_must_start_treatment_planning')}
    >
      <>
        <div style={{ marginBottom: '1rem', padding: '.5rem' }}>
          <textarea
            style={{
              height: '300px',
              border: '1px solid gray',
              width: '100%',
              padding: '.5rem',
              marginTop: '1rem',
            }}
            placeholder={`${t('common.notes')} (${t('common.optional')})`}
            onChange={(event: {
              currentTarget: { value: React.SetStateAction<string> };
            }) => setNotes(event.currentTarget.value)}
            value={notes ?? ''}
          />
        </div>
        <Button
          buttonType="secondary"
          onClick={handleSubmit}
          disabled={isLoading}
          style={{ margin: '0 auto' }}
        >
          {isLoading ? <Loading /> : t('common.sync_plan')}
        </Button>
      </>
    </ActionSection>
  );
};
