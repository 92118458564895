import styled from 'styled-components/macro';
import { AlertCard } from '@candidco/enamel';

export const AlertContainer = styled(AlertCard)`
  background: ${({ theme }) => theme.colors.blue05};
  border: 1px solid ${({ theme }) => theme.colors.blue30};
  border-radius: 4px;
  box-sizing: border-box;
  align-items: flex-start;
  align-items: center;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const TourLink = styled.a`
  text-decoration-line: underline;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-align: left;
`;

export const DismissLink = styled.a`
  text-decoration-line: underline;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text90};
  text-align: left;
`;

export const BannerText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-align: left;
`;
