import { Button } from '@candidco/enamel';
import styled from 'styled-components/macro';

export const ModalContainer = styled.div`
  max-width: 1280px;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.meduim};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  height: 72px;
`;

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
`;

export const StyledImage = styled.img`
  padding: 32px 32px 0px;
  overflow-y: scroll;
`;

export const StyledButton = styled(Button)`
  padding: 8px 24px;
  border-width: 2px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 32px;
  height: 88px;
`;
