import Dinero from 'dinero.js';

import { LineItem, Discount } from 'generated/core/graphql';

export type CatalogItemType = {
  question: string;
  title: string;
  description: string;
  price: Dinero.Dinero;
  imageSrc: string;
  optionType: OptionTypes;
  sku: string;
  loyaltySku?: string;
  quantity: number;
  totalPriceAfterDiscounts: Dinero.Dinero;
  totalPriceAfterLoyalty: Dinero.Dinero;
  totalPriceBeforeDiscounts: Dinero.Dinero;
  providerFacingProductName: string;
  appliedDiscounts?: Discount[];
};

export enum OptionTypes {
  Toggle,
  QuantityDropDown,
  None,
}

export type PartialLineItem = Omit<LineItem, 'credits' | 'postTaxCredits'>;
