import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Body,
  CardContainer,
  Circle,
  Content,
  Header,
  OuterCircle,
  ProtocolRow,
  Spacer,
  WhiteToothIcon,
} from 'pages/Patient/CaseCreator/TreatmentGoals/TreatmentGoals.css';
import { Divider, type } from '@candidco/enamel';
import { TreatmentProtocolLabels } from 'constants/Material';
import TreatmentProtocolsLink from 'components/TreatmentProtocolsLink';
import { Form, GetFormQuery, IntakeTypes, Maybe } from 'generated/core/graphql';
import { useSelector } from 'react-redux';
import {
  selectAreTreatmentGoalsReadyForSubmit,
  selectTreatmentGoalsInitialValues,
  selectDefaultTreatmentGoalValues,
  selectLatestIntake,
  selectIsSubmitted,
} from 'pages/Patient/patientSlice';
import AirwaySvg from 'assets/sup_airway.svg?react';
import { TreatmentGoalForm } from 'pages/Patient/CaseCreator/TreatmentGoals/TreatmentGoalForm';
import { AuthContext } from 'components/AuthProvider';
import { DEFAULT_LINKS } from 'constants/brands';
import { DefaultProtocolOptions } from 'generated/legacy/graphql';
import { StringMap } from 'utils/types';
import { FormikProps, FormikValues } from 'formik';

type AlignerTreatmentGoalContainerProps = {
  treatmentGoalForms: GetFormQuery['getForm'][];
  formRef: React.RefObject<FormikProps<FormikValues>>;
  setSelectedForm: (form: Form) => void;
  onValuesChanged: () => void;
  onSubmit: (values: FormikValues) => void;
};

const defaultProtocolToIntakeTypeMap: StringMap = {
  [DefaultProtocolOptions.Candidairway]: IntakeTypes.CandidAirwayTreatmentGoals,
  [DefaultProtocolOptions.Candidrx]: IntakeTypes.CandidRxTreatmentGoals,
};

export const AlignerTreatmentGoalContainer = ({
  treatmentGoalForms,
  formRef,
  setSelectedForm,
  onValuesChanged,
  onSubmit,
}: AlignerTreatmentGoalContainerProps) => {
  type ProtocolCardProps = {
    header: string;
    body: string;
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    isSelected: boolean;
    anySelected: boolean;
    onClick: () => void;
    disabled?: boolean;
  };

  type Protocol = {
    type: IntakeTypes;
    form: Maybe<Form> | null;
    totalQuestions: number;
  };

  const ProtocolCard = ({
    header,
    body,
    Icon,
    isSelected,
    anySelected,
    onClick,
    disabled = false,
  }: ProtocolCardProps) => {
    return (
      <CardContainer
        onClick={() => {
          !disabled && onClick();
        }}
        isSelected={isSelected}
        anySelected={anySelected}
        disabled={disabled}
        data-testid={`protocol-card-${header}`}
        data-isSelected={isSelected}
      >
        <Content>
          <OuterCircle>
            <Circle>
              <Icon />
            </Circle>
          </OuterCircle>
          <div>
            <Header>{header}</Header>
            <Body>{body}</Body>
          </div>
        </Content>
      </CardContainer>
    );
  };

  const isComplete = useSelector(selectAreTreatmentGoalsReadyForSubmit);
  const initialValues = useSelector(selectTreatmentGoalsInitialValues);
  const isSubmitted = useSelector(selectIsSubmitted);
  const defaultTreatmentGoalValues = useSelector(
    selectDefaultTreatmentGoalValues
  );
  const lastIntake = useSelector(selectLatestIntake);

  const setRxProtocol = () => {
    setSelectedProtocol(rxProtocol);
    setSelectedForm(rxProtocol.form!);
  };

  const setAirwayProtocol = () => {
    setSelectedProtocol(airwayProtocol);
    setSelectedForm(airwayProtocol.form!);
  };
  const getTotalQuestions = (form: Maybe<Form> | null) => {
    if (form) {
      return form.formSchema.reduce(
        (acc, cur) => acc + cur.questions.filter((q) => q.isRequired).length,
        0
      );
    }
    return 0;
  };

  const rxQuestions = useMemo(() => {
    return treatmentGoalForms.find(
      (form) => form?.materialType.name === IntakeTypes.CandidRxTreatmentGoals
    );
  }, [treatmentGoalForms]);

  const airwayQuestions = useMemo(() => {
    return treatmentGoalForms.find(
      (form) =>
        form?.materialType.name === IntakeTypes.CandidAirwayTreatmentGoals
    );
  }, [treatmentGoalForms]);

  const totalRxQuestions = useMemo(
    () => getTotalQuestions(rxQuestions as Form),
    [rxQuestions]
  );

  const totalAirwayQuestions = useMemo(
    () => getTotalQuestions(airwayQuestions as Form),
    [airwayQuestions]
  );

  const [selectedProtocol, setSelectedProtocol] = useState<
    Protocol | undefined
  >();

  const rxProtocol = {
    type: IntakeTypes.CandidRxTreatmentGoals,
    form: rxQuestions as Form,
    totalQuestions: totalRxQuestions,
  };

  const airwayProtocol = {
    type: IntakeTypes.CandidAirwayTreatmentGoals,
    form: airwayQuestions as Form,
    totalQuestions: totalAirwayQuestions,
  };

  const { userInfo } = useContext(AuthContext);
  const defaultProtocol =
    (userInfo?.accountPreferences?.doctor?.defaultProtocol as string) || '';
  const defaultProtocolAsIntakeType =
    defaultProtocolToIntakeTypeMap[defaultProtocol];
  const treatmentGoalsProtocols =
    userInfo?.brandInfo?.configs?.zendesk?.treatmentGoalsProtocols;

  const setDefaultProtocol = () => {
    if (defaultProtocolAsIntakeType === rxProtocol.type) {
      setRxProtocol();
    } else if (defaultProtocolAsIntakeType === airwayProtocol.type) {
      setAirwayProtocol();
    }
  };

  const setPreviouslySelectedProtocol = () => {
    const previouslySelectedProtocolType = lastIntake?.materialType.name;
    if (
      previouslySelectedProtocolType === IntakeTypes.CandidAirwayTreatmentGoals
    ) {
      setAirwayProtocol();
    } else if (
      previouslySelectedProtocolType === IntakeTypes.CandidRxTreatmentGoals
    ) {
      setRxProtocol();
    }
  };

  useEffect(() => {
    // this prevents the loading bar from showing as
    // complete before the forms finish fetching
    //  when 0/0 questions have been answered
    if (!airwayQuestions && !rxQuestions) {
      return;
    }
    // If the provider has already started filling out a protocol that should take priority over the default protocol
    if (lastIntake?.materialType.name) {
      setPreviouslySelectedProtocol();
    } else {
      setDefaultProtocol();
    }
  }, [defaultProtocolAsIntakeType, lastIntake, airwayQuestions, rxQuestions]);

  return (
    <>
      <type.Body>
        Select a design protocol for this case. Find more about our design
        protocols{' '}
        <TreatmentProtocolsLink
          copy="here"
          link={
            treatmentGoalsProtocols || DEFAULT_LINKS.TREATMENT_GOALS_PROTOCOLS
          }
        />
        .
      </type.Body>
      <ProtocolRow>
        <ProtocolCard
          header={TreatmentProtocolLabels.StandardRx}
          body={`${TreatmentProtocolLabels.StandardRx} is used to design treatment plans for general orthodontic patients.`}
          Icon={WhiteToothIcon}
          isSelected={
            selectedProtocol?.type === IntakeTypes.CandidRxTreatmentGoals
          }
          anySelected={!!selectedProtocol}
          onClick={() => setRxProtocol()}
          disabled={isSubmitted}
        />

        <ProtocolCard
          header={TreatmentProtocolLabels.Airway}
          body={`${TreatmentProtocolLabels.Airway} is used for patients who have or will be treated for airway health issues.`}
          Icon={AirwaySvg}
          isSelected={
            selectedProtocol?.type === IntakeTypes.CandidAirwayTreatmentGoals
          }
          anySelected={!!selectedProtocol}
          onClick={() => setAirwayProtocol()}
          disabled={isSubmitted}
        />
      </ProtocolRow>
      {!!selectedProtocol && (
        <>
          <Divider style={{ marginTop: '16px', marginBottom: '36px' }} />
          <Spacer />
          <TreatmentGoalForm
            formToComplete={selectedProtocol?.form?.formSchema!}
            skipValidation={!isComplete}
            initialValues={initialValues}
            defaultValues={defaultTreatmentGoalValues}
            stepTitle="Treatment goals"
            formRef={formRef}
            onSubmit={onSubmit}
            onValuesChanged={onValuesChanged}
            disabled={isSubmitted}
          />
        </>
      )}
    </>
  );
};
