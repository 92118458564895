import React, { useContext } from 'react';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  WorkingPrice,
  usePricingData,
  useSkipper,
  Filter,
} from 'pages/PracticeManagement/Pricing/utils';
import moment from 'moment';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';
import {
  TableContainer,
  CellInput,
  TableRow,
  PageTurnContainer,
} from 'pages/PracticeManagement/Pricing/styles';
import { Button } from '@candidco/enamel';

// Give our default column cell renderer editing superpowers!
const defaultColumn = {
  cell: function Cell({ getValue, row, column: { id: columnId }, table }: any) {
    const { checkHasAccess } = useContext(AuthContext);
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      table.options.meta?.updateData(value, row.original);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (
      columnId === 'rateInCents' &&
      checkHasAccess(ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN)
    ) {
      const isEdited = row.original.rateInCents !== value;
      return (
        <CellInput
          isEditable
          isEdited={isEdited}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
        />
      );
    }

    if (columnId === 'createdAt') {
      return (
        <CellInput
          value={moment(value).format('MM/DD/YY HH:MM Z') as string}
          disabled={true}
        />
      );
    }

    return (
      <CellInput
        isCentered={['createdBy', 'accountId'].includes(columnId)}
        value={value ?? ('' as string)}
        disabled={true}
      />
    );
  },
};

const ContractRatesTable = ({
  practiceId,
  accountId,
}: {
  practiceId: number;
  accountId: number;
}) => {
  const { checkHasAccess } = useContext(AuthContext);

  const columns = React.useMemo<ColumnDef<WorkingPrice>[]>(
    () => [
      {
        accessorKey: 'sku',
        header: () => 'SKU',
        footer: (props: { column: { id: any } }) => props.column.id,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
        footer: (props: { column: { id: any } }) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        footer: (props: { column: { id: any } }) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'accountId',
        header: 'Account ID',
        footer: (props: { column: { id: any } }) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'rateInCents',
        header: 'Rate in Cents',
        footer: (props: { column: { id: any } }) => props.column.id,
        enableColumnFilter: false,
      },
    ],
    []
  );

  const { data, saveChanges, changeSku } = usePricingData({
    practiceId,
    accountId,
  });

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    // Provide our updateData function to our table meta
    meta: {
      updateData: (value: any, original: WorkingPrice) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        changeSku(
          original.sku,
          Number(value),
          original.accountId,
          original.createdAt
        );
      },
    },
    debugTable: true,
  });

  return (
    <TableContainer>
      <div />
      <table>
        <thead>
          {table
            .getHeaderGroups()
            .map(
              (headerGroup: { id: React.Key | undefined; headers: any[] }) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(
                    (header: {
                      id: React.Key | undefined;
                      colSpan: number | undefined;
                      isPlaceholder: any;
                      column: {
                        columnDef: { header: any };
                        getCanFilter: () => any;
                      };
                      getContext: () => any;
                    }) => {
                      return (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter
                                    column={header.column as any}
                                    table={table}
                                  />
                                </div>
                              ) : null}
                            </div>
                          )}
                        </th>
                      );
                    }
                  )}
                </tr>
              )
            )}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.map(
              (row: {
                id: React.Key | undefined;
                getVisibleCells: () => any[];
              }) => {
                return (
                  <TableRow key={row.id}>
                    {row
                      .getVisibleCells()
                      .map(
                        (cell: {
                          id: React.Key | undefined;
                          column: { columnDef: { cell: any } };
                          getContext: () => any;
                        }) => {
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        }
                      )}
                  </TableRow>
                );
              }
            )}
        </tbody>
      </table>
      <div />
      <PageTurnContainer>
        <button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span>
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span>
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </PageTurnContainer>
      <div>{table.getRowModel().rows.length} Rows</div>
      {checkHasAccess(ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN) && (
        <div>
          <Button buttonType="secondary-outline" onClick={() => saveChanges()}>
            Save Changes
          </Button>
        </div>
      )}
    </TableContainer>
  );
};

export default ContractRatesTable;
