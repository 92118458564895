import React from 'react';
import { Loading } from '@candidco/enamel';

import PreferenceSection from 'pages/AccountPreferencesPage/PreferenceSection';
import { StartMonitoringOptions } from 'generated/legacy/graphql';

import { FormikRadioGroup } from 'components/FormikForms';

type ProMonitoringProps = {
  isLoading: boolean;
  id?: string;
};

const ProMonitoring = ({ isLoading, id }: ProMonitoringProps) => {
  const options = [
    {
      displayValue:
        'Automatically activate the patient’s account when the aligner kit is delivered',
      value: StartMonitoringOptions.Automatic,
    },
    {
      displayValue:
        'Manually activate by clicking the “Start monitoring” button on the patient detail page',
      value: StartMonitoringOptions.Manual,
    },
  ];

  return (
    <PreferenceSection
      title="ProMonitoring patient app activation"
      description="Choose when you want your patients to be notified to set up and start using their ProMonitoring account"
      id={id}
    >
      {isLoading ? (
        <Loading isCentered />
      ) : (
        <FormikRadioGroup
          type="radio"
          options={options}
          name={'startMonitoringPreference'}
        />
      )}
    </PreferenceSection>
  );
};

export default ProMonitoring;
