import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';
import { autoQuery } from 'state/system';

import { coreClient } from 'state/GraphQLProvider';

import {
  QueriesActionItemsOverviewArgs,
  ActionItemsOverviewDocument,
  ActionItemsOverview,
  ActionItemTask,
} from 'generated/core/graphql';
import { Definitions } from 'pages/ActionItems/OverviewModules/definitions';

type ActionItemsState = {
  materialsRejectedTasks: ActionItemTask[] | null;
  needsClarificationTasks: ActionItemTask[] | null;
  incompleteSubmissionTasks: ActionItemTask[] | null;
  tpReadyForReviewTasks: ActionItemTask[] | null;
  count: any[] | null;
};

const initialState: ActionItemsState = {
  materialsRejectedTasks: null,
  needsClarificationTasks: null,
  incompleteSubmissionTasks: null,
  tpReadyForReviewTasks: null,
  count: null,
};

export const getActionItemsOverview =
  autoQuery.createQueryAction<QueriesActionItemsOverviewArgs>(
    'actionItems/getOverview',
    ActionItemsOverviewDocument,
    'actionItems/setOverview',
    coreClient
  );

const actionItemsSlice = createSlice({
  name: 'actionItems',
  initialState,
  reducers: {
    reset: () => initialState,
    setOverview(
      state: ActionItemsState,
      action: PayloadAction<{ actionItemsOverview: ActionItemsOverview }>
    ) {
      state.incompleteSubmissionTasks = action.payload.actionItemsOverview
        .incompleteSubmission as ActionItemTask[];
      state.materialsRejectedTasks = action.payload.actionItemsOverview
        .materialsRejected as ActionItemTask[];
      state.needsClarificationTasks = action.payload.actionItemsOverview
        .needsClarification as ActionItemTask[];
      state.tpReadyForReviewTasks = action.payload.actionItemsOverview
        .tpReadyForReview as ActionItemTask[];
      //TODO: Would be nice if this was a dict
      state.count = [
        {
          state: Definitions.InTreatment,
          count: action.payload.actionItemsOverview.inTreatmentCount,
        },
        {
          state: Definitions.Rejected,
          count: action.payload.actionItemsOverview?.materialsRejected?.length,
        },
        {
          state: Definitions.NeedsClarification,
          count: action.payload.actionItemsOverview.needsClarification?.length,
        },
        {
          state: Definitions.Incomplete,
          count:
            action.payload.actionItemsOverview.incompleteSubmission?.length,
        },
        {
          state: Definitions.TpReview,
          count: action.payload.actionItemsOverview.tpReadyForReview?.length,
        },
        {
          state: Definitions.PreTreatment,
          count: action.payload.actionItemsOverview.preTreatmentCount,
        },
        {
          state: Definitions.TotalCases,
          count: action.payload.actionItemsOverview.totalCount,
        },
      ];
    },
  },
});

// selectors
export const selectIncompleteSubmissionTasks = (state: RootState) =>
  state.actionItems.incompleteSubmissionTasks;

export const selectMaterialsRejectedTasks = (state: RootState) =>
  state.actionItems.materialsRejectedTasks;

export const selectNeedsClarificationTasks = (state: RootState) =>
  state.actionItems.needsClarificationTasks;

export const selectTpReadyForReviewTasks = (state: RootState) =>
  state.actionItems.tpReadyForReviewTasks;

export const selectProviderTaskCounts = (state: RootState) =>
  state.actionItems.count;

export const selectMultipleStatusesTasks = (state: RootState) => {
  const {
    incompleteSubmissionTasks,
    materialsRejectedTasks,
    needsClarificationTasks,
  } = state.actionItems;

  const incompleteCustomerIds =
    incompleteSubmissionTasks?.map((task) => task.customerId?.toString()) ?? [];
  const rejectedCustomerIds =
    materialsRejectedTasks?.map((task) => task.customerId?.toString()) ?? [];
  const needsClarificationCustomerIds =
    needsClarificationTasks?.map((task) => task.customerId?.toString()) ?? [];
  return [
    ...new Set([
      ...incompleteCustomerIds.filter((i) => rejectedCustomerIds.includes(i)),
      ...incompleteCustomerIds.filter((i) =>
        needsClarificationCustomerIds.includes(i)
      ),
      ...rejectedCustomerIds.filter((i) =>
        needsClarificationCustomerIds.includes(i)
      ),
    ]),
  ];
};

export default actionItemsSlice.reducer;
