import React from 'react';
import styled from 'styled-components/macro';
import { Button, Modal, colors } from '@candidco/enamel';

import { Spacer, HRDividerLine } from 'styles/layout.css';

const ConfirmDialogContainer = styled.div`
  max-width: 343px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const HeaderContainer = styled.h4`
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const ChildrenContainer = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
`;

const StyledButton = styled(Button)<{ fontColor?: string }>`
  &:not(:disabled) {
    background-color: ${({ color }) => color};
    color: ${({ fontColor = colors.white }) => fontColor};

    &:hover {
      background-color: ${({ color }) => color};
      color: ${({ fontColor = colors.white }) => fontColor};
      filter: brightness(90%);
    }
    &:active {
      background-color: ${({ color }) => color};
      color: ${({ fontColor = colors.white }) => fontColor};
      filter: brightness(75%);
    }
  }
`;

type ConfirmDialogProps = {
  testId?: string;
  children: any;
  onClose?: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  cancelButtonText?: string;
  showCancelButton?: boolean;
  confirmButtonText?: string;
  showCloseButton?: boolean;
  isConfirmDisabled?: boolean;
  headerText?: string;
};

const CompleteCaseConfirmation = ({
  testId,
  children,
  isLoading = false,
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  cancelButtonText = 'Cancel',
  showCancelButton = true,
  confirmButtonText = 'Confirm',
  showCloseButton = false,
  isConfirmDisabled = false,
  headerText = 'Confirmation',
}: ConfirmDialogProps) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayColor={colors.text80}
      hideCloseButton={!showCloseButton}
      onClose={onClose || onCancel}
    >
      <ConfirmDialogContainer>
        <HeaderContainer>{headerText}</HeaderContainer>
        <HRDividerLine />
        <ChildrenContainer>{children}</ChildrenContainer>
        <HRDividerLine />
        <ButtonContainer>
          <Spacer spacing="1rem">
            {showCancelButton && (
              <StyledButton
                color={colors.blue10}
                disabled={isLoading}
                fontColor={colors.black90}
                onClick={onCancel}
              >
                {cancelButtonText}
              </StyledButton>
            )}
            <StyledButton
              data-testid={testId}
              color={colors.blue}
              disabled={isLoading || isConfirmDisabled}
              isLoading={isLoading}
              onClick={onConfirm}
            >
              {confirmButtonText}
            </StyledButton>
          </Spacer>
        </ButtonContainer>
      </ConfirmDialogContainer>
    </Modal>
  );
};

export default CompleteCaseConfirmation;
