import React from 'react';
import { Skeleton, Table } from '@candidco/enamel';
export const TableSkeleton = ({
  numberOfRow,
  numberOfColumn,
}: {
  numberOfRow: number;
  numberOfColumn: number;
}) => {
  const skeletonLoadingCell = {
    name: '',
    label: '',
    options: {
      customBodyRender: () => {
        return <Skeleton width="80%" />;
      },
      customHeadLabelRender: () => {
        return <Skeleton width={70} />;
      },
    },
  };
  const columns = Array(numberOfColumn).fill(skeletonLoadingCell);
  return (
    <Table
      data={Array(numberOfRow).fill({})}
      columns={columns}
      card={false}
      options={{
        selectableRows: 'none',
        fixedHeader: false,
        pagination: false,
        responsive: 'vertical',
      }}
    />
  );
};
