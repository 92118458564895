import React, { useMemo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { mediaQueries } from '@candidco/enamel';
import Tag from 'components/Tag';
import { Version } from 'utils/types';

const Wrapper = styled.div`
  @media ${mediaQueries.mobile} {
    margin-bottom: 0.5rem;

    button {
      margin-bottom: 0.5rem;
    }
  }
`;

type OnSelectVersion = (v: Version) => void;

type Props = {
  className?: string;
  current: Version;
  sortedCollection: any[];
  onSelect: OnSelectVersion;
  reverse: boolean;
};

/**
   Usage:

     const props = useVersionPills(items);
     const currentItem = items[props.currentIndex];

     <VersionPills {...props} />
 */
export const useVersionPills = (
  sortedCollection: any[],
  reverse: boolean = false
) => {
  const [current, setCurrent] = useState(sortedCollection.length);
  const onSelect: OnSelectVersion = useCallback((version) => {
    setCurrent(version);
  }, []);
  const currentIndex = reverse
    ? sortedCollection.length - current
    : current - 1;

  // HIC SUNT DRACONES: This is required in order to
  // invalidate initial value of `current` if the parent
  // component passes a new `sortedCollection` without
  // unmounting first.
  useEffect(() => {
    setCurrent(sortedCollection.length);
  }, [sortedCollection]);

  return {
    current,
    currentIndex,
    onSelect,
    sortedCollection,
    reverse,
  };
};

const mapVersionNumber =
  (totalVersions: number, reverse: boolean) => (_: any, index: number) =>
    reverse ? totalVersions - index : index + 1;

const VersionPills = ({
  sortedCollection,
  current,
  onSelect,
  className,
  reverse,
}: Props) => {
  const versions = useMemo(() => {
    const versionArr = sortedCollection.map(
      mapVersionNumber(sortedCollection.length, reverse)
    );
    if (reverse) {
      versionArr.reverse();
    }
    return versionArr;
  }, [sortedCollection]);

  if (!versions.length) {
    return null;
  }

  return (
    <Wrapper className={className}>
      {versions.map((version: Version) => (
        <Tag
          key={version}
          color={current === version ? 'blue' : 'light-blue'}
          onClick={() => onSelect(version)}
        >
          V{version}
        </Tag>
      ))}
    </Wrapper>
  );
};

export default VersionPills;
