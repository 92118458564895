import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { mediaQueries } from '@candidco/enamel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import DocumentTitle from 'components/DocumentTitle';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import {
  fetchPatient,
  fetchCases,
  selectShortName,
  selectActiveCase,
  fetchPatientLastStepEligible,
  selectPatient,
  selectLatestTreatmentPlanStaging,
  selectIsRefinementModalOpen,
  setIsRefinementModalOpen,
  setIsProductSelectionModalOpen,
  selectIsProductSelectionModalOpen,
  selectReplacementAlignerFormLink,
  resetPatientState,
  selectPatientName,
} from 'pages/Patient/patientSlice';
import { useIsLoading } from 'state/system';
import MainContent from 'pages/Patient/PatientDetail/MainContent';
import ProfileSideBar from 'pages/Patient/PatientDetail/ProfileSideBar';
import {
  RightArrowButton,
  OpenCircle,
  MainContentWrapper,
  PageContainer,
} from 'pages/Patient/PatientDetail/PatientDetail.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ProfileSideBarLegacy from 'pages/Patient/PatientDetail/ProfileSideBarLegacy';
import { RefinementsModal } from 'components/Modals';
import { searchDMPatientsURL } from 'utils/url';
import { ProductSelectionModal } from 'pages/Patient/ProductSelectionModal/ProductSelectionModal';
import {
  applyPromotionsToRefinementCart,
  fetchCouponsForPatient,
} from 'pages/Promotion/promotionsSlice';

const ProfileOpenButton = ({ onClick }: { onClick: () => void }) => (
  <>
    <RightArrowButton onClick={onClick} aria-hidden />
    <OpenCircle />
  </>
);

const PatientDetail = () => {
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  const { id } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const [isProfileOpen, setIsProfileOpen] = useState(true);
  const patient = useSelector(selectPatient);
  const patientName = useSelector(selectPatientName);
  const shortName = useSelector(selectShortName);
  const activeCase = useSelector(selectActiveCase);
  const replacementAlignersFormLink = useSelector(
    selectReplacementAlignerFormLink
  );
  const monitoringLink = searchDMPatientsURL(true);
  const isFetchingPatient = useIsLoading(fetchPatient.type);
  const { push } = useHistory();

  const isRefinementModalOpen = useSelector(selectIsRefinementModalOpen);
  const isProductSelectionModalOpen = useSelector(
    selectIsProductSelectionModalOpen
  );

  const activeTp = useSelector(selectLatestTreatmentPlanStaging);
  const { 'enable-product-selection-modal': enableProductSelectionModal } =
    useFlags();

  useEffect(() => {
    if (id && parseInt(id)) {
      dispatch(
        fetchPatient({
          customerId: id,
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (patient) {
      dispatch(applyPromotionsToRefinementCart());
      dispatch(fetchCouponsForPatient());
    }
  }, [patient]);

  useEffect(() => {
    if (patient) {
      dispatch(fetchCases({ patientIds: [Number(id)] }));
    }
  }, [patient]);

  useEffect(() => {
    if (activeCase?.caseRef) {
      dispatch(fetchPatientLastStepEligible());
    }
  }, [activeCase?.caseRef]);

  const failedFetchingPatient = !patient && !isFetchingPatient;
  if (failedFetchingPatient) {
    return (
      <>
        <span>Failed to load patient. Navigate back to </span>
        <Link to="/patient">Patients</Link>
        <span>.</span>
      </>
    );
  }

  return (
    <DocumentTitle
      title={`${isFetchingPatient || !shortName ? `Loading…` : `${shortName}`}`}
    >
      <RefinementsModal
        isOpen={isRefinementModalOpen}
        onCancelClicked={() => dispatch(setIsRefinementModalOpen(false))}
        onSubmitted={() => {
          dispatch(setIsRefinementModalOpen(false));
          dispatch(resetPatientState());
          if (patient?.id) {
            push(`/patient/${patient.id}/case-creator`);
          }
        }}
        treatmentPlanSteps={activeTp?.data?.steps ?? 0}
        caseId={(activeCase?.data?.caseId ?? 0).toString()}
        caseRef={activeCase?.caseRef ?? ''}
        patientId={activeCase?.patientId.toString() ?? ''}
        candidMonitoringLink={monitoringLink}
        patientName={patientName ?? ''}
        formLink={replacementAlignersFormLink}
      />
      {enableProductSelectionModal && (
        <ProductSelectionModal
          isOpen={isProductSelectionModalOpen}
          patientName={patientName ?? ''}
          onClose={() => dispatch(setIsProductSelectionModalOpen(false))}
        />
      )}
      <PageContainer>
        {enableProductSelectionModal ? (
          <ProfileSideBar isOpen={isProfileOpen} setOpen={setIsProfileOpen} />
        ) : (
          <ProfileSideBarLegacy
            isOpen={isProfileOpen}
            setOpen={setIsProfileOpen}
          />
        )}
        {tabletSize && !isProfileOpen && (
          <ProfileOpenButton
            onClick={() => {
              setIsProfileOpen(true);
            }}
          />
        )}
        <MainContentWrapper isFullWidth={!isProfileOpen}>
          <MainContent />
        </MainContentWrapper>
      </PageContainer>
    </DocumentTitle>
  );
};

export default PatientDetail;
