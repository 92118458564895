import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Button, colors } from '@candidco/enamel';
import ChatIconSVG from 'assets/chat-icon.svg?react';
import { useAuthContext } from 'components/AuthProvider';
import useZendesk, { WidgetVersion } from 'hooks/useZendesk';

const StyledSupportButton = styled(Button)`
  color: ${colors.black70};
  padding: 0 !important;

  &:hover {
    background-color: ${colors.white};
  }

  svg {
    path {
      /* !important used to override enamel styling  */
      stroke: ${colors.black70} !important;
    }
  }
`;

type ZendeskWidgetProps = {
  text?: string;
};
const ZendeskWidget = ({ text }: ZendeskWidgetProps) => {
  const {
    showZendesk,
    widgetVersion,
    handleShowWidget,
    handleCloseWidget,
    handleOpenWidget,
    isWidgetLoaded,
    isWidgetOpen,
    setCustomerInfo,
    setDepartment,
  } = useZendesk();
  const { userInfo } = useAuthContext();

  // Pre-populate customer name/email
  useEffect(() => {
    if (isWidgetLoaded && userInfo) {
      // We only have the user's name if they are a doctor, so below we have handling for no name.
      setCustomerInfo({
        email: userInfo.email!,
        name: userInfo?.doctor?.fullName || '',
      });
    }
  }, [isWidgetLoaded, userInfo]);

  // Show Widget on chat routes, and hide on non-chat routes
  useEffect(() => {
    if (isWidgetLoaded) {
      setDepartment('CandidPro');
      handleShowWidget();
    }
  }, [isWidgetLoaded]);

  useEffect(() => {
    if (widgetVersion === WidgetVersion.Classic) {
      if (isWidgetOpen) {
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            chat: {
              suppress: false,
            },
            helpCenter: {
              suppress: false,
            },
            contactForm: {
              suppress: false,
            },
          },
        });
      } else if (isWidgetLoaded) {
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            chat: {
              suppress: true,
            },
            helpCenter: {
              suppress: true,
            },
            contactForm: {
              suppress: true,
            },
          },
        });
      }
    }
  }, [isWidgetOpen, isWidgetLoaded, widgetVersion]);

  if (!showZendesk) {
    return null;
  }

  return (
    <StyledSupportButton
      onClick={() => (isWidgetOpen ? handleCloseWidget() : handleOpenWidget())}
      leftIcon={<ChatIconSVG />}
      buttonType="text"
    >
      {text ?? ''}
    </StyledSupportButton>
  );
};

export default ZendeskWidget;
