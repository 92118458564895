import React, { useContext, useEffect } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, NotificationContext, AlertCard } from '@candidco/enamel';
import styled from 'styled-components/macro';

import { AppDispatch } from 'state/store';
import useConfirmChanges from 'hooks/useConfirmChanges';
import { centeredLayout } from 'styles/layout.css';
import DocumentTitle from 'components/DocumentTitle';
import Sidebar from 'pages/Prism/Sidebar';
import ReviewPanel from 'pages/Prism/ReviewPanel';
import { CaseSubmissionStates } from 'constants/Case';

import {
  fetchAggregate,
  fetchPrismCustomer,
  resetState,
  selectError,
  selectIsEditing,
  selectIsLoading,
  selectShortName,
  selectNotificationEvent,
  selectAggregate,
  selectPrismCustomer,
  selectIsFetchingPrismCustomer,
} from 'pages/Prism/prismSlice';
import JourneyProvider from 'components/JourneyProvider';

const Container = styled.div`
  ${centeredLayout};
  display: flex;
  height: 100%;
`;

const CaseSubmissionAlertCardContainer = styled.div`
  height: 2rem;
  padding: 2rem 0 0 2rem;
`;

type RouteParams = {
  aggregateRef: string;
  id: string;
};

const PrismView = () => {
  const { aggregateRef } = useParams<RouteParams>();
  const { showNotification } = useContext(NotificationContext);
  const dispatch = useDispatch<AppDispatch>();
  const isPrismDataLoading = useSelector(selectIsLoading);
  const isEditing = useSelector(selectIsEditing);
  const error = useSelector(selectError);
  const customerName = useSelector(selectShortName);
  const notificationEvent = useSelector(selectNotificationEvent);
  const aggregate = useSelector(selectAggregate);
  const customer = useSelector(selectPrismCustomer)?.customer;
  const isCustomerDataLoading = useSelector(selectIsFetchingPrismCustomer);
  const customerId = aggregate?.customer?.id;

  const selectedCase = customer?.cases?.find(
    (c) => c?.caseRef === aggregate?.caseRef
  );

  const photosIneligibleForReview = !!(
    selectedCase?.isGen2 &&
    selectedCase?.state !== CaseSubmissionStates.Submitted
  );

  useConfirmChanges(isEditing);

  useEffect(() => {
    dispatch(fetchAggregate(aggregateRef));

    return () => {
      // Reset state when leaving the page
      dispatch(resetState());
    };
  }, [aggregateRef]);

  useEffect(() => {
    if (customerId && !customer) {
      dispatch(fetchPrismCustomer(customerId));
    }
  }, [customerId, !customer]);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
    }
  }, [error]);

  useEffect(() => {
    if (notificationEvent) {
      const { message, type } = notificationEvent;
      showNotification(message, type);
    }
  }, [notificationEvent]);

  if ((isPrismDataLoading || isCustomerDataLoading) && !aggregate?.caseRef) {
    return (
      <DocumentTitle title="Loading…">
        <Loading isCentered />
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title={`${customerName} - Prism (photo review)`}>
      <Container>
        <JourneyProvider caseRef={aggregate?.caseRef}>
          <Sidebar photosIneligibleForReview={photosIneligibleForReview} />
          {photosIneligibleForReview ? (
            <CaseSubmissionAlertCardContainer>
              <AlertCard
                type="warning"
                displayIcon={true}
                header={
                  'Case pending submission before photos are available for review'
                }
              ></AlertCard>
            </CaseSubmissionAlertCardContainer>
          ) : (
            <ReviewPanel
              aggregateRef={aggregateRef}
              caseRef={aggregate?.caseRef}
            />
          )}
        </JourneyProvider>
      </Container>
      <Prompt message="Changes you made may not be saved." when={isEditing} />
    </DocumentTitle>
  );
};

export default PrismView;
