import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Button, Modal, NotificationContext } from '@candidco/enamel';
import * as Sentry from '@sentry/react';

import {
  CaseTypes,
  CreationChannels,
  useCreateCaseMutation,
  useTransitionJourneyMutation,
} from 'generated/legacy/graphql';
import { JourneyTransition } from 'pages/Case/types';

import { ALIGNER_JOURNEY_TYPE } from 'pages/Case/CaseProvider';
import {
  StyledAlertCard,
  LabelAndInputGroup,
  StyledTextInput,
  StyledModal,
  StyledDivider,
  Header,
  Body,
  InputFields,
  Radio,
  RadioGroup,
  RadioLabel,
  StyledSelectInput,
  Footer,
  TextArea,
  CancelButton,
} from 'components/Modals/AdditionalAlignersModalLegacy.css';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { getBrandDomainSettings } from 'utils/brands';

type AdditionalAlignersModalLegacyProps = {
  isOpen: boolean;
  treatmentPlanSteps: number;
  caseId: string;
  caseRef: string;
  patientId: string;
  candidMonitoringLink: string;
  patientName: string;
  onSubmitted: () => void;
  onCancelClicked: () => void;
  formLink: string;
};

const FreeRefinementBanner = () => {
  return (
    <StyledAlertCard
      type="default"
      displayIcon={true}
      header="This case qualifies for free refinements"
    />
  );
};

type Option = {
  label: string;
  value: string;
};

export const AdditionalAlignersModalLegacy = ({
  isOpen,
  treatmentPlanSteps,
  caseId,
  caseRef,
  patientId,
  candidMonitoringLink,
  patientName,
  onCancelClicked,
  onSubmitted,
  formLink,
}: AdditionalAlignersModalLegacyProps) => {
  const [startedTreatment, setStartedTreatment] = useState<boolean | null>(
    null
  );
  const [selectedStep, setSelectedStep] = useState<string | null>(null);
  const [stepOptions, setStepOptions] = useState<Option[]>([]);
  const [numDays, setNumDays] = useState<number | null>(null);
  const [initialFitIssue, setInitialFitIssue] = useState<string | null>(null);
  const [hadDentalWork, setHadDentalWork] = useState<boolean | null>(null);
  const { monitoringLabel } = getBrandDomainSettings();

  const { showNotification } = useContext(NotificationContext);
  const { 'bypass-ortho-review-process': bypassOrthoReviewProcess } =
    useFlags();

  const [createAdditionalAlignersCase, { loading: isCreatingCase }] =
    useCreateCaseMutation({
      onCompleted: () => {
        onSubmitted();
      },
    });

  const [transitionJourney, { loading: isTransistionJourneySubmitting }] =
    useTransitionJourneyMutation({
      onCompleted: async () => {
        let itiType = '';
        if (!startedTreatment) {
          itiType = 'initial_fit_issue';
        } else if (parseInt(selectedStep!) === treatmentPlanSteps) {
          itiType = 'end_of_treatment_refinement';
        } else if (parseInt(selectedStep!) < treatmentPlanSteps) {
          itiType = 'mid_course_correction';
        }

        const createCaseData = {
          itiType,
          currentStep: startedTreatment ? parseInt(selectedStep!) : 0,
          daysOnCurrentStep: startedTreatment ? numDays : 0,
          notes: !startedTreatment ? initialFitIssue : null,
          // Temporarily all refinements should be free until we build the rest of our refinement policy
          itiPaymentCategory: 'free_additional_aligners',
          hasDentalWorkBeenDone: !startedTreatment ? hadDentalWork : null,
        };
        try {
          await createAdditionalAlignersCase({
            variables: {
              caseType: CaseTypes.ProAdditionalAligners,
              customerId: patientId,
              journey: CreationChannels.ProProvider,
              precedingCaseId: parseInt(caseId),
              data: {
                ...createCaseData,
                isGen2: true,
                requireOrthoReview: !bypassOrthoReviewProcess,
              },
            },
          });
        } catch (err) {
          if (!(err instanceof Error)) {
            throw err;
          }

          Sentry.captureException(err.message);
          showNotification(
            'Something went wrong, please try again in a few moments. If the issue persists, please contact support',
            'error'
          );
        }
      },
    });

  const handleSubmit = () => {
    transitionJourney({
      variables: {
        caseRef: caseRef,
        component: ALIGNER_JOURNEY_TYPE,
        transition: startedTreatment
          ? JourneyTransition.ForceAdditionalAlignersNeeded
          : JourneyTransition.ForceInitialFitIssue,
        transitionReason: startedTreatment
          ? 'Refinements requested'
          : 'Initial fit issue',
      },
    });
  };

  const isInitialFitValid = !startedTreatment && !!initialFitIssue;
  const isStartedTreatmentValid =
    startedTreatment && !!numDays && !!selectedStep;

  useEffect(() => {
    setStepOptions(
      Array.from({ length: treatmentPlanSteps }).map((_val, idx) => {
        return {
          label:
            idx === treatmentPlanSteps - 1
              ? 'Step ' + (idx + 1) + ' (end of treatment)'
              : 'Step ' + (idx + 1),
          value: (idx + 1).toString(),
        };
      })
    );
  }, [treatmentPlanSteps]);

  const startedTreatmentOptions = () => {
    return (
      <InputFields>
        <LabelAndInputGroup>
          <div>
            <div>What aligner step is this patient on?</div>
            <a
              href={candidMonitoringLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              {`Confirm in ${monitoringLabel}`}
            </a>
          </div>
          <StyledSelectInput
            maxMenuHeight={200}
            options={stepOptions}
            onChange={(option: Option) => {
              setSelectedStep(option.value);
            }}
            value={stepOptions.find((o) => o.value === selectedStep)}
          />
        </LabelAndInputGroup>

        <LabelAndInputGroup>
          <div>How many days has the patient been on this step?</div>
          <StyledTextInput
            type="number"
            placeholder="# of days"
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setNumDays(parseInt(e.target.value));
            }}
            value={numDays?.toString()}
          />
        </LabelAndInputGroup>
      </InputFields>
    );
  };

  const initialFitOptions = () => {
    return (
      <>
        <RadioGroup>
          <div>
            Has this patient had dental work since the treatment plan was
            created?
          </div>
          <RadioLabel>
            <Radio
              type="radio"
              id="hadDentalWork"
              checked={hadDentalWork === true}
              onChange={(e) => {
                handleHadDentalWork(e.currentTarget.id);
              }}
            />
            Yes
          </RadioLabel>
          <RadioLabel>
            <Radio
              type="radio"
              id="noDentalWork"
              checked={hadDentalWork === false}
              onChange={(e) => {
                handleHadDentalWork(e.currentTarget.id);
              }}
            />
            No
          </RadioLabel>
        </RadioGroup>

        <TextArea
          placeholder="To the best of your ability, describe the fit issue. Where is the fit the worst? 
        Is there anything else we should know?"
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setInitialFitIssue(e.target.value);
          }}
          value={initialFitIssue || undefined}
        />
      </>
    );
  };

  const handleStartedTreatmentOnChange = (id: string) => {
    setStartedTreatment(id === 'startedTreatment');
  };

  const handleHadDentalWork = (id: string) => {
    setHadDentalWork(id === 'hadDentalWork');
  };

  const submitDisabled = () => {
    return (
      startedTreatment === null ||
      (startedTreatment === false && hadDentalWork === null) ||
      (!isInitialFitValid && !isStartedTreatmentValid)
    );
  };

  const followUpOptions = () => {
    if (startedTreatment === null) {
      return null;
    }
    return startedTreatment ? startedTreatmentOptions() : initialFitOptions();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} hideCloseButton>
      <StyledModal>
        <Header>
          <div>Request refinements</div>
          <div>&nbsp;• {patientName}</div>
        </Header>
        <StyledDivider />
        <Body>
          <RadioGroup>
            <div>
              Has this patient started treatment with the current set of
              aligners?
            </div>
            <RadioLabel>
              <Radio
                type="radio"
                id="startedTreatment"
                checked={startedTreatment === true}
                onChange={(e) => {
                  handleStartedTreatmentOnChange(e.currentTarget.id);
                }}
              />
              Yes
            </RadioLabel>
            <RadioLabel>
              <Radio
                type="radio"
                id="initialFit"
                checked={startedTreatment === false}
                onChange={(e) => {
                  handleStartedTreatmentOnChange(e.currentTarget.id);
                }}
              />
              No
            </RadioLabel>
          </RadioGroup>
          {followUpOptions()}
          {FreeRefinementBanner()}
          <div>
            If you're looking for a replacement aligner,{' '}
            <a href={formLink} rel="noopener noreferrer" target="_blank">
              use this form instead.
            </a>
          </div>
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton onClick={onCancelClicked} buttonType="text">
            Cancel
          </CancelButton>
          <Button
            onClick={handleSubmit}
            buttonType="secondary"
            disabled={submitDisabled()}
            isLoading={isTransistionJourneySubmitting || isCreatingCase}
          >
            Next
          </Button>
        </Footer>
      </StyledModal>
    </Modal>
  );
};
