import styled from 'styled-components/macro';

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  > * {
    flex: 1;
  }
`;

export const Line1Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AddLine2 = styled.div`
  color: ${({ theme }) => theme.colors.blue50};
  cursor: pointer;
`;
