import React, { useContext, useEffect, useReducer } from 'react';
import { useGQLQuery, useGQLMutation } from 'hooks/useGQL';
import {
  AddContractRatesDocument,
  AddContractRatesMutation,
  AddContractRatesMutationVariables,
  GetPricingDocument,
  GetPricingQuery,
  GetPricingQueryVariables,
  ContractRateType,
  ContractRateInput,
} from 'generated/core/graphql';
import { Column, Table } from '@tanstack/react-table';
import { AuthContext } from 'components/AuthProvider';

export type WorkingPrice = {
  rateInCents: number;
  accountId: number;
  createdAt: string;
  createdBy: number | null;
  sku: string;
  edited: boolean;
};
export function Filter({
  column,
}: {
  column: Column<any, any>;
  table: Table<any>;
}) {
  const columnFilterValue = column.getFilterValue();

  return (
    <input
      type="text"
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
    />
  );
}

export function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

type ActionPayload<T, P = any> = {
  type: T;
  payload: P;
};

type Actions =
  | ActionPayload<'mutatePrice', WorkingPrice>
  | ActionPayload<'setPricing', ContractRateType[]>
  | ActionPayload<'mergeNewPrices', ContractRateType[]>;

type LocalPricingState = {
  hasChanges: boolean;
  rates: WorkingPrice[];
};
const initialState: LocalPricingState = {
  hasChanges: false,
  rates: [],
};
function pricingReducer(state: LocalPricingState, action: Actions) {
  if (action.type === 'setPricing') {
    const rates: WorkingPrice[] = action.payload.map((cr) => ({
      ...cr,
      createdBy: cr.createdBy ?? null,
      edited: false,
    }));
    return {
      ...state,
      rates,
      hasChanges: false,
    };
  }
  if (action.type === 'mergeNewPrices') {
    const newPrices = action.payload.reduce(
      (acc, cv) => {
        acc[cv.sku] = cv;
        return acc;
      },
      {} as { [key: string]: ContractRateType }
    );

    const revisedRates: WorkingPrice[] = state.rates.map((cr) => {
      if (newPrices[cr.sku]) {
        const price = newPrices[cr.sku];
        return { ...price, createdBy: price.createdBy ?? null, edited: false };
      }
      return { ...cr, createdBy: cr.createdBy ?? null, edited: false };
    });
    return {
      ...state,
      rates: revisedRates,
      hasChanges: false,
    };
  }

  if (action.type === 'mutatePrice') {
    const revisedRates = state.rates;
    const rateIdx = revisedRates.findIndex(
      (val) => val.sku === action.payload.sku
    );
    if (rateIdx !== -1) {
      revisedRates[rateIdx] = action.payload;
      return {
        ...state,
        rates: revisedRates,
        hasChanges: true,
      };
    }
  }

  return state;
}

type UsePricingDataArgs = {
  practiceId?: number;
  accountId?: number;
};
type UsePricingDataReturn = {
  data: WorkingPrice[];
  dispatch: React.Dispatch<Actions>;
  saveChanges: () => void;
  changeSku: (
    sku: string,
    newPriceInCents: number,
    accountId: number,
    createdAt: string
  ) => void;
};
export const usePricingData = ({
  practiceId,
  accountId: passedAccountId,
}: UsePricingDataArgs): UsePricingDataReturn => {
  const { userInfo } = useContext(AuthContext);
  const [state, dispatch] = useReducer(pricingReducer, initialState);
  const [addContractRates] = useGQLMutation<
    AddContractRatesMutation,
    AddContractRatesMutationVariables
  >(AddContractRatesDocument);
  const [getPricing] = useGQLQuery<GetPricingQuery, GetPricingQueryVariables>(
    GetPricingDocument
  );

  useEffect(() => {
    const refreshPricing = async () => {
      if (!practiceId) {
        return;
      }
      const pricingData = await getPricing({ practiceId: String(practiceId) });
      dispatch({
        type: 'setPricing',
        payload: pricingData?.getContractRates ?? [],
      });
    };
    refreshPricing();
  }, [practiceId]);

  const saveChanges = async () => {
    if (!state.hasChanges || !passedAccountId) {
      return;
    }
    const contractRates = state.rates
      .filter((r) => r.edited)
      .map(
        ({ sku, rateInCents, createdBy }) =>
          ({
            sku,
            rateInCents,
            accountId: passedAccountId,
            createdBy: createdBy ?? null,
          }) as ContractRateInput
      );
    const data = await addContractRates({ contractRates });
    const newContractRates = data?.addContractRates
      ?.contractRates as ContractRateType[];
    dispatch({ type: 'mergeNewPrices', payload: newContractRates });
  };
  const changeSku = (
    sku: string,
    newPriceInCents: number,
    accountId: number,
    createdAt: string
  ) => {
    if (userInfo?.id) {
      dispatch({
        type: 'mutatePrice',
        payload: {
          rateInCents: newPriceInCents,
          accountId,
          createdAt,
          createdBy: Number(userInfo.id),
          sku,
          edited: true,
        },
      });
    }
  };

  return {
    data: state.rates,
    dispatch,
    saveChanges,
    changeSku,
  };
};
