import FullPageModal from 'components/FullPageModal/FullPageModal';
import { CaseTypeNames } from 'constants/Case';
import moment from 'moment';
import { selectActiveCase } from 'pages/OrthoPrism/orthoSlice';
import ProductSelection from 'pages/Patient/ProductSelectionModal/ProductSelection';
import RetainerSelection from 'pages/Patient/ProductSelectionModal/RetainerSelection';
import { RetainerTypes } from 'pages/Patient/ProductSelectionModal/types';
import {
  createCase,
  selectIsLastStepEligible,
  selectPatient,
  selectSelectedProductType,
  setIsRefinementModalOpen,
  setSelectedProduct,
} from 'pages/Patient/patientSlice';
import { ProductTypes } from 'pages/Patient/types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from 'state/store';

type ProductSelectionModalProps = {
  patientName: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ProductSelectionModal = ({
  isOpen,
  onClose,
  patientName,
}: ProductSelectionModalProps) => {
  const selectedProduct = useSelector(selectSelectedProductType);
  const isLastStepEligible = useSelector(selectIsLastStepEligible);
  const [showRetainerOptions, setShowRetainerOptions] = useState(false);
  const [selectedRetainerType, setSelectedRetainerType] =
    useState<RetainerTypes | null>(null);

  const patient = useSelector(selectPatient);

  const activeCase = useSelector(selectActiveCase);
  const dispatch = useDispatch<AppDispatch>();
  const { push } = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const retainerFormLink = useMemo(
    () =>
      `https://formfacade.com/public/113606064810223952308/all/form/1FAIpQLSfdhC6cWGKFuy1-qPVfh4TbC0qC0yokiwH3pJxm_pbKqnB4oQ?emailAddress=${
        patient?.referringDentist?.email
      }&entry.1081703492=${patient?.practice?.name}&entry.433797451=${
        patient?.referringDentist?.fullName
      }&entry.402285835=${patient?.firstName} ${
        patient?.lastName
      }&entry.790415107=${moment(patient?.birthday?.toString()).format(
        'YYYY-MM-DD'
      )}`,
    [patient]
  );

  const internalOnClose = () => {
    //Some cleanup to clear state in case the user reopens the modal
    dispatch(setSelectedProduct(null));
    setShowRetainerOptions(false);
    setSelectedRetainerType(null);
    onClose();
  };

  const createCaseInternal = async (caseType: CaseTypeNames) => {
    if (selectedProduct == null || !patient) {
      return;
    }

    setIsSubmitting(true);
    await dispatch(
      //TODO: This only works for retainer cases. The core endpoint doesn't handle gen2 flag correctly
      //https://app.shortcut.com/candid/story/88184
      createCase({
        caseOptions: {
          caseType: caseType,
          patientId: Number(patient.id),
          practiceId: Number(patient.practice?.id),
          precedingCaseRef:
            caseType === CaseTypeNames.ADDITIONAL_ALIGNER
              ? activeCase?.caseRef
              : null,
        },
      })
    );

    push(`/patient/${patient?.id}/case-creator/diagnostic-materials`);

    setIsSubmitting(false);
    internalOnClose();
  };
  //TODO: Ideally these page specific actions would  be in their respective folder, but there's not enough
  // content to justify that.
  //In general there is way too much product specific logic in this file
  //No ticket for this, this will probably change over time
  const handleProductSelectionOnContinue = async (
    selectedProduct: ProductTypes | null
  ) => {
    if (selectedProduct == null || !patient) {
      return;
    }

    switch (selectedProduct) {
      case ProductTypes.Aligner:
        //Create the aligner case and go to diagnostic materials
        createCaseInternal(CaseTypeNames.ALIGNER);
        break;
      case ProductTypes.Retainer:
        if (!isLastStepEligible) {
          createCaseInternal(CaseTypeNames.RETAINER);
        } else {
          setShowRetainerOptions(true);
        }
        break;
      case ProductTypes.Refinement:
        dispatch(setIsRefinementModalOpen(true));
        internalOnClose();
        break;
      case ProductTypes.Replacement:
        //Open the google forms
        onClose();
        window.open(retainerFormLink, '_blank');
        break;
    }
  };

  const handleRetainerSelectionOnContinue = async () => {
    if (selectedRetainerType == null || !patient) {
      return;
    }

    switch (selectedRetainerType) {
      case RetainerTypes.NewScans:
        //Create the retainer case and go to diagnostic materials
        createCaseInternal(CaseTypeNames.RETAINER);
        break;
      case RetainerTypes.Reorder:
        push(`/patient/${patient?.id}/case-creator/checkout?reorder=true`);
        break;
      case RetainerTypes.LastStep:
        push(`/patient/${patient?.id}/case-creator/checkout?last_step=true`);
        break;
    }
  };

  return (
    <FullPageModal
      continueDisabled={
        selectedProduct == null ||
        (showRetainerOptions && selectedRetainerType == null)
      }
      isOpen={isOpen}
      onClose={internalOnClose}
      onContinue={() => {
        if (showRetainerOptions) {
          handleRetainerSelectionOnContinue();
        } else {
          handleProductSelectionOnContinue(selectedProduct);
        }
      }}
      patientName={patientName}
      titleText="Place order"
      isLoading={isSubmitting}
    >
      {!showRetainerOptions && <ProductSelection />}
      {showRetainerOptions && (
        <RetainerSelection
          selectedRetainer={selectedRetainerType}
          updateSelectedRetainer={(retainerType) =>
            setSelectedRetainerType(retainerType)
          }
        />
      )}
    </FullPageModal>
  );
};
