import React, { useCallback, useContext, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '@candidco/enamel';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import * as TreatmentPlanStagings from 'pages/OrthoPrism/treatmentPlanStagings';
import { useIsLoading } from 'state/system';
import { useHasCustomerAccess } from 'hooks/useHasCustomerAccess';
import {
  selectPatient,
  selectSelectedCase,
  selectSortedTreatmentPlanStagings,
  createTreatmentPlanProOrder,
  fetchTreatmentPlanStagings,
  SubmissionFragment,
  fetchCases,
} from 'pages/Patient/patientSlice';
import { usePatientLoadingStates } from 'pages/Patient/utils';
import LegacyProReviewForm, {
  RejectionDetailProblem,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/LegacyProReviewForm';
import ProReviewForm from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/ProReviewForm';
import {
  ReviewFormWrapper,
  CenterText,
  MaterialReviewFormContainer,
  MaterialReviewFormItem,
  Notes,
  PlanViewer,
  PlanViewerContent,
  StatusBar,
  IFrame,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/TreatmentPlanTab.css';
import {
  AddMaterialEvaluationsDocument,
  AddMaterialEvaluationsMutation,
  AddMaterialEvaluationsMutationVariables,
  Material,
  MaterialEvaluationTypes,
  TreatmentPlanStagingStates,
} from 'generated/core/graphql';
import {
  TabWrapper as Wrapper,
  VersionPillsContainer,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';
import { Sort, sortByCreated } from 'utils/prism';
import OrthoNote from 'components/OrthoNote';
import MaterialSubmissionHistory from 'components/MaterialSubmissionHistory';
import { getSubmissionsFromMaterials } from 'pages/Case/TreatmentPlan/PlanDetailsContainer';
import PlanStagingDetails from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/PlanStagingDetails';
import { getMaterialEvaluationInput } from 'pages/OrthoPrism/Plan/utils';
import * as Sentry from '@sentry/browser';
import { useGQLMutation } from 'hooks/useGQL';
import { ACCESS_GROUPS } from 'constants/index.js';
import { AuthContext } from 'components/AuthProvider';
import { openVisionSoftware } from 'utils/treatmentPlan';
import ContactSupport from 'components/ContactSupport';

const TreatmentPlanStagingTab = () => {
  const {
    'vision-dfa': visionDFAIsEnabled,
    'bypass-ortho-review-process': bypassOrthoReviewProcess,
    'enable-refinement-policy': enableRefinementPolicy,
  } = useFlags();
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const { checkHasAccess } = useContext(AuthContext);
  const dispatch = useDispatch();
  const hasOrthoPermissions = checkHasAccess(ACCESS_GROUPS.ORTHO);
  const { isFetchingPatient, isFetchingCases } = usePatientLoadingStates();
  const patient = useSelector(selectPatient);
  const selectedCase = useSelector(selectSelectedCase);
  const plans = useSelector(selectSortedTreatmentPlanStagings);
  const vpProps = useVersionPills(plans);
  const isSubmittingTreatmentPlanReview = useIsLoading(
    createTreatmentPlanProOrder.typePrefix
  );
  const selectedPlan = plans[vpProps.currentIndex];
  const isFetchingTPs = useIsLoading(fetchTreatmentPlanStagings.typePrefix);
  const hasCustomerAccess = useHasCustomerAccess(patient?.userAccessLevel);
  const activePlan = selectedPlan ?? plans[0];
  const displayTechnicianNotes =
    bypassOrthoReviewProcess ||
    hasOrthoPermissions ||
    activePlan?.data?.bypassOrthoReview;

  const materials = (plans || []) as Material[];

  // Not using materials directly to be consistent with how we handle materials
  // this should make this component and features easier to reuse with other
  // core material components and workflows.
  const submissions = useMemo(
    () => getSubmissionsFromMaterials(materials),
    [materials]
  );

  const isApproved = TreatmentPlanStagings.isApproved(activePlan);
  const isRejected = TreatmentPlanStagings.isRejected(activePlan);
  const isQcRejected = TreatmentPlanStagings.isQcRejected(activePlan);

  const minDate = '0001-01-01T00:00:00Z';

  // Get the last materialEvaluation of type Pro-clinican, and get the evaluationNotes
  const orthoApprovalHistory = activePlan?.materialEvaluations
    ?.filter(
      (p) =>
        p.evaluationType.name ===
          MaterialEvaluationTypes.OrthodonticEvaluation &&
        p?.data?.evaluationNotes
    )
    ?.sort((a, b) =>
      sortByCreated(Sort.Desc)(
        { created: a?.createdAt ?? minDate },
        { created: b?.createdAt ?? minDate }
      )
    )[0];

  const needsProReview =
    activePlan && activePlan.state === TreatmentPlanStagingStates.ProReview;
  const [addMaterialEvaluations, { loading }] = useGQLMutation<
    AddMaterialEvaluationsMutation,
    AddMaterialEvaluationsMutationVariables
  >(AddMaterialEvaluationsDocument);

  const getRejectionReasons = (
    approved: boolean,
    problems: RejectionDetailProblem[]
  ) => {
    const rejectionReasons: string[] = [];
    if (!approved) {
      problems.forEach((p: RejectionDetailProblem) =>
        rejectionReasons.push(p.topic?.name ?? '')
      );
    }
    return rejectionReasons;
  };

  const onSubmitTreatmentPlanReview = useCallback(
    async (
      args: TreatmentPlanStagings.TreatmentPlanStagingSubmitCallbackArgs
    ) => {
      const materialEvaluationType =
        MaterialEvaluationTypes.ProClinicianEvaluation;
      if (!activePlan || !patient) {
        Sentry.captureException(
          `Missing active plan: ${activePlan} or patient: ${patient}, can't submit TreatmentPlanReview`
        );
        return;
      }
      const approved =
        args.transition.includes('approve') ||
        args.transition.includes('accept');
      const rejectionReasons = getRejectionReasons(approved, args.problems);
      const materialEvaluationsInput = getMaterialEvaluationInput(
        args.problems,
        args.notes,
        materialEvaluationType,
        approved,
        rejectionReasons,
        activePlan.id
      );
      const materialEvaluation: AddMaterialEvaluationsMutationVariables = {
        caseRef: selectedCase?.caseRef!,
        patientId: parseInt(patient.id!),
        annotatedFileLocations: [],
        materialEvaluationsInput: materialEvaluationsInput,
      };

      try {
        await addMaterialEvaluations(materialEvaluation);
        if (approved && needsProReview) {
          dispatch(
            createTreatmentPlanProOrder({
              caseRef: selectedCase?.caseRef!,
              customerId: patient.id!,
              shippingAddress: args?.proOrderArgs?.shippingAddress!,
              shippingAddressType: args?.proOrderArgs?.shippingAddressType!,
              sendPatientUpdate: args?.proOrderArgs?.sendPatientUpdate!,
              clientCouponCode: args?.proOrderArgs?.clientCouponCode,
            })
          );
        }
      } catch (err) {
        Sentry.captureException(err);
      }

      dispatch(fetchTreatmentPlanStagings({ caseRef: selectedCase?.caseRef! }));
      dispatch(fetchCases({ patientIds: [Number(patient.id!)] }));
    },
    [dispatch, activePlan, patient, selectedCase]
  );

  if (isFetchingTPs || loading) {
    return (
      <>
        <Loading isCentered />
        <CenterText>Rendering treatment plan</CenterText>
      </>
    );
  }

  if (!activePlan) {
    return null;
  }

  const technicianNotes = activePlan?.data?.notes;
  const hasNotes = !!technicianNotes;

  const displayOrthoNote =
    needsProReview &&
    hasCustomerAccess &&
    orthoApprovalHistory &&
    !activePlan?.data?.bypassOrthoReview;
  return (
    <Wrapper>
      <VersionPillsContainer>
        <VersionPills {...vpProps} />
      </VersionPillsContainer>
      <PlanStagingDetails treatmentPlan={activePlan} />
      <PlanViewer>
        <PlanViewerContent>
          {activePlan?.url && <IFrame scrolling="no" src={activePlan.url} />}
        </PlanViewerContent>
      </PlanViewer>

      <MaterialReviewFormContainer
        container
        direction="column"
        spacing={0}
        alignItems="flex-start"
      >
        <MaterialReviewFormItem item xs={12}>
          <ReviewFormWrapper>
            {selectedCase?.isActive ? (
              <>
                {isApproved && (
                  <StatusBar isApproved>Treatment plan accepted</StatusBar>
                )}
                {isRejected && (
                  <StatusBar isRejected>
                    Treatment plan revision requested
                  </StatusBar>
                )}
                {isQcRejected && (
                  <StatusBar isRejected>Rejected by Quality Control</StatusBar>
                )}
              </>
            ) : (
              <StatusBar isRejected>
                Case is archived. <ContactSupport text="Contact support" /> to
                reopen the case.
              </StatusBar>
            )}

            {hasNotes && displayTechnicianNotes && (
              <Notes>
                <h3>Technician notes</h3>
                <p>{technicianNotes}</p>
              </Notes>
            )}

            {displayOrthoNote && (
              <OrthoNote evaluation={orthoApprovalHistory} />
            )}

            {selectedCase?.isActive && hasCustomerAccess && needsProReview && (
              <>
                {enableRefinementPolicy ? (
                  <ProReviewForm
                    isSubmitting={
                      isSubmittingTreatmentPlanReview ||
                      isFetchingPatient ||
                      isFetchingCases
                    }
                    onSubmit={onSubmitTreatmentPlanReview}
                    onOpenDfaSoftware={() =>
                      patient &&
                      openVisionSoftware(patient.id, activePlan.iteration)
                    }
                    isDfaEnabled={!isMac && visionDFAIsEnabled}
                  />
                ) : (
                  <LegacyProReviewForm
                    isSubmitting={
                      isSubmittingTreatmentPlanReview ||
                      isFetchingPatient ||
                      isFetchingCases
                    }
                    onSubmit={onSubmitTreatmentPlanReview}
                    onOpenDfaSoftware={() =>
                      patient &&
                      openVisionSoftware(patient.id, activePlan.iteration)
                    }
                    isDfaEnabled={!isMac && visionDFAIsEnabled}
                  />
                )}
              </>
            )}
          </ReviewFormWrapper>

          <MaterialSubmissionHistory
            submissions={submissions as SubmissionFragment[]}
            showEmail={false}
            materialName="Treatment plan"
          />
        </MaterialReviewFormItem>
      </MaterialReviewFormContainer>
    </Wrapper>
  );
};

export default TreatmentPlanStagingTab;
