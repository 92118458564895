import React from 'react';
import { NotificationContext } from '@candidco/enamel';

const withNotifications = (WrappedComponent) => {
  const WithNotifications = (props) => (
    <NotificationContext.Consumer>
      {(contextProps) => <WrappedComponent {...props} {...contextProps} />}
    </NotificationContext.Consumer>
  );
  const { displayName, name = 'Component' } = WrappedComponent;
  WithNotifications.displayName = `WithNotifications(${displayName || name})`;

  return WithNotifications;
};

export default withNotifications;
