import React from 'react';
import { Loading } from '@candidco/enamel';
import { useSelector } from 'react-redux';
import { getSelectedPractice } from 'pages/PracticeManagement/shared/slice';
import ContractRates from 'pages/PracticeManagement/Pricing/ContractRates';

const PracticePricing = () => {
  const selectedPractice = useSelector(getSelectedPractice);

  if (!selectedPractice) {
    return <Loading />;
  }
  return <ContractRates />;
};

export default PracticePricing;
