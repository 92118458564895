import { Button } from '@candidco/enamel';
import styled from 'styled-components/macro';

import ImageIconSVG from 'assets/img-icon.svg?react';

export const AlertContainer = styled.div`
  margin-bottom: 0.75rem;
`;

export const AlertBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const StyledButton = styled(Button)`
  border: 2px solid ${({ theme }) => theme.colors.text90};
  border-radius: 25px;
  padding: 8px 24px;
  margin-top: 0.75rem;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 26px;
`;

export const StyledLink = styled.a`
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.blue50};
  line-height: 160%;
`;

export const ImageIcon = styled(ImageIconSVG)`
  width: 16px;
  height: 16px;
`;
