import React from 'react';
import { Modal, theme, Divider } from '@candidco/enamel';
import {
  ButtonRow,
  CloseButton,
  Header,
  ModalContainer,
  StyledButton,
  StyledImage,
  Title,
} from 'components/Modals/PhotoModal.css';
import CloseSVG from 'assets/close.svg?react';

type PhotoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  fileName: string | null;
  url?: string | null;
};

const PhotoModal = ({ isOpen, onClose, fileName, url }: PhotoModalProps) => {
  if (!fileName || !url) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlayColor="rgba(0, 0, 0, 0.36)"
      closeButtonColor={theme.colors.black50}
      hideCloseButton={true}
    >
      <ModalContainer>
        <Header>
          <Title>{fileName}</Title>
          <CloseButton onClick={onClose}>
            <CloseSVG fill={theme.colors.black50} />
          </CloseButton>
        </Header>
        <Divider />
        <StyledImage src={url} />
        <Divider />
        <ButtonRow>
          <div />
          <a href={url} target="_blank" rel="noopener noreferrer" download>
            <StyledButton isShort buttonType="secondary-outline">
              Download
            </StyledButton>
          </a>
        </ButtonRow>
      </ModalContainer>
    </Modal>
  );
};

export default PhotoModal;
