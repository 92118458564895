import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import GraphQLProvider from 'state/GraphQLProvider';
import {
  BaseStyles,
  NotificationProvider,
  ThemeProvider,
  ZendeskProvider,
} from '@candidco/enamel';
import store from 'state/store';
import AuthProvider, { AuthConsumer } from 'components/AuthProvider';
import GlobalErrorBoundary from 'components/GlobalErrorBoundary';
import Header from 'components/Header';
import Pages from 'pages/index'; /* TODO */

import { GlobalStyles } from 'components/App/App.css';

const zendeskSettings = {
  offset: {
    vertical: '56px',
  },
  position: { vertical: 'top' },
  contactForm: {
    suppress: true,
  },
  talk: {
    nickname: 'Tooth Tools',
    suppress: false,
  },
  launcher: {
    chatLabel: { '*': 'Support' },
    label: { '*': 'Support' },
  },
  chat: {
    suppress: true,
  },
  helpCenter: {
    suppress: true,
  },
};
const App = () => (
  <ThemeProvider>
    <BaseStyles />
    <GlobalStyles />
    <GlobalErrorBoundary>
      <GraphQLProvider>
        <Provider store={store}>
          <Router>
            <NotificationProvider>
              <ZendeskProvider
                hideByDefault
                settings={zendeskSettings}
                widgetKey={
                  import.meta.env.VITE_REACT_APP_ZENDESK_WIDGET_KEY as string
                }
              >
                <AuthProvider>
                  <AuthConsumer>
                    {({ isLoggedIn }) => isLoggedIn && <Header />}
                  </AuthConsumer>

                  <main role="main">
                    <Pages />
                  </main>
                </AuthProvider>
              </ZendeskProvider>
            </NotificationProvider>
          </Router>
        </Provider>
      </GraphQLProvider>
    </GlobalErrorBoundary>
  </ThemeProvider>
);

export default App;
