import React from 'react';
import { Chip, Loading } from '@candidco/enamel';
import FileUpload from 'components/FileUpload';
import {
  RemoveButton,
  UploadedScanContainer,
  UploadedScanContainerLeft,
  UploadForm,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/ScanUploadTile.css';
import CloseSVG from 'assets/close.svg?react';
import { ScanTypes } from 'generated/core/graphql';

type ScanUploadTileProps = {
  filename: string | null;
  scanType: ScanTypes;
  isUploading?: boolean;
  isRemoving?: boolean;
  optional?: boolean;
  uploadCallback: (files: FileList | null) => void;
  removeCallback: () => void;
};

const scanTypeInfo = {
  [ScanTypes.UpperScan]: {
    tag: 'UPPER',
    text: 'Upper scan',
    minWidth: '59px',
  },
  [ScanTypes.LowerScan]: {
    tag: 'LOWER',
    text: 'Lower scan',
    minWidth: '64px',
  },
  [ScanTypes.BiteScan]: {
    tag: 'BITE',
    text: 'Bite scan',
    minWidth: '44px',
  },
};

const ScanUploadTile = ({
  filename,
  scanType,
  isUploading,
  isRemoving,
  uploadCallback,
  removeCallback,
  optional = false,
}: ScanUploadTileProps) => {
  const info = scanTypeInfo[scanType];

  if (!filename) {
    return (
      <UploadForm required={!optional}>
        {isUploading ? (
          <Loading isCentered />
        ) : (
          <FileUpload
            limitFileSize={false}
            allowMultipleFiles={false}
            fileType=".stl"
            isDisabled={isUploading}
            isHorizontal
            textOverride={`${info.text}${optional ? ' (optional)' : ''} `}
            onSelectFile={uploadCallback}
          />
        )}
      </UploadForm>
    );
  } else {
    return (
      <UploadedScanContainer>
        {isRemoving ? (
          <Loading isCentered />
        ) : (
          <>
            <UploadedScanContainerLeft>
              <Chip
                label={info.tag}
                size="small"
                style={{ minWidth: info.minWidth }}
              />
              <div>{filename}</div>
            </UploadedScanContainerLeft>
            <RemoveButton disabled={isRemoving} onClick={removeCallback}>
              <CloseSVG />
            </RemoveButton>
          </>
        )}
      </UploadedScanContainer>
    );
  }
};

export default ScanUploadTile;
