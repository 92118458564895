import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { transparentize } from 'polished';
import { colors, mediaQueries, type } from '@candidco/enamel';

export const FullScreenContainer = styled.div`
  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      display: flex;
      align-items: flex-start;
      padding: 2rem 2rem 0;
      background: ${transparentize(0.5, colors.black90)};
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    `};
`;

export const FullScreenContent = styled.div`
  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      position: relative;
      width: 100%;
      margin: auto 0;
      padding-top: 2.5rem;
      padding-bottom: 2rem;
    `};
`;

export const ActionBar = styled.div`
  position: absolute;
  top: ${({ isFullScreen }) => (isFullScreen ? '0' : '95px')};
  right: 10px;
  z-index: 1;
`;

export const ActionButton = styled.button`
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  font-size: 0;
  vertical-align: top;
  background: ${colors.white};
  border-radius: 50%;
  box-shadow:
    0 0.25rem 0.875rem rgba(0, 0, 0, 0.08),
    0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);

  &:active {
    background: ${colors.blue30};
  }

  @media ${mediaQueries.tabletAndBelow} {
    display: ${({ hideOnMobile }) => hideOnMobile && 'none'};
  }

  svg {
    vertical-align: middle;
    pointer-events: none;
    fill: ${colors.black90};
  }
`;

export const ImageContainerWrapper = styled.div`
  position: relative;
  height: 0;
  padding-top: ${({ isFullScreen }) => (isFullScreen ? '50%' : '75%')};
  margin-bottom: 1.25rem;
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: ${colors.black90};
`;

export const Image = styled.div`
  background: ${({ url }) => url && `url(${url}) no-repeat center`};
  background-size: contain;

  ${({ isZooming, zoomHeight, zoomWidth }) =>
    isZooming
      ? css`
          width: ${zoomWidth}px;
          height: ${zoomHeight}px;
          cursor: zoom-out;
        `
      : css`
          width: 100%;
          height: 100%;
          cursor: zoom-in;
        `};
`;

export const Thumbnails = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 4rem);
  grid-auto-rows: 4rem;
  grid-gap: 2rem;
  width: 100%;

  @media ${mediaQueries.tabletAndAbove} {
    grid-template-columns: repeat(auto-fill, 6rem);
    grid-auto-rows: 6rem;
  }
`;

export const Thumbnail = styled.button`
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: ${colors.black90} no-repeat center;
  background-image: ${({ photo }) => photo && `url(${photo})`};
  background-size: cover;
  box-shadow: ${({ isSelected }) =>
    isSelected
      ? `0 0 0 0.125rem ${colors.white}, 0 0 0 0.25rem ${colors.blue}`
      : `0 0 0 1px ${colors.black20}`};
`;

export const XrayInfo = styled.ul`
  display: flex;
  width: 100%;
  padding: 1rem;
  margin: 2rem 0 0;
  font-size: ${type.size.t1};
  background: ${colors.black05};

  > li {
    flex: 1;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
`;

export const Overline = styled(type.Overline)`
  margin-bottom: 0.25rem;
  display: inline-flex;
`;

export const TipContainer = styled.span`
  margin-left: 0.25rem;
  display: inline-flex;
`;
