import React from 'react';
import styled from 'styled-components/macro';
import { colors } from '@candidco/enamel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

import 'components/HelpTooltip/tooltip-themes.css';

enum TooltipTheme {
  Pink = 'pink',
}

enum HelpTooltipPlacement {
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  top = 'top',
}

type IconContainerProps = {
  color: string;
  width: string;
  height: string;
  label?: string;
};

const IconContainer = styled.span<IconContainerProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  flex: none;
  svg {
    color: ${({ color }) => color};
    height: 100%;
    width: 100%;
    ${({ label }) =>
      !!label &&
      `
      position: relative;
      bottom: .1rem;
      margin-right: .2rem
    `}
  }
`;

type HelpTooltipProps = {
  tooltipTheme?: TooltipTheme;
  iconColor?: string;
  placement?: HelpTooltipPlacement;
  content: React.ReactChild | React.ReactChild[];
  height?: string;
  width?: string;
  label?: string;
};

export const HelpTooltip = ({
  tooltipTheme = TooltipTheme.Pink,
  placement = HelpTooltipPlacement.bottom,
  content,
  height = '1rem',
  width = '1rem',
  iconColor = colors.blue50,
  label,
}: HelpTooltipProps) => (
  <Tippy
    placement={placement}
    hideOnClick={false}
    content={content}
    theme={tooltipTheme}
  >
    <IconContainer
      color={iconColor}
      width={width}
      height={height}
      label={label}
    >
      <HelpOutlineIcon />
      {!!label && label}
    </IconContainer>
  </Tippy>
);
