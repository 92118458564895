import React, { useContext, useEffect } from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, NotificationContext } from '@candidco/enamel';

import { logIn } from 'api/login';
import { checkAuthGroups } from 'utils/auth';
import * as propTypes from 'utils/propTypes';
import { AuthContext } from 'components/AuthProvider';
import DocumentTitle from 'components/DocumentTitle';
import { FormikInputWrapper } from 'components/FormikForms';
import {
  emailValidator,
  requiredValidator,
} from 'components/FormikForms/utils';
import { useQuery } from 'hooks/useQuery';
import LoginRightPanel from 'pages/Login/LoginRightPanel';
import {
  SplitContainer,
  LoginContainer,
  LoginForm,
  Logo,
  Subheader,
  InputFields,
  StyledLink,
} from 'pages/Login/Login.css';
import { getBrandDomainSettings } from 'utils/brands';

type FormProps = {
  email: string;
  password: string;
};

type LocationState = {
  redirectPath?: string;
  componentToDisplay?: React.ReactNode;
};

type LoginPageProps = RouteComponentProps<object, StaticContext, LocationState>;

const Login = ({ history, location }: LoginPageProps) => {
  const { handleLogIn, isLoggedIn } = useContext(AuthContext);
  const { showNotification } = useContext(NotificationContext);
  const query = useQuery();

  const {
    brandId,
    loginBackgroundImgSrc,
    label,
    logoSrc,
    subheader,
    showLaunchNotes,
  } = getBrandDomainSettings();

  // call Appcues with dummy identify call to be able to trigger banners on the login page
  window.Appcues.identify('login_dummy_id', {});

  useEffect(() => {
    if (isLoggedIn) {
      history.replace('/');
    }
  }, []);

  /**
   * Hook that handles the redirection from customer portal to auto-login with the passed credentials
   * after resetting password confirm
   */
  useEffect(() => {
    const accessToken = query.get('access_token');
    const refreshToken = query.get('refresh_token');
    const email = query.get('email');
    const groups = query.get('groups')?.split(',') ?? [];

    if (!!accessToken && !!refreshToken && !!email) {
      handleLogIn(accessToken, refreshToken, groups, email);
      history.replace('/');
    }
  }, [query]);

  const handleSubmit = async ({ email, password }: FormProps) => {
    const { state = {} } = location;
    const { redirectPath = '/' } = state;
    const lowerCaseEmail = email.toLowerCase();
    try {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        groups = [],
      } = await logIn(lowerCaseEmail, password, brandId);
      const hasAccess = checkAuthGroups(groups);

      if (!hasAccess) {
        throw new Error('You are not allowed to sign in');
      }

      handleLogIn(accessToken, refreshToken, groups, email);
      history.replace(redirectPath);
    } catch (err: unknown) {
      if (err instanceof Error) {
        showNotification(err.message, 'error');
      } else {
        showNotification('Failed to log in', 'error');
      }
    }
  };

  const resetLink = '/account/reset';

  const initialValues: FormProps = {
    email: '',
    password: '',
  };
  return (
    <DocumentTitle title="Login">
      <SplitContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <LoginContainer>
              <LoginForm>
                {logoSrc && (
                  <Logo alt={label as string} src={logoSrc as string} />
                )}
                {subheader && <Subheader>{subheader}</Subheader>}
                <InputFields>
                  <FormikInputWrapper
                    testId="email-input"
                    autoComplete="username"
                    name="email"
                    type="email"
                    placeholder="Email"
                    validate={emailValidator}
                  />
                  <FormikInputWrapper
                    testId="password-input"
                    autoComplete="current-password"
                    placeholder="Password"
                    name="password"
                    type="password"
                    validate={requiredValidator}
                  />
                </InputFields>
                <Button
                  testId="login-btn"
                  buttonType="secondary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Log in
                </Button>
                <StyledLink to={resetLink}>Forgot password</StyledLink>
              </LoginForm>
            </LoginContainer>
          )}
        </Formik>
        <LoginRightPanel
          backgroundImgSrc={loginBackgroundImgSrc as string}
          showLaunchNotes={showLaunchNotes as boolean}
        />
      </SplitContainer>
    </DocumentTitle>
  );
};

Login.propTypes = {
  history: propTypes.routerHistory.isRequired,
  location: propTypes.routerLocation.isRequired,
};

export default Login;
