import {
  GetLoyaltyProgramsDocument,
  GetLoyaltyProgramsQuery,
  GetLoyaltyProgramsQueryVariables,
  UpdateCurrentPracticeLoyaltyStatusDocument,
  UpdateCurrentPracticeLoyaltyStatusMutation,
  UpdateCurrentPracticeLoyaltyStatusMutationVariables,
} from 'generated/core/graphql';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import ReactSelect from 'react-select';
import { Button, DatePicker, TextInput } from '@candidco/enamel';
import {
  getSelectedPractice,
  getSelectedPracticeLoyaltyStatus,
  setSelectedPracticeLoyaltyStatus,
} from 'pages/PracticeManagement/shared/slice';
import { some } from 'lodash';

type LoyaltySelectOption = {
  label: string;
  value: any;
};

const Flex = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction ?? 'column'};
`;

const Container = styled(Flex)`
  width: 50vw;
  max-width: 450px;
  margin: 0 auto;
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled(Button)`
  width: 8em;
`;

const LoyaltySelect = styled(ReactSelect)<{
  hasError?: boolean;
  width?: number;
}>`
  min-width: 200px;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => (width ? `${width}px` : '200px')};
`;

const convertToOptions = (loyaltyProgram: any): LoyaltySelectOption => ({
  label: loyaltyProgram.name,
  value: loyaltyProgram,
});

const UpdateLoyaltyStatusForm = () => {
  //  todo iterate through the loyalty program tiers and create a drop down to select
  const dispatch = useDispatch();
  const selectedPractice = useSelector(getSelectedPractice);
  const selectedPLS = useSelector(getSelectedPracticeLoyaltyStatus);
  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] =
    useState<any>(null);
  const [selectedLoyaltyProgramTier, setSelectedLoyaltyProgramTier] =
    useState<any>(null);
  const [selectedProgramStart, setProgramStart] = useState<any>(null);
  const [selectedProgramEnd, setProgramEnd] = useState<any>(null);
  const [note, setNote] = useState<any>(null);

  useEffect(() => {
    if (selectedPLS) {
      setProgramStart(selectedPLS.programStart);
      setProgramEnd(selectedPLS.programEnd);
    }
  }, [selectedPLS]);

  const [getLoyaltyProgramData, { data: getLoyaltyProgramResult }] =
    useGQLQuery<GetLoyaltyProgramsQuery, GetLoyaltyProgramsQueryVariables>(
      GetLoyaltyProgramsDocument
    );
  const [
    submitPracticeLoyaltyStatusUpdate,
    { loading: isUpdating, data: updateResult },
  ] = useGQLMutation<
    UpdateCurrentPracticeLoyaltyStatusMutation,
    UpdateCurrentPracticeLoyaltyStatusMutationVariables
  >(UpdateCurrentPracticeLoyaltyStatusDocument);
  const loyaltyProgramsResult =
    getLoyaltyProgramResult?.getLoyaltyPrograms ?? [];
  const selectedLoyaltyProgramTiers =
    selectedLoyaltyProgram?.value.loyaltyProgramTiers ?? [];
  const loyaltyProgramOptions = useMemo(
    () => loyaltyProgramsResult.map(convertToOptions),
    [loyaltyProgramsResult]
  );
  const loyaltyProgramTierOptions = useMemo(
    () => selectedLoyaltyProgramTiers.map(convertToOptions),
    [selectedLoyaltyProgramTiers]
  );

  useEffect(() => {
    getLoyaltyProgramData({});
  }, []);

  useEffect(() => {
    if (
      updateResult?.updateCurrentPracticeLoyaltyStatus?.practiceLoyaltyStatus
        ?.id
    ) {
      dispatch(
        setSelectedPracticeLoyaltyStatus({
          getCurrentPracticeLoyaltyProgram:
            updateResult?.updateCurrentPracticeLoyaltyStatus
              ?.practiceLoyaltyStatus,
        })
      );
    }
  }, [updateResult]);

  const isProgramStartTouched =
    (selectedPLS?.programStart ?? '') !== selectedProgramStart;
  const isProgramEndTouched =
    (selectedPLS?.programEnd ?? '') !== selectedProgramEnd;

  const handleSubmit = () => {
    const practiceId =
      parseInt(selectedPractice?.id as string) ?? selectedPractice?.id;
    const variables: UpdateCurrentPracticeLoyaltyStatusMutationVariables = {
      practiceId,
    };

    if (selectedLoyaltyProgramTier?.value?.id) {
      variables['newTierId'] = selectedLoyaltyProgramTier?.value?.id;
    }
    if (isProgramStartTouched) {
      variables['newProgramStart'] = selectedProgramStart;
    }
    if (isProgramEndTouched) {
      variables['newProgramEnd'] = selectedProgramEnd;
    }

    if (note) {
      variables['note'] = note;
    }

    submitPracticeLoyaltyStatusUpdate(variables);
    setSelectedLoyaltyProgram(null);
    setSelectedLoyaltyProgramTier(null);
    setProgramStart(null);
    setProgramEnd(null);
    setNote(null);
  };

  const isSubmitDisabled = !some([
    selectedLoyaltyProgram && selectedLoyaltyProgramTier,
    isProgramStartTouched,
    isProgramEndTouched,
  ]);
  return (
    <Container>
      <Flex>
        {selectedPLS && (
          <>
            <small>Current Loyalty Program:</small>
            <div>{selectedPLS.loyaltyProgram.name}</div>
            <small>Current Tier:</small>
            <div>{selectedPLS?.loyaltyProgram.currentTier.name}</div>
          </>
        )}
      </Flex>
      <h3>Loyalty Adjustment Form</h3>
      <Flex direction="row">
        <Flex direction="column">
          <small>Program Selection</small>
          <LoyaltySelect
            options={loyaltyProgramOptions}
            onChange={(selection: LoyaltySelectOption) => {
              setSelectedLoyaltyProgram(selection);
              setSelectedLoyaltyProgramTier(null);
            }}
            value={selectedLoyaltyProgram}
          />
        </Flex>
        <Flex direction="column">
          <small>Tier Selection</small>
          <LoyaltySelect
            options={loyaltyProgramTierOptions}
            onChange={(selection: LoyaltySelectOption) =>
              setSelectedLoyaltyProgramTier(selection)
            }
            value={selectedLoyaltyProgramTier}
          />
        </Flex>
      </Flex>
      <Flex direction="column">
        <small>Program Start</small>
        <DatePicker
          value={selectedProgramStart}
          onChange={(newValue) =>
            setProgramStart(newValue?.toISOString() ?? '')
          }
        />
        <small>Program End</small>
        <DatePicker
          value={selectedProgramEnd}
          onChange={(newValue) => setProgramEnd(newValue?.toISOString() ?? '')}
        />
      </Flex>
      <TextInput
        value={note ?? ''}
        label="Save a reason for this change."
        onChange={(e) => {
          setNote(e.currentTarget.value);
        }}
      />
      <StyledButton
        onClick={handleSubmit}
        buttonType="primary"
        disabled={isSubmitDisabled}
        isLoading={isUpdating}
      >
        Submit
      </StyledButton>
    </Container>
  );
};

export default UpdateLoyaltyStatusForm;
