import React, { useEffect, createRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CopyableText from 'components/CopyableText';
import { Popper } from '@material-ui/core';
import { Maybe } from 'generated/legacy/graphql';
import { HRDividerLine } from 'styles/layout.css';
import {
  Wrapper,
  Content,
  Name,
  CustomerId,
  Value,
  Overline,
  EditButton,
  StyledPopper,
  StyledPointer,
  PopperHeader,
  PopperBody,
  PopperFooter,
  UpdateButton,
  DismissLink,
  HighLightedButton,
  UpChevron,
  DownChevron,
  Header,
  NameRow,
  SmallEditButton,
} from 'components/PatientHeader/PatientHeaderLite.css';
import PencilIcon from 'assets/pencil-2.svg?react';
import { Grid, Divider } from '@candidco/enamel';
import {
  patientAddressSimilarToPractice,
  Address,
} from 'utils/shippingAddress';
import { formatBirthday } from 'utils/customer';
import { EditPatientInfo } from 'pages/OrthoPrism/types';
import {
  selectCustomer,
  selectCustomerBrand,
} from 'pages/OrthoPrism/orthoSlice';
import BrandChip from 'components/BrandChip/BrandChip';

type PracticeAddress = {
  name?: string | null | undefined;
};

type Practice = {
  name: string;
  address: PracticeAddress;
};

type Props = {
  id: string;
  fullName: string;
  preferredName?: Maybe<string>;
  extraPatientInfo: EditPatientInfo;
  practice?: Practice | null;
  handleOpenEditPatientModal: () => void;
  handleOpenEditClinicianModal: () => void;
};

const PatientHeaderLite = ({
  id,
  fullName,
  preferredName,
  extraPatientInfo,
  practice,
  handleOpenEditPatientModal,
  handleOpenEditClinicianModal,
}: Props) => {
  const {
    birthday,
    phone,
    email,
    addressLine1,
    addressLine2,
    businessName,
    city,
    stateCode,
    countryCode,
    zip,
    treatingProvider,
    candidClinician,
  } = extraPatientInfo;
  const customerInfo = useSelector(selectCustomer);
  const brandInfo = useSelector(selectCustomerBrand);
  const legalGuardian = customerInfo?.legalGuardian;

  const formattedPhone = phone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  const [showPatientInfo, setShowPatientInfo] = useState(true);

  type ExpandableButtonProps = {
    text: string;
    isExpanded: boolean;
    onClick: (isExpanded: boolean) => void;
  };
  const ExandableButton = ({
    text,
    isExpanded,
    onClick,
  }: ExpandableButtonProps) => {
    return (
      <HighLightedButton onClick={() => onClick(!isExpanded)}>
        <div>{`${isExpanded ? 'Hide' : 'Show'} ${text} `}</div>
        {isExpanded ? <UpChevron /> : <DownChevron />}
      </HighLightedButton>
    );
  };

  const GuardianInfo = () => {
    const { preferredName, firstName, lastName, middleName, phone, birthday } =
      customerInfo?.legalGuardian || {};
    return (
      <>
        <HRDividerLine />
        <Header>Guardian information</Header>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} md={4}>
            <Overline data-testid="overline-heading">GUARDIAN NAME</Overline>
            <Value>{`${firstName} ${middleName} ${lastName}`}</Value>
          </Grid>
          <Grid item xs={6} md={4}>
            <Overline data-testid="overline-heading">PREFERRED NAME</Overline>
            <Value>{`${preferredName}`}</Value>
          </Grid>
          <Grid item xs={6} md={4}>
            <Overline data-testid="overline-heading">PHONE NUMBER</Overline>
            <Value>{phone}</Value>
          </Grid>
          <Grid item xs={6} md={4}>
            <Overline data-testid="overline-heading">DATE OF BIRTH</Overline>
            <Value>{birthday}</Value>
          </Grid>
        </Grid>
      </>
    );
  };

  const PatientInfo = () => {
    return (
      <>
        <HRDividerLine />

        <Header>Patient information</Header>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} md={4}>
            <Overline data-testid="overline-heading">PREFERRED NAME</Overline>
            <Value>{preferredName}</Value>
          </Grid>
          <Grid item xs={6} md={4}>
            <Overline data-testid="overline-heading">DATE OF BIRTH</Overline>
            <Value>{formatBirthday(birthday)}</Value>
          </Grid>
          <Grid item xs={6} md={4}>
            <Overline
              data-testid="overline-heading"
              ref={anchor}
              style={{ width: '215px' }}
            >
              PATIENT HOME ADDRESS
            </Overline>

            <Popper open={!!anchorEl} anchorEl={anchorEl} placement={'right'}>
              <StyledPopper elevation={1}>
                <StyledPointer />
                <PopperHeader>Add a patient home address</PopperHeader>
                <PopperBody>
                  Due to a recent update, we detected that you had your practice
                  address listed as the patient home address.
                </PopperBody>

                <PopperFooter>
                  <UpdateButton
                    onClick={() => {
                      handleOpenEditPatientModal();
                      handlePopperDismiss();
                    }}
                    buttonType="black-outline"
                  >
                    Update
                  </UpdateButton>

                  <DismissLink onClick={handlePopperDismiss}>
                    Dismiss
                  </DismissLink>
                </PopperFooter>
              </StyledPopper>
            </Popper>

            {businessName && (
              <Value style={{ marginBottom: 0 }}>{businessName}</Value>
            )}
            <Value style={{ marginBottom: 0 }}>{addressLines}</Value>
            <Value>{`${city}, ${stateCode} ${zip}`}</Value>
          </Grid>

          <Grid item xs={6} md={4}>
            <Overline data-testid="overline-heading">PATIENT EMAIL</Overline>
            <Value>{email}</Value>
          </Grid>
          <Grid item xs={6} md={4}>
            <Overline data-testid="overline-heading">PHONE</Overline>
            <Value className="nowrap">{formattedPhone}</Value>
          </Grid>
        </Grid>
        {legalGuardian && <GuardianInfo />}
      </>
    );
  };

  const addressLines = addressLine1 + (addressLine2 ? `, ${addressLine2}` : '');
  let addressMatch = patientAddressSimilarToPractice(
    {
      ...practice?.address,
      businessName: practice?.address?.name || '',
    } as Address,
    {
      addressLine1,
      addressLine2,
      businessName,
      city,
      stateCode,
      countryCode,
      zip,
    }
  );

  const anchor = createRef<HTMLInputElement>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    addressMatch = patientAddressSimilarToPractice(
      {
        ...practice?.address,
        businessName: practice?.address?.name,
      } as Address,
      {
        addressLine1,
        addressLine2,
        businessName,
        city,
        stateCode,
        countryCode,
        zip,
      }
    );
  }, [addressLine1, addressLine2, businessName, city, stateCode, zip]);

  useEffect(() => {
    if (addressMatch) {
      setAnchorEl(anchor.current);
    } else {
      setAnchorEl(null);
    }
  }, [addressMatch]);

  const handlePopperDismiss = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <Content>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={11}>
              <Name>
                <NameRow>
                  {fullName}
                  <BrandChip brandInfo={brandInfo} />
                </NameRow>
                <CustomerId data-testid="ortho-prism-header-info">
                  {id}{' '}
                  <CopyableText
                    data-testid="ortho-prism-header-info"
                    label="Customer Id"
                    text={id}
                  />
                </CustomerId>
              </Name>
            </Grid>
            <Grid item xs={1}>
              <EditButton
                onClick={() => {
                  handleOpenEditPatientModal();
                }}
                isShort
                buttonType="secondary"
                leftIcon={<PencilIcon />}
              >
                Edit
              </EditButton>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6} md={4}>
              <Overline data-testid="overline-heading">
                TREATING PROVIDER
              </Overline>
              <Value>
                {treatingProvider?.fullName + ' - ' + treatingProvider?.email}
              </Value>
            </Grid>

            <Grid item xs={6} md={4}>
              <Overline data-testid="overline-heading">
                PROVIDER PRACTICE
              </Overline>
              <Value>{practice?.name}</Value>
            </Grid>
            <Grid item xs={6} md={4}>
              <Overline data-testid="overline-heading">
                CANDID CLINICIAN
              </Overline>
              <Value>
                {candidClinician?.fullName ?? ''}
                <SmallEditButton
                  onClick={() => {
                    handleOpenEditClinicianModal();
                  }}
                  cursor="pointer"
                />
              </Value>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {showPatientInfo && <PatientInfo />}
            <Grid item xs={12}>
              <ExandableButton
                isExpanded={showPatientInfo}
                text={'patient info'}
                onClick={setShowPatientInfo}
              />
            </Grid>
          </Grid>
        </Grid>
      </Content>
      <Divider />
    </Wrapper>
  );
};

export default PatientHeaderLite;
