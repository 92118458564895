import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { type } from '@candidco/enamel';

import { Dropdown } from 'components/Dropdown/Dropdown';
import { useDispatch } from 'react-redux';
import {
  selectScansFromLatestSubmission,
  selectCustomer,
  selectSelectedCase,
  fetchTreatmentPlanStagings,
  selectLatestTreatmentPlanStaging,
  fetchCases,
} from 'pages/OrthoPrism/orthoSlice';
import {
  AddTreatmentPlanStagingDocument,
  AddTreatmentPlanStagingMutation,
  AddTreatmentPlanStagingMutationVariables,
  TreatmentPlanningSoftware,
  TreatmentPlanStagingTypes,
  TreatmentPlanStagingStates,
  MaterialEvaluationTypes,
  AddMaterialEvaluationsDocument,
  AddMaterialEvaluationsMutation,
  AddMaterialEvaluationsMutationVariables,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import { NotificationContext } from '@candidco/enamel';

import { useSelector } from 'react-redux';
const Body = styled.div<{
  rightMargin?: boolean;
}>`
  text-align: right;
  margin-right: ${({ rightMargin }) => rightMargin && '1.5rem'};
`;

const ActionLink = styled(type.Link)<{
  disabled?: boolean;
}>`
  font-weight: 300;
  color: ${({ disabled }) => (disabled ? 'gray' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    cursor: pointer;
  }
`;

const StagingActions = () => {
  // Only show staging actions in dev environment
  if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
    return null;
  }
  const dispatch = useDispatch();
  const { showNotification } = useContext(NotificationContext);
  const selectedCase = useSelector(selectSelectedCase);
  const customer = useSelector(selectCustomer);
  const scans = useSelector(selectScansFromLatestSubmission);
  const treatmentPlanStaging = useSelector(selectLatestTreatmentPlanStaging);
  const canAddTreatmentPlan =
    !treatmentPlanStaging ||
    (treatmentPlanStaging?.state ?? '') in
      [
        TreatmentPlanStagingStates.OrthoRejected,
        TreatmentPlanStagingStates.QcRejected,
      ];
  const canAcceptAsQc =
    treatmentPlanStaging?.state === TreatmentPlanStagingStates.QcReview;
  const canAcceptAsOrtho =
    treatmentPlanStaging?.state === TreatmentPlanStagingStates.OrthoReview;

  const [addTreatmentPlanStaging, { loading: addingTP }] = useGQLMutation<
    AddTreatmentPlanStagingMutation,
    AddTreatmentPlanStagingMutationVariables
  >(AddTreatmentPlanStagingDocument, true);

  const [addMaterialEvaluations, { loading: approvingTP }] = useGQLMutation<
    AddMaterialEvaluationsMutation,
    AddMaterialEvaluationsMutationVariables
  >(AddMaterialEvaluationsDocument, true);

  const createTreatmentPlanStaging = async (
    caseRef: string,
    patientId: number,
    lowerScanId: string,
    upperScanId: string
  ) => {
    if (!customer?.id) {
      return;
    }
    const draftPlanMetadataAwsLocation =
      'materials/sample_patient/planmetadata.json';
    const draftReportAwsLocation = 'materials/sample_patient/report.json';
    const draftViewAwsLocation = 'materials/sample_patient/viewer.zip';
    const draftPlanAwsLocation = 'materials/sample_patient/plan.zip';
    const mutationVariables: AddTreatmentPlanStagingMutationVariables = {
      patientId,
      caseRef,
      creationResources: {
        vision: {
          draftPlanMetadataAwsLocation,
          draftReportAwsLocation,
          draftViewAwsLocation,
          draftPlanAwsLocation,
        },
      },
      scanIds: {
        lowerScanId,
        upperScanId,
      },
      notes: '',
      treatmentPlanStagingType: TreatmentPlanStagingTypes.AlignerStaging,
      software: TreatmentPlanningSoftware.Vision,
    };
    await addTreatmentPlanStaging(mutationVariables);
    dispatch(
      fetchCases({
        patientIds: [parseInt(customer?.id)],
      })
    );
  };

  const handleCreateTreatmentPlan = async () => {
    if (!selectedCase || !customer || !scans || scans.length < 2) {
      showNotification('Please confirm that 2 scans are uploaded', 'error');
      return;
    }
    try {
      const selectedScans = scans.filter((scan) =>
        scan.materialType.name.includes('scan')
      );
      await createTreatmentPlanStaging(
        selectedCase.caseRef,
        parseInt(customer.id),
        selectedScans[0].id,
        selectedScans[1].id
      );
      dispatch(fetchTreatmentPlanStagings({ caseRef: selectedCase.caseRef }));
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }
      showNotification(err.message, 'error');
    }
  };

  const approveTpAs = async (personel: string) => {
    if (!selectedCase || !customer || !treatmentPlanStaging) {
      showNotification('Please check if all materials is loaded', 'error');
      return;
    }
    const materialEvaluationType =
      personel === 'qc'
        ? MaterialEvaluationTypes.QualityControlEvaluation
        : MaterialEvaluationTypes.OrthodonticEvaluation;
    const materialEvaluationsInput = {
      approved: true,
      data: {},
      materialEvaluationType,
      materialId: treatmentPlanStaging?.id,
      rejectionReasons: [],
      repairable: false,
    };

    const materialEvaluation: AddMaterialEvaluationsMutationVariables = {
      caseRef: selectedCase?.caseRef,
      patientId: parseInt(customer?.id),
      annotatedFileLocations: [],
      materialEvaluationsInput: [materialEvaluationsInput],
    };
    try {
      await addMaterialEvaluations(materialEvaluation);
      dispatch(fetchTreatmentPlanStagings({ caseRef: selectedCase.caseRef }));
      dispatch(
        fetchCases({
          patientIds: [parseInt(customer?.id)],
        })
      );
      showNotification(`Treatment plan approved as ${personel}`, 'success');
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }
      showNotification(err.message, 'error');
    }
  };

  const loading = addingTP || approvingTP;

  return (
    <div>
      <Body rightMargin>
        <Dropdown
          showCaret
          title={
            <ActionLink data-testid="action-link">
              {loading ? `Loading` : `Staging actions`}
            </ActionLink>
          }
        >
          <ActionLink
            data-testid="action-link"
            onClick={() => canAddTreatmentPlan && handleCreateTreatmentPlan()}
            disabled={!canAddTreatmentPlan}
          >
            Add treatment plan
          </ActionLink>

          <ActionLink
            data-testid="action-link"
            onClick={() => canAcceptAsQc && approveTpAs('qc')}
            disabled={!canAcceptAsQc}
          >
            Approve as qc
          </ActionLink>

          <ActionLink
            data-testid="action-link"
            onClick={() => canAcceptAsOrtho && approveTpAs('ortho')}
            disabled={!canAcceptAsOrtho}
          >
            Approve as ortho
          </ActionLink>
        </Dropdown>
      </Body>
    </div>
  );
};

export default StagingActions;
