import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Accordion, AccordionSummary, colors } from '@candidco/enamel';
import { AccordionDetails } from '@material-ui/core';

type CollapsibleHistoryProps = {
  header: React.ReactNode | React.ReactNode[];
  isExpanded?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CollapsibleHistory = ({
  header,
  isExpanded,
  children,
}: CollapsibleHistoryProps) => {
  const [expanded, setExpanded] = useState(isExpanded);

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary style={{ padding: '0', background: colors.white }}>
        {header}
      </AccordionSummary>
      <AccordionDetails>
        <VerticalContainer>{children}</VerticalContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default CollapsibleHistory;
