import React, { Fragment, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {
  Button,
  Loading,
  NotificationContext,
  AlertCard,
} from '@candidco/enamel';

import { cleanFilenameForAws, formatFilesNames } from 'utils/materials';

import { getScanTypeLabel, ScanType, getCoreScanType } from 'utils/scans';
import DocumentTitle from 'components/DocumentTitle';
import FileUpload from 'components/FileUpload';
import PatientHeader from 'components/PatientHeader';

import {
  Container,
  DeleteButton,
  FileName,
  FileTypesText,
  Heading,
  ScanTable,
  TableHeader,
  TableRow,
  TableScrollContainer,
  UploadContainer,
} from 'pages/STLUpload/STLUploadOverview.css';

import {
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataQueryVariables,
  GetMaterialUploadDataDocument,
  AddScanDocument,
  AddScanMutation,
  AddScanMutationVariables,
  AddSubmissionDocument,
  AddSubmissionMutation,
  AddSubmissionMutationVariables,
  RemoveMaterialDocument,
  RemoveMaterialMutation,
  RemoveMaterialMutationVariables,
  ScanTypes,
} from 'generated/core/graphql';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';
import { StringMap } from 'utils/types';
import {
  addScans,
  fetchScans,
  fetchCustomer,
  ScanFragment,
  selectUnsubmittedScans,
  selectCustomer,
  removeScan,
  selectScansFromLatestSubmission,
} from 'pages/OrthoPrism/orthoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'state/store';
import { useIsLoading } from 'state/system';
import { CandidProCustomerQuery } from 'generated/legacy/graphql';
import JourneyProvider from 'components/JourneyProvider';

type MatchParams = {
  caseRef: string;
  id: string;
};

const fileNameRequirements = Object.values(ScanType);

const formatCustomerInfo = (
  customerData: CandidProCustomerQuery['customer']
) => {
  if (!customerData) {
    return null;
  }
  const { firstName, lastName, dateOfBirth, id } = customerData;
  const firstInitial = firstName.substring(0, 1);
  const name = `${firstInitial}. ${lastName}`;

  return {
    ...customerData,
    id: id.toString(),
    dob: dateOfBirth,
    name,
  };
};

const STLUploadOverview = ({ match }: RouteComponentProps<MatchParams>) => {
  const [isUploadingSTLs, setIsUploadingSTLs] = useState(false);
  const [isRemovingSTLs, setIsRemovingSTLs] = useState(false);
  const { showNotification } = useContext(NotificationContext);
  const unsubmittedScans = useSelector(selectUnsubmittedScans);
  const submittedScans = useSelector(selectScansFromLatestSubmission);
  const customerData = useSelector(selectCustomer);
  const isScansLoading = useIsLoading(fetchScans.typePrefix);
  const isCustomerDataLoading = useIsLoading(fetchCustomer.type);
  const customerInfo = customerData && formatCustomerInfo(customerData!);

  const dispatch = useDispatch<AppDispatch>();

  const [addScan] = useGQLMutation<AddScanMutation, AddScanMutationVariables>(
    AddScanDocument,
    true
  );
  const [addSubmission] = useGQLMutation<
    AddSubmissionMutation,
    AddSubmissionMutationVariables
  >(AddSubmissionDocument, true);
  const [removeMaterial] = useGQLMutation<
    RemoveMaterialMutation,
    RemoveMaterialMutationVariables
  >(RemoveMaterialDocument, true);
  const [getScanUploadUrl] = useGQLQuery<
    GetMaterialUploadDataQuery,
    GetMaterialUploadDataQueryVariables
  >(GetMaterialUploadDataDocument, true);

  const { caseRef, id } = match.params;

  const hasUnsubmittedScans = !!unsubmittedScans && !!unsubmittedScans.length;
  const hasSubmittedScans = !!submittedScans && !!submittedScans.length;
  const patientId = parseInt(id);
  const isLoading = isScansLoading || isCustomerDataLoading;

  useEffect(() => {
    if (id && caseRef) {
      dispatch(fetchCustomer({ customerId: id }));
      dispatch(
        fetchScans({
          caseRef: caseRef,
        })
      );
    }
  }, [id, caseRef]);

  const submitScans = async () => {
    if (unsubmittedScans.length < 2) {
      showNotification(
        'A valid scan upload must contain at least two files',
        'error'
      );
      return;
    }

    const scanTypes = unsubmittedScans.map((s) => s.materialType);

    const lowerScanExists = scanTypes.some(
      (s) => s.name === ScanTypes.LowerScan
    );
    const upperScanExists = scanTypes.some(
      (s) => s.name === ScanTypes.UpperScan
    );

    if (!upperScanExists || !lowerScanExists) {
      showNotification(
        'Both an upper and lower scan must be submitted',
        'error'
      );
      return;
    }

    const newMaterialIds = unsubmittedScans.map((s) => s.id);
    await addSubmission({
      caseRef: caseRef!,
      patientId,
      materialIds: newMaterialIds,
    });

    dispatch(
      fetchScans({
        caseRef,
      })
    );
  };

  const handleSelectFiles = async (files: FileList | null) => {
    if (!files) {
      return;
    }
    let selected = Array.from(files);

    if (
      selected.some(
        (file) =>
          !fileNameRequirements.some((string) =>
            (file.name.substring(0, file.name.lastIndexOf('.')) || file.name)
              .toLowerCase()
              .includes(string)
          )
      )
    ) {
      showNotification(
        `All file names must include one of ${fileNameRequirements.join(', ')}`,
        'error'
      );

      return;
    }

    try {
      setIsUploadingSTLs(true);
      selected = formatFilesNames(selected);
      const captureDate = moment().format('YYYY-MM-DD');

      const results = await Promise.all(
        selected.map(async (file) => {
          try {
            // upload file to s3
            const uploadData = await getScanUploadUrl({
              patientId: Number(patientId),
              fileName: cleanFilenameForAws(file.name),
            });
            const fields: StringMap = uploadData?.getMaterialUploadData.fields;
            const url = uploadData?.getMaterialUploadData.url!;
            const awsFileLocation =
              uploadData?.getMaterialUploadData.awsLocation ?? '';

            const data = new FormData();
            Object.entries(fields).forEach(([key, value]) =>
              data.append(key, value)
            );
            data.append('file', file);

            await axios({
              method: 'POST',
              url,
              data,
              headers: {
                'Content-Type': file.type,
              },
            });

            // create core material
            const addScanResult = await addScan({
              awsFileLocation,
              data: {
                captureDate,
              },
              patientId: Number(patientId),
              caseRef,
              filename: file.name,
              scanType: getCoreScanType(awsFileLocation)!,
            });
            return {
              result: addScanResult?.addScan?.scan!,
            };
          } catch (err) {
            return {
              error: err,
            };
          }
        })
      );
      const selectedScans = results
        .filter((r) => !!r.result)
        .map((r) => r.result);
      dispatch(addScans({ materials: selectedScans as ScanFragment[] }));

      const exceptions = results.filter((r) => !!r.error);

      if (exceptions.length) {
        showNotification(
          'There was an issue uploading one or more files. Please try uploading those files again',
          'error'
        );
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    } finally {
      setIsUploadingSTLs(false);
    }
  };

  const handleDeleteFile = async (materialId: string) => {
    try {
      setIsRemovingSTLs(true);
      await removeMaterial({ materialId });
      dispatch(removeScan({ materialId }));
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    } finally {
      setIsRemovingSTLs(false);
    }
  };

  if (isLoading || !customerData) {
    return (
      <DocumentTitle data-private title="Loading…">
        <Loading isCentered />
      </DocumentTitle>
    );
  }

  return (
    <JourneyProvider caseRef={caseRef}>
      <DocumentTitle
        data-private
        title={`${customerInfo?.name} - STL uploader`}
      >
        <Fragment>
          <PatientHeader showTopLinks={true} customerInfo={customerInfo!} />
          <Container hasSTLs={hasUnsubmittedScans}>
            <Heading>STL uploader</Heading>
            {hasUnsubmittedScans && (
              <AlertCard
                header="Submit scans when ready!"
                displayIcon={true}
                body="The case cannot continue unless scans are submitted. Click the 'Submit' when you've uploaded all your scans"
                type="warning"
              />
            )}
            <Fragment>
              <UploadContainer isSmall={hasUnsubmittedScans}>
                <FileUpload
                  allowMultipleFiles
                  fileType=".stl"
                  isHorizontal={hasUnsubmittedScans}
                  isDisabled={isUploadingSTLs}
                  onSelectFile={handleSelectFiles}
                  limitFileSize={false}
                />
              </UploadContainer>
              {!hasUnsubmittedScans && (
                <FileTypesText>
                  <b>Accepted file types:</b> .stl
                </FileTypesText>
              )}
              {hasUnsubmittedScans && (
                <TableScrollContainer isUpload>
                  <ScanTable>
                    <TableHeader>
                      <div>File name</div>
                      <div>Scan type</div>
                    </TableHeader>
                    {unsubmittedScans.map((file) => (
                      <TableRow key={file.id}>
                        <FileName>
                          <DeleteButton
                            disabled={isUploadingSTLs}
                            onClick={() => handleDeleteFile(file.id)}
                            type="button"
                          />
                          {file.filename}
                        </FileName>
                        <div>{getScanTypeLabel(file.filename!)}</div>
                      </TableRow>
                    ))}
                  </ScanTable>
                </TableScrollContainer>
              )}
              <Button
                buttonType="secondary"
                disabled={
                  !hasUnsubmittedScans || isUploadingSTLs || isRemovingSTLs
                }
                isLoading={isUploadingSTLs}
                isShort
                onClick={submitScans}
              >
                Submit
              </Button>
              {hasSubmittedScans && (
                <TableScrollContainer>
                  <ScanTable>
                    <TableHeader>
                      <div>File name</div>
                      <div>Scan type</div>
                      <div>Uploaded on</div>
                    </TableHeader>
                    {submittedScans?.map(({ filename, id, url, createdAt }) => (
                      <TableRow key={id!}>
                        <FileName>
                          <a href={url!}>
                            {filename?.replace('candidscans/', '')}
                          </a>
                        </FileName>
                        <div>{getScanTypeLabel(filename!)}</div>
                        <div>{moment(createdAt).format('M/D/YY')}</div>
                      </TableRow>
                    ))}
                  </ScanTable>
                </TableScrollContainer>
              )}
            </Fragment>
          </Container>
        </Fragment>
      </DocumentTitle>
    </JourneyProvider>
  );
};

export default STLUploadOverview;
