import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;

  > input {
    appearance: checkbox;
    align-self: start;
  }
`;
