import { Button } from '@candidco/enamel';
import styled from 'styled-components/macro';

export const ModalBody = styled.div`
  width: 640px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  height: 72px;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 100%;
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  width: 100%;
`;

export const Response = styled.div`
  width: 100%;
`;

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
`;

export const StyledButton = styled(Button)`
  padding: 8px 24px;
  border-width: 2px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 32px;
  height: 88px;
`;

export const StyledTextArea = styled.textarea`
  height: 115px;
  padding: 8px 12px;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  width: 100%;
`;
