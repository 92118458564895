import React from 'react';
import { Button, colors, mediaQueries } from '@candidco/enamel';
import HeaderLogo from 'components/Header/Logo';
import {
  Content,
  Footer,
  Header,
  HeaderTitle,
  HeaderButtonContainer,
  Modal,
} from 'components/FullPageModal/FullPageModal.css';
import { useMediaQuery } from '@material-ui/core';
import ZendeskWidget from 'components/ZendeskWidget';

type FullPageModalProps = {
  patientName: string;
  titleText: string;
  isOpen: boolean;
  children: React.ReactNode | React.ReactNode[];
  continueDisabled: boolean;
  footerLeftContent?: React.ReactNode;
  hasContinueArrow?: boolean;
  isLoading: boolean;
  onClose: () => void;
  onContinue: () => void;
};

const FullPageModal = ({
  patientName,
  titleText,
  children,
  isOpen,
  continueDisabled,
  footerLeftContent,
  hasContinueArrow = false,
  isLoading,
  onClose,
  onContinue,
}: FullPageModalProps) => {
  const title = `${titleText}${patientName ? ` • ${patientName}` : ''}`;
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  return (
    <Modal isOpen={isOpen}>
      <Header>
        {tabletSize && <HeaderLogo canExit={false} />}
        <HeaderTitle data-private>{title}</HeaderTitle>
        <HeaderButtonContainer>
          {tabletSize && <ZendeskWidget text="Support" />}
          <Button
            buttonType="black-outline"
            buttonSize="small"
            onClick={onClose}
            style={{
              borderColor: colors.black70,
              color: colors.black70,
              fontSize: '16px',
            }}
          >
            Exit
          </Button>
        </HeaderButtonContainer>
      </Header>
      <Content>{children}</Content>
      <Footer>
        <div>{footerLeftContent}</div>
        <Button
          buttonSize="medium"
          buttonType="secondary"
          onClick={onContinue}
          disabled={continueDisabled}
          arrow={hasContinueArrow}
          isLoading={isLoading}
        >
          Continue
        </Button>
      </Footer>
    </Modal>
  );
};

export default FullPageModal;
