import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { colors, mediaQueries, type } from '@candidco/enamel';

import { PageSection, siteNarrowWidth } from 'styles/layout.css';

import DeleteIcon from 'assets/delete.svg';

export const Container = styled(PageSection)<{ hasSTLs?: boolean }>`
  width: 100vw;
  max-width: ${({ hasSTLs }) => (hasSTLs ? '64rem' : siteNarrowWidth)};
`;

export const Heading = styled(type.H3)``;

export const UploadContainer = styled.div<{ isSmall?: boolean }>`
  height: ${({ isSmall }) => (isSmall ? '3.5rem' : '30rem')};
  margin-bottom: 1rem;
  margin-top: 1rem;
  background: ${colors.black05};
`;

export const FileTypesText = styled.div`
  margin-bottom: 2rem;
  color: ${colors.black70};
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 8rem 8rem;
  grid-gap: 1.5rem;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const TableScrollContainer = styled.div<{ isUpload?: boolean }>`
  margin-top: 2rem;

  @media ${mediaQueries.mobile} {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  ${({ isUpload }) =>
    isUpload &&
    css`
      margin-bottom: 2rem;

      ${TableRow} {
        grid-template-columns: 1fr 8rem;
      }
    `};
`;

export const ScanTable = styled.div`
  @media ${mediaQueries.mobile} {
    min-width: 44rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`;

export const TableHeader = styled(TableRow).attrs({ as: 'header' })`
  padding-bottom: 0.5rem;
  font-weight: ${type.weight.bold};
  border-bottom: 1px solid ${colors.black20};

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const FileName = styled.div`
  overflow: hidden;
  color: ${colors.blue50};
  text-overflow: ellipsis;

  a {
    font-weight: ${type.weight.default};
    text-decoration: underline;
  }
`;

export const DeleteButton = styled.button`
  width: 1rem;
  height: 1rem;
  margin-top: 0.125rem;
  margin-right: 0.625rem;
  vertical-align: top;
  background: url(${DeleteIcon}) no-repeat;
`;
