import React from 'react';
import {
  getTreatmentPlanStagingViewerUrl,
  toCreatedString,
} from 'utils/treatmentPlan';
import {
  Container,
  Attributes,
  StyledAttributeLabel,
  StyledAttributeValue,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/PlanDetails.css';
import { TreatmentPlanStaging } from 'generated/core/graphql';
import CopyableText from 'components/CopyableText';

const PlanStagingDetails = ({
  treatmentPlan,
}: {
  treatmentPlan: TreatmentPlanStaging;
}) => {
  const created = treatmentPlan?.createdAt ?? null;

  return (
    <Container>
      <Attributes>
        <li>
          <StyledAttributeLabel>Steps</StyledAttributeLabel>
          <StyledAttributeValue>
            {treatmentPlan?.data?.steps}
          </StyledAttributeValue>
        </li>
        <li>
          <StyledAttributeLabel>Created</StyledAttributeLabel>
          <StyledAttributeValue>
            {toCreatedString(created)}
          </StyledAttributeValue>
        </li>
      </Attributes>
      <li
        style={{
          display: 'inline-flex',
          alignItems: 'end',
        }}
      >
        Share Plan
        <CopyableText
          text={getTreatmentPlanStagingViewerUrl(
            treatmentPlan as TreatmentPlanStaging
          )}
          label="Treatment Plan Viewer"
        />
      </li>
    </Container>
  );
};

export default PlanStagingDetails;
