import React, { useEffect, useState, FormEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors, Button, mediaQueries, type, Grid } from '@candidco/enamel';
import { isNotNil } from 'utils/typeCheck';
import { ErrorText, Input as FormInput } from 'styles/inputs.css';
import { ShippingRow } from 'components/SearchPages/Rows';
import {
  EnrichedActiveCaseForShipping,
  enrichShippingCase,
  useShippingContext,
} from 'pages/Shipping/utils';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetCasesDocument,
  GetCasesQuery,
  GetCasesQueryVariables,
} from 'generated/core/graphql';
import { REFACTOR_ANY } from '@Types/refactor';

const SmallHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

const SearchForm = styled.form`
  display: flex;
`;

const Input = styled(FormInput)`
  flex: 1;
  width: 50%;
  max-width: 12rem;
  margin-right: 1rem;
`;

const SubmitButton = styled(Button)`
  @media ${mediaQueries.mobile} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const SearchResults = styled.section`
  padding-top: 5rem;
`;

const CasesTable = styled.div`
  border-top: 1px solid ${colors.black20};
`;

const ShippingSearchPage: React.FC<{ pushTo: string }> = ({
  pushTo = 'shipping',
}) => {
  const { setSelectedOrder, clearState } = useShippingContext();
  const [search, setSearch] = useState<string>('');
  const [cases, setCases] = useState<EnrichedActiveCaseForShipping[]>([]);
  const [getCases, { data: casesData, loading: isLoading, errors }] =
    useGQLQuery<GetCasesQuery, GetCasesQueryVariables>(GetCasesDocument);

  const { push } = useHistory();
  const { search: searchParams } = useLocation();
  const urlParams = new URLSearchParams(searchParams);
  const searchText = urlParams.get('searchTerm');
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);

  const getQueryVariablesForGetCases = (value: string): Record<string, any> => {
    const parsedValue = Number(value);
    const queryVariables: Record<string, any> = {};
    if (!isNaN(parsedValue)) {
      queryVariables['patientIds'] = [parsedValue];
    } else {
      queryVariables['krakenCaseRefs'] = [value];
    }
    return queryVariables;
  };

  useEffect(() => {
    if (searchText) {
      setSearch(searchText);
      setSearchPerformed(true);
    }
    if (searchText) {
      const queryVariables = getQueryVariablesForGetCases(searchText);
      getCases(queryVariables);
    }
  }, [searchText]);

  useEffect(() => {
    const setEnrichedCases = async () => {
      const enrichedCasesPromises =
        casesData?.getCases?.filter(isNotNil).map(enrichShippingCase) || [];
      Promise.all(enrichedCasesPromises).then((enrichedCases) => {
        setCases(
          enrichedCases as REFACTOR_ANY /* TODO: Casting is a quickfix */
        );
      });
    };
    if (casesData) {
      setEnrichedCases();
    }
  }, [casesData]);

  const onSubmitSearch = async (e: FormEvent) => {
    e.preventDefault();
    clearState();
    setCases([]);
    if (search) {
      setSearchPerformed(true);
      push(`/${pushTo}?searchTerm=${search}`);
      const queryVariables = getQueryVariablesForGetCases(search);
      getCases(queryVariables);
    }
  };

  const selectCase = (caseItem: EnrichedActiveCaseForShipping) => {
    const order = caseItem?.customerOrders?.[0];
    setSelectedOrder({
      id: order?.order?.id,
      journeyState: caseItem?.caseState?.providerFacing,
      patientId: caseItem?.patientId,
      krakenId: caseItem?.manufacturerExternalRef,
      shippingAddress: order?.shippingAddress,
      order,
      orderItems: caseItem?.customerOrders,
      associatedCase: caseItem,
    });
  };

  return (
    <Grid container direction="column">
      <SmallHeading>Search by customer ID, or Kraken Supplier ID</SmallHeading>
      <SearchForm>
        <Input
          name="searchTerm"
          placeholder="e.g. XJTUVX"
          required
          type="text"
          onChange={(e: { target: { value: any } }) =>
            setSearch(e.target.value)
          }
          value={search}
        />
        <SubmitButton
          buttonType="secondary"
          disabled={isLoading}
          isLoading={isLoading}
          isShort
          onClick={(e) => {
            onSubmitSearch(e);
          }}
          type="submit"
        >
          Search
        </SubmitButton>
      </SearchForm>
      {errors && <ErrorText>{errors}</ErrorText>}
      <SearchResults>
        {searchPerformed && cases && cases?.length > 0 ? (
          <CasesTable>
            {cases.map((caseItem: EnrichedActiveCaseForShipping, idx: any) => (
              <ShippingRow
                key={caseItem?.caseRef || idx}
                caseItem={caseItem}
                onSelect={() => selectCase(caseItem)}
              />
            ))}
          </CasesTable>
        ) : null}
        {searchPerformed && (!cases || cases.length === 0) ? (
          <p>There are no Pro cases for this customer.</p>
        ) : null}
      </SearchResults>
    </Grid>
  );
};

export default ShippingSearchPage;
