import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPractice,
  fetchPracticeLoyaltyStatus,
  getSelectedPractice,
  getSelectedPracticeLoyaltyStatus,
} from 'pages/PracticeManagement/shared/slice';
import { Loading } from '@candidco/enamel';
import {
  Container,
  Header,
  PracticeName,
  StyledBackButton,
  BackIcon,
  EditButton,
  StyledPencilIcon,
  Info,
  GridItem,
  Label,
  InfoText,
  TabContainer,
  Tab,
  TabLabelContainer,
  LoyaltyBadge,
} from 'pages/PracticeManagement/PracticeManager.css';
import UpdateLoyaltyStatusForm from 'pages/PracticeManagement/LoyaltyProgramSettings/LoyaltyUpdateTierForm';
import EnrollInLoyaltyForm from 'pages/PracticeManagement/LoyaltyProgramSettings/EnrollInLoyaltyForm';
import PracticePricing from 'pages/PracticeManagement/Pricing/PracticePricing';
import { AppDispatch } from 'state/store';

type PracticeManagerParams = {
  practiceId: string;
};

const PracticeManager = () => {
  const [loading, setLoading] = React.useState(true);
  const { practiceId } = useParams<PracticeManagerParams>();
  const dispatch: AppDispatch = useDispatch();
  const selectedPractice = useSelector(getSelectedPractice);
  const selectedPracticeLoyaltyStatus = useSelector(
    getSelectedPracticeLoyaltyStatus
  );
  const [currentTabIndex, setCurrentTabIndex] = React.useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchPractice({ id: practiceId }));
      await dispatch(fetchPracticeLoyaltyStatus({ practiceId }));
      setLoading(false);
    };
    fetchData();
  }, [practiceId, dispatch]);

  const tabs = [
    {
      name: 'Loyalty program',
      Component: () => {
        if (selectedPracticeLoyaltyStatus) {
          return <UpdateLoyaltyStatusForm />;
        }
        return <EnrollInLoyaltyForm />;
      },
    },
    {
      name: 'Pricing',
      Component: () => <PracticePricing />,
    },
  ];

  if (loading || !selectedPractice) {
    return (
      <Container>
        <Loading />;
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <PracticeName>
          <StyledBackButton to={{ pathname: '/management/practice/' }}>
            <BackIcon />
          </StyledBackButton>
          <h4>{selectedPractice.name}</h4>
        </PracticeName>
        <EditButton buttonType="secondary">
          <StyledPencilIcon />
          Edit Info
        </EditButton>
      </Header>
      <Info container spacing={10}>
        <GridItem item xs={'auto'}>
          <Label>Address</Label>
          <InfoText>
            {selectedPractice.address.addressLine1}
            {selectedPractice.address.addressLine2 ?? ''}
          </InfoText>
          <InfoText>
            {selectedPractice.address.city},{' '}
            {selectedPractice.address.stateCode} {selectedPractice.address.zip}
          </InfoText>
        </GridItem>
        <GridItem item xs={'auto'}>
          <Label>Practice ID</Label>
          <InfoText>{selectedPractice.id}</InfoText>
        </GridItem>
        <GridItem item xs={'auto'}>
          <Label>Account ID</Label>
          <InfoText>{selectedPractice.account.id}</InfoText>
        </GridItem>
        <GridItem item xs={'auto'}>
          <Label>Salesforce Child ID</Label>
          <InfoText>{selectedPractice.salesforceChildAccountId}</InfoText>
        </GridItem>
        <GridItem item xs={'auto'}>
          <Label>Salesforce Parent ID</Label>
          <InfoText>
            {selectedPractice.account.salesforceParentAccountId}
          </InfoText>
        </GridItem>
      </Info>
      <TabContainer>
        {tabs.map(({ name }, index) => (
          <Tab
            key={index}
            onClick={() => setCurrentTabIndex(index)}
            isActive={index === currentTabIndex}
          >
            <TabLabelContainer>
              {name}
              {name === 'Loyalty program' && (
                <LoyaltyBadge enrolled={!!selectedPracticeLoyaltyStatus}>
                  {selectedPracticeLoyaltyStatus ? 'Enrolled' : 'Not enrolled'}
                </LoyaltyBadge>
              )}
            </TabLabelContainer>
          </Tab>
        ))}
      </TabContainer>
      {!loading && tabs[currentTabIndex].Component()}
    </Container>
  );
};

export default PracticeManager;
