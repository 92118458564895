import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { mediaQueries, Grid } from '@candidco/enamel';

import checkCircleIconPath from 'assets/check-circle-outline.svg';
import minusCircleIconPath from 'assets/minus-circle-outline.svg';

export const PlanViewer = styled.div`
  position: relative;
  width: 100%;
  height: 75vh;
  margin-bottom: 2rem;
  background: ${({ theme }) => theme.colors.black05};

  @media ${mediaQueries.mobile} {
    max-width: 100%;
    padding-top: 150%;
  }
`;

export const PlanViewerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ReviewFormWrapper = styled.div`
  margin-bottom: 32px;
`;

export const Notes = styled.section`
  margin-bottom: 1.5rem;

  p {
    margin-bottom: 0;
    font-style: italic;
    color: ${({ theme }) => theme.colors.text70};
  }
`;

export const CenterText = styled.div`
  padding-top: 1rem;
  text-align: center;
`;

export const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export const MaterialReviewFormContainer = styled(Grid)`
  max-width: 100vw;
`;

export const MaterialReviewFormItem = styled(Grid)`
  max-width: 680px;
`;

export const StatusBar = styled.div<{
  isPending?: boolean;
  isApproved?: boolean;
  isRejected?: boolean;
}>`
  padding: 0.8125rem 1rem 0.6875rem 2.5rem;
  margin-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-position: 1rem 0.9375rem;

  :only-child {
    margin-bottom: 0;
  }

  ${({ isPending }) =>
    isPending &&
    css`
      padding-left: 1rem;
      background-color: ${({ theme }) => theme.colors.yellow30};
    `};

  ${({ isApproved }) =>
    isApproved &&
    css`
      background-color: ${({ theme }) => theme.colors.green30};
      background-image: url(${checkCircleIconPath});
    `};

  ${({ isRejected }) =>
    isRejected &&
    css`
      background-color: ${({ theme }) => theme.colors.red30};
      background-image: url(${minusCircleIconPath});
    `};
`;
