import styled, { css } from 'styled-components/macro';

export const Container = styled.div<{
  isSelected?: boolean;
  selectionType: 'products' | 'retainers';
}>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: ${({ selectionType }) =>
    selectionType === 'products' ? '24px 32px' : '24px'};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20px 24px;
  }
  border-radius: 12px;

  ${({ isSelected }) =>
    isSelected
      ? css`
          border: 2px solid ${({ theme }) => theme.colors.blue50};
          margin: -1px; /* Prevents shifting when changing border widths */
          box-shadow: 0px 2px 8px 0px #0000001a;
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.black40};
          margin: 0px; /* Prevents shifting when changing border widths */
          box-shadow: none;

          &:hover {
            border: 1px solid ${({ theme }) => theme.colors.black60};
            box-shadow: 0px 1px 4px 0px #0000001a;
          }
        `};
`;

export const TextContainer = styled.div<{
  selectionType: 'products' | 'retainers';
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ selectionType }) =>
    selectionType === 'products' ? '12px' : '2px'};
`;

export const HeaderText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 150%;
`;

export const BodyText = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.black70};
`;

export const PriceText = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 14px;
`;

export const Image = styled.img`
  width: 180px;
  height: 140px;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 96px;
    height: 75px;
  }
`;
