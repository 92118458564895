import React from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import startCase from 'lodash/startCase';
import { Button } from '@candidco/enamel';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AggregateType, Case, StateDataType } from 'generated/legacy/graphql';
import { toAggregateLabel } from 'utils/prism';
import { EnrichedActiveCaseForShipping } from 'pages/Shipping/utils';
import { getCaseTypeLabel } from 'utils/case';

const CaseWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 4fr 2fr 1fr;
  padding: 0.875rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black20};
`;
const CaseHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CaseHeaderWrapperInternalContainer = styled.div`
  display: flex;
  align-items: center;
  h4,
  h6 {
    margin-bottom: 0;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const CaseHeading = styled.h4`
  ${({ theme }) => theme.text.headings.h4}
`;
const CaseActive = styled.h6<{ isActive: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: 1rem;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.green70 : theme.colors.text90};
`;
const CaseViewLink = styled.h4`
  ${({ theme }) => theme.text.body.default}
  color: ${({ theme }) => theme.colors.blue50};
`;

const AggregateWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 1fr;
  padding: 0.875rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.black20};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black20};
`;

const ActiveCellWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[2]};

  ${({ isActive, theme }) =>
    isActive &&
    css`
      font-weight: ${theme.fontWeights.bold};
      color: ${theme.colors.green70};
    `};
`;
const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CellTitle = styled.div`
  margin-bottom: 0.5rem;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text40};
`;

const CellBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
`;

const ViewCellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectButton = styled(Button)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
`;

const Cell = ({
  title,
  body,
}: {
  title: Maybe<string>;
  body: Maybe<string>;
}) => (
  <CellWrapper>
    <CellTitle>{title}</CellTitle>
    <CellBody>{body}</CellBody>
  </CellWrapper>
);

export const ShippingRow = ({
  caseItem,
  onSelect,
}: {
  caseItem: EnrichedActiveCaseForShipping;
  onSelect: () => void;
}) => {
  if (!caseItem || !caseItem.caseRef || !caseItem.customerOrders) {
    return <>There are no Pro cases for this customer.</>;
  }
  const { customerOrders, isActive, manufacturerExternalRef } = caseItem;
  const humanReadableState = caseItem?.caseState?.providerFacing;
  const orderNumbers = customerOrders?.map(
    (order) => order?.order?.orderNumber
  );
  const uniqueOrderNumbers = [...new Set(orderNumbers)];
  return (
    <CaseWrapper>
      <ActiveCellWrapper isActive={isActive!}>
        {isActive ? 'Active' : 'Inactive'}
      </ActiveCellWrapper>
      <Cell title="Order number" body={uniqueOrderNumbers.join(', ')} />
      <Cell title="Supplier case ID" body={manufacturerExternalRef} />
      <Cell title="Journey state" body={humanReadableState} />
      <ViewCellWrapper>
        <SelectButton buttonType="secondary-outline" onClick={onSelect} isShort>
          Select
        </SelectButton>
      </ViewCellWrapper>
    </CaseWrapper>
  );
};

const getLatestDate = (aggregate: AggregateType): string | 'N/A' => {
  const arr = aggregate.stateData?.history;
  if (arr?.length) {
    const idx = arr?.length - 1;
    return (arr![idx] as StateDataType).created as unknown as string;
  }
  return 'N/A';
};

export const CaseHeader = ({ caseItem }: { caseItem: Case }) => {
  const { push } = useHistory();
  const { 'enable-refinement-policy': enableRefinementPolicy } = useFlags();
  const caseType = getCaseTypeLabel(
    caseItem?.caseType?.label ?? '',
    enableRefinementPolicy
  );
  return (
    <CaseHeaderWrapper>
      <CaseHeaderWrapperInternalContainer>
        <CaseHeading>{caseType}</CaseHeading>
        <CaseActive isActive={caseItem?.isActive as boolean}>
          {caseItem?.isActive ? 'Active' : 'Inactive'}
        </CaseActive>
      </CaseHeaderWrapperInternalContainer>
      <CaseViewLink
        onClick={() =>
          push(`/case/${caseItem.customerRef}/${caseItem.caseRef}`)
        }
      >
        View Case
      </CaseViewLink>
    </CaseHeaderWrapper>
  );
};

export const AggregateRow = ({
  aggregate,
  selectFor,
  push,
}: {
  aggregate: AggregateType;
  selectFor: 'prism' | 'ortho';
  push: {
    (path: string, state?: History.PoorMansUnknown): void;
  };
}) => (
  <AggregateWrapper key={aggregate.ref}>
    <Cell
      title="Photo Review type"
      body={toAggregateLabel(aggregate.aggregateType.label)}
    />
    <Cell title="Status" body={startCase(aggregate.stateData?.data ?? '-')} />
    <Cell
      title="Created"
      body={moment
        .parseZone(aggregate.createdAt)
        .local()
        .format('MM/D/YYYY HH:MM')}
    />
    <Cell
      title="Last state change at"
      body={moment
        .parseZone(getLatestDate(aggregate))
        .local()
        .format('MM/D/YYYY HH:MM')}
    />
    <ViewCellWrapper>
      <SelectButton
        buttonType="secondary-outline"
        onClick={() =>
          push(`/${selectFor}/${aggregate.customer.id}/${aggregate.ref}`)
        }
        isShort
      >
        Select
      </SelectButton>
    </ViewCellWrapper>
  </AggregateWrapper>
);
