import styled from 'styled-components/macro';
import { colors } from '@candidco/enamel';

export const ModalContainer = styled.div`
  max-width: 50rem;
  padding: 1.5rem;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  padding: 1.25rem;
  text-align: left;
  padding-right: 6rem;
`;

export const HeaderContainer = styled.div`
  max-width: 22rem;
  text-align: left;
`;

export const Note = styled.p`
  font-weight: 300;
  color: gray;
  display: flex;
`;

interface SpacerProps {
  isVertical?: boolean;
  spacing?: string;
}

export const Spacer = styled.div<SpacerProps>`
  display: flex;
  flex-direction: ${({ isVertical = false }) =>
    isVertical ? 'column' : 'row'};

  > *:not(:first-child) {
    margin-top: ${({ spacing = '1rem', isVertical = false }) =>
      isVertical ? spacing : '0'};
    margin-left: ${({ spacing = '1rem', isVertical = false }) =>
      !isVertical ? spacing : '0'};
  }
`;

export const Title = styled.p`
  ${({ theme }) => theme.fontSizes[4]};
  margin-top: 1.5rem;
  margin-bottom: 1;
  display: flex;
  ${({ theme }) => theme.fontWeights.bold};
`;

export const Content = styled.div`
  margin-bottom: 0.5rem;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: left;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 6.5rem;
  padding: 0.625rem;
  line-height: 1.4;
  border: 1px solid ${colors.black20};
  border-radius: 0.125rem;
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${colors.black40};
  }

  &::placeholder {
    color: ${colors.black60};
  }
`;
