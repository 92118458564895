import React from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CaseTrackerAlert from 'pages/Patient/PatientDetail/CaseTrackerAlert';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AlertTypeEnum } from 'pages/Patient/types';

import {
  Container,
  StepConnector,
  Stepper,
  ActiveStepIcon,
  InactiveStepIcon,
  StepContent,
  ContentText,
  ActionButton,
  ActionLink,
  ActiveStepIconRed,
  ActiveStepIconYellow,
} from 'pages/Patient/PatientDetail/CaseTracker.css';

import {
  selectPatient,
  selectSelectedCase,
  selectAlignerTrackingLink,
  selectAlertStatus,
} from 'pages/Patient/patientSlice';
import { StepConfigs } from 'pages/Patient/PatientDetail/CaseTrackerConfigs';

type StepStatus = AlertTypeEnum.Critical | AlertTypeEnum.Warning | null;

type StepContentType = {
  content: string | React.ReactNode;
  action: string | React.ReactNode | null;
};

const getStepContent = (
  step: number,
  defaultContent: string,
  activeStep: any,
  patientId: string,
  stepConfigs: StepConfigs,
  createdDate?: string,
  stepStatus?: StepStatus,
  trackingLink?: string
): StepContentType => {
  const caseCreatorLink = `/patient/${patientId}/case-creator/diagnostic-materials`;
  const isActiveStep = step === activeStep?.index;
  const isCompletedStep = step < activeStep?.index;
  let content;
  let action = null;

  switch (step) {
    case stepConfigs.caseCreated.index:
      content = `You created your case on ${moment(createdDate).format(
        'MMM D, YYYY'
      )}.`;
      break;
    case stepConfigs.submissionUnderReview.index:
      if (stepStatus) {
        content = (
          <CaseTrackerAlert type={stepStatus} actionLink={caseCreatorLink} />
        );
      } else if (isCompletedStep || isActiveStep) {
        action = (
          <ActionLink to={caseCreatorLink}>View my submission</ActionLink>
        );
      }
      break;
    case stepConfigs.shipped.index:
      if (isActiveStep && trackingLink) {
        action = (
          <ActionButton
            onClick={() => {
              window.open(trackingLink, '_blank');
            }}
          >
            Track package
          </ActionButton>
        );
      }
      break;
    // This will be supported in V2
    // case stepConfigs.alignerKitDelivered.index:
    //   if (isActiveStep) {
    //     content = `The patient received their aligners and should begin treatment. If you’re waiting on a patient to start treatment, you can resend the CandidApp activation email.`;
    //     action = (
    //       <ActionButton>Resend the CandidApp activation email</ActionButton>
    //     );
    //   }
    //   break;
    default:
      content = defaultContent;
  }

  return { content: content || defaultContent, action };
};

const getStepIcon = (
  step: number,
  activeStep: number,
  stepStatus?: StepStatus
) => {
  if (step === activeStep) {
    if (stepStatus === AlertTypeEnum.Critical) {
      return ActiveStepIconRed;
    } else if (stepStatus === AlertTypeEnum.Warning) {
      return ActiveStepIconYellow;
    }
    return ActiveStepIcon;
  } else {
    return InactiveStepIcon;
  }
};

const CaseTracker = ({
  activeStep,
  stepConfigs,
}: {
  activeStep: number;
  stepConfigs: StepConfigs;
}) => {
  const activeStepConfig = Object.values(stepConfigs).find(
    (stepConfig) => stepConfig.index === activeStep
  );
  const patient = useSelector(selectPatient);
  const selectedCase = useSelector(selectSelectedCase);
  const trackingLink = useSelector(selectAlignerTrackingLink);
  const stepStatus = useSelector(selectAlertStatus);

  return (
    <Container data-testid="pdp-case-tracker">
      <Stepper
        activeStep={activeStep}
        connector={<StepConnector />}
        orientation="vertical"
      >
        {Object.values(stepConfigs)
          .sort((s) => s.index)
          .map(({ label, index, defaultContent }) => {
            const StepIcon = getStepIcon(index, activeStep, stepStatus);
            const { content, action } = getStepContent(
              index,
              defaultContent,
              activeStepConfig,
              patient?.id ?? '',
              stepConfigs,
              selectedCase?.createdAt,
              stepStatus,
              trackingLink
            );
            return (
              <Step key={label} active={true}>
                <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                <StepContent>
                  <ContentText>{content}</ContentText>
                  <ContentText>{action}</ContentText>
                </StepContent>
              </Step>
            );
          })}
      </Stepper>
    </Container>
  );
};

export default CaseTracker;
