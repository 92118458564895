import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import HelpCenter from 'pages/HelpCenter/HelpCenter';

const HelpCenterPage = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route path={match.path} exact>
      <HelpCenter />
    </Route>
  </Switch>
);

export default HelpCenterPage;
