import { Maybe } from 'graphql/jsutils/Maybe';
import { LocalCase } from 'utils/types';

import { Case, CaseTypes } from 'generated/legacy/graphql';
import { SupplierServices, CaseData } from 'pages/Case/types';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import { RequiredNonNullable } from 'utils/typeCheck';
import moment from 'moment';

type RequiredCaseFields = RequiredNonNullable<Case, 'caseRef' | 'customerRef'> &
  RequiredNonNullable<Case, 'created'> &
  RequiredNonNullable<Case, 'isActive'>;

type ValidCase = Case & RequiredCaseFields;

export type DecodedCase = Omit<
  Case,
  'supplierServices' | 'treatmentplans' | 'data'
> & {
  supplierServices?: Maybe<SupplierServices>;
  data?: Maybe<CaseData>;
} & RequiredCaseFields;

const maxTeenAndYouthAge = 17;

const caseIsValid = (
  caseToValidate: Maybe<LocalCase>
): caseToValidate is ValidCase => {
  if (
    !caseToValidate ||
    !caseToValidate.caseRef ||
    !caseToValidate.customerRef ||
    !caseToValidate.created
  ) {
    return false;
  }

  return true;
};

/**
 * decode (i.e. parse) json string fields of a case
 * @param case
 */
export const decodeCase = (
  caseToDecode: Maybe<LocalCase>
): Maybe<DecodedCase> => {
  if (!caseIsValid(caseToDecode)) {
    return null;
  }

  const { supplierServices, data } = caseToDecode;

  return {
    ...caseToDecode,
    supplierServices: supplierServices ? JSON.parse(supplierServices) : null,
    data: data ? JSON.parse(data) : null,
  };
};

export const caseTypeForBirthday = (birthday: string) => {
  const current_date = moment();
  const age = current_date.diff(birthday, 'years');

  if (age <= maxTeenAndYouthAge) {
    return CaseTypes.CproTeenAligner;
  }

  return CaseTypes.CpioAdultAligner;
};

const getCaseById = (cases: DecodedCase[], id: number): Maybe<DecodedCase> => {
  return cases.find((_case) => Number(_case.id) === id);
};

export const getPrecedingCase = (
  currentCase: Maybe<DecodedCase>,
  cases: Maybe<DecodedCase[]>
) => {
  const precedingCaseId = currentCase?.precedingCase;
  if (!Array.isArray(cases) || !precedingCaseId) {
    return null;
  }

  return getCaseById(cases, precedingCaseId);
};

/**
 * Given a case and an array of cases for a customer,
 * traverse through preceding cases
 * until we reach a case that has no preceding case
 * @param currentCase
 * @param cases
 * @returns
 */
export const getOriginalCase = (
  currentCase: Maybe<DecodedCase>,
  cases: Maybe<DecodedCase[]>
) => {
  if (!Array.isArray(cases) || !currentCase) {
    return null;
  }

  // record visited cases to check for circular dependency
  const visitedCasesIds = [currentCase.id];

  // traverse through preceding cases
  let originalCase: Maybe<DecodedCase> = currentCase;

  while (originalCase?.precedingCase) {
    const precedingCaseId = originalCase.precedingCase;
    originalCase = getCaseById(cases, precedingCaseId);

    // check if preceding case was not found or
    // there is a circular preceding case dependency
    if (!originalCase || visitedCasesIds.includes(originalCase.id)) {
      return null;
    }

    visitedCasesIds.push(originalCase.id);
  }

  return originalCase;
};

export const getCaseTypeLabel = (
  caseLabel: string,
  enableRefinementPolicy: boolean
): string => {
  if (!caseLabel) {
    return '';
  }
  if (
    !enableRefinementPolicy &&
    caseLabel.toLocaleLowerCase().includes('refinement')
  ) {
    return 'Additional aligner';
  }

  return caseLabel;
};

export const getProviderFacingStatus = (
  status: string,
  enableRefinementPolicy: boolean
): string => {
  if (
    !enableRefinementPolicy &&
    status.toLocaleLowerCase().includes('additional aligners')
  ) {
    return 'Refinements requested';
  }

  return status;
};

// Get a temporary mask provider facing status when ff is not on
export const getTempProviderFacingStatus = (
  status: string,
  enableRefinementPolicy: boolean
): string => {
  if (
    !enableRefinementPolicy &&
    status?.toLocaleLowerCase().includes('refinements requested')
  ) {
    return 'Additional aligners requested';
  }

  return status;
};

export const availableActionsForProviderFacingStatus = (
  providerFacingStatus?: PROVIDER_FACING_STATUSES
) => {
  return {
    canOrderRetainers:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
        PROVIDER_FACING_STATUSES.TREATMENT_COMPLETED,
        PROVIDER_FACING_STATUSES.CASE_COMPLETED,
      ].includes(providerFacingStatus),
    canCreateDmAccount:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
      ].includes(providerFacingStatus),
    canSendDmActivationEmail:
      providerFacingStatus &&
      [PROVIDER_FACING_STATUSES.IN_TREATMENT].includes(providerFacingStatus),
    canConfirmAlignerDelivery:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
      ].includes(providerFacingStatus),
    canViewTpLinkPdf:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
      ].includes(providerFacingStatus),
  };
};
