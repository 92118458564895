import { resetPassword } from 'api/login';
import { Button } from '@candidco/enamel';
import DocumentTitle from 'components/DocumentTitle';
import { FormikInputWrapper } from 'components/FormikForms';
import { emailValidator } from 'components/FormikForms/utils';
import { Formik } from 'formik';
import {
  SplitContainer,
  LoginContainer,
  LoginForm,
  InputFields,
  Logo,
  StyledLink,
  SuccessText,
} from 'pages/Login/Login.css';
import LoginRightPanel from 'pages/Login/LoginRightPanel';
import React from 'react';
import { getBrandDomainSettings } from 'utils/brands';

type FormProps = {
  email: string;
};

type PasswordResetRequestProps = {
  showNotification: (message: string, variant: string) => void;
};

const PasswordResetRequest = ({
  showNotification,
}: PasswordResetRequestProps) => {
  const initialValues: FormProps = {
    email: '',
  };

  const { brandId, loginBackgroundImgSrc, label, logoSrc, showLaunchNotes } =
    getBrandDomainSettings();

  const handleSubmit = async ({ email }: FormProps) => {
    try {
      await resetPassword(email, brandId);
      window.location.href = `/account/reset/success?email=${email}`;
    } catch (error: unknown) {
      if (error instanceof Error) {
        showNotification(error.message, 'error');
      } else {
        showNotification('An error occurred', 'error');
      }
    }
  };

  return (
    <DocumentTitle title="Password reset">
      <SplitContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <LoginContainer>
              <LoginForm>
                {logoSrc && (
                  <Logo alt={label as string} src={logoSrc as string} />
                )}
                <SuccessText>Reset your password</SuccessText>
                <InputFields>
                  <FormikInputWrapper
                    testId="email-input"
                    autoComplete="username"
                    name="email"
                    type="email"
                    placeholder="Email"
                    validate={emailValidator}
                  />
                </InputFields>
                <Button
                  testId="login-btn"
                  buttonType="secondary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Send reset confirmation
                </Button>
                <StyledLink to="/login">Back to login</StyledLink>
              </LoginForm>
            </LoginContainer>
          )}
        </Formik>

        <LoginRightPanel
          backgroundImgSrc={loginBackgroundImgSrc as string}
          showLaunchNotes={showLaunchNotes as boolean}
        />
      </SplitContainer>
    </DocumentTitle>
  );
};

export default PasswordResetRequest;
