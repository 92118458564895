import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@candidco/enamel';

import { AppDispatch } from 'state/store';
import {
  Content,
  ModalContainer,
  Title,
  TextArea,
  ButtonContainer,
  Spacer,
  Note,
  HeaderContainer,
} from 'pages/Prism/Sidebar/SubmissionNoteModal/SubmissionNoteModal.css';
import { createSubmission, selectSelectedPhotos } from 'pages/Prism/prismSlice';
import { AggregateType } from 'generated/legacy/graphql';
type Props = {
  isVisible: boolean;
  aggregate: AggregateType | null;
  photoViewNames: string[];
  onClose: () => void;
};
type TextChange = React.ChangeEvent<HTMLTextAreaElement>;

const SubmissionNoteModal = ({
  isVisible,
  aggregate,
  photoViewNames,
  onClose,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const photoSelections = useSelector(selectSelectedPhotos);
  const [initialNote, setinitialNote] = useState<string>('');

  const handleSubmit = async () => {
    dispatch(
      createSubmission({
        aggregateRef: aggregate!.ref as string,
        submissionPhotoRefs: photoViewNames.map(
          (name) => photoSelections[name]?.ref as string
        ),
        initialNote: initialNote,
      })
    );
    onClose();
  };

  const onChangeNotes = (e: TextChange) => {
    setinitialNote(e.target.value);
  };

  return (
    <Modal isOpen={isVisible} onClose={onClose} hideCloseButton={true}>
      <ModalContainer>
        <HeaderContainer>
          <Title>
            Is there anything you'd like to commmunicate to the orthodontist?
          </Title>
        </HeaderContainer>
        <Content>
          <TextArea
            maxLength={5000}
            onChange={onChangeNotes}
            placeholder="Type a message (optional)"
          />
        </Content>
        <Note>Ex: Patient was unable to open their mouth</Note>
        <ButtonContainer>
          <Spacer spacing="1rem">
            <Button buttonType="secondary" isShort onClick={handleSubmit}>
              Submit
            </Button>
            <Button buttonType="secondary-outline" isShort onClick={onClose}>
              Cancel
            </Button>
          </Spacer>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default SubmissionNoteModal;
