import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetAllPracticesDocument,
  GetAllPracticesQueryVariables,
  GetPracticeDocument,
  GetPracticeQueryVariables,
  GetThinPracticesDocument,
  GetThinPracticesQueryVariables,
  Practice,
} from 'generated/legacy/graphql';
import {
  GetCurrentPracticeLoyaltyProgramDocument,
  GetCurrentPracticeLoyaltyProgramQuery,
  GetCurrentPracticeLoyaltyProgramQueryVariables,
} from 'generated/core/graphql';

import { RootState } from 'state/store';
import { autoQuery } from 'state/system';
import { transformLoyaltyInfo } from 'pages/PracticeManagement/shared/utils';
import { coreClient } from 'state/GraphQLProvider';

export type LoyaltyProgramTier = {
  name: string;
  sortOrder: number;
  id: string;
  entryRule: {
    args: { minNumber: number };
    name: 'min_products';
  };
  loyaltyTierProducts: {
    sku: string;
    discount: number;
  }[];
};

export type EnrolledLoyaltyProgram = {
  practiceLoyaltyStatusId: string;
  caseCount: number;
  evaluationPeriodEnd: string;
  programStart: string;
  programEnd: string;
  loyaltyProgram: {
    name: string;
    interval: number;
    currentTier: {
      id: string;
      sortOrder: number;
      name: string;
    };
    tiersInProgram: LoyaltyProgramTier[];
  };
};

type EnrichedPractice = Practice & {
  enrolledLoyaltyProgram?: EnrolledLoyaltyProgram;
};

export enum PracticeManagementView {
  OVERVIEW = 'Overview',
  LOYALTY = 'Loyalty',
  PRICING = 'Pricing',
}

type PracticeManagementState = {
  selectedView: PracticeManagementView;
  selectedPractice: EnrichedPractice | null;
  practices: EnrichedPractice[];
};

const initialState: PracticeManagementState = {
  selectedView: PracticeManagementView.OVERVIEW,
  selectedPractice: null,
  practices: [],
};

export const fetchAllPractices =
  autoQuery.createQueryAction<GetAllPracticesQueryVariables>(
    'practiceManagement/getPractices',
    GetAllPracticesDocument,
    'practiceManagement/setPractices'
  );

export const fetchThinPractices =
  autoQuery.createQueryAction<GetThinPracticesQueryVariables>(
    'practiceManagement/getPractices',
    GetThinPracticesDocument,
    'practiceManagement/setPractices'
  );

export const fetchPractice =
  autoQuery.createQueryAction<GetPracticeQueryVariables>(
    'practiceManagement/getPractice',
    GetPracticeDocument,
    'practiceManagement/setSelectedPracticeUnnested'
  );

export const fetchPracticeLoyaltyStatus =
  autoQuery.createQueryAction<GetCurrentPracticeLoyaltyProgramQueryVariables>(
    'practiceManagement/getLoyaltyEnrollmentProgram',
    GetCurrentPracticeLoyaltyProgramDocument,
    'practiceManagement/setSelectedPracticeLoyaltyStatus',
    coreClient
  );

const practiceManagementSlice = createSlice({
  name: 'practiceManagement',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedView: (state, action: PayloadAction<PracticeManagementView>) => {
      state.selectedView = action.payload;
    },
    setPractices: (state, action) => {
      const { practices } = action.payload;
      state.practices = practices;
    },
    setSelectedPractice: (state, action: PayloadAction<Practice>) => {
      state.selectedPractice = action.payload;
    },
    setSelectedPracticeUnnested: (
      state,
      action: PayloadAction<{ practice: Practice }>
    ) => {
      state.selectedPractice = action.payload.practice;
    },
    setSelectedPracticeLoyaltyStatus: (
      state,
      action: PayloadAction<GetCurrentPracticeLoyaltyProgramQuery>
    ) => {
      if (state.selectedPractice) {
        state.selectedPractice.enrolledLoyaltyProgram = transformLoyaltyInfo(
          action.payload.getCurrentPracticeLoyaltyProgram
        );
      }
    },
  },
});

export const {
  setSelectedView,
  setSelectedPractice,
  setSelectedPracticeLoyaltyStatus,
} = practiceManagementSlice.actions;

// selectors
export const getSelectedView = (state: RootState) =>
  state.practiceManagement.selectedView;
export const getSelectedPractice = (state: RootState) =>
  state.practiceManagement.selectedPractice;
export const getSelectedPracticeLoyaltyStatus = (state: RootState) =>
  state.practiceManagement.selectedPractice?.enrolledLoyaltyProgram;
export const selectAllPractices = (state: RootState) =>
  state.practiceManagement.practices;

export default practiceManagementSlice.reducer;
