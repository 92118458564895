import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';

import { AuthContext } from 'components/AuthProvider';
import {
  Author,
  DateTime,
  HistoryList,
  Subtitle,
  Title,
  UnderlineLink,
  LinkRow,
  VersionTitle,
} from 'components/MaterialHistory/History.css';
import { ACCESS_GROUPS } from 'constants/index';
import { coreClient } from 'state/GraphQLProvider';
import {
  TpReversalDocument,
  TpReversalMutation,
  TpReversalMutationVariables,
} from 'generated/core/graphql';
import { renderRichTextAsHtml } from 'utils/RichText';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { selectSelectedCase } from 'pages/OrthoPrism/orthoSlice';

export interface IHistoryAttachment {
  filename?: string;
  onClick: () => void;
}

export type HistoryItemType = {
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  dateTime: string;
  title: string;
  notes?: string;
  author?: string;
  attachments?: IHistoryAttachment[];
  reversibleAction: boolean;
};

type HistoryItemSectionProps = {
  children: React.ReactNode;
  subtitle: string;
};

const HistoryItemSection = ({
  subtitle,
  children,
}: HistoryItemSectionProps) => (
  <>
    <Subtitle>{subtitle}</Subtitle>
    {children}
  </>
);

const Attachment = ({ attachment }: { attachment: IHistoryAttachment }) => (
  <LinkRow>
    <UnderlineLink onClick={attachment.onClick}>
      {attachment.filename}
    </UnderlineLink>
  </LinkRow>
);

const HistoryItem = ({
  Icon,
  dateTime,
  title,
  notes,
  author,
  reversibleAction,
  attachments,
}: HistoryItemType) => {
  const selectedCase = useSelector(selectSelectedCase);

  const { checkHasAccess } = useContext(AuthContext);
  const { 'enable-tp-reversal-for-admins': enableTpReversalForAdmins } =
    useFlags();

  const reversableFeatureEnabled =
    enableTpReversalForAdmins && checkHasAccess(ACCESS_GROUPS.ADMIN);

  return (
    <li
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {Icon && <Icon stroke="#6F6F6F" aria-hidden />}
        <DateTime>
          {moment(dateTime).format('MMM. D, YYYY [at] h:mma')}
        </DateTime>
        <Title>{title}</Title>
        {author && <Author>{`(${author})`}</Author>}
        {notes && (
          <HistoryItemSection subtitle="Notes">
            {renderRichTextAsHtml(notes)}
          </HistoryItemSection>
        )}
        {!!attachments?.length && (
          <HistoryItemSection subtitle="Attachments">
            {attachments.map((a) => (
              <Attachment key={a.filename} attachment={a} />
            ))}
          </HistoryItemSection>
        )}
      </div>
      {reversibleAction && reversableFeatureEnabled && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {selectedCase && <TpReversal caseRef={selectedCase.caseRef} />}
        </div>
      )}
    </li>
  );
};

const TpReversal = ({ caseRef }: { caseRef: string }) => {
  const [state, setState] = useState<'loading' | 'ready' | 'error' | null>(
    null
  );
  const reversalFn = useMemo(() => {
    return async () => {
      try {
        setState('loading');
        const res = await coreClient.mutate<
          TpReversalMutation,
          TpReversalMutationVariables
        >({
          mutation: TpReversalDocument,
          variables: {
            caseRef,
          },
        });

        if (res.data?.reverseTreatmentPlanForCase?.success) {
          setState('ready');
        } else {
          setState('error');
        }
      } catch (e) {
        setState('error');
      }
    };
  }, [caseRef]);

  switch (state) {
    case 'loading':
      return <button disabled>In progress...</button>;
    case 'ready':
      return <button disabled>Reversed</button>;
    case 'error':
      return <button disabled>Error</button>;
    default:
      return <button onClick={reversalFn}>Revert</button>;
  }
};

type HistorySectionProps = {
  historyItems: HistoryItemType[];
};

const HistorySection = ({ historyItems }: HistorySectionProps) => (
  <HistoryList>
    {historyItems.map((item) => (
      <HistoryItem key={`${item.title}-${item.dateTime}`} {...item} />
    ))}
  </HistoryList>
);

type HistorySectionType = {
  historyItems: HistoryItemType[];
  version?: number;
};

const HistoryVersionSection = ({
  version,
  historyItems,
}: HistorySectionType) => (
  <ul>
    <VersionTitle>Version {version}</VersionTitle>
    <HistorySection historyItems={historyItems} />
  </ul>
);

type HistoryProps = {
  historySections: HistorySectionType[];
};

const History = ({ historySections }: HistoryProps) =>
  historySections.length === 1 ? (
    <HistorySection {...historySections[0]} />
  ) : (
    <>
      {historySections.map((section) => (
        <HistoryVersionSection key={section.version} {...section} />
      ))}
    </>
  );

export default History;
