import React from 'react';
import { Grid, Skeleton } from '@candidco/enamel';

import {
  Container,
  CaseStatusContainer,
} from 'pages/Patient/PatientDetail/CaseStatus/CaseStatus.css';
import { AccordionContainer } from 'pages/Patient/PatientDetail/PatientDetail.css';
import {
  StyledMaterialSkeleton,
  StyledMaterialTabGrid,
  StyledMaterialTabSkeleton,
} from 'pages/Patient/PatientDetail/Skeletons.css';
import { allTabs as materialsTabs } from 'pages/Patient/PatientDetail/DiagnosticMaterials';
import { BaseSkeleton } from 'components/Skeletons';

export const AvatarSkeleton = ({ isMobile = false }) => {
  const diameter = isMobile ? 48 : 96;
  return (
    <Skeleton
      animation="wave"
      variant="circle"
      width={diameter}
      height={diameter}
    />
  );
};
export const PatientNameSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={184} height={34} />
);

export const PatientIdSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={119} height={20} />
);

export const AttributeLabelSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={104} height={34} />
);

export const AttributeIconSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={20} height={34} />
);

export const AttributeValueSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={236} height={34} />
);

export const CaseStatusSkeleton = () => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="space-between"
  >
    <Grid item>
      <BaseSkeleton />
    </Grid>
    <Skeleton animation="wave" variant="text" width={148} height={66} />
  </Grid>
);

export const IncompleteSubmisionSkeleton = () => (
  <Container>
    <CaseStatusContainer>
      <CaseStatusSkeleton />
    </CaseStatusContainer>
  </Container>
);

export const CaseTrackerSkeleton = () => (
  <AccordionContainer visible>
    <BaseSkeleton isReversed />
  </AccordionContainer>
);

export const MaterialsSkeleton = () => (
  <StyledMaterialSkeleton visible>
    <BaseSkeleton isReversed />
    <StyledMaterialTabGrid container direction="row">
      {materialsTabs.map(() => (
        <StyledMaterialTabSkeleton
          animation="wave"
          variant="text"
          width={80}
          height={36}
        />
      ))}
    </StyledMaterialTabGrid>
  </StyledMaterialSkeleton>
);
