import styled from 'styled-components/macro';
import { Button, Text } from '@candidco/enamel';
import ICLeftArrowSVG from 'assets/ic-arrow-left.svg?react';
import { PageSection } from 'styles/layout.css';

export const PageWrapper = styled(PageSection)`
  padding-top: 1rem;
`;

export const BackButton = styled(Text)`
  color: ${({ theme }) => theme.colors.blue50};
  cursor: pointer;
  padding-bottom: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const LeftArrow = styled(ICLeftArrowSVG)`
  padding-bottom: 0.25rem;
`;

export const BottomPanel = styled.div`
  border-top: ${({ theme }) => theme.borders.default};
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
`;

export const SmallButton = styled(Button).attrs({
  buttonType: 'secondary',
  isShort: true,
})`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
`;

export const StyledAlertCardContainer = styled.div`
  margin-bottom: 16px;
  > div {
    font-size: 14px;
  }
`;

export const BottonPannelText = styled.span`
  margin-right: 1.5rem;
  color: ${({ theme }) => theme.colors.text70};
`;

export const MaterialBannerSubmitedDate = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: -6px;
  color: ${({ theme }) => theme.colors.black80};
`;

export const MaterialBannerWrapper = styled.div`
  margin-bottom: 20px;
`;
