import ArrowSVG from 'assets/arrow-right-line.svg?react';
import styled from 'styled-components/macro';
import { colors, Button, type } from '@candidco/enamel';

export const Wrapper = styled.div`
  position: relative;
`;

export const HistorySection = styled.section`
  padding-top: 2rem;
  margin-top: 2.5rem;
  border-top: ${({ theme }) => theme.borders.default};
`;

export const NotApplicableContainer = styled.div`
  display: block;
  padding: 2rem;
  margin-top: 2rem;
  background: white;
  border: 1px solid ${colors.border};
  border-radius: 0.1875rem;

  p {
    margin-bottom: 0;
  }
`;

export const SectionHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

export const ReopenLink = styled.a`
  font-size: 0.875rem;
  line-height: 1.7;
  color: ${colors.text90};
  text-decoration: underline;
  &:hover {
    color: ${colors.text90};
  }
`;

export const DownloadPhotosButton = styled(Button)`
  margin-top: 2rem;
`;

export const PhotoReviewLink = styled.div`
  padding: 1rem;
`;

export const StyledArrow = styled(ArrowSVG)`
  width: 10px;
  height: auto;
  margin-top: -2px;
  margin-left: 0.25rem;
  fill: currentColor;
`;
