import styled from 'styled-components/macro';
import { Button, colors, type } from '@candidco/enamel';

import AddSVG from 'assets/plus-circle-outline.svg?react';
import {
  FormikSelectWrapper,
  FormikTextAreaWrapper,
} from 'components/FormikForms';

export const FormContainer = styled.form<{ isConfirmView?: boolean }>`
  width: ${({ isConfirmView }) => (isConfirmView ? '23rem' : '40rem')};
  padding: 1rem;
  > div:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const QuestionTypeSelect = styled(FormikSelectWrapper)`
  width: 17.5rem;
`;

export const TextArea = styled(FormikTextAreaWrapper)`
  textarea {
    height: 5rem;
  }
`;

export const ConditionsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width: 24.5rem;
  margin-bottom: 1rem;
`;

export const ChoiceHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 14px;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 8px;
`;

export const ChoiceRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 14px;
  grid-gap: 1rem;
  row-gap: 100px;
  align-items: center;
  padding-bottom: 8px;
`;

export const DeleteButton = styled.button`
  width: 20px;
  height: 20px;
  vertical-align: top;
`;

export const AddAnotherButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 4px 0px 0px;
  gap: 8px;
  > div {
    color: ${colors.blue50};
    line-height: 150%;
  }
`;

export const PlusIcon = styled(AddSVG)`
  width: 20px;
  height 20px;
  > path {
    fill: ${colors.blue50};
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  justify-content: center;
`;

export const PrimaryButtons = styled.div`
  margin-left: auto;
`;

export const TextButton = styled.button<{ isDelete?: boolean }>`
  font-size: ${type.size.t1};
  color: ${({ isDelete }) => isDelete && colors.red50};
  text-decoration: underline;
  margin-bottom: 1rem;
`;

export const SaveButton = styled(Button)`
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-left: 1rem;
`;
