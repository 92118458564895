import React, { useMemo } from 'react';
import { theme } from '@candidco/enamel';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import ReactQuill, { ReactQuillProps, Quill } from 'react-quill';
import styled from 'styled-components/macro';

const BlockEmbed = Quill.import('blots/block/embed');
class VideoBlot extends BlockEmbed {
  static create(url: string) {
    const node = super.create();
    node.setAttribute('src', url);
    // Set non-format related attributes with static values
    node.setAttribute('frameborder', '0');
    node.setAttribute('allowfullscreen', true);
    node.setAttribute('controls', true);
    return node;
  }

  static formats(node: any) {
    // We still need to report unregistered embed formats
    const format: any = {};
    if (node.hasAttribute('height')) {
      format.height = node.getAttribute('height');
    }
    if (node.hasAttribute('width')) {
      format.width = node.getAttribute('width');
    }
    return format;
  }

  static value(node: any) {
    return node.getAttribute('src');
  }

  format(name: string, value: string) {
    // Handle unregistered embed formats
    if (name === 'height' || name === 'width') {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name, value);
      }
    } else {
      super.format(name, value);
    }
  }
}
VideoBlot.blotName = 'raw_video';
VideoBlot.tagName = 'video';
Quill.register(VideoBlot);

type RichTextEditorProps = {
  content?: string | undefined;
  onChange: (value: string) => void;
} & ReactQuillProps;

//TODO: hardcoding for now, but we may want to be smarter about this. Whatever is rendering this is going to have their own styles,
//and should be passing that in.
const StyledEditor = styled(ReactQuill)`
  .ql-editor {
    min-height: ${(props) => (props.theme === 'bubble' ? 'unset' : '140px')};
    color: ${(props) =>
      props.theme === 'bubble' ? theme.colors.text70 : 'unset'};
    max-height: 480px;
    font-size: 16px;
  }

  iframe {
    aspect-ratio: 16/9;
    width: 100%;
  }
`;

const RichTextEditor = ({
  content,
  onChange,
  ...props
}: RichTextEditorProps) => {
  const quillRef = React.useRef<ReactQuill>(null);

  const modulesNoS3Upload = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'video'],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );
  return (
    <>
      <StyledEditor
        {...props}
        value={content}
        onChange={(s) => {
          onChange(s);
        }}
        modules={modulesNoS3Upload}
        ref={quillRef}
      />
    </>
  );
};

export default RichTextEditor;
