import React from 'react';
import { Loading } from '@candidco/enamel';
import { useSelector } from 'react-redux';
import { getSelectedPractice } from 'pages/PracticeManagement/shared/slice';
import ContractRateTable from 'pages/PracticeManagement/Pricing/ContractRatesTable';

const ContractRates = () => {
  const selectedPractice = useSelector(getSelectedPractice);

  if (!selectedPractice) {
    return <Loading />;
  }
  return (
    <ContractRateTable
      practiceId={Number(selectedPractice.id)}
      accountId={Number(selectedPractice.account.id)}
    />
  );
};

export default ContractRates;
