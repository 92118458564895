import styled from 'styled-components/macro';
import { Box, Paper, Popover, Chip, theme } from '@candidco/enamel';
import { Link } from 'react-router-dom';

export const MenuButton = styled(Box)`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  &:hover {
    background-color: ${theme.colors.black10};
  }
`;

export const LoyaltyButton = styled.div`
  display: table;
  flex-direction: row;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  background-color: ${theme.colors.black05};
  width: 100%;
  margin-top: 1rem;
  line-height: 1rem;
  align-items: center;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.text20};
  border-radius: 4px;

  &:hover {
    background-color: ${theme.colors.blue05};
    border-color: ${theme.colors.blue50};
  }
`;

export const LoyaltyLink = styled(Link)`
  width: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 18px;
    width: 18px;

    path {
      stroke-width: 1;
    }
  }
`;

export const LoyaltyMultiPracticeLinkContent = styled.div`
  color: ${({ theme }) => theme.colors.black90};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const ClearChip = styled(Chip)`
  ${({ theme }) => `
            &.MuiChip-root {
                background-color: transparent;
                font-size: ${theme.fontSizes.small};
                font-weight: ${theme.fontWeights.default};
                height:auto;
                padding-top:0;
            }
            & .MuiChip-label{
              font-weight:500;
              line-height:150%;
            }

            float:left;
            
            img {
                margin-bottom: 2px;
                height: 12px;
                width: 12px;
            }
        `}
`;

export const PriceSection = styled.div`
  margin-right: 0.25rem;
  margin-left: auto;

  span {
    float: right;
    clear: right;
    display: block;
  }
`;

export const PriceSpan = styled.span`
  font-size: 14px;
  color: ${theme.colors.black90};
`;

export const PriceTextSpan = styled.span`
  font-size: 12px;
  color: ${theme.colors.black70};
`;

export const StyledPaper = styled(Paper)`
  padding: 0.5rem 1.5rem;
  min-width: 240px;
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

export const UserName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const UserEmail = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${theme.colors.black70};
`;

export const MenuBody = styled.div`
  margin-top: 1.5rem;
`;

export const UserMenuItemContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  &:hover {
    svg {
      stroke: ${theme.colors.blue50};
    }
    div {
      color: ${theme.colors.blue50};
    }
  }
`;

export const MenuItemLabel = styled.div`
  margin-left: 1rem;
  color: ${theme.colors.black90};
  font-size: ${({ theme }) => theme.fontSizes[2]};
`;

export const MobileMenuContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    cursor: pointer;
  }
`;
export const DesktopMenuContainer = styled.div`
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const DesktopPopover = styled(Popover)`
  display: flex;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;
