import {
  CANDID_BRAND_NAME,
  GLIDEWELL_BRAND_NAME,
  GLIDEWELL_URL,
  BRAND_SUPPORTED_FEATURES,
  BRAND_DOMAIN_SETTINGS,
} from 'constants/brands';

export const getBrandDomainSettings = () => {
  const brand = window.location.hostname.includes(GLIDEWELL_URL)
    ? GLIDEWELL_BRAND_NAME
    : CANDID_BRAND_NAME;
  return BRAND_DOMAIN_SETTINGS[brand];
};

export const getBrandSupportedFeatures = (brandName: string) => {
  return BRAND_SUPPORTED_FEATURES[brandName];
};
