import { Button, colors, type } from '@candidco/enamel';
import styled, { css } from 'styled-components';
import { HRDividerLine } from 'styles/layout.css';
import CheckIcon from 'assets/check-blue.svg?react';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2rem;

  > div {
    font-weight: ${type.weight.bold};
    font-size: ${type.size.medium};
    line-height: 20px;

    &:last-child {
      color: ${colors.black70};
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  gap: 1.5rem;
`;

export const StyledDivider = styled(HRDividerLine)`
  margin: 0rem;
`;

export const StyledModal = styled.div`
  @media ${({ theme }) => theme.mediaQueries.isTabletAndAbove} {
    width: 480px;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

export const CancelButton = styled(Button)`
  color: ${colors.black70};
  padding: 0px;

  &:hover {
    background: ${colors.white};
  }
`;

export const Disclaimer = styled.div`
  color: ${({ theme }) => theme.colors.black70};
  font-family: Modern Era;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  padding-top: 24px;
`;

export const ContactSupport = styled.a`
  color: ${({ theme }) => theme.colors.black70};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.black70};
    text-decoration: underline;
  }
`;

export const OptionQuestion = styled.div`
  padding-bottom: 24px;
`;

export const ModalTitle = styled(type.H4)`
  color: ${({ theme }) => theme.colors.black90};
  margin: 0;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const Message = styled.span<{ error?: boolean }>`
  ${({ error }) =>
    error
      ? css`
          color: red; // Color for error messages
        `
      : css`
          color: blue; // Default color for non-error (info) messages
        `}
`;

export const Check = styled(CheckIcon)`
  margin-right: 0.5rem;
`;
