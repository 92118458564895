import { FormSection } from 'generated/core/graphql';
import { FormWrapper } from 'pages/Patient/CaseCreator/TreatmentGoals/TreatmentGoals.css';
import { Formik, FormikValues } from 'formik';
import React from 'react';
import { FormQuestions } from 'components/FormikForms/FormQuestions';

type TreatmentGoalFormProps = {
  formToComplete: FormSection[];
  initialValues: FormikValues;
  defaultValues: FormikValues;
  skipValidation: boolean;
  stepTitle: string;
  formRef: any;
  onSubmit: (values: FormikValues) => void;
  onValuesChanged: (values: FormikValues) => void;
  disabled?: boolean;
};

export const TreatmentGoalForm = ({
  formToComplete,
  initialValues,
  defaultValues,
  skipValidation,
  stepTitle,
  formRef,
  onSubmit,
  onValuesChanged,
  disabled = false,
}: TreatmentGoalFormProps) => {
  return (
    <FormWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        <FormQuestions
          onValuesChanged={onValuesChanged}
          sections={formToComplete}
          skipValidation={skipValidation}
          stepTitle={stepTitle}
          disabled={disabled}
          defaultValues={defaultValues}
        />
      </Formik>
    </FormWrapper>
  );
};
