import React from 'react';
import styled from 'styled-components/macro';
import { IntakeAnswer, IntakeSection } from 'generated/core/graphql';
import { legacyProtocolLabelMap } from 'constants/Material';
import { Spacer } from 'styles/layout.css';
import { renderRichTextAsHtml } from 'utils/RichText';
import { Divider } from '@candidco/enamel';
import ToothChart from 'components/ToothChart/ToothChart';
import { QuestionTypes } from 'generated/core/graphql';

const Explanation = styled.div`
  margin-bottom: 1rem;
`;

const QuestionText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const SurveySectionContainer = styled.div`
  margin-bottom: 1rem;
  width: 60%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

const ToothChartWrapper = styled.div`
  margin-top: 1rem;
`;

type SurveySectionProps = {
  section: IntakeSection;
  displayDivider: boolean;
};

type TreatmentGoalProps = {
  intakeAnswers?: Array<IntakeAnswer>;
  label: string;
};

const TreatmentGoal = ({ intakeAnswers, label }: TreatmentGoalProps) => {
  const displayProtocol = label === 'CandidRx' || label === 'CandidAirway';

  const renderToothChartResponse = (chartAnswers: string[]) => {
    if (!chartAnswers.length) {
      return <>None selected</>;
    } else {
      return (
        <ToothChartWrapper>
          <ToothChart
            values={chartAnswers?.map((e: string) => parseInt(e)) ?? []}
            readOnly={true}
          />
        </ToothChartWrapper>
      );
    }
  };

  return (
    <div key="treatment-goal">
      {displayProtocol && (
        <Spacer isVertical spacing=".25rem">
          <QuestionText>Design protocol</QuestionText>
          <Explanation>{legacyProtocolLabelMap[label]}</Explanation>
        </Spacer>
      )}
      {intakeAnswers?.map((intakeAnswer, index) => {
        // Check for question keys for legacy tooth charts that are not storing the question type
        const displayToothChart =
          intakeAnswer.questionKey === 'tooth chart' ||
          intakeAnswer.questionKey === 'virtual_extractions' ||
          intakeAnswer.questionType === QuestionTypes.ToothChart;

        return (
          <Spacer key={index} isVertical spacing=".25rem">
            <QuestionText>
              {intakeAnswer.question
                ? renderRichTextAsHtml(intakeAnswer.question)
                : ''}
            </QuestionText>
            <>
              <Explanation>
                {displayToothChart
                  ? renderToothChartResponse(intakeAnswer?.listAnswer ?? [])
                  : getAnswerText(intakeAnswer)}
              </Explanation>
            </>
          </Spacer>
        );
      })}
    </div>
  );
};

const getAnswerText = (intakeAnswer: IntakeAnswer) => {
  // intakeAnswer.explanation and intakeAnswer.answer are mutually exclusive
  // so this function can get the answer despite if it is yes/no or explanation.
  switch (intakeAnswer.answer) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    case null:
      return intakeAnswer.explanation ?? '';
    default:
      return '';
  }
};

const SurveySection = ({
  section: { label, answers },
  displayDivider,
}: SurveySectionProps) => {
  return (
    <SurveySectionContainer>
      <TreatmentGoal intakeAnswers={answers} label={label} />
      {displayDivider && <Divider />}
    </SurveySectionContainer>
  );
};

export default SurveySection;
