import React from 'react';
import moment from 'moment';
import { AlertCard, Avatar } from '@candidco/enamel';

import { renderRichTextAsHtml } from 'utils/RichText';
import {
  AlertContainer,
  Container,
  DrName,
  BioLink,
  HeaderRow,
  Header,
  Date,
  Title,
} from 'components/OrthoNote.css';
import {
  getOrthoAvatar,
  getOrthoName,
  getOrthoBioUrl,
} from 'utils/orthoHelper';
import { MaterialEvaluation } from 'generated/core/graphql';
type OrthoNoteProps = {
  evaluation: MaterialEvaluation;
};

const OrthoNote = ({ evaluation }: OrthoNoteProps) => {
  const parsedData = evaluation?.data?.evaluationNotes;
  const email = evaluation?.createdByEmail ?? '';

  const displayDate = moment(evaluation?.createdAt).format('MMM D, YYYY');

  const getNoteText = (): string => {
    let note: string = parsedData ?? '';

    note = note.replace(
      'During treatment planning, the CandidPro treatment planning team considered the following issues:',
      '**During treatment planning, the CandidPro treatment planning team considered the following issues:**\n<br/>'
    );

    note = note.replace(
      'To address those issues, the CandidPro treatment planning team took the following approach:',
      '\n<br/><br/>**To address those issues, the CandidPro treatment planning team took the following approach:**\n<br/>'
    );
    return note;
  };

  const getAvatarContent = () => {
    const image = getOrthoAvatar(email);
    return image ? (
      <img src={image} alt="Avatar" />
    ) : (
      getOrthoName(email).toLocaleUpperCase()[0]
    );
  };

  const getOrthoBioContent = () => {
    const url = getOrthoBioUrl(email);
    if (url) {
      return (
        <BioLink href={url} target="_blank">
          View doctor bio
        </BioLink>
      );
    }
    return null;
  };

  return (
    <Container>
      <Title>Orthodontist comments</Title>
      <AlertCard type="info">
        <AlertContainer>
          <HeaderRow>
            <Header>
              <Avatar>{getAvatarContent()}</Avatar>
              <div>
                <DrName>{getOrthoName(email)}</DrName>
                <Date>Sent on {displayDate}</Date>
              </div>
            </Header>

            {getOrthoBioContent()}
          </HeaderRow>

          <div data-testid="ortho-notes-container">
            {renderRichTextAsHtml(getNoteText())}
          </div>
        </AlertContainer>
      </AlertCard>
    </Container>
  );
};

export default OrthoNote;
