import styled from 'styled-components/macro';
import { Button } from '@candidco/enamel';

export const ModalBody = styled.div`
  max-width: 480px;
  padding: 32px;
`;
export const Title = styled.div`
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Body = styled.div`
  color: ${({ theme }) => theme.colors.black90};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 32px;
`;

export const ExitNoSubmitButton = styled(Button)`
  color: ${({ theme }) => theme.colors.text70};

  :hover {
    background-color: transparent;
  }
`;
