import React, { useCallback, useMemo } from 'react';
import { type } from '@candidco/enamel';

import {
  Maybe,
  PhotoFormattingtRejectionReasonType,
  PhotoQualityRejectionReasonType,
  PhotoTypeType,
  RejectionReason,
} from 'generated/legacy/graphql';

import { useIsLoading } from 'state/system';
import PrismPhotoReview from 'components/PrismPhotoReview';
import LeftArrowSVG from 'assets/arrow-left.svg?react';
import RightArrowSVG from 'assets/arrow-right.svg?react';
import { NavButton, NavButtons, PhotoNav, BackButton } from 'styles/layout.css';

import { PhotoData, PhotoReasonsType } from 'pages/OrthoPrism/types';
import { transitionPhoto } from 'pages/OrthoPrism/orthoSlice';

type PhotoReasonsList = Array<
  PhotoFormattingtRejectionReasonType | PhotoQualityRejectionReasonType
>;

type Props = {
  isSubmissionComplete?: boolean;
  photoData?: PhotoData;
  photoReasonsType: Maybe<PhotoReasonsType>;
  photoTypes: PhotoTypeType[];
  setPhotoState: (
    isRejected: boolean,
    rejectionReasons?: RejectionReason[]
  ) => void;
  setView: (view: string) => void;
  showOverview: () => void;
  view: string;
};

const PhotoDetail = ({
  isSubmissionComplete,
  photoData,
  photoReasonsType,
  photoTypes,
  setPhotoState,
  setView,
  showOverview,
  view,
}: Props) => {
  const isTransitioningPhoto = useIsLoading(transitionPhoto.typePrefix);
  const currIndex = useMemo(
    () => photoTypes.findIndex(({ name }) => name === view) || 0,
    [photoTypes, view]
  );
  const viewLabel = photoTypes[currIndex]?.label;

  const currentRejectionReasons: RejectionReason[] = useMemo(() => {
    const {
      photoFormattingRejectionReasons = [],
      photoQualityRejectionReasons = [],
    } = photoTypes.find(({ name }) => name === view) ?? {};
    let reasons: PhotoReasonsList = [];

    if (photoReasonsType === PhotoReasonsType.Formatting) {
      reasons = photoFormattingRejectionReasons;
    }
    if (photoReasonsType === PhotoReasonsType.Quality) {
      reasons = photoQualityRejectionReasons;
    }

    return reasons.map(({ name, label }) => ({
      name,
      label,
    }));
  }, [view, photoTypes, photoReasonsType]);

  const getPrevView = useCallback(
    () =>
      (photoTypes[currIndex - 1] ?? photoTypes[photoTypes.length - 1])?.name ??
      '',
    [photoTypes, currIndex]
  );

  const getNextView = useCallback(
    () => (photoTypes[currIndex + 1] ?? photoTypes[0])?.name ?? '',
    [photoTypes, currIndex]
  );

  return (
    <>
      <PhotoNav>
        <BackButton
          onClick={showOverview}
          type="button"
          data-testid="OrthoPrism-PhotoDetail-Back"
        >
          <LeftArrowSVG aria-hidden />
          Back
        </BackButton>
        <type.H3>{viewLabel}</type.H3>
        <NavButtons>
          <NavButton
            onClick={() => setView(getPrevView())}
            type="button"
            data-testid="OrthoPrism-PhotoDetail-Prev"
          >
            <LeftArrowSVG role="img" />
          </NavButton>
          <NavButton
            onClick={() => setView(getNextView())}
            type="button"
            data-testid="OrthoPrism-PhotoDetail-Next"
          >
            <RightArrowSVG role="img" />
          </NavButton>
        </NavButtons>
      </PhotoNav>
      <PrismPhotoReview
        isEditingEnabled={true}
        canReview={!!photoReasonsType}
        isOrtho
        isPrpCompleted={isSubmissionComplete}
        isTransitioningPhoto={isTransitioningPhoto}
        handleApproval={() => {
          setPhotoState(false);
          setView(getNextView());
        }}
        handleRejection={(reasons) => {
          setPhotoState(true, reasons);
          setView(getNextView());
        }}
        orthoRejected={photoData?.state.isRejected}
        photo={photoData?.photo}
        rejectionReasons={currentRejectionReasons}
        viewName={view}
        enableRejection={true}
      />
    </>
  );
};

export default PhotoDetail;
