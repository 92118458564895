import styled from 'styled-components/macro';
import { Paper } from '@candidco/enamel';

export const StyledPaper = styled(Paper)`
  padding: 1.2rem;
  max-width: 400px;
`;

export const DefinitionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const TitleAndDefinitionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  -webkit-margin-before: 0.5rem;
`;

export const ColoredBullet = styled.li`
  color: ${(props) => props.color};
  line-height: ${({ theme }) => theme.lineHeights.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: left;
  ${({ theme }) => theme.text.headings.h2};
  -webkit-margin-end: -0.8rem;
`;

export const DefinitionDescription = styled.div`
  color: ${({ theme }) => theme.colors.black80};
  padding-bottom: 1em;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.default};
  text-align: left;
`;

export const PopoverTitle = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black90};
  line-height: ${({ theme }) => theme.lineHeights.bodySmall};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
