import React from 'react';

import {
  FormikDatePicker,
  FormikOption,
  FormikRadioGroup,
  FormikToothChart,
} from 'components/FormikForms';
import { yesNoOptions } from 'components/FormikForms/utils';
import { HelpTooltip } from 'components/HelpTooltip';
import {
  FormQuestion,
  FormMetaChoices,
  QuestionTypes,
} from 'generated/core/graphql';

import { Question as StyledQuestionLabel } from 'styles/layout.css';
import { renderRichTextAsHtml } from 'utils/RichText';
import { FormikValues } from 'formik';

const QuestionLabel = ({
  question,
  tooltip,
  isRequired,
}: {
  question: string;
  tooltip?: string | null;
  isRequired: boolean;
}) => {
  return (
    <StyledQuestionLabel>
      {renderRichTextAsHtml(question!)}
      {isRequired && '*'}
      {tooltip && <HelpTooltip content={tooltip} />}
    </StyledQuestionLabel>
  );
};

const QuestionField = ({
  question,
  disabled,
  validator,
  answerFieldName,
  explanationFieldName,
  listAnswerFieldName,
  defaultValues = {},
}: {
  question: FormQuestion;
  disabled?: boolean;
  validator?: (arg0: string) => string | undefined;
  answerFieldName: string;
  explanationFieldName: string;
  listAnswerFieldName: string;
  defaultValues?: FormikValues;
}) => {
  const convertChoicesToFormikOptions = (
    choices: FormMetaChoices[]
  ): FormikOption<string>[] => {
    if (!choices) {
      return [];
    }
    return choices.map((c) => {
      return {
        displayValue: c.choice!,
        value: c.choice!,
      };
    });
  };

  switch (question.questionType) {
    case QuestionTypes.Boolean: {
      return (
        <FormikRadioGroup
          type="radio"
          options={yesNoOptions}
          name={answerFieldName}
          validate={validator}
          disabled={disabled}
          defaultAnswer={defaultValues[answerFieldName]}
        />
      );
    }
    case QuestionTypes.Date: {
      return (
        <FormikDatePicker
          name={explanationFieldName}
          validate={validator}
          disabled={disabled}
          type="text"
        />
      );
    }
    case QuestionTypes.Choice: {
      return (
        <FormikRadioGroup
          type="radio"
          options={convertChoicesToFormikOptions(
            question.meta?.choices as FormMetaChoices[]
          )}
          name={explanationFieldName}
          validate={validator}
          disabled={disabled}
          defaultAnswer={defaultValues[explanationFieldName]}
        />
      );
    }
    case QuestionTypes.ToothChart: {
      return (
        <FormikToothChart name={listAnswerFieldName} disabled={disabled} />
      );
    }
    default:
      return null;
  }
};

const Question = (props: {
  question: FormQuestion;
  disabled?: boolean;
  validator?: (arg0: string) => string | undefined;
  answerFieldName: string;
  explanationFieldName: string;
  listAnswerFieldName: string;
  defaultValues?: FormikValues;
}) => {
  const { question } = props;

  return (
    <>
      <QuestionLabel
        question={question.question}
        tooltip={question.tooltip}
        isRequired={question.isRequired ?? true}
      />
      {question.questionType && <QuestionField {...props} />}
    </>
  );
};

export default Question;
