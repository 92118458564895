import styled from 'styled-components/macro';
import { Button, colors, mediaQueries } from '@candidco/enamel';

const FOOTER_HEIGHT = '76px';
export const PROGRESSBAR_HEIGHT = '6px';

export const Spacer = styled.div`
  height: ${FOOTER_HEIGHT};
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: ${FOOTER_HEIGHT};
  padding: 0 2rem;
  background-color: white;
  border-top: solid 1px ${colors.black20};
`;

export const ProgressBarWrapper = styled.div`
  position: fixed;
  bottom: ${FOOTER_HEIGHT};
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 6px;
  background-color: white;
  border-top: solid 1px ${colors.black20};
`;

export const StyledButton = styled(Button)`
  .right-icon {
    svg {
      path {
        fill: ${({ disabled }) => (disabled ? colors.black50 : colors.white)};
        stroke: none;
      }
    }
  }
  width: 100%;

  @media ${mediaQueries.tabletAndAbove} {
    width: auto;
    margin-left: 24px;
    white-space: nowrap;
  }
`;

export const SavingSection = styled.div`
  display: none;

  @media ${mediaQueries.tabletAndAbove} {
    display: flex;

    align-items: center;
    justify-content: center;
    color: ${colors.black70};
    svg {
      path {
        stroke: ${colors.black70};
      }
    }
  }
`;
export const LoadingWrapper = styled.div`
  color: ${colors.blue50};
`;

export const StyledSavingText = styled.span`
  margin-left: 8px;
`;

export const Messages = styled.div`
  display: none;

  @media ${mediaQueries.tabletAndAbove} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${colors.black70};
`;

export const Message = styled.span`
  display: none;
  @media ${mediaQueries.tabletAndAbove} {
    display: block;
  }
`;

export const LeftFloatingMessage = styled.div`
  display: none;

  @media ${mediaQueries.tabletAndAbove} {
    position: absolute;
    top: -${PROGRESSBAR_HEIGHT};
    display: block;
    padding: 16px 20px 10px 0;
    background-color: ${colors.white};
    transform: translateY(calc(-100% - 1px));
    width: 100%;
  }
`;
