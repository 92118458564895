import React, { useMemo } from 'react';
import Dinero from 'dinero.js';
import { HRDividerLine } from 'styles/layout.css';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  TotalContainer,
  ItemContainer,
  PriceInfoContainer,
  Total,
  LineItemContainer,
  TaxInfo,
  BulletedLineItem,
  PopoverMessage,
  DiscountDescription,
  DiscountAmount,
  TotalPrice,
} from 'pages/Patient/Checkout/TotalBox.css';
import { Popover } from '@candidco/enamel';
import { Discount, Maybe } from 'generated/core/graphql';
import { CatalogItemType, PartialLineItem } from 'pages/Patient/Checkout/types';
import { findCachedLineItem } from 'pages/Patient/Checkout/utils';

const TotalSummary = ({
  totalQuantity,
  subtotal,
}: {
  totalQuantity: number;
  subtotal: number;
}) => (
  <PriceInfoContainer>
    <LineItemContainer>
      <Total>Total</Total>
      <TaxInfo>
        <BulletedLineItem>Tax not included</BulletedLineItem>
        <Popover
          on="hover"
          direction="top"
          anchor={<InfoIcon fontSize="small" />}
        >
          <PopoverMessage>
            Your state taxes will be added to your invoice
          </PopoverMessage>
        </Popover>
      </TaxInfo>
    </LineItemContainer>
    <div>
      {totalQuantity ? (
        <TotalPrice>{Dinero({ amount: subtotal }).toFormat()}</TotalPrice>
      ) : (
        '—'
      )}
    </div>
  </PriceInfoContainer>
);

const LineItemDiscount = ({ discount }: { discount: Discount }) => (
  <PriceInfoContainer>
    <DiscountDescription>{discount.description}</DiscountDescription>
    <DiscountAmount>
      -{Dinero({ amount: discount.reductionInCents }).toFormat()}
    </DiscountAmount>
  </PriceInfoContainer>
);

const TotalsLineItem = ({
  item,
  lineItemDiscountsCache,
}: {
  item: CatalogItemType;
  lineItemDiscountsCache: PartialLineItem[];
}) => {
  const priceDisplay =
    item.quantity > 0
      ? item.totalPriceBeforeDiscounts.toFormat()
      : 'Select quantity above';
  const discountedQuantity = Dinero({
    amount:
      item.totalPriceBeforeDiscounts.getAmount() -
      item.totalPriceAfterDiscounts.getAmount(),
  });

  const lineItemWithDiscount = findCachedLineItem(
    item.sku,
    item.quantity,
    lineItemDiscountsCache
  );

  return (
    <>
      <PriceInfoContainer>
        <LineItemContainer>
          <ItemContainer>{item.providerFacingProductName}</ItemContainer>
          <ItemContainer>
            <BulletedLineItem>{priceDisplay}</BulletedLineItem>
          </ItemContainer>
        </LineItemContainer>
        <div>{item.quantity ? priceDisplay : '—'}</div>
      </PriceInfoContainer>
      {discountedQuantity.getAmount() > 0 && (
        <>
          {lineItemWithDiscount?.discounts?.map(
            (discount: Maybe<Discount>, index: number) =>
              discount && <LineItemDiscount discount={discount} key={index} />
          )}
        </>
      )}
    </>
  );
};

const TotalBox = ({
  orderItems,
  lineItemDiscountsCache,
}: {
  orderItems: CatalogItemType[];
  lineItemDiscountsCache: PartialLineItem[];
}) => {
  // subtotal and totalQuantity update when the quantity updates on the order item
  const subtotal = useMemo(() => {
    return orderItems.reduce(
      (total, item) => total + item.totalPriceAfterDiscounts.getAmount(),
      0
    );
  }, [orderItems]);

  const totalQuantity = useMemo(() => {
    return orderItems.reduce((acc, item) => acc + item.quantity, 0);
  }, [orderItems]);

  return (
    <TotalContainer>
      {orderItems.map((item: CatalogItemType, index) => (
        <TotalsLineItem
          key={index}
          item={item}
          lineItemDiscountsCache={lineItemDiscountsCache}
        />
      ))}
      <HRDividerLine spacing="0.5rem" mobileSpacing="0.25rem" />
      <TotalSummary totalQuantity={totalQuantity} subtotal={subtotal} />
    </TotalContainer>
  );
};

export default TotalBox;
