import React, { useState } from 'react';
import { Button, Divider, Modal, theme } from '@candidco/enamel';
import {
  HeaderText,
  CancelButton,
  Content,
  ButtonRow,
  ModalContainer,
  RadioGroup,
  RadioLabel,
  Radio,
  Explanation,
  AlertContainer,
  StyledAlertCard,
} from 'components/Modals/ArchiveCaseModal.css';

type ArchiveCaseModalProps = {
  isOpen: boolean;
  inPlanning: boolean;
  onClose: () => void;
  onConfirm: (archiveReason: string) => void;
};

const ArchiveReasons = [
  'This was a test patient',
  'I used a different aligner product',
  'I used a different non-aligner product',
  'My patient is no longer interested in treatment',
  'Candid team advised me not to move forward',
  'Other',
];

const ArchiveCaseModal = ({
  isOpen,
  inPlanning,
  onClose,
  onConfirm,
}: ArchiveCaseModalProps) => {
  const [archiveReason, setArchiveReason] = useState<string>('');
  const [otherExplanation, setOtherExplanation] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherExplanation(event.target.value);
  };

  const submitForm = () => {
    let reason = archiveReason;
    if (reason === 'Other' && otherExplanation) {
      reason = `${reason}: ${otherExplanation}`;
    }
    onConfirm(reason);
  };

  return (
    <Modal
      hideCloseButton={true}
      onClose={onClose}
      isOpen={isOpen}
      overlayColor={theme.colors.text50}
    >
      <ModalContainer>
        <HeaderText>Archive this case</HeaderText>
        <Divider />
        {inPlanning && (
          <AlertContainer>
            <StyledAlertCard
              type="warning"
              displayIcon={false}
              header="Are you sure you want to archive this case? This patient is currently in treatment planning."
            />
          </AlertContainer>
        )}
        <Content>
          <p>Select the reason for archiving</p>
          <RadioGroup>
            {ArchiveReasons?.map((reason) => (
              <RadioLabel>
                <Radio
                  name="reasons"
                  type="radio"
                  value={reason}
                  id="suggestions"
                  onClick={() => setArchiveReason(reason)}
                />
                <div>{reason}</div>
              </RadioLabel>
            ))}
          </RadioGroup>
          {archiveReason === 'Other' && (
            <Explanation
              multiline
              placeholder="Explain"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          )}
        </Content>
        <Divider />
        <ButtonRow>
          <CancelButton buttonType="text" onClick={() => onClose()}>
            Cancel
          </CancelButton>
          <Button
            buttonType="primary-outline"
            disabled={!archiveReason}
            onClick={() => submitForm()}
          >
            Archive
          </Button>
        </ButtonRow>
      </ModalContainer>
    </Modal>
  );
};

export default ArchiveCaseModal;
