import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, NotificationContext } from '@candidco/enamel';
import * as Sentry from '@sentry/react';

import {
  CaseTypes,
  CreationChannels,
  useCreateCaseMutation,
  useTransitionJourneyMutation,
} from 'generated/legacy/graphql';
import { JourneyTransition } from 'pages/Case/types';

import { ALIGNER_JOURNEY_TYPE } from 'pages/Case/CaseProvider';
import {
  StyledAlertCard,
  LabelAndInputGroup,
  StyledModal,
  StyledDivider,
  Header,
  Body,
  InputFields,
  RadioGroup,
  StyledSelectInput,
  TextArea,
  AnswerRow,
  Question,
  FreeRefinementWrapper,
  ReplacementCopy,
  MonitoringLink,
  StyledThumbsUpIcon,
} from 'components/Modals/RefinementsModal.css';
import FullPageModal from 'components/FullPageModal/FullPageModal';
import {
  selectAppliedRefinementDiscountType,
  RefinementType,
} from 'pages/Promotion/promotionsSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getBrandDomainSettings } from 'utils/brands';

type RefinementsModalProps = {
  isOpen: boolean;
  treatmentPlanSteps: number;
  caseId: string;
  caseRef: string;
  patientId: string;
  candidMonitoringLink: string;
  patientName: string;
  onSubmitted: () => void;
  onCancelClicked: () => void;
  formLink: string;
};

type Option = {
  label: string;
  value: number;
};

const INITIAL_FIT_ISSUE_STEP_LIMIT = 2;

const FreeRefinementsBanner = () => (
  <FreeRefinementWrapper>
    <StyledThumbsUpIcon />
    <div>This case qualifies for free refinements</div>
  </FreeRefinementWrapper>
);

export const RefinementsModal = ({
  isOpen,
  treatmentPlanSteps,
  caseId,
  caseRef,
  patientId,
  candidMonitoringLink,
  patientName,
  onCancelClicked,
  onSubmitted,
  formLink,
}: RefinementsModalProps) => {
  const refinementPolicyCoupon = useSelector(
    selectAppliedRefinementDiscountType
  );
  const [startedTreatment, setStartedTreatment] = useState<boolean | null>(
    null
  );
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [stepOptions, setStepOptions] = useState<Option[]>([]);
  const [initialFitIssueDescription, setInitialFitIssueDescription] =
    useState<string>('');
  const { monitoringLabel } = getBrandDomainSettings();
  const patientIsWithinIFIStepLimit =
    startedTreatment &&
    selectedStep !== null &&
    selectedStep <= INITIAL_FIT_ISSUE_STEP_LIMIT;
  const isInititalFitIssue =
    startedTreatment === false || patientIsWithinIFIStepLimit;
  const { showNotification } = useContext(NotificationContext);
  const { 'bypass-ortho-review-process': bypassOrthoReviewProcess } =
    useFlags();

  const [createRefinementsCase, { loading: isCreatingCase }] =
    useCreateCaseMutation({
      onCompleted: () => {
        onSubmitted();
      },
    });
  const isFreeRefinement =
    refinementPolicyCoupon === RefinementType.Free ||
    refinementPolicyCoupon === RefinementType.Ifi;

  const [transitionJourney, { loading: isTransistionJourneySubmitting }] =
    useTransitionJourneyMutation({
      onCompleted: async () => {
        let itiType = '';
        if (isInititalFitIssue) {
          itiType = 'initial_fit_issue';
        } else if (selectedStep && selectedStep === treatmentPlanSteps) {
          itiType = 'end_of_treatment_refinement';
        } else if (selectedStep && selectedStep < treatmentPlanSteps) {
          itiType = 'mid_course_correction';
        }

        const createCaseData = {
          itiType,
          currentStep: startedTreatment ? selectedStep : 0,
          notes: !startedTreatment ? initialFitIssueDescription : null,
        };
        try {
          await createRefinementsCase({
            variables: {
              caseType: CaseTypes.ProAdditionalAligners,
              customerId: patientId,
              journey: CreationChannels.ProProvider,
              precedingCaseId: parseInt(caseId),
              data: {
                ...createCaseData,
                isGen2: true,
                requireOrthoReview: !bypassOrthoReviewProcess,
              },
            },
          });
        } catch (err) {
          if (!(err instanceof Error)) {
            throw err;
          }

          Sentry.captureException(err.message);
          showNotification(
            'Something went wrong, please try again in a few moments. If the issue persists, please contact support',
            'error'
          );
        }
      },
    });

  const handleSubmit = () => {
    transitionJourney({
      variables: {
        caseRef: caseRef,
        component: ALIGNER_JOURNEY_TYPE,
        transition: isInititalFitIssue
          ? JourneyTransition.ForceInitialFitIssue
          : JourneyTransition.ForceAdditionalAlignersNeeded,
        transitionReason: isInititalFitIssue
          ? 'Initial fit issue'
          : 'Refinements',
      },
    });
  };

  useEffect(() => {
    setStepOptions(
      Array.from({ length: treatmentPlanSteps }).map((_val, idx) => {
        return {
          label:
            idx === treatmentPlanSteps - 1
              ? 'Step ' + (idx + 1) + ' (end of treatment)'
              : 'Step ' + (idx + 1),
          value: idx + 1,
        };
      })
    );
  }, [treatmentPlanSteps]);

  const isInitialFitFormValid =
    !startedTreatment && !!initialFitIssueDescription;
  const isStartedTreatmentFormValid =
    startedTreatment &&
    selectedStep &&
    (selectedStep > INITIAL_FIT_ISSUE_STEP_LIMIT || initialFitIssueDescription);

  const isSubmitDisabled =
    startedTreatment === null ||
    (!isInitialFitFormValid && !isStartedTreatmentFormValid);

  return (
    <FullPageModal
      isOpen={isOpen}
      patientName={patientName}
      titleText="Refinements"
      onClose={onCancelClicked}
      onContinue={handleSubmit}
      continueDisabled={isSubmitDisabled}
      footerLeftContent={isFreeRefinement && <FreeRefinementsBanner />}
      hasContinueArrow
      isLoading={isTransistionJourneySubmitting || isCreatingCase}
    >
      <StyledModal>
        <Header>Treatment progress</Header>
        <StyledDivider />
        <Body>
          <RadioGroup>
            <Question>
              Has this patient started treatment with the current set of
              aligners?
            </Question>
            <AnswerRow>
              <Button
                buttonType={
                  startedTreatment ? 'secondary' : 'secondary-outline'
                }
                onClick={() => setStartedTreatment(true)}
              >
                Yes
              </Button>
              <Button
                buttonType={
                  startedTreatment === false ? 'secondary' : 'secondary-outline'
                }
                onClick={() => setStartedTreatment(false)}
              >
                No
              </Button>
            </AnswerRow>
          </RadioGroup>
          {startedTreatment && (
            <InputFields>
              <LabelAndInputGroup>
                <Question style={{ marginBottom: 0 }}>
                  Which aligner step is this patient on?
                </Question>
                <MonitoringLink
                  href={candidMonitoringLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {`Confirm in ${monitoringLabel}`}
                </MonitoringLink>
                <StyledSelectInput
                  maxMenuHeight={200}
                  options={stepOptions}
                  onChange={(option: Option) => {
                    setSelectedStep(option.value);
                  }}
                  value={stepOptions.find((o) => o.value === selectedStep)}
                />
              </LabelAndInputGroup>
            </InputFields>
          )}
          {patientIsWithinIFIStepLimit && (
            <StyledAlertCard
              type="default"
              displayIcon
              header="If the patient is on step 1 or 2, you need to report an initial fit issue"
            />
          )}
          {isInititalFitIssue && (
            <>
              <Question>Please describe the initial fit issue</Question>
              <TextArea
                placeholder="To the best of your ability, describe the fit issue. Where is the fit the worst? Is there anything else we should know?"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setInitialFitIssueDescription(e.target.value);
                }}
                value={initialFitIssueDescription}
              />
            </>
          )}
          <ReplacementCopy>
            If you&apos;re looking for a replacement aligner,{' '}
            <a href={formLink} rel="noopener noreferrer" target="_blank">
              use this form instead.
            </a>
          </ReplacementCopy>
        </Body>
      </StyledModal>
    </FullPageModal>
  );
};
