import React from 'react';
import {
  selectXrays,
  selectXraySubmissions,
  selectLatestXrayMaterialEvaluations,
  selectSelectedCase,
} from 'pages/Patient/patientSlice';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import { useSelector } from 'react-redux';
import { Loading } from '@candidco/enamel';
import {
  TabWrapper as Wrapper,
  VersionPillsContainer,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';
import {
  HistorySection,
  HistoryWrapper,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';
import MaterialApprovedAlertCard from 'components/AlertCards/MaterialApprovedAlertCard';
import XrayGallery from 'components/XrayGallery';
import { toGalleryShape, usePatientLoadingStates } from 'pages/Patient/utils';
import MaterialSubmissionHistory from 'components/MaterialSubmissionHistory';

const XraysTab = () => {
  const xrays = useSelector(selectXrays);
  const xraySubmissions = useSelector(selectXraySubmissions);
  const latestXrayEvaluations = useSelector(
    selectLatestXrayMaterialEvaluations
  );
  const latestXrayEvaluation = latestXrayEvaluations?.[0];
  const selectedCase = useSelector(selectSelectedCase);
  const { isFetchingXrays } = usePatientLoadingStates();

  const vpProps = useVersionPills(xraySubmissions, true);

  const XrayBody = () => {
    if (isFetchingXrays) {
      return <Loading isCentered />;
    }
    const selectedSubmission = xraySubmissions[vpProps.currentIndex];
    const selectedXrays = selectedSubmission
      ? xrays.filter((xray) =>
          xray.submissions.some(
            (submission) => submission.id === selectedSubmission.id
          )
        )
      : [];

    return (
      <Wrapper>
        <VersionPillsContainer>
          <VersionPills {...vpProps} />
        </VersionPillsContainer>
        {!!latestXrayEvaluation?.approved && (
          <MaterialApprovedAlertCard
            evaluationSetId={latestXrayEvaluation.evaluationSet?.id!}
            materialName="xray"
            onReverse={() => null} //TODO Confirm if revert is needed here
            selectedCase={selectedCase!}
          />
        )}
        {!!selectedXrays?.length && (
          <XrayGallery photos={selectedXrays.map(toGalleryShape)} />
        )}
        <HistoryWrapper
          container
          alignItems="center"
          justifyContent="flex-start"
        >
          <HistorySection item>
            <MaterialSubmissionHistory
              submissions={xraySubmissions}
              isExpanded={false}
              materialName="X-rays"
            />
          </HistorySection>
        </HistoryWrapper>
      </Wrapper>
    );
  };

  return <XrayBody />;
};

export default XraysTab;
