import React from 'react';
import { useSelector } from 'react-redux';
import { theme, Loading } from '@candidco/enamel';
import CheckCircle from '@material-ui/icons/CheckCircle'; //TODO: get from enamel
import {
  ChipStyle,
  MaterialState,
  StateInfoProperties,
} from 'components/MaterialStateChip/types';
import { useIsLoading } from 'state/system/slice';

import {
  selectLastSubmission,
  selectCurrentAggregate,
  fetchIntakeForms,
  fetchPrismAggregates,
  fetchScans,
  fetchXrays,
  fetchTreatmentPlanStagings,
  selectScanMaterialState,
  selectXrayMaterialState,
  selectTreatmentGoalsState,
  selectTreatmentPlanStagingState,
} from 'pages/OrthoPrism/orthoSlice';
import { RootState } from 'state/store';
import { StyledChip } from 'components/MaterialStateChip/MaterialStateChip.css';
import { StateTransitions, MaterialStates } from 'generated/core/graphql';

const labelToStyleMap: Record<MaterialState, ChipStyle> = {
  Completed: {
    background: theme.colors.green70,
    foreground: theme.colors.white,
    variant: 'default',
  },
  Incomplete: {
    background: theme.colors.black30,
    foreground: theme.colors.text60,
    variant: 'default',
  },
  'Ready for review': {
    background: theme.colors.orange50,
    foreground: theme.colors.white,
    variant: 'default',
  },
  'Need clarification': {
    background: theme.colors.yellow70,
    foreground: theme.colors.text80,
    variant: 'default',
  },
  'Materials rejected': {
    background: theme.colors.red50,
    foreground: theme.colors.white,
    variant: 'default',
  },
  'Sent for repair': {
    background: theme.colors.white,
    foreground: theme.colors.text70,
    variant: 'outlined',
  },
  Processing: {
    background: theme.colors.white,
    foreground: theme.colors.black90,
    variant: 'outlined',
  },
};

const stateToLabelMap: Record<string, MaterialState> = {
  // this is to map the backend WorkflowState to a display label
  NOT_STARTED: 'Incomplete',
  UPLOADED: 'Incomplete',
  AWAITING_ORTHODONTIC_EVALUATION: 'Ready for review',
  AWAITING_QUALITY_CONTROL_EVALUATION: 'Ready for review',
  AWAITING_PRO_CLINICIAN_EVALUATION: 'Ready for review',
  NEEDS_CLARIFICATION: 'Need clarification',
  NEEDS_REPAIR: 'Sent for repair',
  COMPLETED: 'Completed',
  // rejected transition
  REJECT: 'Materials rejected',

  //Scans
  PROCESSING: 'Processing',

  // these are for prism
  evaluation: 'Incomplete',
  submitted: 'Ready for review',
  rejected_photos: 'Materials rejected',
  rejected_customer: 'Materials rejected',
  needs_clarification: 'Need clarification',
  approved: 'Completed',

  // fallback option
  incomplete: 'Incomplete',
};

const coreStateFetcher = (m?: StateInfoProperties | null) => {
  if (!m) {
    return null;
  }

  if (m?.transition === StateTransitions.Reject) {
    return StateTransitions.Reject;
  }
  return m?.state!;
};

const MaterialStateChip = ({
  name,
  isQc,
  caseOverview,
}: {
  name: string;
  isQc?: boolean;
  caseOverview?: string;
}) => {
  const prismAggregate = useSelector(selectCurrentAggregate);

  const materialToDataMap = {
    'Treatment goals': {
      selector: selectTreatmentGoalsState,
      fetcher: fetchIntakeForms,
      stateFetcher: coreStateFetcher,
    },
    Photos: {
      selector: selectLastSubmission,
      fetcher: fetchPrismAggregates,
      stateFetcher: (m?: StateInfoProperties | null) => {
        const state = m?.stateData?.data;
        // if photos have been rejected & resubmitted, but are still awaiting Byte Club review
        // we need to read state from the aggregate vs the submission.
        const aggregateState = prismAggregate?.stateData?.data;
        if (aggregateState === 'evaluation') {
          return 'incomplete';
        }
        return state;
      },
    },
    Scans: {
      selector: selectScanMaterialState,
      fetcher: fetchScans,
      stateFetcher: (m?: StateInfoProperties | null) => {
        if (!m) {
          return null;
        }

        if (
          m.state === MaterialStates.NotStarted &&
          (caseOverview === 'Awaiting PVS impressions' ||
            caseOverview === 'Awaiting STL export upload')
        ) {
          return 'PROCESSING';
        }

        if (m?.transition === StateTransitions.Reject) {
          return StateTransitions.Reject;
        }
        return m?.state!;
      },
    },
    'X-rays': {
      selector: selectXrayMaterialState,
      fetcher: fetchXrays,
      stateFetcher: coreStateFetcher,
    },
    Plan: {
      selector: selectTreatmentPlanStagingState,
      fetcher: fetchTreatmentPlanStagings,
      stateFetcher: (m?: StateInfoProperties | null) => {
        if (!m) {
          return null;
        }

        if (
          (!!isQc &&
            m.state === MaterialStates.AwaitingQualityControlEvaluation) ||
          (!isQc && m.state === MaterialStates.AwaitingOrthodonticEvaluation)
        ) {
          return m?.state!;
        } else {
          return null;
        }
      },
    },
  };

  const { selector, fetcher, stateFetcher } =
    materialToDataMap[name as keyof typeof materialToDataMap];

  const material = useSelector((state: RootState) => selector(state));
  const isLoading = useIsLoading(fetcher.typePrefix);
  const state = stateFetcher(material as StateInfoProperties);

  if (isLoading) {
    return <Loading />;
  }

  const labelKey = state ?? '';
  const label = stateToLabelMap[labelKey];

  if (!label) {
    return null;
  }
  const style = labelToStyleMap[label];

  if (label === 'Completed') {
    return (
      <CheckCircle style={{ fill: style.background, height: 26, width: 26 }} />
    );
  }

  return (
    <StyledChip
      variant={style.variant}
      size="small"
      label={label}
      background={style.background}
      foreground={style.foreground}
    />
  );
};

export default MaterialStateChip;
