import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AlertCard, Loading } from '@candidco/enamel';
import { Snackbar } from '@material-ui/core';
import { useIsLoading } from 'state/system/slice';
import {
  fetchScans,
  selectScans,
  selectScanSubmissions,
  selectSelectedCase,
  selectLatestScanMaterialEvaluations,
  selectScanMaterialState,
  selectSelectedCaseState,
} from 'pages/OrthoPrism/orthoSlice';
import {
  MaterialReviewFormContainer,
  MaterialReviewFormItem,
  ScanWrapper,
} from 'pages/OrthoPrism/OrthoPrism.css';
import { TabProps } from 'pages/OrthoPrism/types';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import MaterialsTable, {
  MaterialTableColumns,
} from 'components/MaterialsTable';
import MaterialSubmissionHistory from 'components/MaterialSubmissionHistory';
import MaterialReview from 'pages/OrthoPrism/MaterialReview/MaterialReview';
import { useHistory } from 'react-router-dom';
import MaterialApprovedAlertCard from 'components/AlertCards/MaterialApprovedAlertCard';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import { LinkItem } from 'pages/OrthoPrism/OrthoPrism.css';
import { MaterialStates } from 'generated/core/graphql';

const Scans = ({ refreshMaterials }: TabProps) => {
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const scans = useSelector(selectScans);
  const scanSubmissions = useSelector(selectScanSubmissions);
  const scanMaterialState = useSelector(selectScanMaterialState);
  const selectedCaseState = useSelector(selectSelectedCaseState);
  const latestScanEvaluations = useSelector(
    selectLatestScanMaterialEvaluations
  );
  const latestScanEvaluation = latestScanEvaluations?.[0];
  const selectedCase = useSelector(selectSelectedCase);
  const isLoading = useIsLoading(fetchScans.typePrefix);
  const vpProps = useVersionPills(scanSubmissions, true);
  const { push } = useHistory();

  //This should be the same for all aggregates, so just pick the first
  const caseRef = selectedCase?.caseRef!;

  //Because of 'Needs clarification` actions taken within one tab may affect any other tab
  const handleOnSubmit = (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean
  ) => {
    if (refreshMaterials) {
      refreshMaterials(reloadScans, reloadXrays, reloadPhotos, caseRef);
    }
    setReviewSubmitted(true);
    window.scrollTo(0, 0);
  };

  const handleOnReverse = () => {
    refreshMaterials && refreshMaterials(true, false, false, caseRef);
    setReviewSubmitted(true);
    window.scrollTo(0, 0);
  };

  const ScanBody = () => {
    if (isLoading) {
      return <Loading isCentered />;
    }

    if (
      !scans.length ||
      selectedCase?.caseState?.internal ===
        PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION
    ) {
      const alertHeaderText = selectedCase?.isGen2
        ? 'Scans not yet submitted'
        : '';
      const alertBodyText = selectedCase?.isGen2
        ? 'Continue submission'
        : 'Scans not yet submitted';

      const overview = selectedCaseState?.internal;
      if (overview === 'Awaiting PVS impressions') {
        return (
          <AlertCard type="default" header="PVS Processing" displayIcon>
            Our lab is either waiting to receive your PVS impressions or is
            processing them. Processing usually takes around one business day
            after our team has received the PVS impressions.
          </AlertCard>
        );
      }

      if (overview === 'Awaiting STL export upload') {
        return (
          <AlertCard
            type="default"
            header="Intraoral scan sync processing"
            displayIcon
          >
            Our lab is either waiting to receive your intraoral scans or is
            processing them. Processing usually takes around one business day
            after our team has received the intraoral scans.
          </AlertCard>
        );
      }

      return (
        <AlertCard type="warning" header={alertHeaderText} displayIcon={true}>
          {selectedCase?.isGen2 ? (
            <LinkItem
              onClick={() =>
                push(`/patient/${selectedCase?.patientId}/case-creator`)
              }
            >
              Continue submission
            </LinkItem>
          ) : (
            <>{alertBodyText}</>
          )}
        </AlertCard>
      );
    }

    const selectedSubmission = scanSubmissions[vpProps.currentIndex];
    const selectedScans = selectedSubmission
      ? scans.filter((scan) =>
          scan.submissions.some(
            (submission) => submission.id === selectedSubmission.id
          )
        )
      : [];

    const isLatestSubmission =
      selectedSubmission?.id === scanSubmissions[0]?.id;
    const showMaterialReview =
      isLatestSubmission &&
      scanMaterialState?.state ===
        MaterialStates.AwaitingOrthodonticEvaluation &&
      !!selectedScans.length;

    return (
      <ScanWrapper>
        <VersionPills {...vpProps} />
        {!!latestScanEvaluation?.approved && (
          <MaterialApprovedAlertCard
            evaluationSetId={latestScanEvaluation.evaluationSet?.id!}
            materialName="scan"
            onReverse={handleOnReverse}
            selectedCase={selectedCase!}
          />
        )}
        <MaterialsTable
          materials={selectedScans}
          excludedColumns={[MaterialTableColumns.UP_TO_DATE]}
        />
        <MaterialReviewFormContainer
          container
          direction="column"
          alignItems="center"
          spacing={0}
        >
          <MaterialReviewFormItem item xs={12}>
            {showMaterialReview && (
              <MaterialReview
                materials={selectedScans}
                materialName="scans"
                onSubmit={handleOnSubmit}
              />
            )}
          </MaterialReviewFormItem>
          <MaterialReviewFormItem item xs={12}>
            <MaterialSubmissionHistory
              submissions={scanSubmissions}
              materialName="Scans"
            />
          </MaterialReviewFormItem>
        </MaterialReviewFormContainer>
      </ScanWrapper>
    );
  };

  return (
    <>
      <Snackbar
        open={reviewSubmitted}
        message="Review submitted"
        onClose={() => setReviewSubmitted(false)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        data-testid="Scan-review-snackbar"
      />
      <ScanBody />
    </>
  );
};

export default Scans;
