import React from 'react';
import { theme } from '@candidco/enamel';
import { BrandChipProps, ChipProps } from 'components/BrandChip/types';
import { StyledChip } from 'components/BrandChip/BrandChip.css';

const BrandChip = ({ brandInfo }: BrandChipProps) => {
  const brandName = brandInfo?.name ?? 'candid_pro';

  const brandMap: Record<string, ChipProps> = {
    glidewell: {
      background: theme.colors.text70,
      foreground: 'white',
      label: 'ProMonitoring',
    },
    candid_pro: {
      background: theme.colors.red50,
      foreground: 'white',
      label: 'CandidPro',
    },
  };

  const props = brandMap[brandName];
  return <StyledChip {...props} />;
};

export default BrandChip;
