import React, { useEffect, useState, FormEvent, FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button } from '@candidco/enamel';

import {
  usePrismAggregatesSearchLazyQuery,
  AggregateType,
  Case,
  PrismAggregatesSearchQuery,
} from 'generated/legacy/graphql';
import DocumentTitle from 'components/DocumentTitle';
import { ErrorText, Input as FormInput } from 'styles/inputs.css';
import { AggregateRow, CaseHeader } from 'components/SearchPages/Rows';
import {
  PageHeader,
  PageHeaderContent,
  PageHeading,
  PageSection,
} from 'styles/layout.css';

const SmallHeading = styled.h4`
  ${({ theme }) => theme.text.headings.h4}
  margin-bottom: 1rem;
`;

const SearchForm = styled.form`
  display: flex;
`;

const Input = styled(FormInput)`
  flex: 1;
  width: 50%;
  max-width: 12rem;
  margin-right: 1rem;
`;

const SubmitButton = styled(Button)`
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const SearchResults = styled.section`
  padding-top: 5rem;
`;

const CustomerName = styled.h3`
  ${({ theme }) => theme.text.headings.h3}
  margin-bottom: 2rem;
`;

type AggregateSearchPageProps = {
  customerId?: string;
  selectFor: 'prism' | 'ortho';
  pageTitle: string;
  searchCta?: string;
};

type CaseAggregatesTuple = [Case, AggregateType[]];

const createCaseAggregates = (
  data: PrismAggregatesSearchQuery | undefined
): CaseAggregatesTuple[] => {
  const tupleArray: CaseAggregatesTuple[] = [];
  const cases = data?.customer?.cases as Case[];
  const aggregates = data?.prismAggregates;
  if (data && cases?.length && aggregates?.length) {
    cases.forEach((caseItem) => {
      const filteredAggregates = aggregates.filter(
        (aggregate) => aggregate?.caseRef === caseItem?.caseRef
      ) as AggregateType[];
      tupleArray.push([caseItem, filteredAggregates]);
    });
  }
  return tupleArray;
};

const AggregateSearchPage: FC<AggregateSearchPageProps> = ({
  customerId = '',
  pageTitle,
  selectFor = 'prism',
  searchCta = 'Search',
}) => {
  const [searchId, setSearchId] = useState(customerId);
  const [
    getPrismAggregates,
    { data: customerAggregateDataWithCases, loading: isLoading, error },
  ] = usePrismAggregatesSearchLazyQuery();
  useEffect(() => {
    if (customerId) {
      getPrismAggregates({
        variables: {
          customerId,
        },
      });
    }
  }, [customerId]);
  const { push } = useHistory();
  const { pathname } = useLocation();

  const pathBase = pathname.split('/')[1];
  const customer =
    customerAggregateDataWithCases?.prismAggregates?.[0]?.customer ?? null;
  const aggregatesByCase = createCaseAggregates(customerAggregateDataWithCases);

  const onSubmitSearch = (e: FormEvent) => {
    e.preventDefault();

    if (searchId) {
      push(`/${pathBase}/${searchId}`);
    }
  };

  return (
    <DocumentTitle title={pageTitle}>
      <>
        <PageHeader>
          <PageHeaderContent>
            <PageHeading>{pageTitle}</PageHeading>
          </PageHeaderContent>
        </PageHeader>
        <PageSection>
          <SmallHeading>Search by customer ID</SmallHeading>
          <SearchForm onSubmit={onSubmitSearch}>
            <Input
              name="customerID"
              placeholder="Customer ID"
              required
              type="tel"
              onChange={(e) => setSearchId(e.target.value)}
              value={searchId}
            />
            <SubmitButton
              buttonType="secondary"
              disabled={isLoading}
              isLoading={isLoading}
              isShort
              type="submit"
            >
              {searchCta}
            </SubmitButton>
          </SearchForm>
          {error && <ErrorText>{error.message}</ErrorText>}
          {customerId && aggregatesByCase && (
            <SearchResults>
              <CustomerName>
                {customer?.firstName} {customer?.lastName}
              </CustomerName>
              {aggregatesByCase.length ? (
                <div>
                  {aggregatesByCase?.map(([caseItem, aggregates]) => {
                    return (
                      <div key={caseItem?.caseRef as string}>
                        <CaseHeader caseItem={caseItem} />
                        {aggregates.length ? (
                          aggregates.map((aggregate) =>
                            AggregateRow({ aggregate, selectFor, push })
                          )
                        ) : (
                          <div>
                            <div>
                              There are no aggregates for this case. Please
                              notify engineering with case reference below.
                            </div>
                            <small>Case Reference: {caseItem.caseRef}</small>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>There are no cases for this customer.</p>
              )}
            </SearchResults>
          )}
        </PageSection>
      </>
    </DocumentTitle>
  );
};

export default AggregateSearchPage;
