import React, { useContext } from 'react';
import {
  Button,
  Modal,
  NotificationContext,
  colors,
  type,
} from '@candidco/enamel';
import { HelpTooltip } from 'components/HelpTooltip';
import { Box } from '@material-ui/core';

import ReactSelect from 'react-select';
import styled from 'styled-components/macro';
import { ErrorText } from 'styles/inputs.css';
import {
  PracticeSelectOption,
  usePromotionContext,
} from 'pages/Promotion/Context';
import * as Sentry from '@sentry/browser';

export const PracticeSelect = styled(ReactSelect)<{
  width?: number;
}>`
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => (width ? `${width}px` : '200px')};
  .react-select__menu-list {
    max-height: 6em;
  }
`;

export const CreateBundleModal = () => {
  const {
    createBundle,
    bundleCreationVisible,
    setBundleCreationVisible,
    setSelectedPractice,
    selectedPractice,
    practiceOptions,
    getPracticeError,
  } = usePromotionContext();
  const { showNotification } = useContext(NotificationContext);

  const cleanup = () => {
    setBundleCreationVisible(false);
    setSelectedPractice({ label: '', value: '' });
  };

  const handleCreateBundle = () =>
    createBundle(selectedPractice.value)
      .catch((error) => {
        showNotification('Failed to create bundle', 'error');
        console.error(error);
        Sentry.captureException(error);
      })
      .finally(cleanup);

  return (
    <Modal
      isOpen={bundleCreationVisible}
      isFullScreenOnMobile={true}
      onClose={cleanup}
    >
      <Box minWidth={480}>
        <Box marginY="1em" marginX="2em">
          <type.H4
            style={{
              color: colors.text90,
            }}
          >
            Add Bundle
          </type.H4>
        </Box>
        {getPracticeError && <ErrorText>{getPracticeError.message}</ErrorText>}
        <hr />
        {practiceOptions && practiceOptions.length > 0 && (
          <Box margin="2em">
            <Box display="flex" flexDirection="row">
              <type.Body>
                Which practice do you want to create a bundle for?
              </type.Body>
              <Box marginLeft=".5em">
                <HelpTooltip content="Currently only supports Candid Pro practices." />
              </Box>
            </Box>
            <PracticeSelect
              options={practiceOptions}
              hasError={!!getPracticeError}
              onChange={(option: PracticeSelectOption) =>
                setSelectedPractice(option)
              }
              value={selectedPractice}
              width={400}
              classNamePrefix="react-select"
            />
          </Box>
        )}
        <hr />
        <Box
          display="flex"
          justifyContent="space-between"
          marginY="1em"
          marginX="2em"
        >
          <Button
            buttonType="text"
            style={{ color: colors.text70 }}
            onClick={cleanup}
          >
            Cancel
          </Button>
          <Button
            buttonType="secondary"
            disabled={selectedPractice.value === ''}
            onClick={handleCreateBundle}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
