import styled from 'styled-components/macro';

export const ListContainer = styled.ul`
  margin-left: 1.5rem;
  margin-bottom: 0;
`;

export const ListItem = styled.li`
  list-style-type: disc;
`;

export const ListItemText = styled.div`
  display: inline;
`;
