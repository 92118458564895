import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '@candidco/enamel';

import { PhotoType, PhotoTypes } from 'generated/legacy/graphql';
import { AppDispatch } from 'state/store';
import { movePhoto } from 'pages/Prism/prismSlice';
import {
  Content,
  ModalContainer,
  Label,
  ModalArrayContainer,
  ModalArrayItem,
  Title,
} from 'pages/Prism/ReviewPanel/MovePhotoModal/MovePhotoModal.css';
import { PT_SVG_DICT } from 'components/PrismPhotoReview/PrismPhotoReview';

type Props = {
  currentViewName: string;
  currentViewLabel: string;
  evaluationId: string;
  isVisible: boolean;
  onClose: () => void;
  photo: PhotoType;
  photoTypes: {
    treatmentViewNames: string[];
    itiViewNames: string[];
  };
  photoViewMap: Record<string, PhotoType | null>;
};

const MovePhotoModal = ({
  currentViewName,
  currentViewLabel,
  evaluationId,
  isVisible,
  onClose,
  photo,
  photoTypes,
  photoViewMap,
}: Props) => {
  const [isMoving, setIsMoving] = useState(false);
  const [selectedView, setSelectedView] = useState<string | null>(null);
  const [selectedViewLabel, setSelectedViewLabel] = useState<string | null>(
    null
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // If the photo being moved has changed, reset selection
    setSelectedView(null);
  }, [evaluationId]);

  const handleSubmit = async () => {
    try {
      setIsMoving(true);
      dispatch(movePhoto({ to: selectedView as PhotoTypes, photo }));
      setIsMoving(false);
      onClose();
    } catch (err) {
      setIsMoving(false);
    }
  };

  const renderPhotoItem = useCallback(
    (name) => {
      const SvgPlaceholder = PT_SVG_DICT[name];
      const label = photoViewMap[name]?.photoType?.label ?? null;
      return (
        <ModalArrayItem
          key={name}
          view={name}
          isSelected={
            selectedView ? name === selectedView : name === currentViewName
          }
          onClick={() => {
            setSelectedView(name);
            setSelectedViewLabel(label);
          }}
          data-private
        >
          <SvgPlaceholder />
        </ModalArrayItem>
      );
    },
    [selectedView, currentViewName, photoViewMap]
  );

  const { treatmentViewNames, itiViewNames } = photoTypes;

  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <ModalContainer>
        <Title>Where should this photo be?</Title>
        <Content>
          <ModalArrayContainer>
            {treatmentViewNames.map(renderPhotoItem)}
          </ModalArrayContainer>
          {!!itiViewNames.length && (
            <ModalArrayContainer iti>
              {itiViewNames.map(renderPhotoItem)}
            </ModalArrayContainer>
          )}
        </Content>
        <Label>{selectedViewLabel ?? currentViewLabel}</Label>
        <Button
          buttonType="secondary"
          disabled={
            !selectedView || isMoving || selectedView === currentViewName
          }
          isLoading={isMoving}
          isShort
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ModalContainer>
    </Modal>
  );
};

export default MovePhotoModal;
