import React, { useMemo } from 'react';
import { Column } from 'react-virtualized';
import { Skeleton } from '@candidco/enamel';

import VirtualizedSearchTable, {
  SortableColumn,
  monitoringLinkRenderer,
  customerCellRenderer,
  privateCellRenderer,
} from 'components/SearchTablePage/table';
import { getBrandDomainSettings } from 'utils/brands';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCaseTypeLabel } from 'utils/case';

export const PatientSearchTable = () => {
  const {
    'white-labeling-brand-support': whiteLabeling,
    'enable-refinement-policy': enableRefinementPolicy,
  } = useFlags();
  const { monitoringLabel } = getBrandDomainSettings();
  const columns = useMemo(
    () => [
      <Column
        label="ID"
        dataKey="id"
        key="id"
        width={100}
        cellRenderer={({ cellData }) =>
          customerCellRenderer(cellData, 'patient')
        }
      />,
      <SortableColumn
        label="First name"
        dataKey="firstName"
        sortField="FirstName"
        key="firstName"
        headerStyle={{ textTransform: 'none' }}
        width={125}
        flexGrow={1}
        cellRenderer={({ cellData }) => privateCellRenderer(cellData)}
      />,
      <SortableColumn
        label="Last name"
        dataKey="lastName"
        sortField="LastName"
        key="lastName"
        width={125}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label="Case type"
        dataKey="caseType"
        key="caseType"
        width={125}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? (
            getCaseTypeLabel(cellData ?? '', enableRefinementPolicy)
          ) : (
            <Skeleton width={100} />
          )
        }
      />,
      <Column
        label="Status"
        dataKey="providerFacingStatus"
        key="providerFacingStatus"
        headerStyle={{ textTransform: 'none' }}
        width={250}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? cellData : <Skeleton width={100} />
        }
      />,
      <SortableColumn
        label="Treating provider"
        dataKey="treatingProvider"
        sortField="ReferringDentistFullName"
        key="treatingProvider"
        width={150}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label={monitoringLabel}
        dataKey="email"
        key="email"
        width={150}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          monitoringLinkRenderer(cellData, whiteLabeling)
        }
      />,
    ],
    []
  );

  return (
    <VirtualizedSearchTable
      columns={columns}
      filterType="provider_facing_status"
      title="Patients"
    />
  );
};
