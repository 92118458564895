import React, { useMemo } from 'react';
import Dinero from 'dinero.js';
import ProductCard from 'pages/Patient/ProductSelectionModal/ProductCard';
import {
  SelectionContainer,
  ModalContainer,
  Title,
  CostContainer,
  StrikethroughText,
} from 'pages/Patient/ProductSelectionModal/Selection.css';

import RetainerCandidPng from 'assets/products/candid/retainers.png';
import AlignersCandidPng from 'assets/products/candid/aligners.png';
import RetainerGlidewellPng from 'assets/products/glidewell/retainers.png';
import AlignersGlidewellPng from 'assets/products/glidewell/aligners.png';
import RefinementsPng from 'assets/products/common/refinements.png';
import ReplacementPng from 'assets/products/common/replacementAligners.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPatientBrandName,
  selectActiveCaseHasApprovedTP,
  selectSelectedProductType,
  setSelectedProduct,
  selectIsPatientElgiibleForRefinement,
  selectPatient,
} from 'pages/Patient/patientSlice';
import {
  selectAppliedRefinementDiscountType,
  RefinementType,
} from 'pages/Promotion/promotionsSlice';
import { ProductTypes } from 'pages/Patient/types';

import { ProductDetails } from 'pages/Patient/ProductSelectionModal/types';
import useGetDiscountedPrice from 'hooks/useGetDiscountedPrice';
import { useAuthContext } from 'components/AuthProvider';
import { Skeleton } from '@candidco/enamel';
import { SKUs } from 'constants/brands';
import { getBrandSupportedFeatures } from 'utils/brands';

const ProductSelection = () => {
  const patientBrandName = useSelector(selectPatientBrandName);
  const patient = useSelector(selectPatient);
  const hasApprovedTp = useSelector(selectActiveCaseHasApprovedTP);
  const refinementType = useSelector(selectAppliedRefinementDiscountType);
  const selectedProduct = useSelector(selectSelectedProductType);
  const isPatientElgiibleForRefinement = useSelector(
    selectIsPatientElgiibleForRefinement
  );
  const dispatch = useDispatch();
  const { cart: alignerCart } = useGetDiscountedPrice({
    productType: ProductTypes.Aligner,
    patientId: patient?.id ?? '',
    practiceId: patient?.practice?.id ?? '',
  });
  const { cart: refinementCart } = useGetDiscountedPrice({
    productType: ProductTypes.Refinement,
    patientId: patient?.id ?? '',
    practiceId: patient?.practice?.id ?? '',
  });

  const { cart: retainerCart } = useGetDiscountedPrice({
    productType: ProductTypes.Retainer,
    patientId: patient?.id ?? '',
    practiceId: patient?.practice?.id ?? '',
  });

  const { userInfo, currentPracticeLoyaltyTier } = useAuthContext();

  const displayPrices = useMemo(() => {
    if (userInfo) {
      const { DisplayPricesToProviders } = getBrandSupportedFeatures(
        userInfo?.brandInfo?.name ?? ''
      );
      return DisplayPricesToProviders;
    }
    return false;
  }, [userInfo]);

  //TODO: move this over to brand.ts and figure out these map
  //https://app.shortcut.com/candid/story/88256

  const AlignerDisplayPrice = () => {
    if (!alignerCart || alignerCart.length !== 1) {
      return <Skeleton animation="wave" variant="text" width={'100%'} />;
    }

    const showStrikethrough =
      !alignerCart[0].totalPriceBeforeDiscounts.equalsTo(
        alignerCart[0].totalPriceAfterLoyalty
      );

    return (
      <CostContainer>
        <div>Cost: </div>
        {showStrikethrough && (
          <StrikethroughText>
            {alignerCart[0]?.totalPriceBeforeDiscounts.toFormat()}
          </StrikethroughText>
        )}
        <div>{alignerCart[0]?.totalPriceAfterLoyalty.toFormat()}</div>
        <div>•</div>
        {`${currentPracticeLoyaltyTier?.name} loyalty tier pricing`}
      </CostContainer>
    );
  };

  const RefinementDisplayPrice = () => {
    if (!refinementCart || refinementCart.length !== 1) {
      return <Skeleton animation="wave" variant="text" width={'100%'} />;
    }
    return (
      <CostContainer>
        <div>{`Cost: ${refinementCart[0].totalPriceAfterDiscounts.getAmount() === 0 ? 'Free' : refinementCart[0].totalPriceAfterDiscounts.toFormat()}`}</div>
      </CostContainer>
    );
  };

  const RetainerDisplayPrice = () => {
    if (!retainerCart || retainerCart.length !== 1) {
      return <Skeleton animation="wave" variant="text" width={'100%'} />;
    }
    const price = Dinero({
      amount: retainerCart[0].totalPriceAfterLoyalty.getAmount(),
    }).toFormat();
    return (
      <CostContainer>
        <div>{`Cost: ${price}`}</div>
      </CostContainer>
    );
  };

  const ReplacementDisplayPrice = () => {
    //Staging doesn't seem to have all contract rates, so fetching it from the cart fails for some practices.
    const price = Dinero({
      amount: SKUs[userInfo?.brandInfo?.name ?? ''].replacement.price,
    }).toFormat();
    return (
      <CostContainer>
        <div>{`Cost: ${price} per aligner`}</div>
      </CostContainer>
    );
  };

  const CandidProducts: ProductDetails[] = [
    {
      titleText: 'PermaForm™ retainers',
      bodyText:
        'Our ortho-recommended retention product based on new patient scans. Thicker material and a closer trimline. Order up to 4 retainers at a time.',
      imagePath: RetainerCandidPng,
      productType: ProductTypes.Retainer,

      isAvailable: () => true,
      PriceComponent: <RetainerDisplayPrice />,
    },
    {
      titleText: 'Comprehensive aligners',
      bodyText:
        "CandidPro's proprietary down-to-the-micron manufacturing process creates some of the best clear aligners in the industry.",
      imagePath: AlignersCandidPng,
      productType: ProductTypes.Aligner,
      isAvailable: () => refinementType === null,
      PriceComponent: <AlignerDisplayPrice />,
    },
    {
      titleText: 'Refinements',
      bodyText:
        'Start another round of aligners to ensure a perfectly aligned smile. Our team will determine how many more trays are needed.',
      imagePath: RefinementsPng,
      productType: ProductTypes.Refinement,
      isAvailable: () =>
        isPatientElgiibleForRefinement &&
        (refinementType === RefinementType.Free ||
          refinementType === RefinementType.Paid),
      PriceComponent: <RefinementDisplayPrice />,
    },
    {
      titleText: 'Replacement aligners',
      bodyText:
        'If a patient misplaced one or more aligner trays, you can easily order replacements.',
      imagePath: ReplacementPng,
      productType: ProductTypes.Replacement,
      isAvailable: () => hasApprovedTp ?? false,
      PriceComponent: <ReplacementDisplayPrice />,
    },
  ];

  const GlidewellProducts: ProductDetails[] = [
    {
      titleText: 'PermaForm™ retainers',
      bodyText:
        'Our ortho-recommended retention product based on new patient scans. Thicker material and a closer trimline. Order up to 4 retainers at a time.',
      imagePath: RetainerGlidewellPng,
      productType: ProductTypes.Retainer,
      isAvailable: () => true,
      PriceComponent: <RetainerDisplayPrice />,
    },
    {
      titleText: 'Comprehensive aligners',
      bodyText:
        "CandidPro's proprietary down-to-the-micron manufacturing process creates some of the best clear aligners in the industry.",
      imagePath: AlignersGlidewellPng,
      productType: ProductTypes.Aligner,
      isAvailable: () => refinementType === null,
      PriceComponent: <AlignerDisplayPrice />,
    },
    {
      titleText: 'Refinements',
      bodyText:
        'Start another round of aligners to ensure a perfectly aligned smile. Our team will determine how many more trays are needed.',
      imagePath: RefinementsPng,
      productType: ProductTypes.Refinement,
      isAvailable: () =>
        isPatientElgiibleForRefinement &&
        (refinementType === RefinementType.Free ||
          refinementType === RefinementType.Paid),
      PriceComponent: <RefinementDisplayPrice />,
    },
    {
      titleText: 'Replacement aligners',
      bodyText:
        'If a patient misplaced one or more aligner trays, you can easily order replacements.',
      imagePath: ReplacementPng,
      productType: ProductTypes.Replacement,
      isAvailable: () => hasApprovedTp ?? false,
      PriceComponent: <ReplacementDisplayPrice />,
    },
  ];

  const availableProducts = () => {
    const products =
      patientBrandName === 'glidewell' ? GlidewellProducts : CandidProducts;
    return products.filter((product) => {
      if (product.isAvailable()) {
        return product;
      }
    });
  };

  return (
    <ModalContainer>
      <Title> Place a new order</Title>
      <SelectionContainer>
        {availableProducts().map((product) => {
          return (
            <ProductCard
              key={product.productType}
              header={product.titleText}
              body={product.bodyText}
              imageSrc={product.imagePath}
              isSelected={selectedProduct === product.productType}
              PriceComponent={
                displayPrices && product.PriceComponent
                  ? product.PriceComponent
                  : null
              }
              onClick={() => {
                dispatch(setSelectedProduct(product.productType));
              }}
            />
          );
        })}
      </SelectionContainer>
    </ModalContainer>
  );
};

export default ProductSelection;
