import React, { useEffect, useMemo } from 'react';
import { useImmerReducer } from 'use-immer';
import { Button, Modal } from '@candidco/enamel';

import { RejectionReason } from 'generated/legacy/graphql';

import {
  Checkbox,
  FormContainer,
  Label,
  Reasons,
} from 'components/PrismPhotoReview/ReasonsModal/ReasonsModal.css';
import { Sort, sortByLabel } from 'utils/prism';

type Props = {
  isVisible?: boolean;
  onClose: () => void;
  onSubmit: (reasons: RejectionReason[]) => void;
  photoId: string;
  reasons: RejectionReason[];
};

type LocalState = {
  selections: {
    [key: string]: {
      reason: RejectionReason;
      checked: boolean;
    };
  };
  isRejecting: boolean;
};

const initSelections = (reasons: RejectionReason[]): LocalState => {
  const selections = reasons.reduce(
    (selections: LocalState['selections'], reason) => {
      selections[reason.name] = {
        reason,
        checked: false,
      };

      return selections;
    },
    {}
  );

  return {
    selections,
    isRejecting: false,
  };
};

type IsNotRejecting = { type: 'isNotRejecting' };
type IsRejecting = { type: 'isRejecting' };
type Reset = { type: 'reset'; payload: RejectionReason[] };
type Check = { type: 'check'; payload: RejectionReason['name'] };
type Uncheck = { type: 'uncheck'; payload: RejectionReason['name'] };
type LocalActions = Reset | Check | Uncheck | IsNotRejecting | IsRejecting;
function reducer(draft: LocalState, action: LocalActions) {
  switch (action.type) {
    case 'reset':
      return initSelections(action.payload);
    case 'check':
      draft.selections[action.payload].checked = true;
      return draft;
    case 'uncheck':
      draft.selections[action.payload].checked = false;
      return draft;
    case 'isRejecting':
      draft.isRejecting = true;
      return draft;
    case 'isNotRejecting':
      draft.isRejecting = false;
      return draft;
  }
}

const readyToSubmit = (state: LocalState) =>
  Object.entries(state.selections).some(([, selection]) => selection.checked);
const selectCheckedSelections = (state: LocalState): RejectionReason[] =>
  Object.entries(state.selections)
    .filter(([, selection]) => selection.checked)
    .map(([, selection]) => selection.reason);

const ReasonsModal = ({
  isVisible,
  onClose,
  onSubmit,
  photoId,
  reasons,
}: Props) => {
  const [state, dispatch] = useImmerReducer<LocalState, LocalActions>(
    reducer,
    initSelections(reasons)
  );
  const isReadyToSubmit = useMemo(() => readyToSubmit, [state])(state);
  const getCheckedSelections = useMemo(() => selectCheckedSelections, [state]);
  let selectedReasons: RejectionReason[] = [];

  useEffect(() => {
    // If the photo in review has changed, reset selections
    dispatch({ type: 'reset', payload: reasons });
  }, [photoId, reasons]);

  useEffect(() => {
    selectedReasons = getCheckedSelections(state);
  }, [state]);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    if (checked) {
      dispatch({ type: 'check', payload: name });
    } else {
      dispatch({ type: 'uncheck', payload: name });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      dispatch({ type: 'isRejecting' });
      onSubmit(selectedReasons);
      dispatch({ type: 'isNotRejecting' });
      dispatch({ type: 'reset', payload: reasons });
    } catch (err) {
      dispatch({
        type: 'isNotRejecting',
      });
    }
  };

  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <FormContainer
        onSubmit={handleSubmit}
        data-testid="PrismPhotoReview-ReasonsModal"
      >
        <Reasons>
          {reasons.sort(sortByLabel(Sort.Desc)).map(({ name, label }) => {
            return (
              <Label key={name}>
                <Checkbox
                  checked={state.selections[name]?.checked ?? false}
                  name={name}
                  onChange={handleCheckbox}
                  type="checkbox"
                  data-testid="PrismPhotoReview-ReasonsModal-Checkbox"
                />
                {label}
              </Label>
            );
          })}
        </Reasons>
        <Button
          buttonType="secondary"
          disabled={!isReadyToSubmit || state.isRejecting}
          isLoading={state.isRejecting}
          isShort
          type="submit"
          data-testid="PrismPhotoReview-ReasonsModal-Submit"
        >
          Reject photo
        </Button>
      </FormContainer>
    </Modal>
  );
};

export default ReasonsModal;
