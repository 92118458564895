import React, { Component } from 'react';
import { debounce } from 'lodash';
import { isEqual } from 'lodash';
import Cropper from 'cropperjs';
import { Loading } from '@candidco/enamel';
import 'cropperjs/dist/cropper.min.css';

import {
  CropMetadataInput,
  PhotoType,
  RejectionReason,
} from 'generated/legacy/graphql';
import FileUpload from 'components/FileUpload';

import EvaluationButtons from 'components/PrismPhotoReview/EvaluationButtons';
import PhotoTools from 'components/PrismPhotoReview/PhotoTools';
import ReasonsModal from 'components/PrismPhotoReview/ReasonsModal';
import {
  Container,
  Image,
  ImageContainer,
  ViewDefault,
} from 'components/PrismPhotoReview/PrismPhotoReview.css';
import { formatCropMetadata } from 'utils/prism';

import ExtraoralNoSmile from 'assets/photoTypes/extraoral_no_smile.svg?react';
import ExtraoralRightProfileNoSmile from 'assets/photoTypes/extraoral_right_profile_no_smile.svg?react';
import ExtraoralSmile from 'assets/photoTypes/extraoral_smile.svg?react';
import IntraoralFrontalView from 'assets/photoTypes/intraoral_frontal_view.svg?react';
import IntraoralUpperOcclusal from 'assets/photoTypes/intraoral_upper_occlusal.svg?react';
import IntraoralRightLateralView from 'assets/photoTypes/intraoral_right_lateral_view.svg?react';
import IntraoralLowerOcclusal from 'assets/photoTypes/intraoral_lower_occlusal.svg?react';
import IntraoralLeftLateralView from 'assets/photoTypes/intraoral_left_lateral_view.svg?react';

import IntraoralFrontalViewWithAligners from 'assets/photoTypes/intraoral_frontal_view_w_aligners.svg?react';
import IntraoralUpperOcclusalWithAligners from 'assets/photoTypes/intraoral_upper_occlusal_w_aligners.svg?react';
import IntraoralRightLateralViewWithAligners from 'assets/photoTypes/intraoral_right_lateral_view_w_aligners.svg?react';
import IntraoralLowerOcclusalWithAligners from 'assets/photoTypes/intraoral_lower_occlusal_w_aligners.svg?react';
import IntraoralLeftLateralViewWithAligners from 'assets/photoTypes/intraoral_left_lateral_view_w_aligners.svg?react';

export const PT_SVG_DICT: {
  [k: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
} = {
  extraoral_no_smile: ExtraoralNoSmile,
  extraoral_right_profile_no_smile: ExtraoralRightProfileNoSmile,
  extraoral_smile: ExtraoralSmile,
  intraoral_frontal_view: IntraoralFrontalView,
  intraoral_upper_occlusal: IntraoralUpperOcclusal,
  intraoral_right_lateral_view: IntraoralRightLateralView,
  intraoral_lower_occlusal: IntraoralLowerOcclusal,
  intraoral_left_lateral_view: IntraoralLeftLateralView,

  intraoral_frontal_view_w_aligners: IntraoralFrontalViewWithAligners,
  intraoral_upper_occlusal_w_aligners: IntraoralUpperOcclusalWithAligners,
  intraoral_right_lateral_view_w_aligners:
    IntraoralRightLateralViewWithAligners,
  intraoral_lower_occlusal_w_aligners: IntraoralLowerOcclusalWithAligners,
  intraoral_left_lateral_view_w_aligners: IntraoralLeftLateralViewWithAligners,
};

type CropperJS = Cropper & {
  canvas: HTMLDivElement;
  canvasData: Cropper.CanvasData;
  container: HTMLDivElement;
  containerData: Cropper.ContainerData;
  imageData: Cropper.ImageData;
  render: () => void;
};

type Props = {
  aspectRatio?: number;
  canReview?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  isTransitioningPhoto: boolean;
  isOrtho?: boolean;
  isPrpCompleted?: boolean;
  onSaveEdits?: (
    file: File | Blob | null,
    transformMeta: CropMetadataInput
  ) => Promise<void>;
  onSelectPhoto?: (files: FileList | null) => void;
  orthoRejected?: boolean;
  photo?: PhotoType | null;
  rejectionReasons: RejectionReason[];
  setEditingState: (editingState: boolean) => void;
  isEditingEnabled: boolean;
  handleRejection: (reasons: RejectionReason[]) => void;
  handleApproval: () => void;
  viewName: string;
  enableRejection?: boolean;
};

class PrismPhotoReview extends Component<Props> {
  static defaultProps = {
    canReview: true,
    setEditingState: () => {},
  };

  isCropperLoaded = false;
  isShowingOriginal = false;
  image: HTMLImageElement | null = null;
  cropper?: CropperJS;
  containerRect: DOMRect | null = null;
  onResize: () => void;

  state = {
    isCropping: false,
    isSaving: false,
    isZooming: false,
    rotation: 0,
    flip: 1,
    showReasonsModal: false,
  };

  constructor(props: Props) {
    super(props);
    this.onResize = debounce(this.handleResize, 200);
  }

  componentDidMount() {
    if (this.image) {
      this.initCropper();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isLoading: wasLoading, photo: prevPhoto } = prevProps;
    const { isLoading, photo: currPhoto } = this.props;

    if (!wasLoading && isLoading && this.isCropperLoaded) {
      // If uploading or refreshing photos, remove Cropper
      this.removeCropper();
    } else if (wasLoading && !isLoading && currPhoto) {
      // On upload or refresh complete, init Cropper
      this.initCropper();
    } else if (!isEqual(prevPhoto, currPhoto)) {
      if (prevPhoto && currPhoto) {
        // If updating the photo in view, reload Cropper with the new photo URL
        this.updatePhoto();
      } else if (!prevPhoto && currPhoto) {
        // If changing from no photo in view to photo in view, init Cropper
        this.initCropper();
      } else if (prevPhoto && !currPhoto && this.isCropperLoaded) {
        // If changing from photo in view to no photo in view, remove Cropper
        this.removeCropper();
      }
    }
  }

  componentWillUnmount() {
    if (this.isCropperLoaded) {
      this.removeCropper();
    }
  }

  handleApprovePhoto = async () => {
    try {
      this.setState({ isApproving: true });
      this.props.handleApproval();
      this.setState({ isApproving: false });
    } catch (err) {
      this.setState({ isApproving: false });
    }
  };

  handleRejectPhoto = async (reasons: RejectionReason[]) => {
    this.props.handleRejection(reasons);
    this.setState({ showReasonsModal: false });
  };

  handleCrop = () => {
    if (!this.cropper || !this.isCropperLoaded || this.state.isCropping) {
      return;
    }

    const originalPhoto = this.props.photo?.prevVersion;

    if (originalPhoto) {
      const { photoUrl } = originalPhoto;
      this.isShowingOriginal = true;
      this.cropper.canvas.removeEventListener('click', this.toggleZoom);
      this.cropper.container.removeEventListener('mousemove', this.panImage);
      this.cropper.replace(photoUrl!);
    } else {
      this.cropper.crop();
      this.cropper.setDragMode('move');
    }

    this.setState({ isCropping: true });
    this.props.setEditingState(true);
  };

  handleFlip = () => {
    if (!this.cropper || !this.isCropperLoaded) {
      return;
    }

    const nextFlip = -1 * this.state.flip;
    this.setState({ flip: nextFlip });
    this.cropper.scaleX(nextFlip);
    this.props.setEditingState(true);
  };

  handleRotateLeft = () => {
    if (!this.cropper || !this.isCropperLoaded) {
      return;
    }

    this.cropper.rotate(-90);
    this.zoomCanvasToFit();
    this.props.setEditingState(true);
  };

  handleRotateRight = () => {
    if (!this.cropper || !this.isCropperLoaded) {
      return;
    }

    this.cropper.rotate(90);
    this.zoomCanvasToFit();
    this.props.setEditingState(true);
  };

  handleRotationSlider = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.cropper || !this.isCropperLoaded) {
      return;
    }

    const rotation = parseInt(e.target.value, 10);
    this.setState({ rotation });
    this.cropper.rotateTo(rotation);
    this.props.setEditingState(true);
  };

  panImage = (e: MouseEvent) => {
    if (!this.cropper || !this.containerRect) {
      return;
    }

    const { height, width } = this.cropper.containerData;
    const { height: imgHeight, width: imgWidth } = this.cropper.canvasData;
    let x = (width - imgWidth) / 2;
    let y = (height - imgHeight) / 2;
    // Only pan horizontally if zoomed image is wider than container
    if (imgWidth > width) {
      const cursorX = e.pageX - this.containerRect.left - window.pageXOffset;
      x = -1 * (cursorX / width) * (imgWidth - width);
    }
    // Only pan vertically if zoomed image is taller than container
    if (imgHeight > height) {
      const cursorY = e.pageY - this.containerRect.top - window.pageYOffset;
      y = -1 * (cursorY / height) * (imgHeight - height);
    }
    this.cropper.canvas.style.transform = `translate(${x}px,${y}px)`;
  };

  toggleZoom = () => {
    if (!this.cropper) {
      return;
    }

    if (!this.state.isZooming) {
      this.cropper.zoom(1.5);
      this.cropper.container.addEventListener('mousemove', this.panImage);
      this.setState({ isZooming: true });
    } else {
      this.cropper.zoom(-1.5);
      this.cropper.container.removeEventListener('mousemove', this.panImage);
      this.setState({ isZooming: false });
    }
  };

  preventZoom = (e: any) => {
    const {
      detail: { originalEvent },
    } = e as Cropper.ZoomEvent;

    // Only allow scrollwheel zoom when cropping
    if (!this.state.isCropping && originalEvent) {
      e.preventDefault();
    }
  };

  handleSaveEdits = async () => {
    const { onSaveEdits } = this.props;

    if (!this.cropper || !onSaveEdits) {
      return;
    }

    const { scaleX, scaleY, ...transformData } = this.cropper.getData();
    const formattedData = formatCropMetadata(transformData);
    const canvas = this.cropper.getCroppedCanvas();

    canvas.toBlob(async (blob) => {
      if (!this.cropper) {
        return;
      }

      try {
        this.setState({ isSaving: true });
        this.cropper.disable();
        await onSaveEdits(blob, formattedData);
        this.isShowingOriginal = false;
        this.setState({
          isCropping: false,
          isSaving: false,
          rotation: 0,
        });
        this.cropper.setDragMode('none');
        this.props.setEditingState(false);
        this.cropper.enable();
      } catch (err) {
        this.setState({ isSaving: false });
        this.cropper.enable();
      }
    }, 'image/jpeg');
  };

  handleDiscardEdits = () => {
    if (!this.cropper) {
      return;
    }

    this.cropper.setDragMode('none');
    this.cropper.clear();
    this.cropper.reset();

    if (this.isShowingOriginal) {
      const url = this.props.photo?.photoUrl;
      this.cropper.canvas.removeEventListener('click', this.toggleZoom);
      this.cropper.container.removeEventListener('mousemove', this.panImage);
      this.cropper.replace(url!);
      this.isShowingOriginal = false;
    }

    this.setState({
      isCropping: false,
      rotation: 0,
    });
    this.props.setEditingState(false);
  };

  updatePhoto = () => {
    if (!this.cropper) {
      return;
    }

    if (this.isCropperLoaded) {
      const { aspectRatio, photo } = this.props;
      this.cropper.canvas.removeEventListener('click', this.toggleZoom);
      this.cropper.container.removeEventListener('mousemove', this.panImage);
      this.cropper.replace(photo?.photoUrl!);
      this.cropper.setAspectRatio(aspectRatio!);
      this.isShowingOriginal = false;
      this.setState({
        isCropping: false,
        isZooming: false,
        rotation: 0,
      });
      this.props.setEditingState(false);
    } else {
      window.setTimeout(this.updatePhoto, 100);
    }
  };

  handleResize = () => {
    if (!this.cropper) {
      return;
    }

    // We must manually reset the Cropper canvas on resize, as the 'responsive'
    // option does not handle this correctly
    if (this.state.isZooming) {
      this.cropper.container.removeEventListener('mousemove', this.panImage);
      this.setState({ isZooming: false });
    }

    this.cropper.render();
    this.cropper.rotateTo(this.cropper.imageData.rotate);
  };

  zoomCanvasToFit() {
    if (!this.cropper) {
      return;
    }

    const { height: containerHeight } = this.cropper.getContainerData();
    const { height: canvasHeight } = this.cropper.getCanvasData();

    if (canvasHeight > containerHeight) {
      this.cropper.zoom(1 - canvasHeight / containerHeight);
    } else if (canvasHeight < containerHeight) {
      const ratio =
        1 - containerHeight / canvasHeight + canvasHeight / containerHeight;
      const zoom = ratio < 0 ? 1 + ratio : 1 - ratio;
      this.cropper.zoom(zoom);
    }
  }

  initCropper() {
    const { aspectRatio, isOrtho } = this.props;
    this.cropper = new Cropper(this.image!, {
      aspectRatio,
      autoCrop: false,
      autoCropArea: 0.5,
      background: false,
      center: false,
      checkCrossOrigin: false,
      checkOrientation: false,
      dragMode: 'none',
      highlight: false,
      minCropBoxWidth: 50,
      responsive: false,
      toggleDragModeOnDblclick: false,
      viewMode: 1,
      zoomOnTouch: false,
      zoomOnWheel: !isOrtho,
      ready: () => {
        if (!this.cropper) {
          return;
        }

        this.cropper.canvas.addEventListener('click', this.toggleZoom);
        this.cropper.container.addEventListener('zoom', this.preventZoom);

        if (this.isShowingOriginal) {
          this.cropper.crop();
          this.cropper.setDragMode('move');

          const cropMetadata = this.props.photo?.cropMetadata;
          if (cropMetadata) {
            this.cropper.setData(cropMetadata);
            if (cropMetadata.rotate) {
              this.setState({
                rotation: cropMetadata.rotate,
              });
            }
          }
        }

        if (!this.isCropperLoaded) {
          window.addEventListener('resize', this.onResize);
          this.containerRect = this.cropper.container.getBoundingClientRect();
          this.isCropperLoaded = true;
        }
      },
    }) as CropperJS;
  }

  removeCropper() {
    if (!this.cropper) {
      return;
    }

    window.removeEventListener('resize', this.onResize);
    this.cropper.canvas.removeEventListener('click', this.toggleZoom);
    this.cropper.container.removeEventListener('zoom', this.preventZoom);
    this.cropper.container.removeEventListener('mousemove', this.panImage);
    this.cropper.destroy();
    delete this.cropper;
    this.isCropperLoaded = false;
    this.isShowingOriginal = false;
    this.setState({
      isCropping: false,
      isZooming: false,
      rotation: 0,
    });
    this.props.setEditingState(false);
  }

  renderPhotoOrUpload() {
    const { isOrtho, onSelectPhoto, photo, viewName } = this.props;
    const otherProps: { [k: string]: any } = {};
    const ComponentSVG = PT_SVG_DICT[viewName] ?? null;

    if (ComponentSVG !== null) {
      otherProps['image'] = (
        <ViewDefault>
          <ComponentSVG />
        </ViewDefault>
      );
    }

    if (!photo) {
      return !isOrtho ? (
        <FileUpload
          testId="PrismPhotoReview-photoUpload"
          onSelectFile={onSelectPhoto!}
          showTextOverImage
          {...otherProps}
        />
      ) : null;
    }

    return (
      <Image
        data-testid={'PrismPhotoReview-image-' + viewName}
        ref={(el) => {
          this.image = el;
        }}
        alt=""
        src={photo.photoUrl!}
        crossOrigin="anonymous"
      />
    );
  }

  render() {
    const {
      canReview,
      isEditing,
      isEditingEnabled,
      isLoading,
      isOrtho,
      isPrpCompleted,
      orthoRejected,
      photo,
      rejectionReasons,
      isTransitioningPhoto,
      enableRejection,
    } = this.props;
    const { isCropping, isSaving, isZooming, rotation, showReasonsModal } =
      this.state;
    const photoState = photo?.stateData?.data;

    return (
      <Container hasTools={!isOrtho} isResponsive={isOrtho}>
        <ImageContainer
          data-testid="PrismPhotoReview-ImageContainer"
          isResponsive={isOrtho}
          isZoomable={!isCropping && !isSaving}
          isZooming={isZooming}
          data-private
        >
          {isLoading ? <Loading /> : this.renderPhotoOrUpload()}
        </ImageContainer>
        {photo && !isLoading && !isPrpCompleted && (
          <>
            {canReview && isEditingEnabled && (
              <EvaluationButtons
                isApproved={
                  isOrtho ? !orthoRejected : photoState === 'approved'
                }
                isApproving={isTransitioningPhoto}
                isRejected={isOrtho ? orthoRejected : photoState === 'rejected'}
                onClickApprove={this.handleApprovePhoto}
                onClickReject={() => this.setState({ showReasonsModal: true })}
                enableRejection={enableRejection}
              />
            )}
            {!isOrtho && isEditingEnabled && (
              <PhotoTools
                currentRotation={rotation}
                isEditing={isEditing}
                isSaving={isSaving}
                isVisible={!isZooming}
                onChangeRotation={this.handleRotationSlider}
                onClickFlip={this.handleFlip}
                onClickCrop={this.handleCrop}
                onClickRotateLeft={this.handleRotateLeft}
                onClickRotateRight={this.handleRotateRight}
                onDiscardEdits={this.handleDiscardEdits}
                onSaveEdits={this.handleSaveEdits}
              />
            )}
            <ReasonsModal
              isVisible={showReasonsModal}
              onClose={() => this.setState({ showReasonsModal: false })}
              onSubmit={(reasons) => this.handleRejectPhoto(reasons)}
              photoId={photo.id}
              reasons={rejectionReasons}
            />
          </>
        )}
      </Container>
    );
  }
}

export default PrismPhotoReview;
