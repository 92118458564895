import React from 'react';
import { useSelector } from 'react-redux';
import { AlertCard, Loading } from '@candidco/enamel';
import { usePatientLoadingStates } from 'pages/Patient/utils';

import {
  selectScans,
  selectScanSubmissions,
  selectSelectedCase,
  selectLatestScanMaterialEvaluations,
} from 'pages/Patient/patientSlice';
import {
  ScanTabWrapper,
  VersionPillsContainer,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import MaterialsTable, {
  MaterialTableColumns,
} from 'components/MaterialsTable';
import MaterialSubmissionHistory from 'components/MaterialSubmissionHistory';
import MaterialApprovedAlertCard from 'components/AlertCards/MaterialApprovedAlertCard';

import {
  HistorySection,
  HistoryWrapper,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';
import { InternalCaseStates } from 'constants/Case';

const ScansTab = () => {
  const scans = useSelector(selectScans);
  const scanSubmissions = useSelector(selectScanSubmissions);
  const selectedCase = useSelector(selectSelectedCase);
  const { isFetchingScans } = usePatientLoadingStates();
  const vpProps = useVersionPills(scanSubmissions, true);
  const latestScanEvaluations = useSelector(
    selectLatestScanMaterialEvaluations
  );
  const latestScanEvaluation = latestScanEvaluations?.[0];

  const ScanBody = () => {
    if (isFetchingScans) {
      return <Loading isCentered />;
    }

    if (
      selectedCase?.caseState?.internal ===
      InternalCaseStates.AWAITING_PVS_IMPRESSIONS
    ) {
      return (
        <AlertCard type="default" header="PVS Processing" displayIcon>
          Our lab is either waiting to receive your PVS impressions or is
          processing them. Processing usually takes around one business day
          after our team has received the PVS impressions.
        </AlertCard>
      );
    }

    if (selectedCase?.caseState?.internal === 'Awaiting STL export upload') {
      return (
        <AlertCard
          type="default"
          header="Intraoral scan sync processing"
          displayIcon
        >
          Our lab is either waiting to receive your intraoral scans or is
          processing them. Processing usually takes around one business day
          after our team has received the intraoral scans.
        </AlertCard>
      );
    }

    const selectedSubmission = scanSubmissions[vpProps.currentIndex];
    const selectedScans = selectedSubmission
      ? scans.filter((scan) =>
          scan.submissions.some(
            (submission) => submission.id === selectedSubmission.id
          )
        )
      : [];

    return (
      <ScanTabWrapper>
        <VersionPillsContainer>
          <VersionPills {...vpProps} />
        </VersionPillsContainer>
        {!!latestScanEvaluation?.approved && (
          <MaterialApprovedAlertCard
            evaluationSetId={latestScanEvaluation.evaluationSet?.id!}
            materialName="scan"
            onReverse={() => null} //TODO Confirm if revert is needed here
            selectedCase={selectedCase!}
          />
        )}
        <MaterialsTable
          materials={selectedScans}
          excludedColumns={[MaterialTableColumns.UP_TO_DATE]}
        />
        <HistoryWrapper
          container
          alignItems="center"
          justifyContent="flex-start"
        >
          <HistorySection item>
            <MaterialSubmissionHistory
              submissions={scanSubmissions}
              isExpanded={false}
              materialName="Scans"
            />
          </HistorySection>
        </HistoryWrapper>
      </ScanTabWrapper>
    );
  };

  return <ScanBody />;
};

export default ScansTab;
