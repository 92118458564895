import React, { useContext, useRef } from 'react';
import { Formik } from 'formik';
import { FormikProps } from 'formik';
import { Modal, Button, Grid } from '@candidco/enamel';
import {
  StyledModal,
  Header,
  StyledDivider,
  Body,
  Footer,
  CancelButton,
  ModalTitle,
} from 'components/Modals/OrderRetainersModal/OrderRetainersModal.css';
import { FormikInputWrapper } from 'components/FormikForms';
import { requiredValidator } from 'components/FormikForms/utils';
import { NotificationContext } from '@candidco/enamel';
import { useDispatch } from 'react-redux';
import { useIsLoading } from 'state/system';
import { AppDispatch } from 'state/store';
import { REFACTOR_ANY } from '@Types/refactor';
import { AsyncThunk } from '@reduxjs/toolkit';

export type CreateTeamCaseCouponModalProps = {
  isOpen: boolean;
  onClose: () => void;
  createTeamCaseCoupon: AsyncThunk<void, { practiceId: string }, object>;
};

export type CreateTeamCaseCouponModalFormValues = {
  patientId: string;
};

const CreateTeamCaseCouponModal = ({
  isOpen,
  onClose,
  createTeamCaseCoupon,
}: CreateTeamCaseCouponModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showNotification } = useContext(NotificationContext);

  const isFetchingPromotions = useIsLoading('promotions/fetchPromotions');
  const isCreatingCoupon = useIsLoading('promotions/createTeamCaseCoupon');

  const isLoading = isFetchingPromotions;
  const formRef =
    useRef<FormikProps<CreateTeamCaseCouponModalFormValues>>(null);

  const onSubmit = async ({ practiceId }: { practiceId: string }) => {
    try {
      // If you want to catch errors thrown by the dispatch function, you need to .unwrap() the dispatch action
      await dispatch(createTeamCaseCoupon({ practiceId })).unwrap();
      showNotification(`Coupon created successfully`, 'success');
      onClose();
    } catch (err) {
      const error = err as REFACTOR_ANY;
      showNotification(`Failed to create coupon: ${error?.message}`, 'error');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <StyledModal>
        <Header>
          <ModalTitle>Create Team Case Coupon</ModalTitle>
        </Header>
        <StyledDivider />
        <Body>
          <Formik
            initialValues={{ practiceId: '' }}
            onSubmit={onSubmit}
            validateOnChange={true}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            innerRef={formRef}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              style={{ marginLeft: '-0.5rem', marginTop: 0 }}
            >
              <Grid item xs={12} md={12}>
                <FormikInputWrapper
                  name="practiceId"
                  label="Practice ID"
                  validate={requiredValidator}
                  type="text"
                  placeholder="Enter practice ID"
                />
              </Grid>
            </Grid>
          </Formik>
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton buttonType="text" onClick={onClose}>
            Cancel
          </CancelButton>
          <Button
            onClick={() => formRef.current?.submitForm()}
            buttonType="secondary"
            isLoading={isLoading || isCreatingCoupon}
          >
            Submit
          </Button>
        </Footer>
      </StyledModal>
    </Modal>
  );
};

export default CreateTeamCaseCouponModal;
