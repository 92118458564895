import React, { useState } from 'react';
import { useFormik, FormikProps } from 'formik';
import { ApolloError } from '@apollo/client';
import styled from 'styled-components/macro';
import { Switch } from '@material-ui/core';
import { type, Divider } from '@candidco/enamel';

import { ReactSelectOption } from 'components/FormikForms';
import { CaseSource } from 'generated/core/graphql';
import {
  useCreateCaseMutation,
  CaseTypes,
  CreationChannels,
  ThirdPartiesAvailableForSyncing,
} from 'generated/legacy/graphql';
import { Dropdown } from 'components/Dropdown/Dropdown';
import ConfirmDialog from 'components/ConfirmDialog';
import withNotifications from 'hocs/withNotifications';
import useSyncToThirdParty from 'hooks/useSyncToThirdParty';
import {
  Label,
  StyledReactSelect,
  CustomerReactSelectStyles,
} from 'styles/inputs.css';
import { apolloErrorToString } from 'utils/apollo';
import { useIsOnlyCandidPro } from 'hooks/useIsOnlyCandidPro';

type CreateCaseFormProps = {
  caseOptionType: CaseOptions;
  requireOrthoReview: boolean;
};

type CaseTypeOptions = {
  label: string;
  value: CaseOptions;
};

enum CaseOptions {
  AdultGen1,
  AdultGen2,
  TeenGen1,
  TeenGen2,
}

const createCaseInitialValues: CreateCaseFormProps = {
  caseOptionType: CaseOptions.AdultGen1,
  requireOrthoReview: true,
};

const Body = styled.div<{
  rightMargin?: boolean;
}>`
  text-align: right;
  margin-right: ${({ rightMargin }) => rightMargin && '1.5rem'};
`;

const OrthoReviewOption = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyleToggle = styled(Switch)`
  & .MuiIconButton-root {
    color: ${({ theme }) => theme.colors.black50};
    opacity: 1;
    &:hover {
      background-color: transparent;
    }
  }
  & .MuiSwitch-thumb {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.text20};
  }

  & .MuiSwitch-switchBase.Mui-checked {
    color: ${({ theme }) => theme.colors.blue50};

    &:hover {
      background-color: transparent;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.blue30};
  }
`;

const CustomerLink = styled(type.Link)<{
  action?: CustomerAction;
  disabled?: boolean;
}>`
  font-weight: 300;
  color: ${({ disabled }) => (disabled ? 'gray' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    cursor: pointer;
  }
`;

type Case = {
  isActive: boolean;
  caseRef: string;
  source?: string;
};

const customerDescriptions: Record<CustomerAction, string> = {
  createNewCase: 'Create a new case',
  syncToSalesforce: 'Sync to Salesforce',
  syncToZendesk: 'Sync to Zendesk',
};

const getCaseTypeFromSelect = (input: CaseOptions) => {
  if (input === CaseOptions.AdultGen1 || input === CaseOptions.AdultGen2) {
    return CaseTypes.CpioAdultAligner;
  }

  return CaseTypes.CproTeenAligner;
};

const getIsGen2FromSelect = (input: CaseOptions) => {
  return input === CaseOptions.AdultGen2 || input === CaseOptions.TeenGen2;
};

export enum CustomerAction {
  createNewCase = 'createNewCase',
  syncToSalesforce = 'syncToSalesforce',
  syncToZendesk = 'syncToZendesk',
}

const hideQuickActionsConditions: { (): boolean }[] = [
  (): boolean => useIsOnlyCandidPro(),
];

type CustomerActionProps = {
  customerId: string;
  cases: Case[];
  showNotification: (message: string, variant: string) => void;
  refetchCases: (customerId: string) => void;
  hideActions?: CustomerAction[];
};

const renderCustomerBody = (
  caseTypeOptions: CaseTypeOptions[],
  createCaseFormik: FormikProps<CreateCaseFormProps>,
  action?: CustomerAction
) => {
  let copy;
  switch (action) {
    case CustomerAction.createNewCase: {
      copy = (
        <>
          <Label htmlFor="create-case-type">Case type</Label>
          <StyledReactSelect
            id="create-case-type"
            label="Case type"
            options={caseTypeOptions}
            menuPosition={'fixed'}
            onChange={(option: ReactSelectOption) => {
              createCaseFormik.setFieldValue('caseOptionType', option.value);
            }}
            value={caseTypeOptions.find(
              ({ value }) => value === createCaseFormik.values.caseOptionType
            )}
            styles={CustomerReactSelectStyles}
          />
          {getIsGen2FromSelect(createCaseFormik.values.caseOptionType) && (
            <>
              <Divider style={{ marginTop: '24px' }} />
              <OrthoReviewOption>
                <div>Require ortho review</div>
                <StyleToggle
                  defaultChecked
                  disableRipple
                  onChange={(e) =>
                    createCaseFormik.setFieldValue(
                      'requireOrthoReview',
                      e.target.checked
                    )
                  }
                />
              </OrthoReviewOption>
            </>
          )}
        </>
      );
      break;
    }
    case CustomerAction.syncToSalesforce: {
      copy = 'Sync the customer to Saleforce?';
      break;
    }
    case CustomerAction.syncToZendesk: {
      copy = 'Sync the customer to Zendesk?';
      break;
    }

    default: {
      copy = '';
      break;
    }
  }

  return <div>{copy}</div>;
};

const CustomerActionsComponent = ({
  customerId,
  cases,
  refetchCases,
  showNotification,
  hideActions = [],
}: CustomerActionProps) => {
  const [shouldOpenDialog, setShouldOpenDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState<
    CustomerAction | undefined
  >(undefined);
  const { syncToThirdParty } = useSyncToThirdParty();
  const createCaseFormik = useFormik({
    initialValues: createCaseInitialValues,
    onSubmit: () => {},
  });

  const coreCases = cases.filter((c) => c.source === CaseSource.Core);
  const showErrorNotification = (err: ApolloError) => {
    const apolloError = apolloErrorToString(err);
    const errorMessage = apolloError || 'There has been an error';
    showNotification(errorMessage, 'error');
  };

  const [createCase] = useCreateCaseMutation({
    onError: showErrorNotification,
    onCompleted: () => {
      setCurrentAction(undefined);
      refetchCases(customerId);
      onSuccessActions(`Successfully created a new case for ${customerId}`);
    },
  });

  const onSuccessActions = (message: string) => {
    showNotification(message, 'success');
  };

  const caseTypeOptions: CaseTypeOptions[] = [
    {
      label: 'Gen 2 Candid Pro Teen Case',
      value: CaseOptions.TeenGen2,
    },
    {
      label: 'Gen 2 Candid Pro Adult Case',
      value: CaseOptions.AdultGen2,
    },
  ];

  const handleClick = (action: CustomerAction) => {
    setCurrentAction(action);
    setShouldOpenDialog(true);
  };

  const handleConfirm = () => {
    switch (currentAction) {
      case CustomerAction.createNewCase: {
        const selectedOption = createCaseFormik.values.caseOptionType;

        const isGen2 = getIsGen2FromSelect(selectedOption);
        const caseType = getCaseTypeFromSelect(selectedOption);
        const requireOrthoReview =
          !isGen2 || createCaseFormik.values.requireOrthoReview;

        createCase({
          variables: {
            customerId,
            caseType,
            journey: CreationChannels.Studio,
            data: {
              isGen2: isGen2,
              requireOrthoReview: requireOrthoReview,
            },
          },
        });
        break;
      }
      case CustomerAction.syncToSalesforce: {
        showNotification(`Syncing customer to Salesforce`, 'message');
        syncToThirdParty({
          coreCases,
          thirdParty: ThirdPartiesAvailableForSyncing.Salesforce,
          customerId,
          onCompleted: () => {
            showNotification(
              `Successfully synced customer to Salesforce`,
              'success'
            );
          },
        });
        break;
      }
      case CustomerAction.syncToZendesk: {
        showNotification(`Syncing customer to Zendesk`, 'message');
        syncToThirdParty({
          coreCases,
          thirdParty: ThirdPartiesAvailableForSyncing.Zendesk,
          customerId,
          onCompleted: () => {
            showNotification(
              `Successfully synced customer to Zendesk`,
              'success'
            );
          },
        });
        break;
      }

      default: {
        break;
      }
    }

    setShouldOpenDialog(false);
    setTimeout(() => {
      setCurrentAction(undefined);
    }, 300);
  };

  const links = Object.keys(CustomerAction)
    .filter((ca) => {
      const hasActiveCase = cases?.some((caseItem) => caseItem.isActive);

      if (hideActions.includes(ca as CustomerAction)) {
        return false;
      }
      // Do not display Create New Case link if customer has active case or flag is off
      if (ca === CustomerAction.createNewCase && hasActiveCase) {
        return false;
      }

      return true;
    })
    .map((ca: string) => {
      return (
        <CustomerLink
          action={ca as CustomerAction}
          key={ca}
          onClick={() => handleClick(ca as CustomerAction)}
        >
          {customerDescriptions[ca as CustomerAction]}
        </CustomerLink>
      );
    });

  if (hideQuickActionsConditions.some((condition) => condition())) {
    return null;
  }

  return (
    <div>
      <Body>
        <Dropdown showCaret title={<CustomerLink>Quick actions</CustomerLink>}>
          {links}
        </Dropdown>
      </Body>
      <ConfirmDialog
        isOpen={shouldOpenDialog}
        onCancel={() => {
          setShouldOpenDialog(false);
          setTimeout(() => {
            setCurrentAction(undefined);
          }, 300);
        }}
        onConfirm={() => {
          handleConfirm();
        }}
        showCancelButton={!!currentAction}
        confirmButtonText={'Confirm'}
      >
        {renderCustomerBody(caseTypeOptions, createCaseFormik, currentAction)}
      </ConfirmDialog>
    </div>
  );
};

export default withNotifications(CustomerActionsComponent);
