import React from 'react';
import { Grid } from '@candidco/enamel';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  Container,
  CaseStatusContainer,
  ProviderFacingStatus,
  CaseNumber,
  InfoCardContainer,
} from 'pages/Patient/PatientDetail/CaseStatus/CaseStatus.css';

import Actions from 'pages/Patient/PatientDetail/CaseStatus/Actions';
import RefinementsRequestedAlertCard from 'components/AlertCards/RefinementsRequestedAlertCard';
import CaseCanceledDataAlertCard from 'components/AlertCards/CaseCanceledDataAlertCard';
import CaseCompletedDataAlertCard from 'components/AlertCards/CaseCanceledDataAlertCard';
import {
  getRefinementsRequestedAlertCardProps,
  getCaseCanceledDataAlertCardProps,
  getCaseCompletedDataAlertCardProps,
} from 'components/AlertCards/utils';

import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import {
  selectBypassOrthoReview,
  selectProviderFacingStatus,
  selectSelectedCase,
  selectSubsequentCase,
  reopenCase,
  fetchCases,
  selectLatestCase,
} from 'pages/Patient/patientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCardProps, InfoCardMapping } from 'pages/Patient/types';

import { usePatientLoadingStates } from 'pages/Patient/utils';
import { CaseStatusSkeleton } from 'pages/Patient/PatientDetail/Skeletons';
import { getTempProviderFacingStatus } from 'utils/case';

const statusToInfoCardMapping: InfoCardMapping = {
  [PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED]: {
    Card: RefinementsRequestedAlertCard,
    getProps: getRefinementsRequestedAlertCardProps,
  },
  [PROVIDER_FACING_STATUSES.CASE_CANCELED]: {
    Card: CaseCanceledDataAlertCard,
    getProps: getCaseCanceledDataAlertCardProps,
  },
  [PROVIDER_FACING_STATUSES.CASE_COMPLETED]: {
    Card: CaseCompletedDataAlertCard,
    getProps: getCaseCompletedDataAlertCardProps,
  },
};

const InfoCard = ({
  providerFacingStatus,
  subsequentCase,
  selectedCase,
  isLatestCase,
  reopenCase,
}: InfoCardProps) => {
  const { Card, getProps } =
    statusToInfoCardMapping[providerFacingStatus] || {};
  const dispatch = useDispatch();
  return Card ? (
    <InfoCardContainer>
      <Card
        {...getProps({
          selectedCase,
          subsequentCase,
        })}
        caseRef={selectedCase?.caseRef}
        reopenCase={() => {
          dispatch(reopenCase(selectedCase?.caseRef));
          dispatch(fetchCases({ patientIds: [selectedCase?.patientId] }));
        }}
        isLatestCase={isLatestCase}
      />
    </InfoCardContainer>
  ) : null;
};

const CaseStatus = () => {
  const { 'enable-refinement-policy': enableRefinementPolicy } = useFlags();

  const selectedCase = useSelector(selectSelectedCase);
  const subsequentCase = useSelector(selectSubsequentCase);
  const latestCase = useSelector(selectLatestCase);
  const bypassOrthoReview = useSelector(selectBypassOrthoReview);
  const providerFacingStatus = getTempProviderFacingStatus(
    useSelector(selectProviderFacingStatus) ?? '',
    enableRefinementPolicy
  );
  const { isFetchingPatient, isFetchingCases } = usePatientLoadingStates();

  const isLoading = isFetchingPatient || isFetchingCases;
  return (
    <Container data-testid="pdp-case-status">
      <CaseStatusContainer>
        {isLoading ? (
          <CaseStatusSkeleton />
        ) : (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item direction="column" alignItems="center">
              <CaseNumber>
                CASE #{selectedCase?.shortCaseRef}
                {bypassOrthoReview && ' • NO ORTHO REVIEW'}
              </CaseNumber>
              <ProviderFacingStatus>
                {providerFacingStatus}
              </ProviderFacingStatus>
            </Grid>
            <Actions
              providerFacingStatus={
                providerFacingStatus as PROVIDER_FACING_STATUSES
              }
              selectedCase={selectedCase}
              isLoading={isLoading}
            />
          </Grid>
        )}
        <InfoCard
          providerFacingStatus={
            providerFacingStatus as PROVIDER_FACING_STATUSES
          }
          subsequentCase={subsequentCase}
          selectedCase={selectedCase}
          isLatestCase={selectedCase?.caseRef === latestCase?.caseRef}
          reopenCase={reopenCase}
        />
      </CaseStatusContainer>
    </Container>
  );
};

export default CaseStatus;
