import React, { useState, useCallback, useEffect } from 'react';
import { Practice } from 'generated/legacy/graphql';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchThinPractices,
  selectAllPractices,
} from 'pages/PracticeManagement/shared/slice';
import DocumentTitle from 'components/DocumentTitle';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components/macro';
import { FormikInputWithTrailingIcon } from 'components/FormikForms';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';

const Heading = styled.h2`
  margin-bottom: 1.5rem;
`;

const Container = styled.div`
  padding: 3rem;
  width: calc(400px + 6rem);
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;
  max-height: 300px;
  overflow-y: scroll;
`;

const Result = styled(Link)`
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.text90};
  border-radius: 5px;
`;

const PracticeSearchBar = styled(FormikInputWithTrailingIcon)`
  width: 400px;
  max-width: calc(100vw - 6rem);
  input {
    padding-left: 1rem;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.colors.text40};
`;

const PracticeSearchResults = (props: { practices: Practice[] }) => {
  const { practices } = props;
  return practices.length ? (
    <ResultsContainer>
      {practices.map((practice) => {
        return (
          <Result
            key={practice.id}
            to={{ pathname: `/management/practice/${practice.id}` }}
          >
            {practice.name}
          </Result>
        );
      })}
    </ResultsContainer>
  ) : null;
};

const PracticeSearch = () => {
  const dispatch = useDispatch();
  const allPractices = useSelector(selectAllPractices);
  const [displayedPractices, setDisplayedPractices] = useState<Practice[]>([]);
  const [searchInputText, setSearchInputText] = useState<string>('');
  const [showResults, setShowResults] = useState<boolean>(false);
  const setSearchTextDebounced = useCallback(
    debounce((text) => {
      const doSearch = text.length >= 3;
      if (doSearch) {
        dispatch(fetchThinPractices({ name: text }));
      } else {
        setDisplayedPractices([]);
      }
      setShowResults(doSearch);
    }, 300),
    []
  );

  useEffect(() => {
    setDisplayedPractices(showResults ? allPractices : []);
  }, [allPractices]);

  return (
    <DocumentTitle title="Practice Management">
      <Container>
        <Heading>Practice management</Heading>
        <Formik initialValues={{ name: '' }} onSubmit={() => {}}>
          <PracticeSearchBar
            type="text"
            placeholder={'Search for a practice'}
            trailingIcon={<StyledSearchIcon />}
            name="name"
            testId="customer-search-input"
            onChange={(e) => {
              setSearchInputText(e.target.value);
              setSearchTextDebounced(e.target.value);
            }}
            value={searchInputText}
          />
        </Formik>
        <PracticeSearchResults practices={displayedPractices} />
      </Container>
    </DocumentTitle>
  );
};

export default PracticeSearch;
