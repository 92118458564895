import React from 'react';

import { CaseTypeNames } from 'constants/Case';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import { ACTIONS } from 'constants/caseStatus';

import MonitoringSvg from 'assets/ic_eye.svg?react';
import SupportSvg from 'assets/ic_support.svg?react';
import ViewPrintTreatmentPlanPdfSvg from 'assets/ic_file.svg?react';
import ArchiveSvg from 'assets/ic_archive.svg?react';
import MarkDeliveredSvg from 'assets/ic_package.svg?react';
import RequestAdditionalAlignersSvg from 'assets/ic_plus.svg?react';
import ViewOrdersAndShippingSvg from 'assets/ic_tag.svg?react';
import TrackShipmentSvg from 'assets/ic_truck.svg?react';
import OrderRetainerSvg from 'assets/ic-pause-circle.svg?react';
import OrderReplacementSvg from 'assets/ic_refresh-ccw.svg?react';

interface ActionConfig {
  [key: string]: {
    allowedCaseTypes: string[];
    allowedStatuses: PROVIDER_FACING_STATUSES[];
    icon: React.ReactNode;
  };
}

//Generic information about how each of the actions displays.
//Order given is how they're displayed in the UI
export const actionConfigs: ActionConfig = {
  [ACTIONS.ARCHIVE_CASE]: {
    allowedCaseTypes: [
      CaseTypeNames.ALIGNER,
      CaseTypeNames.ADDITIONAL_ALIGNER,
      CaseTypeNames.RETAINER,
    ],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION,
      PROVIDER_FACING_STATUSES.MATERIALS_UNDER_REVIEW,
      PROVIDER_FACING_STATUSES.ACTION_NEEDED,
      PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS,
      PROVIDER_FACING_STATUSES.TREATMENT_PLAN_READY_FOR_YOUR_REVIEW,
    ],
    icon: <ArchiveSvg />,
  },
  [ACTIONS.VIEW_PRINT_TREATMENT_PLAN_PDF]: {
    allowedCaseTypes: [CaseTypeNames.ALIGNER, CaseTypeNames.ADDITIONAL_ALIGNER],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
      PROVIDER_FACING_STATUSES.IN_TREATMENT,
    ],
    icon: <ViewPrintTreatmentPlanPdfSvg />,
  },
  [ACTIONS.TRACK_SHIPMENT]: {
    allowedCaseTypes: [CaseTypeNames.ALIGNER, CaseTypeNames.ADDITIONAL_ALIGNER],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
    ],
    icon: <TrackShipmentSvg />,
  },
  [ACTIONS.MARK_DELIVERED]: {
    allowedCaseTypes: [CaseTypeNames.ALIGNER, CaseTypeNames.ADDITIONAL_ALIGNER],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
    ],
    icon: <MarkDeliveredSvg />,
  },
  [ACTIONS.VIEW_IN_CANDID_MONITORING]: {
    allowedCaseTypes: [CaseTypeNames.ALIGNER, CaseTypeNames.ADDITIONAL_ALIGNER],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.IN_TREATMENT,
      PROVIDER_FACING_STATUSES.IN_RETENTION,
      PROVIDER_FACING_STATUSES.CASE_COMPLETED,
      PROVIDER_FACING_STATUSES.TREATMENT_COMPLETED,
      PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
    ],
    icon: <MonitoringSvg />,
  },
  [ACTIONS.ORDER_RETAINERS]: {
    allowedCaseTypes: [CaseTypeNames.ALIGNER, CaseTypeNames.ADDITIONAL_ALIGNER],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.IN_TREATMENT,
      PROVIDER_FACING_STATUSES.CASE_COMPLETED,
      PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
    ],
    icon: <OrderRetainerSvg />,
  },
  [ACTIONS.ORDER_REPLACEMENT]: {
    allowedCaseTypes: [CaseTypeNames.ALIGNER, CaseTypeNames.ADDITIONAL_ALIGNER],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.IN_TREATMENT,
      PROVIDER_FACING_STATUSES.CASE_COMPLETED,
      PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
    ],
    icon: <OrderReplacementSvg />,
  },
  [ACTIONS.REQUEST_REFINEMENTS]: {
    allowedCaseTypes: [CaseTypeNames.ALIGNER, CaseTypeNames.ADDITIONAL_ALIGNER],
    allowedStatuses: [PROVIDER_FACING_STATUSES.IN_TREATMENT],
    icon: <RequestAdditionalAlignersSvg />,
  },
  [ACTIONS.VIEW_ORDERS_AND_SHIPPING]: {
    allowedCaseTypes: [
      CaseTypeNames.ALIGNER,
      CaseTypeNames.RETAINER,
      CaseTypeNames.ADDITIONAL_ALIGNER,
    ],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
      PROVIDER_FACING_STATUSES.IN_TREATMENT,
      PROVIDER_FACING_STATUSES.IN_RETENTION,
      PROVIDER_FACING_STATUSES.CASE_COMPLETED,
      PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE,
      PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
    ],
    icon: <ViewOrdersAndShippingSvg />,
  },
  [ACTIONS.CONTACT_SUPPORT]: {
    allowedCaseTypes: [
      CaseTypeNames.ALIGNER,
      CaseTypeNames.RETAINER,
      CaseTypeNames.ADDITIONAL_ALIGNER,
    ],
    allowedStatuses: [
      PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION,
      PROVIDER_FACING_STATUSES.MATERIALS_UNDER_REVIEW,
      PROVIDER_FACING_STATUSES.ACTION_NEEDED,
      PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS,
      PROVIDER_FACING_STATUSES.TREATMENT_PLAN_READY_FOR_YOUR_REVIEW,
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
      PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
      PROVIDER_FACING_STATUSES.IN_TREATMENT,
      PROVIDER_FACING_STATUSES.IN_RETENTION,
      PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
      PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE,
      PROVIDER_FACING_STATUSES.TREATMENT_COMPLETED,
      PROVIDER_FACING_STATUSES.CASE_COMPLETED,
      PROVIDER_FACING_STATUSES.CASE_EXPIRED,
      PROVIDER_FACING_STATUSES.CASE_CANCELED,
    ],
    icon: <SupportSvg />,
  },
};
